import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import * as React from 'react';

export default function BasicPagination({ ...props }) {

  return (
    props?.count>1?
    <div className="pagination">
      <Stack spacing={2}>
        <Pagination className="mypagination" {...props} />
      </Stack>
    </div>:null
  );
}
