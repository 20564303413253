import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import * as React from 'react';

export default function SectionSkeleton() {
  return (
      <div className='skeleton'>
    <Stack spacing={1}>
      <Skeleton className="headingskeleton"/>
      <div className='gridskeleton'>
          <div className='loopskeleton'>
            <Skeleton variant="rectangular" className='imgcard' width='100%' height='250px' />
            <Skeleton variant="text" />
            <Skeleton width="40%" />
        </div>
        <div className='loopskeleton'>
            <Skeleton variant="rectangular" className='imgcard' width='100%' height='250px' />
            <Skeleton variant="text" />
            <Skeleton width="40%" />
        </div>
        <div className='loopskeleton'>
            <Skeleton variant="rectangular" className='imgcard' width='100%' height='250px' />
            <Skeleton variant="text" />
            <Skeleton width="40%" />
        </div>
        <div className='loopskeleton'>
            <Skeleton variant="rectangular" className='imgcard' width='100%' height='250px' />
            <Skeleton variant="text" />
            <Skeleton width="40%" />
        </div>
      </div>
    </Stack> 
    </div>
  );
}