import { Button } from '@mui/material';
import React from 'react';
import { SpinnerCircularFixed } from 'spinners-react';

const LightBtn = ({ title, style, onClick, type, loading, disabled }) => {
  return (
    <>
      <Button
        className="buy-btn lightbtn"
        style={style}
        onClick={onClick}
        type={type}
        disabled={disabled ? disabled : loading ? true : false}
      >
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SpinnerCircularFixed size={15} color="#fff" />{' '}
            <span
              style={{
                marginLeft: '5px',
              }}
            >
              {' '}
              Loading...
            </span>
          </div>
        ) : (
          title
        )}
      </Button>
    </>
  );
};

export default LightBtn;
