import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { baseUrl, notify } from '../../../core/constants';
import { updateGuestUser } from '../../../redux/actions/guestUser';
import { addLogin } from '../../../redux/actions/login';
import LightBtn from '../../universal/Buttons/LightBtn';
import InputField from '../../universal/InputField';
export default function ConfirmRegistrationDialog({
  status,
  userDatahas,
  setState,
  type,
  query,
}) {
  const [open, setOpen] = React.useState(status);
  const [loading, setloading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    return setState(false);
  };
  // formik start
  const initialValues = {
    otp: '',
  };
  const validationSchema = Yup.object({
    otp: Yup.string().required('Please enter OTP'),
  });
  const onSubmit = (values) => {
    setloading(true);
    var axios = require('axios');
    var data = JSON.stringify({
      token: values?.otp,
    });

    var config = {
      method: 'post',
      url: `${baseUrl}user/email-verification`,
      headers: {
        userToken: userDatahas?.userToken,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode == '102') {
          notify(response.data?.message, 'error');
          formik.handleReset();
        } else {
          notify(response.data?.message, 'success');
          let userDetails = {
            token: userDatahas.userToken,
            user: userDatahas.user,
          };
          dispatch(addLogin(userDetails));
          let user = userDatahas?.user;
          dispatch(
            updateGuestUser({
              user: user,
              userToken: userDetails.token,
            })
          );
          if (type == 2) {
            navigate(
              {
                pathname: '/checkout',
                search: query,
              },
              { replace: true }
            );
          }
          handleClose();
        }
        setloading(false);
      })
      .catch(function (error) {
        console.log(error);
        setloading(false);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        onBackdropClick={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="confirmregistration"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <div className="register-heading ">
                Please enter the code we sent to your email address
              </div>
              <form onSubmit={formik.handleSubmit}>
                <InputField
                  placeholder="Verification code"
                  name="otp"
                  id="otp"
                  type="text"
                  autocomplete="one-time-code"
                  onBlur={formik.handleBlur}
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  error={formik.errors.otp}
                />
                <div className="btn-section">
                  <LightBtn
                    type="submit"
                    title={loading ? 'Loading...' : 'Submit'}
                  />
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
