import { Grid } from '@mui/material';

const RightCategory = ({ subMenu, extraContent, topPicksContent }) => {
  return (
    <>
      <div className="subnav-content">
        <Grid container spacing={1} className="mobileset-left">
          <Grid item xs={1} className="main_left_menu width49-left-mobile">
            <div>
              <div className="width20-right-mobile">
                <div className="top-heading1 mobilehead">
                  {extraContent?.extraInfoTitle}
                </div>
                <div className="ng-star-inserted">
                  <img
                    loading="lazy"
                    alt="extraInfoImage"
                    className="preloved-img"
                    src={extraContent?.extraInfoImage}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} className="width100-left-mobile bor-pad">
            <div className="top-heading">Top Picks</div>
            <div fxlayout="column">
              <div className="displayset-mobile">
                {topPicksContent?.map((item, index) => (
                  <div
                    onClick={() => {
                      window.open(
                        item?.headerMenuSlug?`${item?.headerMenuSlug}/${item?.productSlug}`:
                        
                        `/${item?.tagsAttached[0]?.middleTagName?item?.tagsAttached[0]?.middleTagName
                          ?.replace(/\s/g, '-'):item?.tagsAttached[0]?.parentName?.replace(/\s/g, '-')
                          ?.toLowerCase()}/${item?.tagsAttached[0]?.tagName
                          ?.replace(/\s/g, '-')
                          ?.toLowerCase()}/${item?.productSlug
                          ?.replace(/\s/g, '-')
                          ?.toLowerCase()}`,
                        '_blank'
                      );
                    }}
                    key={index}
                    className="image-container"
                  >
                    {item?.images.map((img, imgIndex) => {
                      if (img.isCover === 1) {
                        return (
                          <img
                            loading="lazy"
                            alt={item?.name}
                            key={imgIndex}
                            className="img-row"
                            src={img.image}
                          />
                        );
                      }
                    })}

                    <div className="product-name">{item?.name}</div>
                    <div className="product-price">£ {item?.price}</div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={5}
            className="main_left_menu width49-left-mobile desktop-grid"
          >
            {subMenu?.map((item, index) => (
              <div key={index} className="desktoprow">
                <div
                  className="top-heading1"
                  onClick={() => {
                   
                    window.open(
                      item?.navigationPath?.toLowerCase(),
                      '_blank'
                    );
                  }}
                >
                  {item?.name}
                </div>
                {item?.headerSubMenuChilds?.map((subItem, subIndex) => (
                  <div
                    key={subIndex}
                    className="option-heading "
                    onClick={() => {
                     
                      window.open(
                        subItem?.navigationPath?subItem?.navigationPath.toLowerCase(): `/${subItem?.uniqueId}`,
                        '_blank'
                      );
                    }}
                  >
                    {subItem.subMenuChildName}
                  </div>
                ))}
              </div>
            ))}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RightCategory;
