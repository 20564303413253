import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { images } from '../../../assets/_index';
import '../../../scss/universal/_latestArrivals.scss';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import MainHeading from '../../universal/Heading/MainHeading';
import { CrawerLink } from '../CrawlerLink';
import ProductSlider from '../ProductSlider';

const LatestArrivals = ({ CustomData, type, mainID }) => {
  const navigate = useNavigate();

  const filter = {
    tags: '',
    feedtype: 0,
    metadatas: '',
    sortbyprice: '',
    startprice: 0,
    endprice: 1000,
    search: '',
    isFreeDelivery: 0,
    discountSale: 0,
    isSellerFilter: 0,
    sellers: 0,
    isEthicalValueFilter: 0,
    ethicalValues: 0,
    customHomeFilterType: '',
    customHomeFilterTypeId: '',
    customHeaderLinkFilterType: 0,
    customHeaderLinkFilterTypeId: 0,
    customHeaderLinkFilterTypeAll: 0,
    customButtonLinkFilterType: 0,
    customButtonLinkFilterTypeId: 0,
    per_page_limit: 24,
  };

  const title = CustomData?.title.replace(/\s/g, '-').toLowerCase();

  return (
    <>
      <div className="latestArrivals">
        {CustomData.path ? (
          <CrawerLink path={`/store/${CustomData.path}`} />
        ) : (
          <CrawerLink path={`/${CustomData?.linkSlug?CustomData?.linkSlug:title}`} />
        )}
        <div className="latest-section mobilebg desktopbg">
          <Container className="all-screen-container">
            <Grid container spacing={2} className="con-responsive-wm">
              <Grid item xs={12} sm={12} md={4}>
                <img
                  loading="lazy"
                  alt={CustomData?.title}
                  title={CustomData?.title}
                  src={images.sellerOther}
                  className="saller-img mobilehide"
                />
                 <div className='buttontext_section'>
                  <div> <MainHeading title={CustomData?.title} />
                <p className="latest-para pera-text18 mobilehide">
                  {CustomData?.description}{' '}
                </p></div>
               
                <div className="innerpages">
                  <DarkBtn
                    onClick={() =>
                      CustomData.path
                        ? navigate(`/store/${CustomData.path}`)
                        : navigate({
                            pathname: `/${CustomData?.linkSlug?CustomData?.linkSlug:title}`,
                          })
                    }
                    title={
                      CustomData?.buttonLabel
                        ? CustomData?.buttonLabel
                        : 'Browse New Products'
                    }
                  />
                </div>
                </div>
                <p className="latest-para pera-text18 mobileshow paddleft16">
                  {CustomData?.description}{' '}
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={8} className="hidedot ls97inch">
                {type == 'basket'
                  ? CustomData?.products?.data?.length > 0 && (
                      <ProductSlider
                        latest
                        Data={CustomData?.products?.data}
                        mainID={mainID}
                      />
                    )
                  : CustomData?.products?.length > 0 && (
                      <ProductSlider
                        latest
                        Data={CustomData?.products}
                        mainID={mainID}
                      />
                    )}
              </Grid>
            </Grid>
            <div className="mobileshow textcenter homepagesbtn">
              <DarkBtn
                onClick={() =>
                  CustomData.path
                    ? navigate(`/store/${CustomData.path}`)
                    : navigate({
                        pathname: `/${CustomData?.linkSlug?CustomData?.linkSlug:title}`,
                      })
                }
                title={
                  CustomData?.buttonLabel
                    ? CustomData?.buttonLabel
                    : 'Browse New Products'
                }
              />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default LatestArrivals;
