import { DELETE_ITEM, SET_ITEM, UPDATE_ITEM } from '../constant';
// Action CRUD for CART information 
export const addCartItem = (cartdata) => {
    return ({ type: SET_ITEM, data: cartdata });
}
export const deleteCartItem = () => {
    return ({ type: DELETE_ITEM });
};

export const updateCartItem = (cartdata) => {
    return ({ type: UPDATE_ITEM, data: cartdata });
};

