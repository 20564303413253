import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import DarkBtn from "../../../../components/universal/Buttons/DarkBtn";
import CheckoutField from "../../../../components/universal/InputField/CheckoutField";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ConfirmRegistrationDialog from "../../../../components/dialogs/ConfirmRegistration";
import ResendPasswordDialog from "../../../../components/dialogs/ResendPassword";
import { baseUrl, notify } from "../../../../core/constants";
import { updateGuestUser } from "../../../../redux/actions/guestUser";
import { addLogin } from "../../../../redux/actions/login";
import AxiosAll from "../../../../services/axiosAll";

const CheckoutPassword = () => {
  const [open, setOpen] = React.useState(false);
  const [emailstate, setEmailstate] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const mail = params.get("email");
  const cart_id = params.get("cart_id");
  const isSubscription = params.get("isSubscription");
  const [state, setstate] = React.useState(false);
  const nav = useNavigate();

  const [tempToken, settempToken] = React.useState("");
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const voucher = searchParams.get("voucher");

  React.useEffect(() => {
    if (
      !userDetails?.token &&
      location.pathname === "/is_reedem_gift_voucher" &&
      voucher
    ) {
      setOpen(true);
    }
  }, []);

  React.useEffect(() => {
    if (!userDetails?.token && location.pathname === "/signin") {
      setOpen(true);
    }
  }, []);

  React.useEffect(() => {
    formik.setFieldValue("email", mail);
  }, [search]);
  // formik start
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("E-mail address is required.")
      .email("Enter valid email address"),
    password: Yup.string().required("Password is required."),
  });

  const onSubmit = (values) => {
    var data = {
      email: values.email,
      password: values.password,
      deviceToken: guestDetails.token,
      deviceType: 3,
      guest_user_id: userData?.user?.id,
    };
    login(data);
  };
  const login = async (data) => {
    try {
      const response = await axios(`${baseUrl}user/login`, {
        method: "post",
        data: data,
        headers: {
          "Content-Type": "application/json",
          userToken: userData?.userToken,
        },
      });

      if (response.data.status === "Success") {
        notify(response.data.message, "success");
        if (response?.data?.result?.user?.isVerified == 0) {
          setEmailstate(true);
          reSendMail(data.email);
          settempToken({ userToken: response?.data?.result?.userToken });
        } else {
          let userDetails = {
            token: response?.data?.result?.userToken,
            user: response?.data?.result?.user,
          };
          dispatch(addLogin(userDetails));
          let user = response?.data?.result?.user;
          dispatch(
            updateGuestUser({
              user: user,
              userToken: userDetails.token,
            })
          );
          //   if (isSubscription) {
          //     nav(`/checkout?cart_id=${cart_id}&isSubscription=1`);
          //   } else {
          //     nav(cart_id ? `/basket/${cart_id}` : `/basket`);
          //   }
          // } else {
          //   notify(response.data.message, 'error');
          // }
          if (cart_id) {
            if (isSubscription) {
              nav(`/checkout?cart_id=${cart_id}&isSubscription=1`, { replace: true });
            } else {
              nav(`/checkout?cart_id=${cart_id}&skip=true`, { replace: true });
            }
          } else {
            nav("/checkout", { replace: true });
          }
        }
      } else {
        notify(response.data.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const reSendMail = async (data) => {
    try {
      const res = await AxiosAll("post", `user/resend-link?`, { email: data });
      notify(res.data.message, "success");
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="mobilecheckoutpassword">
      <div className="email-text">What’s your password?</div>
      <div className="continuing-text">
        {mail}
        <span
          onClick={() => {
            nav(
              cart_id
                ? isSubscription
                  ? `/checkout-email?cart_id=${cart_id}&isSubscription=1`
                  : `/checkout-email?cart_id=${cart_id}&skip=true`
                : "/checkout-email"
            );
          }}
        >
          {" "}
          Edit
        </span>{" "}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <CheckoutField
          password={true}
          name="password"
          id="password"
          outocomplete="current-password"
          type={isPasswordShow ? "password" : "text"}
          placeholder="Enter your password"
          title="Password"
          onBlur={formik.handleBlur}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
          isPasswordShow={isPasswordShow}
          setIsPasswordShow={setIsPasswordShow}
        />

        <div className="forgot-text">
          {" "}
          <ResendPasswordDialog msg="Forgotten your password?" />
        </div>
        <div className="btn-section-new">
          <DarkBtn type="submit" title={Loading ? "Loading..." : "Login"} />
          {/* <DarkBtn type="submit" title={Loading ? 'Loading...' : 'Next'} /> */}
        </div>
      </form>
      {emailstate ? (
        <ConfirmRegistrationDialog
          type={2}
          query={
            cart_id
              ? isSubscription
                ? `?cart_id=${cart_id}&isSubscription=1`
                : `?cart_id=${cart_id}&skip=true`
              : ""
          }
          userDatahas={tempToken}
          status={emailstate}
          setState={setEmailstate}
        />
      ) : null}
    </div>
  );
};

export default CheckoutPassword;
