import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { images } from '../../../assets/_index';
import AxiosAll from '../../../services/axiosAll';
import Feedback from '../Feedback';

const Footer = () => {
  const [footerData, setFooterData] = useState([]);
  const [socialLinks, setsocialLinks] = useState({});
  const location = useLocation();

  // Api call for Footer
  const footerApi = async () => {
    try {
      const res = await AxiosAll('get', `footer?`);
      const data = res?.data?.result[0].content?.default.menu;
      setFooterData(data);
      setsocialLinks(res?.data?.result[0].content?.default?.socialLink);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    footerApi();
  }, []);

  return (
    <>
      <div className="footer mobilehide">
        <div className="mobilehide">
          <div className=" footer1">
            <Grid container spacing={2}>
              {footerData.map((e, index) => {
                return (
                  <Grid key={index} item xs={2.75}>
                    <ul>
                      <div className="footer-heading">{e?.mainMenu?.name}</div>
                      <ul>
                        {e?.subMenu?.map((data, i) => {
                          return (
                            <li
                              onClick={() => window.open(data?.url, '_Self')}
                              key={i}
                            >
                              {data?.name}
                            </li>
                          );
                        })}
                      </ul>
                    </ul>
                  </Grid>
                );
              })}

              <Grid item xs={1} className="feed-back">
                <Feedback />
              </Grid>
            </Grid>
          </div>
          <div className=" footer2">
            <Grid container spacing={2} className="last-footer">
              <Grid item xs={10}>
                <div className="copyright-text">
                  © GoEthical 2022 <span className="copyright-para"></span>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="rightalign">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={socialLinks?.facebook}
                  >
                    {' '}
                    <img
                      loading="lazy"
                      src={images.facebook}
                      alt="facebook"
                      title="facebook"
                      className="facebook-icon"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={socialLinks?.twitter}
                  >
                    {' '}
                    <img
                      loading="lazy"
                      src={images.twitter}
                      alt="twitter"
                      title="twitter"
                      className="facebook-icon"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={socialLinks?.instagram}
                  >
                    {' '}
                    <img
                      loading="lazy"
                      src={images.instagram}
                      alt="instagram"
                      title="instagram"
                      className="facebook-icon"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={socialLinks?.linkedIn}
                  >
                    {' '}
                    <img
                      loading="lazy"
                      src={images.linkedin}
                      alt="linkedin"
                      title="linkedin"
                      className="facebook-icon"
                    />
                  </a>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {location.pathname.includes('checkout') ||
      location.pathname.includes('basket') ? null : (
        <div className="footer mobileshow">
          <div className="mobileshow">
            <div className="heading-root">
              {footerData.map((e, index) => {
                return (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id={index}
                    >
                      <Typography className="footer-heading">
                        {e?.mainMenu?.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <ul>
                          {e?.subMenu?.map((data, i) => {
                            return (
                              <li
                                onClick={() => window.open(data?.url, '_blank')}
                                key={i}
                              >
                                {data?.name}
                              </li>
                            );
                          })}
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>

            <div className="follow-section">
              <div className="social_media">
                <div className="footer-heading"> Follow us</div>
                <div className="social_icon">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={socialLinks?.facebook}
                  >
                    {' '}
                    <img
                      loading="lazy"
                      src={images.facebook}
                      alt="facebook"
                      title="facebook"
                      className="facebook-icon"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={socialLinks?.twitter}
                  >
                    {' '}
                    <img
                      loading="lazy"
                      src={images.twitter}
                      alt="twitter"
                      title="twitter"
                      className="facebook-icon"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={socialLinks?.instagram}
                  >
                    {' '}
                    <img
                      loading="lazy"
                      src={images.instagram}
                      alt="instagram"
                      title="instagram"
                      className="facebook-icon"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={socialLinks?.linkedIn}
                  >
                    {' '}
                    <img
                      loading="lazy"
                      src={images.linkedin}
                      alt="linkedin"
                      title="linkedin"
                      className="facebook-icon"
                    />
                  </a>
                </div>
              </div>
              <Feedback />
            </div>
            <div className="last-footer">
              <div className="copyright-text">
                <span className="copyright_content">
                  © GoEthical 2022 <span className="desktop_footer"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
