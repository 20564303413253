export const images = {
  mainLogo: require('../assets/images/header/goethical_logo.webp'),
  mainLogoinner: require('../assets/images/header/m_nav_logo.png'),
  facebook: require('../assets/images/Facebook.webp'),
  instagram: require('../assets/images/Instagram.webp'),
  linkedin: require('../assets/images/Linkedin.webp'),
  twitter: require('../assets/images/Twitter.webp'),
  smilegray: require('../assets/images/footer/smile.png'),
  mehgray: require('../assets/images/footer/meh.png'),
  frowngray: require('../assets/images/footer/frown.png'),
  smilewhite: require('../assets/images/footer/white/smile.png'),
  mehwhite: require('../assets/images/footer/white/meh.png'),
  frownwhite: require('../assets/images/footer/white/frown.png'),
  happyEmoji: require('../assets/images/happy.webp'),
  sadEmoji: require('../assets/images/sad.webp'),
  faceEmoji: require('../assets/images/emoji.webp'),
  searchIcon: require('../assets/images/header/search-icon.webp'),
  userIcon: require('../assets/images/header/profile-icon.webp'),
  userMobileIcon: require('../assets/images/header/mobileprofile-icon.png'),
  basketIcon: require('../assets/images/header/card-icon.webp'),
  basketIcon2: require('../assets/images/header/basket-image.webp'),
   trustpilotLogo: require('../assets/images/homePage/trustpilot-icon.webp'),
  ethicalIcon: require('../assets/images/ethicalicon.webp'),
  ethicalBackground: require('../assets/images/home/ethicalbackground.webp'),
  menuIcon: require('../assets/images/homePage/menu-icon.webp'),
  messageicon: require('../assets/images/message-icon.webp'),
  usericon: require('../assets/images/user-1.webp'),
  plus: require('../assets/images/plus.webp'),
  basket: require('../assets/images/basket.webp'),
  createAccount: require('../assets/images/Create-image.webp'),
  errowDown: require('../assets/images/arrow-down-iconnew.webp'),
  card: require('../assets/images/customer/Card.webp'),
  edit: require('../assets/images/customer/edit-icon.webp'),
  referAFriend: require('../assets/images/header/refer-a-friend.webp'),
  treegif: require('../assets/images/customer/Tree-gif.gif'),
  customerBasket: require('../assets/images/customer/customerbasket.webp'),
  reviewUser: require('../assets/images/user-new.webp'),
  sellerOther: require('../assets/images/we-are-open-image.webp'),
  shareIcon: require('../assets/images/share-more-icon.webp'),
  facebook1: require('../assets/images/sellerSide/blackfacebook.webp'),
  instagram1: require('../assets/images/sellerSide/instagram.webp'),
  linkedin1: require('../assets/images/sellerSide/linkedin.webp'),
  twitter1: require('../assets/images/sellerSide/twitter.webp'),
  visa: require('../assets/images/card/visa.webp'),
  mastercard: require('../assets/images/card/mastercard.webp'),
  amex: require('../assets/images/card/amex.webp'),
  discover: require('../assets/images/card/discover.webp'),
  jcb: require('../assets/images/card/jcb.webp'),
  dinner_club: require('../assets/images/card/dinner-club.webp'),
  union_pay: require('../assets/images/card/union-pay.webp'),
  shopByValeCardImg: require('../assets/images/home/shopbyvaluecard.webp'),
  bannerONError: require('../assets/images/banner.webp'),
 testBannerImg: require('../assets/images/1652876382657313153.png'),
 bannerbgImg: require('../assets/images/homePage/image-layer-mobile.png'),
 testpro: require('../assets/images/16548625601464648282.webp'),
 cardIcon: require('../assets/images/cardicon.png'),
 Newcard1: require('../assets/images/home/image-1.png'),
 mobileLineBg: require('../assets/images/home/linebg.png'),
 LineBgFeatured : require('../assets/images/home/linebgblog.png'),
 mobileLineBgBlog : require('../assets/images/home/linebg2.png'),
};
