import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {  Grid } from '@mui/material';

export default function ReviewSkeleton() {
  return (
    <div className='reviewskeleton'>
     
        <Skeleton variant="text" className="heading" />
        <Stack spacing={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Skeleton variant="rectangular" className="reviewbox" />
            </Grid>
            <Grid item xs={12} sm={8}>
              <div className='saller-stock'>
                <Skeleton variant="circular" className="usericon" />
                <div className='name-datetime'>
                <Skeleton variant="text" className="usrename" />
                <Skeleton variant="text" className="datetime" />
                </div>
                
              </div>
              <div className='productname-img'>
                  <Skeleton variant="text" className="productimg" />
                  <Skeleton variant="text" className="productname" />
              </div>
            </Grid>
          </Grid>
        </Stack>
     
    </div>
  );
}