import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';

export default function AlertDialog({ images, open, setOpen }) {
  const [imageGalleryItems, setImageGalleryItems] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    images.map((image) => {
      const imageGalleryItem = {
        original: image.image,
        thumbnail: image.image,
      };
      setImageGalleryItems((prevState) => [...prevState, imageGalleryItem]);
    });
  }, []);

  return (
    <div className="ConfirmYourAge">
      <div onClick={handleClickOpen} className="img-number">
        {' '}
        +{imageGalleryItems.length - 4}{' '}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="imagegallery"
      >
        <DialogContent>
          {/* <div>
            <img alt="pluse" src={images.closeIcon} className="plus-img" />
            </div> */}
          <ImageGallery
            items={imageGalleryItems}
            showPlayButton={false}
            showIndex={false}
            originalHeight={600}
            originalWidth={1000}
            thumbnailHeight={150}
            thumbnailWidth={250}
            infinite={false}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
