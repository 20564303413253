import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { images } from '../../../assets/_index';
import { baseUrl, notify } from '../../../core/constants';
import { updateGuestUser } from '../../../redux/actions/guestUser';
import { addLogin } from '../../../redux/actions/login';
import AxiosAll from '../../../services/axiosAll';
import Register from '../../dialogs/Register';
import ResendPassword from '../../dialogs/ResendPassword';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import InputField from '../../universal/InputField';
import SocialLogin from '../../universal/SocialLogin';
import ConfirmRegistrationDialog from '../ConfirmRegistration';

export default function AlertDialog({ setIsRegisterOpen }) {
  const [open, setOpen] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const [state, setstate] = React.useState(false);
  const nav = useNavigate();
  const [tempToken, settempToken] = React.useState('');
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const voucher = searchParams.get('voucher');

  React.useEffect(() => {
    if (
      !userDetails?.token &&
      location.pathname === '/is_reedem_gift_voucher' &&
      voucher
    ) {
      setOpen(true);
    }
  }, []);

  React.useEffect(() => {
    if (!userDetails?.token && location.pathname === '/signin') {
      setOpen(true);
    }
  }, []);

  // formik start
  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('E-mail address is required.')
      .email('Enter valid email address'),
    password: Yup.string().required('Password is required.'),
  });

  const onSubmit = (values) => {
    setLoading(true);
    var axios = require('axios');
    var data = JSON.stringify({
      email: values.email,
      password: values.password,
      deviceToken: guestDetails.token,
      deviceType: 3,
      guest_user_id: userData?.user?.id,
    });

    var config = {
      method: 'post',
      url: `${baseUrl}user/login`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.statusCode == '102') {
          notify(response.data.message, 'error');
        } else if (response?.data?.result?.user?.isVerified) {
          notify(response.data.message, 'success');
          formik.handleReset();
          handleClose();
          let userDetails = {
            token: response?.data?.result?.userToken,
            user: response?.data?.result?.user,
          };
          dispatch(addLogin(userDetails));
          let user = response?.data?.result?.user;
          dispatch(
            updateGuestUser({
              user: user,
              userToken: userDetails.token,
            })
          );
          if (window.location.pathname.includes('confirm-order')) {
            nav('/');
          }
          if (location.pathname.includes('signin')) {
            nav('/');
          }
        } else {
          reSendMail(values.email);
          setstate(true);
          settempToken({ userToken: response?.data?.result?.userToken });
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const reSendMail = async (data) => {
    try {
      const res = await AxiosAll('post', `user/resend-link?`, { email: data });
      notify(res.data.message, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const handleClickOpen = () => {
    formik.handleReset();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="signin">
        <Button type="button" className="sign-in" onClick={handleClickOpen}>
          <span className="mobilehide tabshow signintext">Sign in</span>
          <img
            loading="lazy"
            alt="Profile image"
            title="Sign in"
            src={images.userIcon}
            className="profile mobilehide tabshow "
          />
          <img
            loading="lazy"
            alt="Profile image"
            title="Sign in"
            src={images.userMobileIcon}
            className="profile mobileshow tabhide"
          />
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="signindialogs"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="button-section-row">
                <div className="sign-heading">Sign in</div>
                <div>
                  <LightBtn
                    title="Register"
                    onClick={() => {
                      setOpen(false);
                      setIsRegisterOpen(true);
                    }}
                  />
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <InputField
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  autocomplete="email"
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                />
                <div className="password">
                  <InputField
                    password
                    name="password"
                    id="password"
                    outocomplete="current-password"
                    type={isPasswordShow ? 'password' : 'text'}
                    placeholder="Password"
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.errors.password}
                    isPasswordShow={isPasswordShow}
                    setIsPasswordShow={setIsPasswordShow}
                  />
                </div>

                <ResendPassword />
                <div className="login-btn-section">
                  <DarkBtn
                    type="submit"
                    className="textcenter"
                    title={Loading ? 'Loading...' : 'Login'}
                  />
                </div>
              </form>
              <fieldset>
                <legend>Or </legend>
              </fieldset>
              <SocialLogin />

              <div className="para pera-text18">
                By clicking Sign in or Continue with Google, Facebook, or Apple,
                you agree to GoEthicals Terms of Use and Privacy Policy. We may
                send you communications; you may change your preferences in your
                account settings. We’ll never post without your permission.
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {state ? (
          <ConfirmRegistrationDialog
            userDatahas={tempToken}
            status={state}
            setState={setstate}
          />
        ) : null}
      </div>
    </>
  );
}
