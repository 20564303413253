import { Container, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import * as React from 'react';

export default function StoreSkeleton() {
  return (
      <div className='storeskeleton skeleton'>
        <Skeleton variant="rectangular" className="bannerskeleton"   width="100%"/>
        <Container>
    <Stack spacing={1}>
     
      <Skeleton variant="text" className="topbradcrm" />
      <div className='img-pname'>
      <Skeleton variant="rectangular" className="storeimg" />
      <div className=''>
      <Skeleton variant="text" className="pnameskeleton" />
      <Skeleton variant="text" className="taglineskeleton" />
      </div>
      </div>
      <div className='storebtn'>
      <Skeleton variant="text" className="skiptext" />
      <Skeleton variant="rectangular" className="allbtn" />
      <Skeleton variant="rectangular" className="allbtn" />
      <Skeleton variant="rectangular" className="allbtn" />
      <Skeleton variant="rectangular" className="allbtn" />
      </div>
      <Grid container spacing={2} className='gridset'>
  <Grid item xs={12} sm={8}>
  <Skeleton variant="text" className="abouthead" />
  <Skeleton variant="text" className="subhead" />
  <Skeleton variant="text" className="" />
  <Skeleton variant="text" className="" />
  <Skeleton variant="text" className="" />
  <Skeleton variant="text" className="" />
  </Grid>
  <Grid item xs={12} sm={4}>
  <Skeleton variant="rectangular" className="aboutimg" />
  </Grid>

</Grid>
      </Stack>
      </Container>
    </div>
  );
}