import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { HashLink } from 'react-router-hash-link';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ productDetail, Specifications }) {
  const [value, setValue] = React.useState(0);

  // navigate(`/store/${productDetail?.seller?.nameSlug}`)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // let ret = Policies?.find((o) => o?.typeName === 'RETURN_POLICY');
  // let ref = Policies?.find((e) => e?.typeName === 'REFUND_POLICY');
  // let shipp = Policies?.find((i) => i?.typeName === 'SHIPPING_POLICY');
  // const [returnPolicy, setReturnPolicy] = React.useState(ret?.content);
  // const [refundPolicy, setRefundPolicy] = React.useState(ref?.content);
  // const [shippingPolicy, setShippingPolicy] = React.useState(shipp?.content);

  return (
    <div className="tabsection">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* <Tab label="Product Description" {...a11yProps(0)} /> */}
            <Tab label="Specifications" {...a11yProps(0)} />
            <Tab label="Seller Policies" {...a11yProps(1)} />
          </Tabs>
        </Box>
        {/* <TabPanel value={value} index={0}>
          <div className='tab-para pera-text18' style={{
            whiteSpace: 'pre-line'
          }
          }>
            {Description ? Description : "No description available."}
          </div>

        </TabPanel> */}
        <TabPanel value={value} index={0}>
          <div
            className="tab-para pera-text18"
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {Specifications ? Specifications : 'No specification available.'}{' '}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div
            className="tab-para pera-text18"
            style={{
              whiteSpace: 'pre-line',
              textDecoration: 'underline',
            }}
          >
            {/* adding cSS to heading is remaining */}
            {/* <h2>RETURN POLICY</h2>
            {returnPolicy ? returnPolicy : 'No return policy available.'}
            <h2>REFUND POLICY</h2>
            {refundPolicy ? refundPolicy : 'No refund policy available.'}
            <h2>SHIPPING POLICY</h2>
            {shippingPolicy ? shippingPolicy : 'No shopping policy available.'} */}
            <HashLink to={`/store/${productDetail?.seller?.nameSlug}#Policies`}>
              <h2> View seller policies</h2>
            </HashLink>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
