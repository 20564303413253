import React from 'react';
import { images } from '../../../assets/_index';

const sellerFooter = () => {
  return (
    <div className="sellerfooter">
      <div className="footer-section">
        <div>
          <img
            loading="lazy"
            src={images.mainLogo}
            alt="goEthical logo"
            className="logo-img"
          />
        </div>
        <div className="link-section">
          <div className="privacy-link">Privacy Policy</div>
          <div className="privacy-link">Terms and Conditions</div>
          <div className="privacy-link">Contact Us</div>
        </div>
        <div className="icon-section">
          <img
            loading="lazy"
            src={images.facebook1}
            alt="facebook"
            className="icon-img"
          />
          <img
            loading="lazy"
            src={images.twitter1}
            alt="twitter"
            className="icon-img"
          />
          <img
            loading="lazy"
            src={images.instagram1}
            alt="instagram"
            className="icon-img"
          />
          <img
            loading="lazy"
            src={images.linkedin1}
            alt="linkedin"
            className="icon-img"
          />
        </div>
      </div>
    </div>
  );
};

export default sellerFooter;
