import { DELETE_CART, SET_CART, UPDATE_CART } from '../constant';
// Action CRUD for CART information 
export const addCart = (cartdata) => {
  return ({ type: SET_CART, data: cartdata });
  }

export const deleteCart = () => {
  return ({ type: DELETE_CART });
  };
  
export const updateCart = (cartdata) => { 
    return({ type: UPDATE_CART, data: cartdata });    
  };

