import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";


import { useNavigate } from "react-router-dom";
import DarkBtn from "../../../components/universal/Buttons/DarkBtn";

const GuestCheckoutNew = () => {

  const nav = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const cart_id = params.get('cart_id');
  const isSubscription = params.get('isSubscription');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="guestcheckoutnew">
      <Container className="all-screen-container">
        <div className="checkout-width">
          <div className="checkout-heading">
            Choose How You Would Like To Check Out
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} xl={6}>
              <div className="borderclass">
                <div className="member-text">
                  Check out as a member for a <br/> speedy checkout
                </div>
                <div className="credential-text">
                  Use your GoEthical credentials for Sign in or <br/> the GoEthical
                  App.
                </div>
                <DarkBtn title="Login" onClick={() => nav(cart_id ?
                  isSubscription ? `/login-checkout?cart_id=${cart_id}&isSubscription=1` : `/login-checkout?cart_id=${cart_id}&skip=true`
                  : `/login-checkout`)} />
                <DarkBtn
                  title="Sign Up"
                  onClick={() => nav(cart_id ? isSubscription ? `/register-checkout?cart_id=${cart_id}&isSubscription=1` : `/register-checkout?cart_id=${cart_id}&skip=true` : `/register-checkout`)}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              xl={6}
              className="guest-checkout-sec"
            >
              <div className="member-text">Check Out as Guest</div>
              <div className="credential-text right-side-text">
                You can create a GoEthical account at any point during the
                checkout process.
              </div>
              <div className="checkout-btn-align">
                <DarkBtn
                  title="Guest Checkout"
                  onClick={() => nav(cart_id ? isSubscription ?
                    `/checkout?cart_id=${cart_id}&isSubscription=1` :
                    `/checkout?cart_id=${cart_id}&skip=true` : `/checkout?skip=true`)}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default GuestCheckoutNew;
