import { DELETE_ITEM, SET_ITEM, UPDATE_ITEM } from '../constant';

let initialState = {
    countItem: '0'
}

const itemCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ITEM:
            return { countItem: action.data };
        case DELETE_ITEM:
            return { countItem: 0 };
        case UPDATE_ITEM:
            return {
                countItem: action.data
            };
        default:
            return state;
    }
}

export default itemCountReducer;