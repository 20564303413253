import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getScreenSizeImagDimention } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';
import MobileFilter from '../../dialogs/MobileFilter';
import ProductCardSkeleton from '../../Skeleton/ProductCardSkeleton';
import Filters from '../Filters';
import MainHeading from '../Heading/MainHeading';
import Pagination from '../Pagination';
import ProductCard from '../ProductCard';

const Store = ({ title }) => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [productList, setProductList] = useState([]);
  const [isProductLoading, setIsProductLoading] = useState(false);

  const [query, setQuery] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  let searchParams = new URLSearchParams(location.search);

  const pageNumber = searchParams.get('page');

  const [page, setPage] = useState(pageNumber ? parseInt(pageNumber) : 1);

  useEffect(() => {
    setPage(pageNumber ? parseInt(pageNumber) : 1);
  }, [location.pathname, pageNumber]);

  const getFilterValues = (values) => {
    setQuery((prev) => ({ ...prev, ...values }));
  };

  const getProducts = async (query) => {
    setIsProductLoading(true);
    try {
      let newObject = query;
      let keys = Object.keys(newObject);
      keys.map((key) => {
        query[key] = newObject[key];
        return null;
      });

      const res = await AxiosAll(
        'get',
        `product/user-products/${title}?page=${page}&screen_type=${getScreenSizeImagDimention()}&${Object.keys(
          newObject
        )
          .map((key) => `${key}=${query[key]}`)
          .join('&')}&`,
        '',
        userData?.userToken
      );

      const data = res?.data.result;
      setProductList(data);

      setIsProductLoading(false);
    } catch (error) {
      console.log(error);
      setIsProductLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getProducts(query);
    }
    return () => {
      setProductList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page,userData?.userToken]);

  return (
    <div className="products">
      <Container className="all-screen-container">
        <Grid container spacing={2}>
          <Grid item sm={3} className="mobilehide">
            <Filters getFilterValues={getFilterValues} />
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <div className="headbtn-section">
              <div className="heading-product-btn">
                <MainHeading title="Store" />
              </div>
              <p className="display-title mobilehide   ">
                Displaying {productList?.total ? productList?.total : 0} results
              </p>
            </div>

            <div className="displayresult">
              <MobileFilter getFilterValues={getFilterValues} />
              <p className="display-title mobileshow">
                Displaying {productList?.total ? productList?.total : 0} results
              </p>
            </div>
            <div className="image-container">
              {isProductLoading ? (
                <ProductCardSkeleton type="productPage" />
              ) : productList?.data?.length > 0 ? (
                productList?.data?.map((product, index) => {
                  return (
                    <ProductCard
                      key={index}
                      position={index}
                      productName={product.name}
                      productPrice={
                        product.subscriptionPrice
                          ? product.subscriptionPrice
                          : product.price
                      }
                      image={
                        product.images[0].imageScreenWise
                          ? product.images[0].imageScreenWise
                          : product.images[0].imageWebp
                          ? product.images[0].imageWebp
                          : product.images[0].image
                      }
                      tag={product.tagsAttached[0].parentName}
                      item={product}
                      productId={product?.id}
                    />
                  );
                })
              ) : (
                <div className="emptydata">
                  <div className="ahh-text">Oops!</div>
                  <div className="result-text">
                    <div>We can’t find a match for</div>
                    <div>
                      Don’t give up! Check the spelling or try something more
                      generic.
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isProductLoading
              ? null
              : productList?.data?.length > 0 && (
                  <Pagination
                    count={productList?.lastPage}
                    page={page}
                    onChange={(e, val) => {
                      setPage(val);
                      navigate(
                        val === 1
                          ? { pathname: `/store/${title}` }
                          : {
                              pathname: `/store/${title}`,
                              search:
                                query.tags ||
                                query.metadatas ||
                                query.sortbyprice
                                  ? `tags=${query.tags}&metadatas=${query.metadatas}&sortbyprice=${query.sortbyprice}&page=${val}`
                                  : `page=${val}`,
                            }
                      );

                      const heading = document.getElementById('heading');
                      console.log('heading', heading);

                      if (heading) {
                        heading.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                        });
                      }
                    }}
                  />
                )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Store;
