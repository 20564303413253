import React from 'react';

export const CrawerLink=({path,type})=>{
    const newLink = window.location.origin+path
   return( type=="url" ?
   <a style={{display:"none"}} href={ `${path}`}>{path}</a>:
   <a style={{display:"none"}} href={ `${newLink}`}>{path}</a>
      )
}

export const CrawerUrl=(path)=>{
  
   return <a style={{display:"none"}} href={ `${path}`}>{path}</a>
      
}
