import { Button } from '@mui/material';
import React from 'react';

const ProductBtn = ({ title, style, onClick, ...props }) => {
  return (
    <>
      <Button
        className="product-btn"
        style={style}
        onClick={onClick}
        {...props}
      >
        {title}
      </Button>
    </>
  );
};

export default ProductBtn;
