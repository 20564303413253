import { Navigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/home';
import NewCheckout from '../pages/NewCheckoutPage';
import CheckoutPage from '../pages/NewCheckoutPage/Checkout';
import GuestCheckoutNew from '../pages/NewCheckoutPage/GuestCheckoutNew';
import LoginCheckout from '../pages/NewCheckoutPage/LoginCheckout';
import CheckoutEmail from '../pages/NewCheckoutPage/MobileCheckoutFlow/CheckoutEmail';
import CheckoutPassword from '../pages/NewCheckoutPage/MobileCheckoutFlow/CheckoutPassword';
import CheckoutRegister from '../pages/NewCheckoutPage/MobileCheckoutFlow/CheckoutRegister';
import RegisterCheckout from '../pages/NewCheckoutPage/RegisterCheckout';
import { NotFound } from '../pages/NotFound';
import { NotFoundProduct } from '../pages/NotFoundProduct';
import OrderComplete from '../pages/OrderComplete';
import OrderFailed from '../pages/OrderFailed';
import Products from '../pages/Products';
import ResetPassword from '../pages/ResetPassword';
import Retailers from '../pages/Retailers';
import SearchProduct from '../pages/SearchProduct';
import SellerStorefront from '../pages/SellerStorefront';
import ShopCollection from '../pages/ShopCollection';
import SingleProductDetails from '../pages/SingleProductDetails';
import WordpressIfream from '../pages/wordPressIfream';
// all Public routings
const PublicRoutes = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/is_reedem_gift_voucher" element={<Home />} />
      <Route path="/goethical_fees-3" element={<Navigate to="/blog/goethical_fees-3/"  />} />
      <Route path="/signin" element={<Home />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/product-not-found" element={<NotFoundProduct />} />
      <Route path="shop-collection" element={<ShopCollection />} />
      {/* <Route path="retailers" element={<Retailers />} /> */}
      <Route path="search/retailers/:query" element={<Retailers />} />
      {/* <Route path="search-product" element={<SearchProduct />} /> */}
      <Route path="search/:search_query" element={<SearchProduct />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      {/* OLD ROUTE  */}
      <Route
        path=":parentName/:tagName/:productSlug/:generatedProductId"
        element={<SingleProductDetails />}
      /> 
     {/*  */}
      {/* LATEST ROUTER    */}
      <Route
        path=":parentName/:tagName/:productSlug"
        element={<SingleProductDetails />}
      />
  {/*  */}
      <Route path="basket" element={<NewCheckout />} />
      <Route path="basket/:id" element={<NewCheckout />} />
      <Route path="basket/:id/isSubscription/products" element={<NewCheckout />} />

      <Route path="/:page_name" element={<Products />} />
      <Route path="/:page_name_1/:page_name" element={<Products />} />
{/*       
      <Route path="products" element={<Products />} /> 
     */}
      {/* <Route path="single-product" element={<SingleProductDetails />} /> */}
      <Route path="store/:title" element={<SellerStorefront />} />

      <Route path="confirm-order" element={<OrderComplete />} />
      
      <Route path="checkout" element={<CheckoutPage />} />
      <Route path="wordpress-header" element={<WordpressIfream />} />
      <Route path="login" element={<Home />} />
      <Route path="order-failed" element={<OrderFailed />} />
      {/* <Route path="new-checkout" element={<NewCheckout />} /> */}
      <Route path="guest-checkout" element={<GuestCheckoutNew />} />
      {/* <Route path="new-checkout-page" element={<CheckoutPage />} /> */}
      <Route path="login-checkout" element={<LoginCheckout />} />
      <Route path="register-checkout" element={<RegisterCheckout />} />
      <Route path="checkout-email" element={<CheckoutEmail />} />
      <Route path="checkout-password" element={<CheckoutPassword />} />
      <Route path="checkout-register" element={<CheckoutRegister />} />
    </Routes>
  );
};

export default PublicRoutes;
