import packageEnv from '../package.json';
import { PreProdServer, ProdServer, staggingServer } from './Sever';
// main server controller 
const EnvType=(Env)=>{
    switch (Env) {
        case 1:
            return (staggingServer)

        case 2:
            return (PreProdServer)

        case 3:
            return (ProdServer)
 
        default:
            return (staggingServer)

    }
}
export default {
    currentEnv:EnvType(packageEnv?.env)
}