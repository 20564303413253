import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useNetwork } from '../src/core/network';
import CustomHelmet from './components/universal/Helmet';
import { LandScapeView } from './components/universal/landScapeView';
import { TRACKING_ID } from './core/constants';
import NoInternet from './pages/Nointernet';
import { persistor, store } from './redux/store';
import Routes from './routing';
function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID, { debug: true });
    // ReactGA.initialize(TRACKING_ID);
    // ReactGA.ga("require", "ec");
  }, []);

  const [isDesktop, setDesktop] = useState(
    window.innerWidth < 700 || window.innerWidth > 920
  );

  const updateMedia = () => {
    setDesktop(window.innerWidth < 700 || window.innerWidth > 920);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return useNetwork() ? (
    <Provider store={store}>
      <CustomHelmet
        description="Discover a world of incredible ethical, artisan and preloved produces with GoEthical; the UK’s first dedicated ethical marketplace and lifestyle app."
        url="https://goethical.com/"
        image="https://goethical.com/assets/images/header/goethical_logo.png"
      />
      <PersistGate loading={null} persistor={persistor}>
        {isDesktop ? <Routes /> : <LandScapeView />}
      </PersistGate>
    </Provider>
  ) : (
    <NoInternet />
  );
}

export default App;
