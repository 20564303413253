import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function CategoryFilterSkeleton() {
  return (
      <div className=''>
        <Skeleton variant="text" className=''/>
        <Skeleton variant="text" className='marginleft20'/>
        <Skeleton variant="text" className='marginleft20'/>
        <Skeleton variant="text" className='marginleft40'/>
        <Skeleton variant="text" className='marginleft40'/>
        <Skeleton variant="text" className=''/>
    </div>
  );
}