import { Container, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import * as React from 'react';

export default function ShopByValueFilterSkeleton() {
  return (
      <div className='shopbyvaluefilterskeleton '>
        
        
    <Stack spacing={1} className="">
    <div className='filterskeletonset' >
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        <div className='filtersklton'>
            <Skeleton variant="circular" className="flicon"   width="100%"/>
            <Skeleton variant="text" className="fltitle" />
        </div>
        </div>
      </Stack>
     
    </div>
  );
}