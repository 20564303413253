import { combineReducers } from 'redux';
import cartReducer from './cartcount';
import guestReducer from './guestReducer';
import guestUserReducer from './guestUserReducer';
import itemCountReducer from './itemCount';
import loginReducer from './loginReducer';
import newsLatterReducer from './newsLatter';
import userReducer from './users';


const rootReducer = combineReducers({
  guestReducer: guestReducer,
  guestUserReducer: guestUserReducer,
  loginReducer: loginReducer,
  userReducer: userReducer,
  cartReducer: cartReducer,
  newsLatterReducer: newsLatterReducer,
  itemCountReducer: itemCountReducer
});

export default rootReducer;
