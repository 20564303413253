import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { images } from '../../../assets/_index';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import AddAddress from '../../../components/universal/Cart/AddAddress';
import EditAddress from '../../../components/universal/Cart/EditAddress';
import Payment from '../../../components/universal/Cart/Payment';
import ChcekoutField from '../../../components/universal/InputField/CheckoutField';
import { notify } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';
import BasketContent from './BasketContent';

const CheckoutPage = () => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [isShowAddressEditList, setIsShowAddressEditList] = useState(false);
  const [isShowAddressEdit, setIsShowAddressEdit] = useState(false);
  const [cartList, setCartList] = useState([]);
  const [cartDetails, setCartDetails] = useState({});
  const [editAddress, setEditAddress] = useState({});
  const [isNewAddressAdded, setIsNewAddressAdded] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [balance, setBalance] = useState({});
  const [values, setValues] = useState({
    defaultAddress: '',
    defaultCard: '',
    billingAddress: '',
    sameAsBilling: false,
    useCredit: false,
    giftCard: false,
  });
  const [selectedCard, setSelectedCard] = useState({});
  const [isShowCardEditList, setIsShowCardEditList] = useState(false);
  const [isShowCardEdit, setIsShowCardEdit] = useState(false);
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [isAddNewCard, setIsAddNewCard] = useState(false);
  const [isNewCardAdded, setIsNewCardAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [voucherToken, setVoucherToken] = useState('');
  const [isVoucherApplied, setIsVoucherApplied] = useState(false);
  const [isVoucherLoading, setIsVoucherLoading] = useState(false);
  const [isAddressListLoading, setIsAddressListLoading] = useState(true);
  const [isCardListLoading, setIsCardListLoading] = useState(false);
  const [isCardDeleted, setIsCardDeleted] = useState(false);

  const nav = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const cart_id = params.get('cart_id');
  const isSubscription = search.includes('isSubscription=1');

  const getCartList = async () => {
    try {
      const res = await AxiosAll(
        'get',
        isSubscription
          ? `cart-subscription?cart_id=${cart_id}&page=1&pageSize=1000&is_credit=${
              values?.useCredit ? 1 : 0
            }&`
          : `cart?page=1&per_page_limit=1000&skip_outofstock=1&selected_all=0&is_credit=${
              values?.useCredit ? 1 : 0
            }&${cart_id ? `&cart_id=${cart_id}` : ''}`,
        '',
        userData?.userToken
      );

      const { cart, ...rest } = res?.data?.result;
      setCartList(res?.data?.result?.cart?.data);
      setCartDetails(rest);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCartList();
  }, [values?.useCredit, isCardDeleted]);

  const getAddress = async () => {
    try {
      const res = await AxiosAll(
        'get',
        'address?address_type=2&',
        '',
        userData.userToken
      );
      setAddressList(res?.data?.result?.addresses);
      setIsAddressListLoading(false);
    } catch (error) {
      console.log(error);
      setIsAddressListLoading(false);
    }
  };

  useEffect(() => {
    getAddress();
  }, [isShowAddressEdit, isNewAddressAdded]);

  const getCardList = async () => {
    setIsCardListLoading(true);
    try {
      const res = await AxiosAll('get', 'card?', '', userData.userToken);
      setCardList(res?.data?.result?.cards);
      setIsCardListLoading(false);
    } catch (error) {
      console.log(error);
      setIsCardListLoading(false);
    }
  };

  useEffect(() => {
    getCardList();
  }, [isNewCardAdded]);

  const getBalance = async () => {
    try {
      const res = await AxiosAll(
        'get',
        'wallet/balance?',
        '',
        userData.userToken
      );
      setBalance(res?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBalance();
  }, [isVoucherApplied]);

  useEffect(() => {
    if (addressList?.length > 0) {
      addressList.map((item) => {
        if (item.isDefaultAddress === 1) {
          setValues({
            ...values,
            defaultAddress: `${item?.id}`,
          });
        } else {
          setValues({
            ...values,
            defaultAddress: `${addressList[0]?.id}`,
          });
        }
      });
    }
  }, [addressList]);

  useEffect(() => {
    if (values.defaultAddress) {
      setSelectedAddress(
        addressList.find((item) => `${item.id}` === values.defaultAddress)
      );
    }
  }, [values.defaultAddress, addressList]);

  useEffect(() => {
    if (cardList?.length > 0) {
      cardList.map((item) => {
        if (item.isDefault === 1) {
          setValues({
            ...values,
            defaultCard: `${item?.id}`,
          });
        } else {
          setValues({
            ...values,
            defaultCard: `${cardList[0]?.id}`,
          });
        }
      });
    }
  }, [cardList]);

  useEffect(() => {
    if (values.defaultCard) {
      setSelectedCard(
        cardList.find((item) => `${item.id}` === values.defaultCard)
      );
    }
  }, [values.defaultCard, cardList]);

  const handleDeleteCard = async (id) => {
    try {
      const res = await AxiosAll(
        'delete',
        `card/${id}?`,
        '',
        userData.userToken
      );
      if (res.data.statusCode === 200) {
        getCardList();
      }
      setIsCardDeleted(!isCardDeleted);
      setIsShowCardEditList(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubscriptionCheckout = async (data) => {
    setIsLoading(true);
    try {
      const res = await AxiosAll(
        'post',
        'order-subscription?skip_outofstock=1&',
        data,
        userData.userToken
      );
      if (res.data.statusCode === 200) {
        setIsLoading(true);
        if (res?.data?.result?.data?.is3DSecure) {
          window.open(res?.data?.result?.data?.is3DSecureURL, '_Self');
        } else {
          nav({
            pathname: '/confirm-order',
            search: `?generated_order_id=${res?.data?.result?.data?.generatedOrderId}`,
          });
        }
      } else {
        nav('/order-failed', { state: { message: res?.data?.message } });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      nav('/order-failed', { state: { message: error?.data?.message } });
    }
  };

  const applyToken = async () => {
    setIsVoucherLoading(true);
    try {
      const res = await AxiosAll(
        'post',
        'voucher/redeemToken?',
        {
          voucherToken: voucherToken,
        },
        userData.userToken
      );
      if (res.data.statusCode === 200) {
        notify('Voucher applied successfully', 'success');
        setIsVoucherApplied(true);
        setValues({
          ...values,
          useCredit: true,
        });
      } else {
        notify(res?.data?.message, 'error');
      }
      setIsVoucherLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckout = async (data) => {
    setIsLoading(true);
    try {
      const res = await AxiosAll(
        'post',
        'order?skip_outofstock=1&',
        data,
        userData.userToken
      );
      if (res.data.statusCode === 200) {
        if (res?.data?.result?.data?.is3DSecure) {
          setIsLoading(true);
          window.open(res?.data?.result?.data?.is3DSecureURL, '_Self');
        } else {
          nav({
            pathname: '/confirm-order',
            search: `?generated_order_id=${res?.data?.result?.data?.generatedOrderId}&order_id=${res?.data?.result?.data?.orderId}&status=success`,
          });
        }
      } else {
        nav('/order-failed', { state: { message: res?.data?.message } });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    const data = {
      addressId: parseInt(values.defaultAddress),
      cardId: parseInt(values.defaultCard),
      cart_id: cart_id,
      is_gift_wrapping: 0,
      is_purchase_gift_voucher: 0,
      is_redeem_wallet_payment: values.useCredit ? 1 : 0,
      totalAmount: cartDetails?.totalPayablePrice,
      web_redirect_url: `${window.location.origin}/`,
    };
    if (isSubscription) {
      handleSubscriptionCheckout(data);
    } else {
      handleCheckout(data);
    }
  };

  const isShowPlaceOrderButton = () => {
    if (
      isShowAddressEdit ||
      isShowCardEdit ||
      isShowCardEditList ||
      isAddNewAddress ||
      isAddNewCard ||
      isShowAddressEditList ||
      addressList?.length === 0 ||
      cardList?.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const showPaymentEditButton = () => {
    if (
      isShowAddressEditList ||
      isShowCardEditList ||
      isAddNewAddress ||
      isAddNewCard ||
      addressList?.length === 0 ||
      cardList?.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const cardImage = (cardBrand) => {
    if (cardBrand === 'Visa') {
      return (
        <img
          loading="lazy"
          alt="Visa"
          src={images.visa}
          className="visacard cardiconset"
        />
      );
    } else if (cardBrand === 'MasterCard') {
      return (
        <img
          loading="lazy"
          alt="MasterCard"
          src={images.mastercard}
          className="cardiconset"
        />
        // <img src={images.cardIcon} className="cardiconset" />
      );
    } else if (cardBrand === 'American Express') {
      return (
        <img
          loading="lazy"
          alt="American Express"
          src={images.amex}
          className="cardiconset"
        />
      );
    } else if (cardBrand === 'Discover') {
      return (
        <img
          loading="lazy"
          alt="Discover"
          src={images.discover}
          className="cardiconset"
        />
      );
    } else if (cardBrand === 'JCB') {
      return (
        <img
          loading="lazy"
          alt="JCB"
          src={images.jcb}
          className="cardiconset"
        />
      );
    } else if (cardBrand === 'Diners Club') {
      return (
        <img
          loading="lazy"
          alt="Diners Club"
          src={images.dinner_club}
          className="cardiconset"
        />
      );
    } else if (cardBrand === 'UnionPay') {
      return (
        <img
          loading="lazy"
          alt="UnionPay"
          src={images.union_pay}
          className="cardiconset"
        />
      );
    } else {
      return (
        <img
          loading="lazy"
          alt="card brand"
          src={images.card}
          className="cardiconset"
        />
      );
    }
  };

  useEffect(() => {
    if (addressList?.length === 0) {
      setIsAddNewAddress(true);
      setIsShowAddressEditList(true);
      setIsShowAddressEdit(false);
      setIsShowCardEditList(false);
      setIsAddNewCard(false);
    } else if (cardList.length === 0) {
      setIsShowCardEditList(true);
      setIsAddNewCard(true);
      setIsShowAddressEditList(false);
      setIsShowCardEdit(false);
      setIsAddNewAddress(false);
    } else {
      if (isAddNewAddress) {
        setIsShowAddressEditList(true);
      } else {
        setIsShowAddressEditList(false);
      }
      if (isAddNewCard) {
        setIsShowCardEditList(true);
      } else {
        setIsShowCardEditList(false);
      }

      setIsAddNewAddress(false);
      setIsShowCardEdit(false);
      setIsAddNewCard(false);
    }
  }, [cardList?.length, addressList?.length]);

  const showBillingAddress = () => {
    if (!isShowCardEditList && cardList?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="checkoutpagenew">
      <Container className="all-screen-container">
        <div className="checkoutpageinner">
          <h2 className="checkouttitle">Checkout</h2>
          <div className="mobilecartitem">
            <span>
              {cartDetails?.totalProducts}{' '}
              {cartDetails?.totalProducts > 1 ? 'items' : 'item'}
            </span>{' '}
            £{cartDetails?.totalPayablePrice?.toFixed(2)}
          </div>
          <Grid container spacing={6} className="fieldandcart">
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              xl={8}
              className="deliveryareagrid"
            >
              <div className="deliveryarea">
                <div className="deliverttitleedit baskettitleedit">
                  <h3
                    id="delivery-section"
                    className={`checkouttitle ${
                      (addressList?.length > 0 && values?.defaultAddress) ||
                      isShowAddressEdit ||
                      isAddNewAddress
                        ? ''
                        : 'payment-title-disable'
                    }`}
                  >
                    <span className="dvhide">Delivery</span>
                    <span className="dvshow">Delivery Options</span>{' '}
                    {!isShowAddressEdit &&
                      !isShowAddressEditList &&
                      !isAddNewAddress && (
                        <TaskAltIcon className="inputiconmeinner deliveroptionicon" />
                      )}
                  </h3>
                  {!isShowAddressEditList && (
                    <p
                      onClick={() => {
                        setIsShowAddressEditList(true);
                        setIsShowCardEditList(false);
                        setIsShowCardEdit(false);
                        setIsAddNewCard(false);
                      }}
                    >
                      Edit
                    </p>
                  )}
                </div>
                {
                  <div className="inputfeilds">
                    {isShowAddressEditList &&
                      !isShowCardEditList &&
                      !isShowAddressEdit &&
                      !isAddNewAddress && (
                        <>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            defaultValue={`${
                              addressList?.find(
                                (item) => item.isDefaultAddress === 1
                              )?.id
                            }`}
                            className="billingcontry"
                            onChange={(e) =>
                              setValues({
                                ...values,
                                defaultAddress: e.target.value,
                              })
                            }
                            value={values.defaultAddress}
                          >
                            {addressList?.length > 0 && (
                              <span className="delivery-address-new">
                                Delivery Address
                              </span>
                            )}
                            {addressList?.map((address, index) => {
                              return (
                                <FormControlLabel
                                  key={index}
                                  value={address.id}
                                  className={`${
                                    address?.id === selectedAddress?.id &&
                                    'selectedaddress'
                                  }`}
                                  control={<Radio />}
                                  label={
                                    <div className="mtextbtnset">
                                      <div>
                                        <Fragment key={index}>
                                          <p>{address?.addressName}</p>
                                          <p>{address?.address}</p>
                                          <p>
                                            {address?.city}, {address?.postcode}
                                            , {address?.country}
                                          </p>
                                          <p>{userData?.user?.email}</p>
                                          <p className="marginbott">
                                            {address?.phone}
                                          </p>
                                        </Fragment>
                                      </div>
                                      <div
                                        className="editbtnall"
                                        onClick={() => {
                                          setEditAddress(
                                            addressList.find(
                                              (item) => item.id === address.id
                                            )
                                          );
                                          setIsShowAddressEdit(true);
                                        }}
                                      >
                                        Edit
                                      </div>
                                    </div>
                                  }
                                />
                              );
                            })}
                            {userDetails?.token && addressList?.length > 0 && (
                              <span
                                onClick={() => {
                                  setIsAddNewAddress(true);
                                  const width = window.innerWidth;
                                  if (width <= 426) {
                                    const height = (
                                      (window.innerHeight ||
                                        document.documentElement.clientHeight ||
                                        document.body.clientHeight) / 4
                                    ).toFixed(0);
                                    window.scrollTo(
                                      0,
                                      document.getElementById(
                                        'delivery-section'
                                      ).offsetTop - parseInt(height)
                                    );
                                  } else {
                                    const height = (
                                      (window.innerHeight ||
                                        document.documentElement.clientHeight ||
                                        document.body.clientHeight) / 20
                                    ).toFixed(0);
                                    window.scrollTo(
                                      0,
                                      document.getElementById(
                                        'delivery-section'
                                      ).offsetTop - parseInt(height)
                                    );
                                  }
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                Add New Address
                              </span>
                            )}
                          </RadioGroup>
                        </>
                      )}
                    {!isShowAddressEditList && selectedAddress?.addressName && (
                      <>
                        <span className="delivery-address-new">
                          Delivery Address
                        </span>
                        <div className="billingcontry">
                          <p>{selectedAddress?.addressName}</p>
                          <p>{selectedAddress?.address}</p>
                          <p>
                            {selectedAddress?.city}, {selectedAddress?.postcode}
                            , {selectedAddress?.country}
                          </p>
                          <p className="email-text-inner">
                            {userData?.user?.email}
                          </p>
                          <p className="phone-text-inner">
                            {selectedAddress?.phone}
                          </p>
                        </div>
                      </>
                    )}
                    {isAddNewAddress && (
                      <AddAddress
                        addressList={addressList}
                        isNewAddressAdded={isNewAddressAdded}
                        setIsNewAddressAdded={setIsNewAddressAdded}
                        isAddNewAddress={isAddNewAddress}
                        setIsAddNewAddress={setIsAddNewAddress}
                        setIsShowAddressEditList={setIsShowAddressEditList}
                      />
                    )}

                    {isShowAddressEdit && (
                      <EditAddress
                        address={editAddress}
                        setIsShowEdit={setIsShowAddressEdit}
                      />
                    )}
                    {addressList?.length === 0
                      ? isShowAddressEditList &&
                        !isShowAddressEdit &&
                        !isAddNewAddress
                      : isShowAddressEditList &&
                        !isShowAddressEdit &&
                        !isAddNewAddress && (
                          <div className="btnalignright">
                            <DarkBtn
                              title="Continue to Payment"
                              className="addaddressbtn"
                              type="submit"
                              onClick={() => {
                                setIsShowAddressEditList(false);
                                setIsShowCardEditList(true);
                                if (cardList?.length === 0) {
                                  setIsAddNewCard(true);
                                }
                                const width = window.innerWidth;
                                if (width <= 426) {
                                  const height = (
                                    (window.innerHeight ||
                                      document.documentElement.clientHeight ||
                                      document.body.clientHeight) / 4
                                  ).toFixed(0);
                                  window.scrollTo(
                                    0,
                                    document.getElementById('payment-section')
                                      .offsetTop - parseInt(height)
                                  );
                                } else {
                                  const height = (
                                    (window.innerHeight ||
                                      document.documentElement.clientHeight ||
                                      document.body.clientHeight) / 20
                                  ).toFixed(0);
                                  window.scrollTo(
                                    0,
                                    document.getElementById('payment-section')
                                      .offsetTop - parseInt(height)
                                  );
                                }
                              }}
                            />
                          </div>
                        )}
                  </div>
                }
              </div>
              <div className="paymentarea">
                <div className="paymenttitleedit baskettitleedit">
                  <h3
                    id="payment-section"
                    className={`checkouttitle ${
                      (cardList?.length > 0 && values?.defaultCard) ||
                      isAddNewCard
                        ? ''
                        : 'payment-title-disable'
                    }`}
                  >
                    Payment
                    {cardList?.length > 0 && !isShowCardEditList && (
                      <TaskAltIcon className="inputiconmeinner" />
                    )}
                  </h3>
                  {/* showPaymentEditButton() && */}
                  {showPaymentEditButton() && (
                    <p
                      onClick={() => {
                        setIsShowCardEditList(true);
                        setValues({
                          ...values,
                          sameAsBilling: false,
                        });
                      }}
                    >
                      Edit
                    </p>
                  )}
                </div>

                <div className="inputfeilds">
                  {isShowCardEditList && (
                    <div className="billingcontry">
                      Billing Country/Region
                      <br />
                      <p>United Kingdom</p>
                    </div>
                  )}
                  {userDetails?.token &&
                    !isSubscription &&
                    isShowCardEditList &&
                    !isAddNewCard && (
                      <div className="usecredit">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Would you like to use your credit?"
                          value={values?.useCredit}
                          checked={values?.useCredit}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              useCredit: e.target.checked,
                            })
                          }
                        />
                        <span className="amount">
                          (£{balance?.balance?.toFixed(2)})
                        </span>
                      </div>
                    )}
                  {userDetails?.token &&
                    !isSubscription &&
                    isShowCardEditList &&
                    !isAddNewCard && (
                      <div className="usecredit">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Do you have a gift card, product voucher or promo code?"
                          value={values?.giftCard}
                          checked={values?.giftCard}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              giftCard: e.target.checked,
                            });
                            if (e.target.checked) {
                              setVoucherToken('');
                              setIsVoucherApplied(false);
                            }
                          }}
                        />
                      </div>
                    )}

                  {values.giftCard && isShowCardEditList && (
                    <div>
                      <ChcekoutField
                        title="Enter code here"
                        value={voucherToken}
                        onChange={(e) => setVoucherToken(e.target.value)}
                      />

                      {voucherToken !== '' && (
                        <div class="billingcontry btnalignright">
                          <DarkBtn
                            title={`${isVoucherApplied ? 'Applied' : 'Apply'}`}
                            className="addaddressbtn"
                            loading={isVoucherLoading}
                            type="button"
                            disable={isVoucherApplied}
                            onClick={applyToken}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {cardList?.length > 0
                    ? isShowCardEditList &&
                      !isAddNewCard && (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          defaultValue={values.defaultCard}
                          className="billingcontry"
                          onChange={(e) =>
                            setValues({
                              ...values,
                              defaultCard: e.target.value,
                            })
                          }
                          value={values.defaultCard}
                        >
                          {cardList?.length > 0 &&
                            cardList?.map((item, index) => {
                              return (
                                <FormControlLabel
                                  className={`${
                                    item?.id === selectedCard?.id &&
                                    'selectedaddress'
                                  }`}
                                  key={index}
                                  value={item?.id}
                                  control={<Radio />}
                                  label={
                                    <Fragment key={index}>
                                      <div className="mtextbtnset">
                                        <p className="card-p">
                                          {cardImage(item?.cardBrand)}
                                          {item?.cardLast4Digits}{' '}
                                          <span className="exp-text">
                                            &nbsp;Exp:
                                          </span>{' '}
                                          {item?.expMonth}/{item?.expYear}
                                        </p>
                                        <div
                                          className="deletebtn"
                                          onClick={() => {
                                            handleDeleteCard(item?.id);
                                            setIsAddNewCard(true);
                                          }}
                                        >
                                          Delete
                                        </div>
                                      </div>
                                    </Fragment>
                                  }
                                />
                              );
                            })}
                          <div className="addnewaddbtn">
                            {userDetails?.token && !isAddNewCard && (
                              <span onClick={() => setIsAddNewCard(true)}>
                                Add New Card
                              </span>
                            )}
                          </div>
                        </RadioGroup>
                      )
                    : null}
                  {!isShowCardEditList && selectedCard?.cardBrand ? (
                    <div className="billingcontry">
                      <span>Payment Method</span>
                      <p className="card-p">
                        {cardImage(selectedCard?.cardBrand)}
                        {selectedCard?.cardDigits}{' '}
                        <span className="exp-text">&nbsp;Exp: </span>{' '}
                        {selectedCard?.expMonth}/{selectedCard?.expYear}
                      </p>
                      {/* <br /> */}
                    </div>
                  ) : null}
                  {showBillingAddress() && (
                    <div className="billingcontry">
                      <span> Billing Address</span>
                      <p>{selectedCard?.addressName}</p>
                      <p>{selectedCard?.address}</p>
                      <p>
                        {selectedCard?.city},{selectedCard?.postcode},
                        {selectedCard?.country}
                      </p>
                    </div>
                  )}
                  {isShowCardEditList && isAddNewCard && (
                    <div className="billingcontry paymentmethod selectpayment">
                      Select payment method
                      <br />
                      <FormControlLabel
                        control={<Radio />}
                        checked={true}
                        value={true}
                        label="Credit or Debit Card"
                      />
                    </div>
                  )}
                  {isAddNewCard && (
                    <Payment
                      setIsAddNewCard={setIsAddNewCard}
                      isAddNewCard={isAddNewCard}
                      setIsNewCardAdded={setIsNewCardAdded}
                      isNewCardAdded={isNewCardAdded}
                      setIsShowCardEditList={setIsShowCardEditList}
                      cardList={cardList}
                      selectedCard={selectedCard}
                      address={selectedAddress}
                      isCardListLoading={isCardListLoading}
                    />
                  )}
                  {isShowCardEditList && !isAddNewCard && (
                    <div className="btnalignright">
                      <DarkBtn
                        title="Continue To Order Review"
                        onClick={() => {
                          setIsShowCardEditList(false);
                          setIsShowAddressEditList(false);
                          setIsShowAddressEdit(false);
                          setIsShowCardEdit(false);
                          setIsVoucherApplied(false);
                          setVoucherToken('');
                          setValues({
                            ...values,
                            giftCard: false,
                          });

                          const height = (
                            (window.innerHeight ||
                              document.documentElement.clientHeight ||
                              document.body.clientHeight) / 20
                          ).toFixed(0);

                          window.scrollTo(
                            0,
                            document.getElementById('review-section')
                              .offsetTop - parseInt(height)
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="orderreviewarea">
                <h3
                  id="review-section"
                  className={`checkouttitle ${
                    !isShowAddressEditList && !isShowCardEditList
                      ? ''
                      : 'payment-title-disable'
                  }`}
                >
                  Order Review
                </h3>
                {isShowPlaceOrderButton() && !userDetails?.token && (
                  <p className="termsandcondition-text">
                    By clicking the Place Order button, you confirm that you
                    have read, understand and accept our
                    <span
                      onClick={() =>
                        window.open(
                          'https://goethical.com/blog/terms-and-conditions/',
                          '_self'
                        )
                      }
                    >
                      Terms of Use
                    </span>
                    and acknowledge that you have read GoEthical's
                    <span
                      onClick={() =>
                        window.open(
                          'https://goethical.com/blog/privacy-policy/',
                          '_self'
                        )
                      }
                    >
                      Privacy Policy
                    </span>
                    .
                  </p>
                )}
                <div className="inputfeilds">
                  <div className="mobileshow">
                    <BasketContent
                      cartList={cartList}
                      cartDetails={cartDetails}
                    />
                  </div>
                  {isShowPlaceOrderButton() ? (
                    <div className="btnalignright">
                      <DarkBtn
                        title="Place Order"
                        onClick={onSubmit}
                        loading={isLoading}
                        disable={isLoading}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              xl={4}
              className="baskettopmobile"
            >
              <div className="basketarea mobilehide">
                <div className="baskettitleedit">
                  <h3 className="checkouttitle">In Your Basket</h3>
                  <p
                    onClick={() => {
                      if (cart_id && isSubscription) {
                        nav(`/basket/${cart_id}/isSubscription/products`);
                      } else if (cart_id) {
                        nav(`/basket/${cart_id}`);
                      } else {
                        nav('/basket');
                      }
                    }}
                  >
                    Edit
                  </p>
                </div>
                {cartList?.length > 0 && (
                  <BasketContent
                    cartList={cartList}
                    cartDetails={cartDetails}
                  />
                )}
              </div>
              <Accordion className="basketarea mobileshow">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <div className="baskettitleedit">
                      <h3 className="checkouttitle">
                        <span className="mobilehide">In Your Basket</span>
                        <span className="mobileshow">In Your Bag</span>
                      </h3>
                      <p
                        onClick={() => {
                          if (cart_id && isSubscription) {
                            nav(`/basket/${cart_id}/isSubscription/products`);
                          } else if (cart_id) {
                            nav(`/basket/${cart_id}`);
                          } else {
                            nav('/basket');
                          }
                        }}
                      >
                        Edit
                      </p>
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <BasketContent
                      cartList={cartList}
                      cartDetails={cartDetails}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default CheckoutPage;
