import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { images } from '../../../assets/_index';
import { getScreenSize } from '../../../core/constants';

const InnerBanner = ({
  data,
  shopValue,
  shopCollectiontype,
  shopBannerStatus,
}) => {
  const mobile = data?.mobileBannerImageWebp
    ? data?.mobileBannerImageWebp
    : data?.mobileBannerImage;
  const tablet = data?.tabletBannerImageWebp
    ? data?.tabletBannerImageWebp
    : data?.tabletBannerImage;
  const web = data?.webBannerImage
    ? data?.webBannerImageWebp
      ? data?.webBannerImageWebp
      : data?.webBannerImage
    : data?.bannerImageWebp
    ? data?.bannerImageWebp
    : data?.bannerImage;
  const defaultSize = shopCollectiontype
    ? data?.bannerImage
      ? data?.bannerImageWebp
        ? data?.bannerImageWebp
        : data?.bannerImage
      : data?.imageWebp
      ? data?.imageWebp
      : data?.image
    : data?.imageWebp
    ? data?.imageWebp
    : data?.image;

  const imageview = (deviceSize) => {
    if (deviceSize === 'mobile') {
      if (mobile) {
        return mobile;
      } else {
        return defaultSize;
      }
    } else if (deviceSize === 'tablet') {
      if (tablet) {
        return tablet;
      } else {
        return defaultSize;
      }
    } else if (deviceSize === 'web') {
      if (web) {
        return web;
      } else {
        return defaultSize;
      }
    } else {
      return defaultSize;
    }
  };
  const nav = useNavigate();

  return (
    <div className="innerbanner">
      <div
        className="baanerbgimg"
        // style={{ backgroundImage: `url(${imageview(getScreenSize())})` }}
      >
        {imageview(getScreenSize()) != undefined && (
          <img
            loading="lazy"
            src={imageview(getScreenSize())}
            alt={data?.bannerTitle}
            title={data?.bannerTitle}
            className="innerbannerimg"
            onError={(event) => {
              event.target.onerror = '';
              event.target.src = images?.bannerONError;
              return true;
            }}
          />
        )}
        <Container className="all-screen-container">
          <div className="image-section">
            {data?.bannerTitle ? (
              data?.bannerTitle?.match(/<\/?[^>]*>/) ? (
                <div
                  className="buy-heading"
                  dangerouslySetInnerHTML={{ __html: data?.bannerTitle }}
                ></div>
              ) : (
                data?.bannerTitle && (
                  <h1 className="buy-heading">{data?.bannerTitle}</h1>
                )
              )
            ) : data?.title?.match(/<\/?[^>]*>/) ? (
              <div
                className="buy-heading"
                dangerouslySetInnerHTML={{ __html: data?.title }}
              ></div>
            ) : (
              data?.title && <h1 className="buy-heading">{data?.title}</h1>
            )}

            {data?.subTitle ? (
              data?.subTitle?.match(/<\/?[^>]*>/) ? (
                <div
                  className="buy-para pera-text18"
                  dangerouslySetInnerHTML={{ __html: data?.subTitle }}
                ></div>
              ) : (
                data?.subTitle && (
                  <p className="buy-para pera-text18">{data?.subTitle}</p>
                )
              )
            ) : data?.bannerDescription?.match(/<\/?[^>]*>/) ? (
              <div
                className="buy-para pera-text18"
                dangerouslySetInnerHTML={{ __html: data?.bannerDescription }}
              ></div>
            ) : (
              data?.bannerDescription && (
                <p className="buy-para pera-text18">
                  {data?.bannerDescription}
                </p>
              )
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InnerBanner;
