import React, { useEffect, useState } from 'react';

const BlogsCard = ({ title, content, link, image }) => {
  const [newLink, setNewLink] = useState('');
  const addStr = (str, index, stringToAdd) => {
    setNewLink(
      str?.substring(0, index) + stringToAdd + str.substring(index, str?.length)
    );
  };

  useEffect(() => {
    if (link) {
      addStr(link, 22, 'blog/');
    }
  }, [link]);

  return (
    <div className="blogcard">
      <div className="img-background-row">
        <a href={link} target="_blank">
          <img loading="lazy" alt={title} title={title} className="img-row" src={image} />
        </a>
      </div>
      <h3 className="card-heading">
        <a href={link} target="_blank">
          {title}
        </a>
      </h3>

      <p className="card-para">
        <span className="wordbrk">
          {content?.toString().slice(0, 111).concat(' . . . ')}
        </span>
        <span className="read-heading">
          <a href={link} target="_blank">
            Continue Reading
          </a>{' '}
        </span>
      </p>
    </div>
  );
};

export default BlogsCard;
