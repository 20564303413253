import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Container, Grid } from '@mui/material';

export default function SingleProductSkeleton() {
  return (
    <div className='singleproductskeleton'>
      <Container>
        <Skeleton variant="text" className="bradcrum" />
        <Stack spacing={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rectangular" className="mainimg" />
              <div className='multiimg'>
                <div className='loopskeleton'>
                  <Skeleton variant="rectangular" className='imgcard' />

                </div>
                <div className='loopskeleton'>
                  <Skeleton variant="rectangular" className='imgcard' />

                </div>
                <div className='loopskeleton'>
                  <Skeleton variant="rectangular" className='imgcard' />

                </div>
                <div className='loopskeleton'>
                  <Skeleton variant="rectangular" className='imgcard' />

                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className='saller-stock'>
                <Skeleton variant="text" className="sellerskeleton" />
                <Skeleton variant="text" className="stockskeleton" />
              </div>
              <div className='productname-price'>
                <div className="productnameskeleton">
                  <Skeleton variant="text" className="productname" />
                  <Skeleton variant="text" className="productname" />
                </div>
                <Skeleton variant="text" className="priceskeleton" />
              </div>
              <div className='peraskeleton'>
                  <Skeleton variant="text" className="" />
                  <Skeleton variant="text" className="" />
                  <Skeleton variant="text" className="" />
                  <Skeleton variant="text" className="" />
                  <Skeleton variant="text" className="" />
              </div>
              <div className='variantskeleton'>
                  <Skeleton variant="text" className="variantheading" />
                  <div className="variantcat">
                      <div className='varientloop'>
                          <Skeleton variant="circular" className='variantimg' />
                          <Skeleton variant="text" className="" />
                      </div>
                      <div className='varientloop'>
                          <Skeleton variant="circular" className='variantimg' />
                          <Skeleton variant="text" className="" />
                      </div>
                      <div className='varientloop'>
                          <Skeleton variant="circular" className='variantimg' />
                          <Skeleton variant="text" className="" />
                      </div>
                      <div className='varientloop'>
                          <Skeleton variant="circular" className='variantimg' />
                          <Skeleton variant="text" className="" />
                      </div>
                      <div className='varientloop'>
                          <Skeleton variant="circular" className='variantimg' />
                          <Skeleton variant="text" className="" />
                      </div>
                  </div>
              </div>
              <div className='selectboxskeleton'>
                  <Skeleton variant="text" className="titleskeleton" />
                  <Skeleton variant="rectangular" className='selectbox' />
              </div>
              <div className='selectboxskeleton'>
                  <Skeleton variant="text" className="titleskeleton" />
                  <Skeleton variant="rectangular" className='selectbox' />
              </div>
              <div className='selectboxskeleton'>
                  <Skeleton variant="text" className="titleskeleton" />
                  <Skeleton variant="rectangular" className='selectbox' />
              </div>
              <div className='buttonsection'>
              <Skeleton variant="rectangular" className='btnskeleton' />
              <Skeleton variant="rectangular" className='btnskeleton' />
              </div>
            </Grid>
          </Grid>
          <div className='productdescription'>
              <div className='headingskeleton'>
              <Skeleton variant="text" className="pdtitle" />
              <Skeleton variant="text" className="pdtitle" />
              <Skeleton variant="text" className="pdtitle" />
              </div>
              <Skeleton variant="text" className="pddsc" />
              <Skeleton variant="text" className="pddsc" />
              <Skeleton variant="text" className="pddsc" />
              <Skeleton variant="text" className="pddsc" />
          </div>
        </Stack>
      </Container>
    </div>
  );
}