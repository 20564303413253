import { Container } from '@mui/material'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import LightBtn from '../../components/universal/Buttons/LightBtn'

const OrderFailed = () => {
    const { state } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='Orderfailed'>
            <Container className='all-screen-container'>
                <div className="order-section">
                    <div className="order-heading  main-heading40">Order Failed!</div>
                    <div className="order-number ">
                        Your last transcation was not completed.
                    </div>
                    <>
                        {/* <div className="order-heading main-heading40">Payment for last transaction failed due to authorization, please retry payment.</div> */}
                        <div className="tree-text">
                            {state?.message}</div>
                        <div className='back-btn'>
                            <LightBtn onClick={() => { window.history.back() }} title="Back to checkout" />
                        </div>
                    </>
                </div>
            </Container>
        </div>
    )
}

export default OrderFailed