import loadable from '@loadable/component';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RedeemVoucherEmail from '../../components/dialogs/RedeemVoucherEmail';
import CandleSection from '../../components/home/CandlesSection';
import ImageBanner from '../../components/home/ImageBanner';
import MainSkeleton from '../../components/Skeleton';
import ImageBannerSkeleton from '../../components/Skeleton/imageBanner';
import SectionSkeleton from '../../components/Skeleton/sectionSkeleton';
import FeaturedCollections from '../../components/universal/FeaturedCollectionsNew';
import CustomHelmet from '../../components/universal/Helmet';
import { path } from '../../core/constants';
import AxiosAll from '../../services/axiosAll';

const OurStory = loadable(() => import('../../components/home/OurStory'));
const BlogSection = loadable(() => import('../../components/home/BlogSection'));
const DynamicContent = loadable(() =>
  import('../../components/home/DynamicContent')
);
const EthicalBrand = loadable(() =>
  import('../../components/home/EthicalBrand')
);
const ExclusiveBlogs = loadable(() =>
  import('../../components/home/ExclusiveBlogs')
);
const FavouriteBrands = loadable(() =>
  import('../../components/home/FavouriteBrands')
);
const FromTheGram = loadable(() => import('../../components/home/FromTheGram'));
const ImageSection = loadable(() =>
  import('../../components/home/ImageSection')
);
const NewsLetterSection = loadable(() =>
  import('../../components/home/NewsLetterSection')
);
const ShopByValues = loadable(() =>
  import('../../components/home/ShopByValues')
);
const ShopCollectionSection = loadable(() =>
  import('../../components/home/ShopCollectionSection')
);
const Testimonials = loadable(() =>
  import('../../components/home/Testimonials')
);
const TrendingSection = loadable(() =>
  import('../../components/home/TrendingSection')
);
const LatestArrivals = loadable(() =>
  import('../../components/universal/LatestArrivals')
);

const Home = () => {
  const [data, setData] = useState([]);
  const { isShowLatter } = useSelector((state) => state.newsLatterReducer);
  const [Loading, setLoading] = useState(true);
  const location = useLocation();
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [lastPage, setLastPage] = useState(0);
  const [iteration, setIteration] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  const homeData = JSON.parse(localStorage.getItem('homeData'));
  const [isCacheLoading, setIsCacheLoading] = useState(false);

  const getHomeData = async () => {
    AxiosAll('get', `${path.homeData}?page=1&`, data, userData.userToken)
      .then(function (response) {
        const to = response.data.result.lastPage;
        setLastPage(to);
        setTotalCount(response.data.result.total);

        for (let i = 1; i <= to; i++) {
          setIteration(i);
          AxiosAll(
            'get',
            `${path.homeData}?page=${i}&`,
            data,
            userData.userToken
          ).then(function (response) {
            const data = response.data.result.data;

            setData((prevState) => [...prevState, ...data]);
          });
        }
      })
      .catch(function (error) {});
  };

  const sortedData = data.sort(function (a, b) {
    return a.orderBy.valueOf() - b.orderBy.valueOf();
  });

  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const addCache = () => {
    if (sortedData.length === totalCount) {
      setIsCacheLoading(true);
      localStorage.setItem('homeData', JSON.stringify(sortedData));
      localStorage.setItem('homeDataTime', new Date().getTime());
      setTimeout(() => setLoading(false), 200);
      setIsCacheLoading(false);
    }
  };

  useEffect(() => {
    getHomeData();
  }, []);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const homeDataTime = localStorage.getItem('homeDataTime');
    if (homeDataTime) {
      const timeDifference = currentTime - homeDataTime;
      // 180000 = 3 minutes
      if (timeDifference > 180000) {
        localStorage.removeItem('homeData');
        addCache();
      }
    }
  }, [sortedData, totalCount]);

  useEffect(() => {
    if (!localStorage.getItem('homeData')) {
      if (iteration === lastPage && totalCount === sortedData.length) {
        addCache();
      }
    }
  }, [iteration, lastPage, sortedData, totalCount]);

  useLayoutEffect(() => {
    if (location.pathname === '/login') {
      window.location.href = 'https://seller.goethical.com/login';
    }
  }, [location.pathname]);

  return (
    <>
      <CustomHelmet
        title="Buy Sustainable Products Online | Ethical Marketplace | GoEthical"
        description="Shop artisan, shop a wide range of organic, cruelty-free, vegan & sustainable products including, gifts and gift sets, fashion, Featured Designerwear, toys & much more."
        keyword="shop artisan, Gifts and Gift Sets, Featured Designerwear, goethical"
        metaDescription="Shop artisan, shop a wide range of organic, cruelty-free, vegan & sustainable products including, gifts and gift sets, fashion, Featured Designerwear, toys & much more."
        path=" "
      />
      <RedeemVoucherEmail />
      {homeData === null ? (
        <MainSkeleton />
      ) : (
        homeData?.map((item, index) => {
          return (
            <div key={index} className="homepage27">
              {item.sectionType == 'banner_with_multiple_image' ? (
                item.content ? (
                  <ImageBanner CustomData={item?.content} />
                ) : (
                  <ImageBannerSkeleton />
                )
              ) : null}
              {item.sectionType == 'trending_product' ? (
                item.content ? (
                  <div className="scrollenable">
                    <TrendingSection CustomData={item?.content} />
                  </div>
                ) : (
                  <SectionSkeleton />
                )
              ) : null}
              {item.sectionType == 'shop_by_value' ? (
                item?.content ? (
                  <div className="scrollenable">
                    <ShopByValues CustomData={item} />
                  </div>
                ) : (
                  <SectionSkeleton />
                )
              ) : null}
              {item.sectionType == 'custom_products' ? (
                item?.content ? (
                  <div className="scrollenable">
                    <DynamicContent CustomData={item?.content} />
                  </div>
                ) : (
                  <SectionSkeleton />
                )
              ) : null}
              {item.sectionType == 'latest_arrival' ? (
                <div className="scrollenable">
                  <LatestArrivals CustomData={item.content} />        
                </div>
              ) : null}

                {item.sectionType == 'new_dynamic_content_block' ? (
                <div className="scrollenable">     
                  <CandleSection CustomData={item.content} />        
                </div>
              ) : null}
              {item.sectionType == 'right_image_left_content' ? (
                <OurStory CustomData={item.content} />
              ) : null}
              {item.sectionType == 'theme_base_content' ? (
                item?.content ? (
                  <ImageSection CustomData={item.content} />
                ) : (
                  <ImageBannerSkeleton />
                )
              ) : null}
              {item.sectionType == 'shop_collection' ? (
                item?.content ? (
                  <div className="scrollenable">
                    <FeaturedCollections CustomData={item.content} />
                    {/* <ShopCollectionSection CustomData={item.content} /> */}
                  </div>
                ) : (
                  <SectionSkeleton />
                )
              ) : null}
              {item.sectionType == 'testimonial' ? (
                item?.content ? (
                  <div className="scrollenable">
                  <Testimonials CustomData={item.content} />
                  </div>
                ) : (
                  <SectionSkeleton />
                )
              ) : null}
              {item.sectionType == 'feature_brand' ? (
                item?.content ? (
                  <div className="scrollenable">
                    <FavouriteBrands CustomData={item.content} />
                  </div>
                ) : (
                  <SectionSkeleton />
                )
              ) : null}
              {item.sectionType == 'registration_section' ? (
                // <div className="scrollenable">
                  <EthicalBrand CustomData={item.content} />
                // </div>
              ) : null}
              {item.sectionType == 'instagram_post' ? (
                item?.content ? (
                  <div className="scrollenable">
                    <FromTheGram CustomData={item.content} />
                  </div>
                ) : (
                  <SectionSkeleton />
                )
              ) : null}
              {item.sectionType == 'blog' ? (
                item?.content ? (
                  <div className="scrollenable">
                  <BlogSection CustomData={item.content} />
                  </div>
                ) : (
                  <SectionSkeleton />
                )
              ) : null}
              {item.sectionType ==
              'newsletter_related_blog_ideas_and_personalised_tips' ? (
                <ExclusiveBlogs CustomData={item.content} />
              ) : null}
             
            </div>
          );
        })
      )}
      {isShowLatter ? <NewsLetterSection /> : null}
{/* <FeaturedCollections/> */}
     {/* <NewFeaturedCard/> */}
    </>
  );
};

export default Home;
