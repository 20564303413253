import axios from 'axios';
import { getScreenSize, getScreenSizeImagDimention } from '../core/constants';
import currentURL from '../env';
import { deleteLogin } from '../redux/actions/login';
import { store } from '../redux/store';

const AxiosAll = async (method, url, params, userToken) => {
  const state = store.getState();
  const { guestDetails } = state.guestReducer;
  const baseURL = currentURL.currentEnv;
  return await axios({
    method: method,
    url: `${baseURL}${url}deviceType=3&deviceToken=${guestDetails?.token}&platform=${getScreenSize()}&screen_type=${getScreenSizeImagDimention()}`,
    data: params,
    headers: { 'Content-Type': 'application/json', userToken: userToken!==undefined?userToken:'12345' },
  })
    .then((res) => {
      if (res.data.statusCode === 401) {
        store.dispatch(deleteLogin());
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
export default AxiosAll;
