import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Footer from '../components/universal/Footer';
import { CurrrentHeader } from '../components/universal/Header/headerConfig';
import SellerFooter from '../components/universal/SellerFooter';
import SellerHeader from '../components/universal/SellerHeader';
import WpHeader from '../components/universal/WpHeader';
import { addGuestUser } from '../redux/actions/guestUser';
import AxiosAll from '../services/axiosAll';
import AppRoutes from './app';
import PublicRoutes from './public';
const Routes = (props) => {
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const dispatch = useDispatch();
const [state, setstate] = useState(false);
  const location = window.location.pathname;

  const getUserData = async () => {
    try {
      const res = await AxiosAll('post', 'user/guestRegister?', {
        browser_id: guestDetails.browser_id,
        deviceToken: guestDetails.token,
        deviceType: 3,
      });
      dispatch(addGuestUser(res.data.result));
      setstate(true)
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, [window.location.pathname, window.location.search]);

  const getBasketCount = async () => {
    try {
      const res = await AxiosAll('get', 'basketCount?', '', userDetails.token);
      const data = res?.data?.result?.totalProducts;

      await localStorage.setItem('basketCount', data != undefined ? data : 0);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    localStorage.setItem('isBasketCountUpdated', false);

    // if userData is empty, get user data
    if (!userDetails.token) {
      getUserData();
    }
  }, [userDetails.token]);

  useEffect(() => {
    getBasketCount();
  }, []);


  // checking For user token for login
  return (
  <BrowserRouter>
 
      {window.location.href.includes('/reset-password') ? (
        <SellerHeader />
      ) : location === '/wordpress-header' ? (
        <WpHeader />
      ) : (
        userDetails.token?
        <CurrrentHeader />:
        state?
        <CurrrentHeader />:null
      )}
      <Toaster />
      { userDetails.token ? <AppRoutes /> :state? <PublicRoutes />:null}
      {window.location.href.includes('/reset-password') ? (
        <SellerFooter />
      ) : window.location.href.includes('/wordpress-header') ? null : (
        userDetails.token?
        <Footer />:
        state?
        <Footer />:null 
      )}
    </BrowserRouter>
  );
};

export default Routes;
