import { Container, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import StoreSkeleton from '../../components/Skeleton/storeSkeleton';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import ProductBtn from '../../components/universal/Buttons/ProductBtn';
import MultiColor from '../../components/universal/Heading/MultiColor';
import CustomHelmet from '../../components/universal/Helmet';
import InnerBanner from '../../components/universal/InnerBanner';
import ProductSlider from '../../components/universal/ProductSlider';
import Rate from '../../components/universal/Rating';
import Reviews from '../../components/universal/Reviews';
import Store from '../../components/universal/Store';
import {
  baseUrl,
  baseUrlV1,
  deviceToken,
  getScreenSize,
  getScreenSizeImagDimention
} from '../../core/constants';
const SellerStorefront = () => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const navigate = useNavigate();
  const [PopularProduct, setPopularProduct] = useState([]);
  const [StoreDetail, setStoreDetail] = useState({});
  const [isLoadingProperProducts, setisLoadingProperProducts] = useState(true);
  const [isLoadingDetails, setisLoadingDetails] = useState(true);
  const [SkiptheSection, setSkiptheSection] = useState(false);
  const params = useParams();
  const location = useLocation();

  const [returnPolicy, setReturnPolicy] = React.useState('');
  const [refundPolicy, setRefundPolicy] = React.useState('');
  const [shippingPolicy, setShippingPolicy] = React.useState('');
  const filter = {
    feedtype: 24,
    startprice: 0,
    endprice: 1000,
    search: '',
    metadatas: '',
    tags: '',
    sortbyprice: 0,
    isFreeDelivery: 0,
    discountSale: 0,
    isSellerFilter: 0,
    sellers: 0,
    isEthicalValueFilter: 0,
    ethicalValues: 0,
    customHomeFilterType: '',
    customHomeFilterTypeId: '',
    shopCollection: 0,
    customHeaderLinkFilterType: 0,
    customHeaderLinkFilterTypeId: 0,
    customHeaderLinkFilterTypeAll: 0,
    customButtonLinkFilterType: 0,
    customButtonLinkFilterTypeId: 0,
    per_page_limit: 24,
    type: 1,
  };

  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const getTagProducts = async () => {
    try {
      const res = await axios(
        `${baseUrl}product?feedtype=24&page=1&deviceType=3&deviceToken=${deviceToken}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken:userData?.userToken?userData?.userToken:"12345",
          },
        }
      );
      setPopularProduct(res?.data?.result?.data);

      setisLoadingProperProducts(false);
    } catch (error) {
      console.log(error);
    }
  };

  const perPageProduct = async () => {
    try {
      const res = await axios(
        `${baseUrlV1}product/user-products/${params?.title
        }?page=1&tags=&metadatas=&sortbyprice=&per_page_limit=12&deviceType=3&deviceToken=${deviceToken}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      const data = res.data?.result;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    perPageProduct();
  }, []);

  const getStoreDetails = async () => {
    try {
      const res = await axios(
        `${baseUrlV1}user/${params?.title
        }?deviceType=3&deviceToken=${deviceToken}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken: userData?.userToken?userData?.userToken:"12345",
          },
        }
      );

      setStoreDetail(res?.data?.result);
      setisLoadingDetails(false);
      window.scroll(0, 0);
      setReturnPolicy(
        res?.data?.result?.user?.metadata.find(
          (o) => o.typeName === 'RETURN_POLICY'
        )?.content
      );
      setRefundPolicy(
        res?.data?.result?.user?.metadata.find(
          (e) => e.typeName === 'REFUND_POLICY'
        )?.content
      );
      setShippingPolicy(
        res?.data?.result?.user?.metadata.find(
          (i) => i.typeName === 'SHIPPING_POLICY'
        )?.content
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTagProducts();
    getStoreDetails();
  }, [params?.title,userData?.userToken]);

  return isLoadingDetails ? (
    <StoreSkeleton />
  ) : (
    <div className="sellerStorefront">
      {SkiptheSection ? null : (
        <InnerBanner
          data={StoreDetail?.sellerStoreFront}
          shopCollectiontype={true}
        />
      )}

      <div className="products">
        <CustomHelmet
          title={StoreDetail?.sellerStoreFront?.seoMetaTitle
            ? StoreDetail?.sellerStoreFront?.seoMetaTitle
            :`${StoreDetail?.user?.userName} | GoEthical`}
          metaTitle={
            StoreDetail?.sellerStoreFront?.seoMetaTitle
              ? StoreDetail?.sellerStoreFront?.seoMetaTitle
              : StoreDetail?.user?.userName
          }
          metaDescription={
            StoreDetail?.sellerStoreFront?.seoMetaDescription
              ? StoreDetail?.sellerStoreFront?.seoMetaDescription
              : StoreDetail?.user?.bio
          }
          description={StoreDetail?.user?.bio}
          imageUrl={ StoreDetail?.sellerStoreFront?.seoMetaImage? StoreDetail?.sellerStoreFront?.seoMetaImage:StoreDetail?.user?.profilePicture}
          keyword={ StoreDetail?.sellerStoreFront?.seoMetaKeywords? StoreDetail?.sellerStoreFront?.seoMetaKeywords:StoreDetail?.user?.userName}
          url={window.location.href}
          path={window?.location?.pathname}
        />

        {/* bug by sanket sir to show sellername not store name */}
        <Container className="all-screen-container">
          <div className="BreadcrumbList ">
            <h2 className="breadcrumb-title"> Sellers / </h2>
            <h2 className="breadcrumb-title bold">
              {StoreDetail?.user?.name
                ? StoreDetail?.user?.name
                : StoreDetail?.user?.userName}{' '}
            </h2>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={5} sm={2}>
              <div className="logobox">
                <img
                  loading="lazy"
                  alt={
                    StoreDetail?.user?.userName
                      ? StoreDetail?.user?.userName
                      : StoreDetail?.user?.name
                  }
                  title={
                    StoreDetail?.user?.userName
                      ? StoreDetail?.user?.userName
                      : StoreDetail?.user?.name
                  }
                  src={
                    StoreDetail?.sellerStoreFront?.logo
                      ? StoreDetail?.sellerStoreFront?.logoWebp
                        ? StoreDetail?.sellerStoreFront?.logoWebp
                        : StoreDetail?.sellerStoreFront?.logo
                      : StoreDetail?.user?.profilePicture
                  }
                />
              </div>
            </Grid>
            <Grid item xs={7} sm={10} className="titlereviewsection">
              <div className="titlesection">
                <div className="seller-name">
                  {' '}
                  {StoreDetail?.user?.userName
                    ? StoreDetail?.user?.userName
                    : StoreDetail?.user?.name}{' '}
                </div>
                <div className="storetag">
                  {' '}
                  {StoreDetail?.sellerStoreFront?.tagLine}
                </div>
              </div>
              {StoreDetail?.userStatsData?.reviewsRecievedCount != 0 && (
                <div className="product-rating">
                  <div className="rating-section">
                    <div className="review">
                      (
                      {StoreDetail?.userStatsData?.reviewsRecievedCount
                        ? StoreDetail?.userStatsData?.reviewsRecievedCount
                        : 0}{' '}
                      {`${StoreDetail?.userStatsData?.reviewsRecievedCount > 1
                          ? 'Reviews'
                          : 'Review'
                        }`}{' '}
                      )
                    </div>
                    <div>
                      <Rate
                        defaultRating={
                          StoreDetail?.user?.averageRating
                            ? StoreDetail?.user?.averageRating
                            : 0
                        }
                      />
                    </div>
                  </div>
                  <div className="read-reviews">
                    {StoreDetail?.userStatsData?.reviewsRecievedCount > 1
                      ? 'Read Reviews'
                      : 'Read Review'}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          {SkiptheSection ? null : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="Seller-Storefront-info">
                    <div className="store-container">
                      <div className="about-tab">
                        <button
                          onClick={() => setSkiptheSection(true)}
                          className="skip-btn heading20"
                        >
                          Skip to section
                        </button>
                        <div fxlayout="column">
                          <ScrollContainer className="scroll-container">
                            <div className="btnmobile">
                              {StoreDetail?.sellerStoreFront && (
                                <HashLink
                                  to={`${window.location.pathname}#About`}
                                >
                                  {window.location.href.includes('#About') ? (
                                    <DarkBtn title="About" />
                                  ) : (
                                    <ProductBtn
                                      title="About"
                                    // className="about-btn"
                                    />
                                  )}
                                </HashLink>
                              )}

                              <HashLink
                                to={`${window.location.pathname}#Store`}
                              >
                                {window.location.href.includes('#Store') ? (
                                  <DarkBtn title="Store" />
                                ) : (
                                  <ProductBtn
                                    title="Store"
                                  // className="about-btn"
                                  />
                                )}
                              </HashLink>

                              <HashLink
                                to={`${window.location.pathname}#Review`}
                              >
                                {window.location.href.includes('#Review') ? (
                                  <DarkBtn title="Reviews" />
                                ) : (
                                  <ProductBtn
                                    title="Reviews"
                                  // className="about-btn"
                                  />
                                )}
                              </HashLink>

                              <HashLink
                                to={`${window.location.pathname}#Policies`}
                              >
                                {window.location.href.includes('#Policies') ? (
                                  <DarkBtn title="Policies" />
                                ) : (
                                  <ProductBtn
                                    title="Policies"
                                  // className="about-btn"
                                  />
                                )}
                              </HashLink>
                            </div>
                          </ScrollContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>

              {/* About us */}
              {StoreDetail?.sellerStoreFront && (
                <>
                  <Grid container spacing={4} className="seller-info">
                    <Grid item xs={12} sm={12} md={7}>
                      <div className="about-title">
                        {' '}
                        {StoreDetail?.sellerStoreFront?.heading}
                      </div>
                      <div className="about-sub-title">
                        {' '}
                        {StoreDetail?.sellerStoreFront?.subHeading1}
                      </div>
                      {StoreDetail?.sellerStoreFront?.description1 ? (
                        <p
                          className="aboutsub-info heading20 "
                          dangerouslySetInnerHTML={{
                            __html: StoreDetail?.sellerStoreFront?.description1,
                          }}
                        ></p>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <img
                        loading="lazy"
                        alt={StoreDetail?.sellerStoreFront?.subHeading1}
                        title={StoreDetail?.sellerStoreFront?.subHeading1}
                        src={StoreDetail?.sellerStoreFront?.image1}
                        className="about-img"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={4}
                    className="seller-info invermobile"
                  >
                    <Grid item xs={12} sm={12} md={5}>
                      <img
                        alt={StoreDetail?.sellerStoreFront?.subHeading2}
                        title={StoreDetail?.sellerStoreFront?.subHeading2}
                        loading="lazy"
                        src={StoreDetail?.sellerStoreFront?.image2}
                        className="about-img"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} className="paddleft20">
                      <div className="about-sub-title">
                        {' '}
                        {StoreDetail?.sellerStoreFront?.subHeading2}
                      </div>
                      {StoreDetail?.sellerStoreFront?.description2 ? (
                        <p
                          className="aboutsub-info heading20 "
                          dangerouslySetInnerHTML={{
                            __html: StoreDetail?.sellerStoreFront?.description2,
                          }}
                        ></p>
                      ) : null}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Container>
        <div id="Store">
          <Store title={params?.title} />
        </div>
      </div>

      {/* popular product section */}
      <div className="popular-product-section">
        <Container className="all-screen-container">
          <div className="head-btn scrollenable">
            <Grid container spacing={2} className="headingmarbtm">
              <Grid item xs={12} sm={2} className="textright mobilehide"></Grid>
              <Grid item xs={12} sm={12} md={8} className="">
                <MultiColor coloredTitle="Popular" title="Products" />
              </Grid>
              <Grid item xs={12} sm={2} className="textright mobilehide">
                <LightBtn
                  onClick={() => navigate('/popular-products')}
                  title="Shop All"
                />
              </Grid>
            </Grid>
          </div>
          <div className="hidedot scrollenable">
            {isLoadingProperProducts
              ? null
              : PopularProduct && (
                <ProductSlider trending Data={PopularProduct} />
              )}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="mobileshow textcenter">
              <DarkBtn
                onClick={() => navigate('/products/popular-products')}
                title="Shop All"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* Review section */}
      <div id="Review">
        <Reviews id={params?.title} type="Store" />
        {/* Shop Policies section */}
      </div>
      <div id="Policies" className="shop-policies-section">
        <Container className="all-screen-container">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <div className="shoppolicies-heading">Shop Policies</div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <div className="shoppolicies-para heading20">
                {' '}
                {shippingPolicy
                  ? shippingPolicy
                  : 'No shopping policy available.'}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <div className="shoppolicies-heading">Returns & Exchanges</div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <div className="shoppolicies-para heading20">
                {returnPolicy ? returnPolicy : 'No return policy available.'}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="shoppaddingbt">
            <Grid item xs={12} sm={12} md={3}>
              <div className="shoppolicies-heading">Refund Policies</div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <div className="shoppolicies-para heading20">
                {refundPolicy ? refundPolicy : 'No refund policy available.'}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default SellerStorefront;
