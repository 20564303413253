import { Container, Grid } from '@mui/material';
import React from 'react';
import { images } from '../../../assets/_index';
import LightBtn from '../../universal/Buttons/LightBtn';
import { CrawerLink } from '../../universal/CrawlerLink';

const EthicalBrand = ({ CustomData }) => {
  return (
    <div className="ethicalbrand">
      <div
        className="ethical-container"
        style={{
          backgroundImage: `url(${
            CustomData?.backgroundImage
              ? CustomData?.backgroundImageWebp
                ? CustomData?.backgroundImageWebp
                : CustomData?.backgroundImage
              : images?.ethicalBackground
          })`,
        }}
      >
        <Container className="all-screen-container">
          <Grid container spacing={2} className="big27scree">
            <Grid item xs={3} sm={3} className="mobilehide">
              <img
                loading="lazy"
                title="ethical image"
                alt="ethical image"
                className="ethical-icon"
                src={
                  CustomData?.image
                    ? CustomData?.imageWebp
                      ? CustomData?.imageWebp
                      : CustomData?.image
                    : images?.ethicalIcon
                }
              />
            </Grid>
            <Grid item xs={7} sm={4}>
              <h2 className="ethical-heading">
                {CustomData?.leftContent?.title
                  ? CustomData?.leftContent?.title
                  : 'Are you an Ethical Brand ?'}
              </h2>
              <p className="ethical-para">
                {CustomData?.leftContent?.description
                  ? CustomData?.leftContent?.description
                  : 'Register to become an Ethical Seller with us today'}
              </p>
              <CrawerLink
                path="https://seller.goethical.com/pro-seller-registration"
                type="url"
              />
              <LightBtn
                onClick={() =>
                  window.open(
                    'https://seller.goethical.com/pro-seller-registration',
                    '_blank'
                  )
                }
                title={
                  CustomData?.leftContent?.buttonLabel
                    ? CustomData?.leftContent?.buttonLabel
                    : 'Register now'
                }
              />
            </Grid>
            <Grid item xs={5} sm={3} className="mobileshow">
              <img
                loading="lazy"
                alt="ethical image"
                title="ethical image"
                className="ethical-icon"
                src={
                  CustomData?.image ? CustomData?.image : images?.ethicalIcon
                }
              />
            </Grid>
            <Grid item xs={4} sm={3} className="mobileshow"></Grid>
            <Grid item xs={8} sm={4}>
              <div
                className="ethical-heading mobiletextright"
                dangerouslySetInnerHTML={{
                  __html: CustomData?.rightContent?.title
                    ? CustomData?.rightContent?.title
                    : `Selling <span className="diffHeading"> Artisan</span> or <span className="diffHeading"> Preloved ? </span>`,
                }}
              ></div>
              <div className="ethical-para">
                {CustomData?.rightContent?.description
                  ? CustomData?.rightContent?.description
                  : 'Start selling today on the app!'}
              </div>
              <LightBtn
                onClick={() =>
                  window.open(
                    'https://seller.goethical.com/create-account/seller-registration',
                    '_blank'
                  )
                }
                title={
                  CustomData?.rightContent?.buttonLabel
                    ? CustomData?.rightContent?.buttonLabel
                    : 'Register now'
                }
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default EthicalBrand;
