import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import ChcekoutField from '../../InputField/CheckoutField';
import DarkBtn from '../../Buttons/DarkBtn';
import AxiosAll from '../../../../services/axiosAll';
import { notify } from '../../../../core/constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateGuestUser } from '../../../../redux/actions/guestUser';
import ProductBtn from '../../Buttons/ProductBtn';

const EditAddress = ({ address, setIsShowEdit }) => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);

  const dispatch = useDispatch();

  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    postCode: '',
    country: '',
    email: '',
    phone: '',
    isDefault: false,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    postCode: Yup.string().required('Post code is required'),
    country: Yup.string().required('Country is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    phone: Yup.string()
      .required('Phone is required')
      .min(10, 'Phone number is invalid')
      .max(13, 'Phone number is invalid'),
  });

  const updateAddress = async (data) => {
    try {
      const res = await AxiosAll(
        'put',
        `address/${address.id}?`,
        data,
        userData.userToken
      );
      if (res.data.status === 'Success') {
        notify(res.data.message, 'success');
        setIsShowEdit(false);
        const height = (
          (window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight) / 20
        ).toFixed(0);

        window.scrollTo(
          0,
          document.getElementById('delivery-section').offsetTop -
            parseInt(height)
        );
      } else {
        notify(res.data.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (values) => {
    const data = {
      addressName: values.firstName + ' ' + values.lastName,
      address: values.address,
      address2: values.address2,
      city: values.city,
      postcode: values.postCode,
      country: values.country,
      email: values.email,
      phone: values.phone,
      is_default_address: values.isDefault ? 1 : 0,
      latitude: '28.4575230000000000',
      longitude: '77.0266270000000000',
      is_new: 1,
    };
    updateAddress(data);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (address && address?.addressName) {
      const name = address?.addressName?.split(' ');
      const firstName = name?.slice(0, name.length - 1)?.join(' ');
      const lastName = name[name?.length - 1];
      formik.setFieldValue('firstName', firstName);
      formik.setFieldValue('lastName', lastName);
      formik.setFieldValue('address', address.address);
      formik.setFieldValue('address2', address.address2);
      formik.setFieldValue('city', address.city);
      formik.setFieldValue('postCode', address.postcode);
      formik.setFieldValue('country', address.country);
      formik.setFieldValue('email', address.email);
      formik.setFieldValue('phone', address.phone);
      formik.setFieldValue(
        'isDefault',
        address.isDefaultAddress === 1 ? true : false
      );
      formik.setFieldValue('email', userData.user.email);
    }
  }, [address]);

  return (
    <form onSubmit={formik.handleSubmit} className="inputfeilds">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <ChcekoutField
            title="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            type="text"
            error={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <ChcekoutField
            title="Surname"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            type="text"
            error={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
      </Grid>
      <div className="addressmanually">
        <ChcekoutField
          title="Enter address line 1"
          name="address"
          value={formik.values.address}
          onChange={formik.handleChange}
          type="text"
          error={formik.touched.address && formik.errors.address}
        />
        <ChcekoutField
          title="Add Company, C/O, Apt, Suite, Unit"
          name="address2"
          value={formik.values.address2}
          onChange={formik.handleChange}
          type="text"
          error={formik.touched.address2 && formik.errors.address2}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} xl={4}>
            <ChcekoutField
              title="Town/City"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              type="text"
              error={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} xl={4}>
            <ChcekoutField
              title="Postcode"
              name="postCode"
              value={formik.values.postCode}
              onChange={formik.handleChange}
              type="text"
              error={formik.touched.postCode && formik.errors.postCode}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} xl={4}>
            <ChcekoutField
              title="Country/Region"
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              type="text"
              error={formik.touched.country && formik.errors.country}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <ChcekoutField
            title="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            type="email"
            error={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <ChcekoutField
            title="Phone number"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onKeyDown={(e) => {
              if (
                [
                  'Backspace',
                  'Delete',
                  'Tab',
                  'Escape',
                  'Enter',
                  'ArrowLeft',
                  'ArrowRight',
                  '0',
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                ].indexOf(e.key) < 0
              ) {
                e.preventDefault();
              }
            }}
            type="number"
            min="1"
            error={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        {userDetails?.token && (
          <div className="usecredit">
            <FormControlLabel
              control={<Checkbox />}
              label="Set As Default"
              name="isDefault"
              checked={formik.values.isDefault}
              value={formik.values.isDefault}
              onChange={(e) =>
                formik.setFieldValue('isDefault', e.target.checked)
              }
            />
          </div>
        )}
      </Grid>
      <div className="btnalignright">
        {formik.values.firstName === '' ||
        formik.values.lastName === '' ||
        formik.values.address === '' ||
        formik.values.city === '' ||
        formik.values.postCode === '' ||
        formik.values.country === '' ||
        formik.values.email === '' ||
        formik.values.phone === '' ? (
          <ProductBtn title="Save & Continue" disabled={true} type="button" />
        ) : (
          <DarkBtn title="Save & Continue" type="submit" />
        )}

        <DarkBtn
          title="Cancel"
          type="button"
          onClick={() => {
            setIsShowEdit(false);
            const element = document.getElementById('delivery-section');
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }}
        />
      </div>
    </form>
  );
};

export default EditAddress;
