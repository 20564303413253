import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CrawerLink } from '../CrawlerLink';

const NewFeaturedCard = ({ title, image, link, id, type, item }) => {

  const navigate = useNavigate();

  return (
    <div
      onClick={() => {navigate({
              pathname: `/shop-collection-${item?.linkSlug?.replaceAll(/ /g, '-')}`,
            })
      }}
      className="featurecardnew"
    >
    
      <img loading="lazy" alt={title} title={title} className="favcard" src={image} />
      <div className="favproduct"> {title} </div>
    </div>
  );
};

export default NewFeaturedCard;
