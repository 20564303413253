import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { notify } from '../../../../core/constants';
import { updateGuestUser } from '../../../../redux/actions/guestUser';
import AxiosAll from '../../../../services/axiosAll';
import DarkBtn from '../../Buttons/DarkBtn';
import ProductBtn from '../../Buttons/ProductBtn';
import CheckoutField from '../../InputField/CheckoutField';
import ChcekoutField from '../../InputField/CheckoutField';
import SearchIcon from '@mui/icons-material/Search';

const AddAddress = ({
  setIsNewAddressAdded,
  isNewAddressAdded,
  setIsAddNewAddress,
  isAddNewAddress,
  addressList,
  setIsShowAddressEditList,
}) => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    automaticAddress: '',
    enterManually: false,
    city: '',
    postCode: '',
    country: '',
    email: '',
    phone: '',
    isDefault: false,
    sameAsBilling: false,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      // only alphabets and space
      .matches(/^[a-zA-Z ]+$/, 'Firstname must be alphabets only')
      .required('First name is required'),
    lastName: Yup.string()
      .matches(/^[a-zA-Z ]+$/, 'Surname must be alphabets only')
      .required('Last name is required'),
    enterManually: Yup.boolean().required('Enter manually is required'),
    address: Yup.string().when('enterManually', {
      is: true,
      then: Yup.string().required('Address is required'),
    }),
    city: Yup.string().when('enterManually', {
      is: true,
      then: Yup.string().required('City is required'),
    }),
    postCode: Yup.string().when('enterManually', {
      is: true,
      then: Yup.string().required('Post code is required'),
    }),
    country: Yup.string().when('enterManually', {
      is: true,
      then: Yup.string().required('Country is required'),
    }),
    country: Yup.string().required('Country is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    phone: Yup.string()
      .matches(/^[0-9]*$/, 'Phone number must be numeric only')
      .required('Phone is required')
      .min(10, 'Phone number is invalid')
      .max(13, 'Phone number is invalid'),
  });

  const addAddress = async (data) => {
    setIsLoading(true);
    try {
      const res = await AxiosAll('post', 'address?', data, userData.userToken);
      if (res.data.status === 'Success') {
        notify(res.data.message, 'success');
        setIsNewAddressAdded(!isNewAddressAdded);
        // setIsAddNewAddress(false);
        if (addressList?.length === 0) {
          const height = (
            (window.innerHeight ||
              document.documentElement.clientHeight ||
              document.body.clientHeight) / 20
          ).toFixed(0);

          window.scrollTo(
            0,
            document.getElementById('payment-section').offsetTop -
              parseInt(height)
          );
        }
      } else {
        notify(res.data.message, 'error');
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onSubmit = (values) => {
    const data = {
      address: values.address,
      address2: values.address2,
      addressName: `${values.firstName} ${values.lastName}`,
      city: values.city,
      country: values.country,
      is_default_address: values.isDefault ? 1 : 0,
      is_new: 1,
      latitude: '28.4575230000000000',
      longitude: '77.0263440000000000',
      phone: values.phone,
      postcode: values.postCode,
      email: values.email,
      address_type: 2,
    };
    if (!userDetails?.token && addressList.length < 1) {
      if (values?.email) {
        RegisterGuestEmail(values.email);
      } else {
        notify('Please Email Address', 'error');
      }
    } else {
      addAddress(data);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (userDetails?.token) {
      formik.setFieldValue('email', userDetails?.user?.email);
    }
  }, [userDetails]);

  const RegisterGuestEmail = async (email) => {
    try {
      const res = await AxiosAll(
        'post',
        'user/checkeoutasguest?',
        {
          email,
        },
        userData.userToken
      );

      if (res.data.status === 'Success') {
        let userDetails = {
          token: res?.data?.result?.userToken,
          user: res?.data?.result?.user,
        };
        let user = res?.data?.result?.user;
        dispatch(
          updateGuestUser({
            user: user,
            userToken: userDetails.token,
          })
        );

        const data = {
          address: formik.values.address,
          address2: formik.values.address2,
          addressName: `${formik.values.firstName} ${formik.values.lastName}`,
          city: formik.values.city,
          country: formik.values.country,
          is_default_address: formik.values.isDefault ? 1 : 0,
          is_new: 1,
          latitude: '28.4575230000000000',
          longitude: '77.0263440000000000',
          phone: formik.values.phone,
          postcode: formik.values.postCode,
          // email: values.email,
          address_type: 2,
        };
        addAddress(data);
      } else {
        notify(res.data.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      formik.values.firstName &&
      formik.values.lastName &&
      formik.values.email &&
      formik.values.phone
    ) {
      formik.setFieldValue('enterManually', true);
    }
  }, [
    formik.values.firstName,
    formik.values.lastName,
    formik.values.email,
    formik.values.phone,
  ]);

  return (
    <form onSubmit={formik.handleSubmit} className="">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <ChcekoutField
            title="First Name"
            autocomplete="given-name"
            name="firstName"
            id="given-name"
            value={formik.values.firstName?.replaceAll(' ', '')}
            onChange={formik.handleChange}
            onKeyDown={(e) => {
              if (
                !/^[a-zA-Z ]+$/.test(e.key) &&
                !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
            type="text"
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <ChcekoutField
            title="Surname"
            name="lastName"
            id="family-name"
            autocomplete="family-name"
            value={formik.values.lastName?.replaceAll(' ', '')}
            onKeyDown={(e) => {
              if (
                !/^[a-zA-Z ]+$/.test(e.key) &&
                !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            error={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
      </Grid>
      {!formik.values.enterManually && (
        <div className="iconwithsearch">
          <SearchIcon />
          <Autocomplete
            style={{
              width: '100%',
              padding: '10px',
              backgroundImage:
                'url("../../../../../src/assets/images/header/search-icon.webp")',
            }}
            className="address-input-checkout"
            fetchdetails="true"
            name="automaticAddress"
            inputAutocompleteValue={formik.values.automaticAddress}
            options={{
              strictBounds: true,
              types: [],
              componentRestrictions: { country: 'UK' },
            }}
            placeholder="Start typing the first line of your address"
            apiKey={'AIzaSyB-FuDQXKvkxz3sf9StDeCduqj4xXM1qpU'}
            onPlaceSelected={(place) => {
              const address = place.formatted_address;
              const address_components = place.address_components;
              formik.setFieldValue('address', address);
              const street_number = address_components.find((component) =>
                component.types.includes('street_number')
              );
              const postal_code = address_components.find((component) =>
                component.types.includes('postal_code')
              );
              const city = address_components.find((component) =>
                component.types.includes('locality')
              );
              const country = address_components.find((component) =>
                component.types.includes('country')
              );
              const city2 = address_components.find((component) =>
                component.types.includes('administrative_area_level_2')
              );
              // if (street_number) {
              //   formik.setFieldValue('address2', street_number.long_name);
              // }
              if (postal_code) {
                formik.setFieldValue('postCode', postal_code.long_name);
              }
              if (city) {
                formik.setFieldValue('city', city.long_name);
              }
              if (country) {
                formik.setFieldValue('country', country.long_name);
              }
              if (!city && city2) {
                formik.setFieldValue('city', city2.long_name);
              }
              formik.setFieldValue('enterManually', true);
            }}
          />
        </div>
      )}

      <div className="addressmanually">
        {!formik.values.enterManually && (
          <p
            onClick={() =>
              formik.setFieldValue(
                'enterManually',
                !formik.values?.enterManually
              )
            }
          >
            Enter address manually
          </p>
        )}
        {formik.values.enterManually && (
          <ChcekoutField
            title="Enter address line 1"
            name="address"
            id="address-line1"
            autocomplete="address-line1"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            error={formik.touched.address && formik.errors.address}
          />
        )}
        {formik.values.enterManually && (
          <ChcekoutField
            title="Add Company, C/O, Apt, Suite, Unit"
            name="address2"
            id="address-line2"
            autocomplete="address-line2"
            value={formik.values.address2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            error={formik.touched.address2 && formik.errors.address2}
          />
        )}
        {formik.values.enterManually && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} xl={4}>
              <ChcekoutField
                title="Town/City"
                autocomplete="address-level2"
                name="city"
                id="address-level2"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                error={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={4}>
              <ChcekoutField
                title="Postcode"
                name="postCode"
                id="postal-code"
                autocomplete="postal-code"
                value={formik.values.postCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                error={formik.touched.postCode && formik.errors.postCode}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={4}>
              <ChcekoutField
                title="Country/Region"
                name="country"
                id="country-name"
                autocomplete="country-name"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                error={formik.touched.country && formik.errors.country}
              />
            </Grid>
          </Grid>
        )}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <ChcekoutField
            title="Email"
            name="email"
            id="email"
            disabled={userDetails?.token ? true : false}
            autocomplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="email"
            error={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <CheckoutField
            title="Phone number"
            name="phone"
            id="tel"
            autocomplete="tel"
            value={formik.values.phone}
            onKeyDown={(e) => {
              if (
                [
                  'Backspace',
                  'Delete',
                  'Tab',
                  'Escape',
                  'Enter',
                  'ArrowLeft',
                  'ArrowRight',
                  '0',
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                ].indexOf(e.key) < 0
              ) {
                e.preventDefault();
              }
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            min="1"
            error={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        {userDetails?.token && (
          <div className="usecredit">
            <FormControlLabel
              control={<Checkbox />}
              label="Set As Default"
              name="isDefault"
              value={formik.values.isDefault}
              onChange={(e) =>
                formik.setFieldValue('isDefault', e.target.checked)
              }
            />
          </div>
        )}
      </Grid>
      <div className="btnalignright">
        {formik.values.firstName === '' ||
        formik.values.lastName === '' ||
        formik.values.email === '' ||
        formik.values.phone === '' ||
        formik.values.address === '' ||
        formik.values.city === '' ||
        formik.values.postCode === '' ||
        formik.values.country === '' ? (
          <ProductBtn title="Save & Continue" type="button" disabled={true} />
        ) : (
          <DarkBtn title="Save & Continue" type="submit" loading={isLoading} />
        )}

        {addressList?.length > 0 && isAddNewAddress && (
          <DarkBtn
            title="Cancel"
            type="button"
            onClick={() => {
              setIsAddNewAddress(false);
              const height = (
                (window.innerHeight ||
                  document.documentElement.clientHeight ||
                  document.body.clientHeight) / 20
              ).toFixed(0);
              window.scrollTo(
                0,
                document.getElementById('delivery-section').offsetTop -
                  parseInt(height)
              );
            }}
          />
        )}
      </div>
    </form>
  );
};

export default AddAddress;
