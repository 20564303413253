import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import axios from 'axios';
import { useFormik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { baseUrl, notify } from '../../../core/constants';
import ProductBtn from '../../universal/Buttons/ProductBtn';
import RetailersBtn from '../../universal/Buttons/RetailersBtn';
import OnOffSwitch from '../../universal/OnOffSwitch';

export default function AddGiftMessageDialog({
  item,
  findPrice2,
  setisUpdatecartmsg,
  giftWrappingSaved,
  setGiftWrappingSaved,
}) {
  const [open, setOpen] = React.useState(false);
  const { userData } = useSelector((state) => state.guestUserReducer);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.handleReset();
  };

  const initialValues = {
    giftMessage: item?.giftMessage,
    personalization: item?.personalization,
    isGiftWrapping: item?.isGiftWrapping == 1 ? true : false,
  };
  // const validationSchema = Yup.object().shape({
  //   giftMessage: Yup.string().required('Gift message is required'),
  //   personalization: Yup.string().required('Personalisation message is required'),
  // });
  const onSubmit = (values) => {
    console.log(values);
    const data = {
      personalization: values?.personalization,
      giftMessage: values?.giftMessage,
      isGiftWrapping: values?.isGiftWrapping ? 1 : 0,
    };
    if (
      formik.values.personalization.length >
      item?.productDetails?.personalizationCharterLimit
    ) {
      notify(
        `You have exceeded the limit of characters (${item?.productDetails?.personalizationCharterLimit}) for personalized messages.`,
        'error'
      );
    } else {
      infoUpdateApiCall(data);
    }
  };
  const infoUpdateApiCall = async (data) => {
    try {
      const response = await axios(
        `${baseUrl}cart/items/edit-gift-personalisation-message/${item?.cartItemId}`,
        {
          method: 'put',
          data: data,
          headers: {
            'Content-Type': 'application/json',
            userToken: userData?.userToken,
          },
        }
      );

      if (response.data.status === 'Success') {
        handleClose();
        notify(response.data.message, 'success');
        updateCallback();
        setGiftWrappingSaved(!giftWrappingSaved);
      } else {
        notify(response.data.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateCallback = () => {
    return setisUpdatecartmsg(true);
  };
  const formik = useFormik({
    initialValues,
    //validationSchema,
    onSubmit,
  });
  const cancelButton = () => {
    //formik.handleReset()
    setOpen(false);
    formik.setFieldValue('giftMessage', item?.giftMessage);
    formik.setFieldValue('personalization', item?.personalization);
  };
  return (
    <div>
      <div className="gift-option-dialog" onClick={handleClickOpen}>
        Gift Options
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="addgiftmessage"
      >
        <DialogContent>
          <div className="image-product">
            <img
              loading="lazy"
              src={item?.productDetails?.images[0]?.image}
              className="checkoutproductimg"
              alt="checkout"
            />
            <div className="product-text-section">
              <div className="product-text">{item?.productDetails?.name}</div>
              <div className="product-price">£{findPrice2}</div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="add-message">Add a Gift Message (Free)</div>

            <textarea
              rows="6"
              cols="50"
              className="textarea-field"
              name="giftMessage"
              value={formik.values.giftMessage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            {/* {formik.touched.giftMessage &&
            formik.errors.giftMessage && (
              <div className="error-message">
                {formik.errors.giftMessage}
              </div>
            )} */}
            {item?.productDetails?.isWrappingAvailable == 1 ? (
              <div className="gift-wrapping-section">
                <div className="wrapping-text">
                  Gift Wrapping{' '}
                  <span>
                    (£
                    {parseFloat(item?.productDetails?.wrappingPrice)?.toFixed(
                      2
                    )}
                    )
                  </span>
                </div>

                <div>
                  <OnOffSwitch
                    name="isGiftWrapping"
                    value={formik?.values?.isGiftWrapping}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'isGiftWrapping',
                        e?.target?.checked
                      );
                    }}
                  />
                </div>
              </div>
            ) : null}

            {item?.productDetails?.personalization == 1 ? (
              <>
                <div className="personalisation-section">
                  <div className="add-message">Personalisation message</div>
                  <div className="add-message">{`(${formik.values.personalization.length}/${item?.productDetails?.personalizationCharterLimit})`}</div>
                </div>
                <textarea
                  rows="6"
                  cols="50"
                  className="textarea-field"
                  name="personalization"
                  placeholder={
                    item?.productDetails?.personalisationNote
                      ? item?.productDetails?.personalisationNote
                      : ''
                  }
                  value={formik.values.personalization}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
                {/* {formik.touched.personalization &&
              formik.errors.personalization && (
                <div className="error-message">
                  {formik.errors.personalization}
                </div>
              )} */}
              </>
            ) : null}

            <div className="button-section-new">
              <ProductBtn
                onClick={() => {
                  handleClose();
                }}
                title="Cancel"
              />
              <RetailersBtn type="submit" title="Save" />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
