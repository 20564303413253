import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { images } from '../../../assets/_index';
import { baseUrl, deviceToken } from '../../../core/constants';
import { addCart } from '../../../redux/actions/cartcount';
import AxiosAll from '../../../services/axiosAll';
import RegisterDialog from '../../dialogs/Register';
import SignIn from '../../dialogs/SignIn';
import LeftCategory from './HeaderSubMenu/LeftCategory';
import RightCategory from './HeaderSubMenu/RightCategory';
import ResponsiveDrawer from './MobileMenu';
import { TopBar } from './Topbar';
import ViewAccount from './UserSignOut';

const Header = () => {
  const location = useLocation();
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { count } = useSelector((state) => state.cartReducer);
  const { countItem } = useSelector((state) => state?.itemCountReducer);
  const [topBarData, setTopBarData] = useState({});
  const [cartData, setCartData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userSearches, setUserSearches] = useState([]);
  const [isShowSearchHistory, setIsShowSearchHistory] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const nav = useNavigate();
  const dispatch = useDispatch();
  const path = location.pathname
    ? location.pathname.replace(/^\/|\/$/g, '').split('/')[0]
    : '';

  const searchRef = useRef(null);
  const headerRef = useRef(null);

  localStorage.setItem('isBasketCountUpdated', false);

  const getTopBarData = async () => {
    try {
      const res = await AxiosAll('get', 'topBar?');
      const data = res?.data?.result[0]?.content;
      setTopBarData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTopBarData();
  }, []);

  const getBasketCount = async () => {
    try {
      const res = await AxiosAll(
        'get',
        'basketCount?',
        '',
        userData?.userToken?userData?.userToken:"12345"
      );
      const data = res?.data?.result?.totalProducts;
      await dispatch(addCart(data ? data : '0'));
      await localStorage.setItem('basketCount', data != undefined ? data : 0);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBasketCount();
  }, [userData?.userToken]);

  const getCartData = async () => {
    try {
      const res = await AxiosAll(
        'get',
        'cart?page=1&per_page_limit=8&',
        '',
        userData?.userToken?userData?.userToken:"12345"
      );
      const data = res?.data?.result;
      setCartData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCartData();
  }, [count, countItem]);

  const getHeaderData = async () => {
    try {
      const res = await AxiosAll('get', 'header?');
      const data = res.data.result;

      setHeaderData(data);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   const element = headerRef.current;

  //   const handleMouseOver = () => {
  //     getHeaderData();
  //   };
  //   element.addEventListener('mouseover', handleMouseOver);
  //   return () => {
  //     element.removeEventListener('mouseover', handleMouseOver);
  //   };
  // }, [headerRef]);

  useEffect(() => {
    getHeaderData();
  }, []);

  const userSearch = async (query) => {
    try {
      await axios.post(
        `${baseUrl}user_searches?deviceType=3&deviceToken=${deviceToken}`,
        query,
        {
          headers: {
            userToken: userData?.userToken?userData?.userToken:"12345",
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getUserSearches = async () => {
    try {
      const res = await axios(
        `${baseUrl}user_searches?deviceType=3&${deviceToken}`,
        {
          headers: {
            userToken: userData?.userToken?userData?.userToken:"12345",
          },
        }
      );
      const data = res.data.result;
      setUserSearches(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserSearches();
  }, []);

  const deleteHistory = async (id) => {
    try {
      await axios.delete(
        `${baseUrl}user_searches/${id}?deviceType=3&deviceToken=${deviceToken}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (path !== 'search' && path !== 'retailers') {
      setSearchQuery('');
    }
  }, [path]);

  const handleSearch = async (query) => {
    await userSearch({
      query: query,
    });
  };

  return (
    <>
      <div className="header">
        {topBarData && <TopBar topBarData={topBarData} />}

        <Container className="all-screen-container">
          <Grid container spacing={2} className="logo-section">
            <Grid item xs={2.5} sm={2} className="mobileshow tabhide">
              <div className="menu-icon">
                <ResponsiveDrawer headerData={headerData} />
              </div>
            </Grid>
            <Grid item xs={userDetails?.token ? '4.5' : '6'} sm={3} md={2.5}>
              <div className="mainLogo">
                <Link to="/">
                  <img
                    loading="lazy"
                    src={images.mainLogo}
                    alt="goEthical logo"
                    title="goEthical logo"
                    ref={headerRef}
                  />
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={7.5} className="mobilehide tabshow">
              <div className="search-bar search-field">
                <input
                  name="search"
                  type="text"
                  id="the-search-mobile"
                  placeholder="Search Products &amp; Sellers"
                  className="search-input-field"
                  autoComplete="off"
                  ref={searchRef}
                  aria-autocomplete="list"
                  aria-expanded="false"
                  aria-haspopup="true"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && searchQuery !== '') {
                      nav(
                        `search/${searchQuery
                          ?.replaceAll(/\//g, '-')
                          ?.replaceAll(/\\/g, '-')
                          ?.trim()
                          ?.replaceAll(/\s\s+/g, ' ')
                          ?.replaceAll(' ', '+')}`
                      );
                      handleSearch(searchQuery);
                      setIsShowSearchHistory(false);
                    }
                  }}
                  onClick={() => {
                    getUserSearches();
                    setIsShowSearchHistory(true);
                  }}
                  onFocus={() => {
                    getUserSearches();
                    setIsShowSearchHistory(true);
                  }}
                  onBlur={() =>
                    setTimeout(() => setIsShowSearchHistory(false), 202)
                  }
                />
                <img
                  loading="lazy"
                  alt="search"
                  title="search"
                  src={images.searchIcon}
                  className="search-icon cursorpointer"
                  onClick={() => {
                    if (searchQuery?.length > 0) {
                      nav(
                        `search/${searchQuery
                          ?.replaceAll(/\//g, '-')
                          ?.replaceAll(/\\/g, '-')
                          ?.trim()
                          ?.replaceAll(/\s\s+/g, ' ')
                          ?.replaceAll(' ', '+')}`
                      );
                      handleSearch(searchQuery);
                    }
                  }}
                />
              </div>
              {isShowSearchHistory && userSearches?.length > 0 && (
                <div className="search-history">
                  <ul
                    role="listbox"
                    id="use-autocomplete-demo-listbox"
                    aria-labelledby="use-autocomplete-demo-label"
                    className="css-wom04s"
                  >
                    {userSearches?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <SearchIcon
                              style={{
                                marginRight: '-15px',
                              }}
                            />
                            <li
                              className="cursorpointer"
                              key={index}
                              onClick={() => {
                                nav(
                                  `search/${item?.query
                                    ?.replaceAll(/\//g, '-')
                                    ?.replaceAll(/\\/g, '-')
                                    ?.trim()
                                    ?.replaceAll(/\s\s+/g, ' ')
                                    ?.replaceAll(' ', '+')}`
                                );
                                handleSearch(item.query);
                                setSearchQuery(item.query);
                              }}
                            >
                              {item.query}
                            </li>
                          </div>
                          <div
                            style={{
                              width: '40px',
                            }}
                          >
                            <ClearIcon
                              className="cursorpointer"
                              onClick={() => {
                                deleteHistory(item.id);
                                setTimeout(() => {
                                  setIsShowSearchHistory(true);
                                  getUserSearches();
                                  searchRef.current.focus();
                                }, 204);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={userDetails?.token ? 5 : 3}
              sm={2}
              className="padd-leftremove"
            >
              <div className="card-login-btn">
                <RegisterDialog
                  title="Register"
                  register
                  setIsRegisterOpen={setIsRegisterOpen}
                  isRegisterOpen={isRegisterOpen}
                />

                {userDetails.token ? (
                  <ViewAccount />
                ) : (
                  <SignIn
                    setIsRegisterOpen={setIsRegisterOpen}
                    isRegisterOpen={isRegisterOpen}
                  />
                )}

                <div className="basket-icon">
                  <img
                    loading="lazy"
                    onClick={() => nav('basket')}
                    src={images.basketIcon}
                    className="card-img"
                    alt="shopping basket"
                    title="shopping basket"
                  />
                  <span onClick={() => nav('basket')} className="numbers">
                    ({count ? count : '0'}){' '}
                  </span>
                  <div className="cart-menu">
                    <div className="backet-card">
                      <img
                        loading="lazy"
                        alt="Shopping basket"
                        title="Shopping basket"
                        src={images.basketIcon2}
                        className="backet-img"
                      />
                      <span className="basket-text">Your Basket</span>
                    </div>
                    <div className="basket-cart-part">
                      {cartData?.cart?.data?.slice(0, 2)?.map((item, index) => {
                        return (
                          <>
                            <Grid
                              key={index}
                              container
                              spacing={2}
                              className="basket-img-row cursorpointer"
                              onClick={() =>
                                nav(
                                  item.productDetails?.headerMenuSlug
                                    ? `${
                                        item.productDetails?.headerMenuSlug
                                      }/${item?.productDetails.productSlug
                                        ?.replaceAll(' ', '-')
                                        ?.toLowerCase()}`
                                    : `/${
                                        item.productDetails.tagsAttached[0]
                                          ?.middleTagName
                                          ? item.productDetails.tagsAttached[0]?.middleTagName
                                              ?.toLowerCase()
                                              ?.replaceAll(' ', '-')
                                          : item.productDetails.tagsAttached[0]?.parentName?.toLowerCase()
                                      }/${item?.productDetails?.tagsAttached[0]?.tagName
                                        ?.replaceAll(' ', '-')
                                        ?.toLowerCase()}/${item?.productDetails?.productSlug
                                        ?.replaceAll(' ', '-')
                                        ?.toLowerCase()}`
                                )
                              }
                            >
                              <Grid item xs={5.5}>
                                <div>
                                  <img
                                    loading="lazy"
                                    src={item?.productDetails?.images?.map(
                                      (img) => {
                                        if (img.isCover) {
                                          return img.image;
                                        }
                                      }
                                    )}
                                    alt={item?.productDetails?.name}
                                    title={item?.productDetails?.name}
                                    className="product-img"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6.5}>
                                <div className="name">
                                  {item?.productDetails?.name}
                                </div>
                                <div className="rate">
                                  £{item?.productDetails?.price?.toFixed(2)}
                                </div>
                              </Grid>
                            </Grid>
                            <hr className="line" />
                          </>
                        );
                      })}
                      <div className="basket-area">
                        <div className="backet-total">Basket Total</div>
                        <div className="total-price">
                          £
                          {cartData?.totalPrice
                            ? cartData?.totalPrice?.toFixed(2)
                            : 0}
                        </div>
                      </div>
                      <div className="btn-align">
                        <Button
                          onClick={() => nav('/')}
                          className="shopping-btn"
                          tabIndex="0"
                        >
                          Continue Shopping
                        </Button>
                        <Button
                          onClick={() => nav('basket')}
                          className="checkout-btn"
                        >
                          Go to Checkout
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} className="mobileshow tabhide">
              <div className="search-bar search-field">
                <input
                  type="text"
                  name="search"
                  id="the-search"
                  placeholder="Search Products &amp; Sellers"
                  className="search-input-field"
                  autoComplete="off"
                  aria-autocomplete="list"
                  aria-expanded="false"
                  ref={searchRef}
                  aria-haspopup="true"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && searchQuery !== '') {
                      nav(
                        `search/${searchQuery
                          ?.replaceAll(/\//g, '-')
                          ?.replaceAll(/\\/g, '-')
                          ?.trim()
                          ?.replaceAll(/\s\s+/g, ' ')
                          ?.replaceAll(' ', '+')}`
                      );
                      handleSearch(searchQuery);
                      setIsShowSearchHistory(false);
                    }
                  }}
                  onClick={() => {
                    getUserSearches();
                    setIsShowSearchHistory(true);
                  }}
                  onFocus={() => {
                    getUserSearches();
                    setIsShowSearchHistory(true);
                  }}
                  onBlur={() =>
                    setTimeout(() => setIsShowSearchHistory(false), 202)
                  }
                />
                <img
                  loading="lazy"
                  src={images.searchIcon}
                  alt="search icon"
                  title="search icon"
                  className="search-icon "
                  onClick={() => {
                    if (searchQuery?.length > 0) {
                      nav(
                        `search/${searchQuery
                          ?.replaceAll(/\//g, '-')
                          ?.replaceAll(/\\/g, '-')
                          ?.trim()
                          ?.replaceAll(/\s\s+/g, ' ')
                          ?.replaceAll(' ', '+')}`
                      );
                      handleSearch(searchQuery);
                    }
                  }}
                />
              </div>
              {isShowSearchHistory && userSearches?.length > 0 && (
                <div className="search-history mobileshow">
                  <ul
                    role="listbox"
                    id="use-autocomplete-demo-listbox"
                    aria-labelledby="use-autocomplete-demo-label"
                    className="css-wom04s"
                  >
                    {userSearches?.map((item, index) => {
                      return (
                        <div
                          className="mobilesetfilter"
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <SearchIcon
                              style={{
                                marginRight: '-20px',
                              }}
                            />
                            <li
                              className="cursorpointer"
                              key={index}
                              onClick={() => {
                                nav(
                                  `search/${item?.query
                                    ?.replaceAll(/\//g, '-')
                                    ?.replaceAll(/\\/g, '-')
                                    ?.trim()
                                    ?.replaceAll(/\s\s+/g, ' ')
                                    ?.replaceAll(' ', '+')}`
                                );
                                handleSearch(item.query);
                                setSearchQuery(item.query);
                              }}
                            >
                              {item.query}
                            </li>
                          </div>
                          <ClearIcon
                            className="cursorpointer "
                            onClick={() => {
                              deleteHistory(item.id);
                              setTimeout(() => {
                                setIsShowSearchHistory(true);
                                getUserSearches();
                                searchRef.current.focus();
                              }, 204);
                            }}
                          />
                        </div>
                      );
                    })}
                  </ul>
                </div>
              )}
            </Grid>
          </Grid>
          <div className="toolbar-list1">
            {/* left nav bar*/}
            <ScrollContainer className="scroll-container topimgwidth">
              {headerData?.map((item, index) => {
                if (item.extraContent.position === '1') {
                  return (
                    <div key={index} className="navbar ">
                      <div className="subnav">
                        {/* <CrawerLink
                          // path={`/${item?.uniqueId?.toLowerCase()}`}
                        /> */}
                        <button
                          // onClick={() => {
                          //   nav({
                          //     pathname: `/${item?.uniqueId?.toLowerCase()}`,
                          //   });
                          // }}
                          className="nav-text1 desktop-view-menu cursorpointer"
                        >
                          {item?.name}
                        </button>
                        <button
                          // onClick={() => {
                          //   nav({
                          //     pathname: `/${item?.uniqueId?.toLowerCase()}`,
                          //   });
                          // }}
                          className="nav-text1 mobile-view-menu cursorpointer"
                        >
                          {item?.name}
                        </button>

                        <LeftCategory
                          subMenu={item?.subMenu}
                          topPicksContent={item?.topPicksContent}
                        />
                      </div>
                    </div>
                  );
                } else if (item.extraContent.position === '2') {
                  return (
                    <div key={index} className="navbar ">
                      <div className="subnav">
                        {/* <CrawerLink
                          path={`/${item?.uniqueId?.toLowerCase()}`}
                        /> */}
                        <button
                          // onClick={() => {
                          //   nav({
                          //     pathname: `/${item?.uniqueId?.toLowerCase()}`,
                          //   });
                          // }}
                          className="nav-text1 desktop-view-menu cursorpointer"
                        >
                          {item.name}
                        </button>
                        <button
                          // onClick={() => {
                          //   nav({
                          //     pathname: `/${item?.uniqueId?.toLowerCase()}`,
                          //   });
                          // }}
                          className="nav-text1 mobile-view-menu cursorpointer"
                        >
                          {item.name}
                        </button>
                        <RightCategory
                          subMenu={item?.subMenu}
                          topPicksContent={item?.topPicksContent}
                          extraContent={item?.extraContent}
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </ScrollContainer>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
