import React from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../../../assets/_index';

const CheckoutHeader = () => {
  const nav = useNavigate();

  return (
    <div className="checkoutheader">
      <div onClick={() => nav('/')} className="checkoutlogo">
        <img src={images.mainLogoinner} className="headerlogo"></img>
      </div>
      <div className="userprofiledetails">
        <p>Need help?</p>
        <a href="mailto:enquiries@goethical.com">enquiries@goethical.com</a>
      </div>
    </div>
  );
};

export default CheckoutHeader;
