import { Grid } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll';

const LeftCategory = ({ subMenu, topPicksContent }) => {
  return (
    <>
      <div className="subnav-content">
        <Grid container spacing={1} className="mobileset-left ">
          <Grid
            item
            xs={2.5}
            className="main_left_menu width49-left-mobile desktopleftrow"
          >
            {subMenu?.map((item, index) => {
              return (
                <div key={index} className="desktopleftpad">
                  <div
                    className="top-heading1"
                    onClick={() => {
                     
                      window.open(
                        item?.navigationPath,
                        '_blank'
                      );
                    }}
                  >
                    {' '}
                    {item?.name}{' '}
                  </div>
                  {item?.headerSubMenuChilds.map((subitem, subitemIndex) => {
                    return (
                      <div
                        className="option-heading "
                        key={subitemIndex}
                        onClick={() => {
                     
                          window.open(
                            subitem?.navigationPath?
                          subitem?.navigationPath.toLowerCase():
                          `/${subitem?.uniqueId}`
                          ,
                            '_blank'
                          );
                        }}
                      >
                        {subitem?.subMenuChildName}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Grid>
          <Grid item xs={9.5} className="width100-left-mobile bor-pad ">
            <div className="top-heading">Top Picks</div>
            <div fxlayout="column">
              <ScrollContainer className="scroll-container">
                <div className="displayset-mobile">
                  {topPicksContent?.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          window.open(
                            item?.headerMenuSlug?`${item?.headerMenuSlug}/${item?.productSlug}`:
                        
                            `/${item?.tagsAttached[0]?.middleTagName?item?.tagsAttached[0]?.middleTagName
                              ?.replace(/\s/g, '-'):item?.tagsAttached[0]?.parentName?.replace(/\s/g, '-')
                              ?.toLowerCase()}/${item?.tagsAttached[0]?.tagName
                              ?.replace(/\s/g, '-')
                              ?.toLowerCase()}/${item?.productSlug
                              ?.replace(/\s/g, '-')
                              ?.toLowerCase()}`
                          ,
                            '_blank'
                          );
                        }}
                        key={index}
                        className="image-container"
                      >
                        {item?.images.map((img, index) => {
                          if (img.isCover) {
                            return (
                              <img
                                loading="lazy"
                                alt="row"
                                draggable="false"
                                key={index}
                                className="img-row "
                                src={img.image}
                              />
                            );
                          }
                        })}
                        <div className="product-name"> {item?.name}</div>
                        <div className="product-price">£ {item?.price}</div>
                      </div>
                    );
                  })}
                </div>
              </ScrollContainer>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LeftCategory;
