import toast from 'react-hot-toast';
import currentURL from '../env';
// export const baseUrl = 'https://prod.goethical.com/api/v2/'
export const baseUrl = `${currentURL.currentEnv}`;
export const baseUrlV1 = `${currentURL.currentEnv.replace('v2/', 'v1/')}`;
export const deviceToken = 'a75a8f6c-80c4-4ced-a5d8-80b821f7dfbc';

export const path = {
  homeData: 'home/getHomeScreenData',
  feedBack: 'feedback-store?',
  signup: 'user/signup?',
  login: 'user/login',
};

export const getScreenSize = () => {
  if (window.innerWidth <= 450) {
    return 'mobile';
  } else if (window.innerWidth <= 820) {
    return 'tablet';
  } else {
    return 'web';
  }
};

export const getScreenSizeImagDimention = () => {
  if (window.innerWidth <= 450) {
    return '400x400';
  } else if (window.innerWidth <= 768) {
    return '400x400';
  } else {
    return '500x500';
  }
};

export const dynamicContent = (
  deviceSize,
  property,
  mobile,
  tablet,
  web,
  defaultSize
) => {
  if (deviceSize === 'mobile') {
    if (mobile?.[property]) {
      return mobile[property];
    } else {
      return defaultSize[property];
    }
  } else if (deviceSize === 'tablet') {
    if (tablet?.[property]) {
      return tablet[property];
    } else {
      return defaultSize[property];
    }
  } else if (deviceSize === 'web') {
    if (web?.[property]) {
      return web[property];
    } else {
      return defaultSize[property];
    }
  } else {
    return defaultSize[property];
  }
};

export const notify = (message, type) => {
  toast(`${message}`, {
    type: `${type}`,
    position: 'bottom-center',
    borderRadius: '2px',
    background: '#333',
    color: '#fff',
    autoClose: 4000,
  });
};

export const category = [
  {
    name: 'All',
    value: '0',
  },
  {
    name: 'Ethical',
    value: '44',
  },
  {
    name: 'Artisan',
    value: '295',
  },
  {
    name: 'Preloved',
    value: '42',
  },
];

export const sortBy = [
  {
    name: 'Most recent',
    value: '0',
  },
  {
    name: 'Highest priced',
    value: '2',
  },
  {
    name: 'Lowest priced',
    value: '1',
  },
];

export const capitalize = (s) => {
  return s?.charAt(0).toUpperCase() + s.slice(1);
};
export const TRACKING_ID = 'G-51GB78DR7P'; // OUR_TRACKING_ID

export const GTAG_Key = 'AW-739467275/EIvDCM-Ejr0DEIvAzeAC'; //gtag id we are using for conversion event on prod don't change it
//verified key with mail key = AW-739467275/EIvDCM-Ejr0DEIvAzeAC

// capitalize first letter of every word
export const capitalizeFirstLetter = (s) => {
  return s?.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
