import { Grid } from '@mui/material';
import CartItem from '../../../../components/universal/Cart/CartItem';

const BasketContent = ({ cartDetails, cartList }) => {
  return (
    <>
      <Grid container spacing={2} className="gridsetmobile">
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <div className="basketcontent">
            <div className="subttotal">
              <p>Subtotal</p>
              <p>£{cartDetails?.totalPrice?.toFixed(2)}</p>
            </div>
            <div className="estdelivery">
              <p>Estimated delivery</p>
              <p>£{cartDetails?.totalShippingCharges?.toFixed(2)}</p>
            </div>
            {cartDetails?.totalGiftWrapping > 0 && (
              <div className="estdelivery">
                <p>Gift Wrapping</p>
                <p>£{parseFloat(cartDetails?.totalGiftWrapping)?.toFixed(2)}</p>
              </div>
            )}
            <div className="total">
              <p>Total</p>
              <p>£{cartDetails?.totalPayablePrice?.toFixed(2)}</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          {cartList?.map((item, index) => {
            return item?.isSelectedItem == 1 ? (
              <CartItem key={index} item={item} />
            ) : null;
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default BasketContent;
