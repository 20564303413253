import { Container, Input } from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";
import { deviceToken, dynamicContent, getScreenSize, notify } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
const ExclusiveBlogs = ({ CustomData }) => {
  const mobile = CustomData?.mobile;
  const tablet = CustomData?.tablet;
  const web = CustomData?.web;
  const defaultSize = CustomData?.default;
  const newsLetterEntry = async (data) => {
    try {
      const res = await AxiosAll(
        'post',
        `newsLetter-Entries?deviceType=3&deviceToken=${deviceToken}`,
        data
      )
      notify(res.data.message, 'success')
    }
    catch (error) {
      console.log(error);
    }
  };

  // formik start
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required('E-mail address is required.')
      .email('Enter valid email address'),
  });
  const onSubmit = (values) => {
    formik.handleReset();
    newsLetterEntry({ email: values?.email, type: 2 })

  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Container className="exclusiveblog all-screen-container">
      <h2 className="blog-text">
        {' '}
        {CustomData && dynamicContent(
          getScreenSize(),
          'title',
          mobile,
          tablet,
          web,
          defaultSize
        ) ? dynamicContent(
          getScreenSize(),
          'title',
          mobile,
          tablet,
          web,
          defaultSize
        )?.replace('GoEthical.com', '') : `Yes! Send me exclusive blogs, unique gifts ideas, and personalised tips for shopping and selling on`}
        <span className="sitename">GoEthical.com</span>
      </h2>
      <form onSubmit={formik.handleSubmit} className="form-part">
        <div className="textcenter">
          <Input
            name="email"
            id='email_address'
            placeholder={CustomData && dynamicContent(
              getScreenSize(),
              'placeHolder',
              mobile,
              tablet,
              web,
              defaultSize
            ) ? dynamicContent(
              getScreenSize(),
              'placeHolder',
              mobile,
              tablet,
              web,
              defaultSize
            ) : 'Enter Your Email'}
            className="email-input-field"
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}

          />
          {formik.errors.email ? <div className='input-error-message'>{formik.errors.email}</div> : null}
        </div>
        <div className="textcenter submit-btn-section">
          <DarkBtn title={CustomData && dynamicContent(
            getScreenSize(),
            'buttonLabel',
            mobile,
            tablet,
            web,
            defaultSize
          ) ? dynamicContent(
            getScreenSize(),
            'buttonLabel',
            mobile,
            tablet,
            web,
            defaultSize
          ) : 'Submit'} type="submit" />
        </div>
      </form>
    </Container>

  );
};

export default ExclusiveBlogs;
