import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { RadioGroup } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import ImageGallery from '../../components/dialogs/ImageGallery';
import NotifyProductDialog from '../../components/dialogs/NotifyProduct';
import SingleProductTab from '../../components/SingleProductTab';
import SingleProductSkeleton from '../../components/Skeleton/SingleProductSkeleton';
import CategoryBtn from '../../components/universal/Buttons/CategoryBtn';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import Checkbox from '../../components/universal/Checkbox';
import MainHeading from '../../components/universal/Heading/MainHeading';
import CustomHelmet from '../../components/universal/Helmet';
import InputField from '../../components/universal/InputField';
import SelectBoxColor from '../../components/universal/InputField/SelectBoxColor';
import SelectBoxShipping from '../../components/universal/InputField/SelectBoxShipping';
import SelectBoxSize from '../../components/universal/InputField/SelectBoxSize';
import SelectBoxSubscription from '../../components/universal/InputField/SelectBoxSubscription';
import LatestArrivals from '../../components/universal/LatestArrivals';
import ProductSlider from '../../components/universal/ProductSlider';
import Rate from '../../components/universal/Rating';
import Reviews from '../../components/universal/Reviews';
import Share from '../../components/universal/Share';
import {
  baseUrl,
  baseUrlV1,
  deviceToken,
  getScreenSize,
  getScreenSizeImagDimention,
  notify
} from '../../core/constants';
import { addCart } from '../../redux/actions/cartcount';
import '../../scss/pages/_singleProductDetails.scss';
import AxiosAll from '../../services/axiosAll';

const SingleProductDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [FeatureBannerData, setFeatureBannerData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingFe, setIsLoadingFe] = React.useState(true);
  const [TagProductList, setTagProductList] = React.useState([]);
  const [isLoadingTP, setIsLoadingTP] = React.useState(true);
  const [productDetail, setProductDetail] = useState([]);
  const [badges, setBadges] = useState([]);
  const [filteredBadges, setFilteredBadges] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  const [colors, setColors] = useState([]);
  const [filteredColors, setFilteredColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState(0);
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState({});
  const [stockStatus, setStockStatus] = useState(true);
  const [isWishList, setIsWishList] = useState(false);
  const [load, setLoad] = useState(false);
  const [inventoryColorId, setInventoryColorId] = useState('');
  const [selectedShipping, setSelectedShipping] = useState('');
  const [selectedSubscription, setSelectedSubscription] = useState(2);
  const [submitType, setSubmitType] = useState('');
  const [rating, setRating] = useState({});
  const [isAgeConfirmOpen, setIsAgeConfirmOpen] = useState(false);
  const [ageConfirmValue, setAgeConfirmValue] = useState(false);
  const [isLoadingBuyNow, setIsLoadingBuyNow] = useState(false);
  const [isLoadingAddToBasket, setIsLoadingAddToBasket] = useState(false);
  const [isSetupNowLoading, setIsSetupNowLoading] = useState(false);
  const [isShowLetMeKnow, setIsShowLetMeKnow] = useState(true);
  const [isSubscriptionShow, setIsSubscriptionShow] = useState(false);
  const [coverImage, setCoverImage] = useState('');
  const [mainID, setmainID] = useState('');
  const [imageOpen, setImageOpen] = useState(false);
  const [ageConfirmFor, setAgeConfirmFor] = useState('');
  const [deliveryTime, setDeliveryTime] = useState({});

  const location = useLocation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   scrollTo(window.location.pathname);
  // }, [SingleProductTab]);

  useEffect(() => {
    if (params.generatedProductId === 'undefined') {
      navigate(`/${params.parentName}/${params.tagName}/${params.productSlug}`);
    }
  }, []);

  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, window.location.search]);

  const getColorValue = (colorId) => {
    setSelectedColor(colorId);
  };

  useEffect(() => {
    setSelectedSize({});
    formik.setFieldValue('giftMessage', '');

    // eslint-disable-next-line
  }, [location.pathname]);

  const getSizeValue = (sizeId) => {
    setInventoryColorId(sizeId);
    sizes.map((item) => {
      if (item.productInventoryId === sizeId) {
        setSelectedSize(item);
        productDetail.productInventory.map((item2, index) => {
          item2.inventoryColors.map((item3) => {
            if (item3.id === item.productInventoryId) {
              setSelectedSize(item3);
            }
          });
        });
      }
    });
  };

  useEffect(() => {
    if (
      productDetail?.productInventory?.length === 1 &&
      productDetail?.productInventory[0]?.sizeId === 0
    ) {
      productDetail?.productInventory[0]?.inventoryColors?.forEach((item) => {
        if (item.colorId === selectedColor) {
          setInventoryColorId(item.id);
          setSelectedSize(item);
        }
      });
    }
  }, [productDetail?.productInventory, selectedColor]);

  useEffect(() => {
    if (selectedSize?.isAddedToWishlist) {
      if (selectedSize?.isAddedToWishlist === 1) {
        setIsWishList(true);
      } else {
        setIsWishList(false);
      }
    } else if (
      productDetail?.productInventory?.length === 1 &&
      productDetail?.productInventory[0]?.inventoryColors?.length === 1
    ) {
      if (
        productDetail?.productInventory[0]?.inventoryColors[0]
          ?.isAddedToWishlist === 1
      ) {
        setIsWishList(true);
      } else {
        setIsWishList(false);
      }
    } else {
      setIsWishList(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryColorId, selectedSize, selectedColor]);

  const getShippingValue = (deliveryId) => {
    setSelectedShipping(deliveryId);
  };

  useEffect(() => {
    if (selectedShipping) {
      const deliveryTime = productDetail?.shippingMethods?.find(
        (shipping, index) => selectedShipping === shipping?.id
      );
      setDeliveryTime(deliveryTime);
    } else {
      setDeliveryTime(productDetail?.deliveredBy);
    }
  }, [productDetail, selectedShipping]);

  const getSubscriptionValue = (subscriptionId) => {
    setSelectedSubscription(subscriptionId);
  };

  useEffect(() => {
    if (window.location.pathname?.includes(`/products`)) {
      navigate(window.location.pathname?.replace('/products', ''));
    }
    // eslint-disable-next-line
  }, []);

  const getProductDetail = async () => {
    try {
      setIsLoading(true);
      const res = await axios(
        `${baseUrl}product/${
          params.generatedProductId
            ? params.generatedProductId
            : params.productSlug
        }?deviceType=3&deviceToken=${deviceToken}`,
        {
          headers: {
            userToken: userData.userToken,
          },
        }
      );
      const data = res.data.result.product;
      if (res.data.statusCode === 200) {
        getFeatureProducts(data?.sellerId);
        getTagProducts(data?.tagsAttached[0]?.tagId);
        data?.images?.map((item) => {
          if (item.isCover === 1) {
            setPreviewImage(item.image);
          }
        });
        setProductDetail(data);
        setmainID(data?.id);
        if (data.isSubscription === 1) {
          setIsSubscriptionShow(true);
        } else {
          setIsSubscriptionShow(false);
        }
        setIsLoading(false);

        if (params.generatedProductId) {
          if (data?.headerMenuSlug) {
            navigate(`${data?.headerMenuSlug}/${data?.productSlug}`);
          } else {
            navigate(
              `/${
                data?.tagsAttached[0]?.middleTagName
                  ? data?.tagsAttached[0]?.middleTagName
                      ?.replaceAll(' ', '-')
                      ?.toLowerCase()
                  : data?.tagsAttached[0]?.parentName
                      ?.replaceAll(' ', '-')
                      ?.toLowerCase()
              }/${data?.tagsAttached[0]?.tagName
                ?.replaceAll(' ', '-')
                ?.toLowerCase()}/${data?.productSlug}`
            );
          }
        } else if (location.pathname.includes('undefined')) {
          if (data?.headerMenuSlug) {
            navigate(`${data?.headerMenuSlug}/${data?.productSlug}`);
          } else {
            navigate(
              `/${
                data?.tagsAttached[0]?.middleTagName
                  ? data?.tagsAttached[0]?.middleTagName
                      ?.replaceAll(' ', '-')
                      ?.toLowerCase()
                  : data?.tagsAttached[0]?.parentName
                      ?.replaceAll(' ', '-')
                      ?.toLowerCase()
              }/${data?.tagsAttached[0]?.tagName
                ?.replaceAll(' ', '-')
                ?.toLowerCase()}/${data?.productSlug}`
            );
          }
        }
      } else if (
        res.data.statusCode == 102 &&
        params.productSlug == undefined
      ) {
        navigate('/');
      } else if (
        res.data.statusCode == 102 &&
        params.productSlug != undefined
      ) {
        // notify(res?.data?.message,'error')
        navigate('/product-not-found');
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const addToWishList = async () => {
    try {
      const res = await axios.post(
        `${baseUrl}cart?deviceType=3&deviceToken=${deviceToken}`,
        {
          deliveryType:
            productDetail?.shippingMethods.length > 1
              ? selectedShipping
              : productDetail?.shippingMethods[0].id,
          giftMessage: formik.values.giftMessage,
          inventoryColorId: inventoryColorId,
          is_gift_wrapping: formik.values.is_gift_wrapping === true ? 1 : '0',
          personalization: formik.values.personalization,
          productId: productDetail?.id,
          stocks: 1,
          subscription_type: selectedSubscription,
          is_wishlist_item: 1,
        },
        {
          headers: {
            userToken: userData.userToken,
          },
        }
      );

      if (res.data.status === 'Success') {
        setIsWishList(true);
        getProductDetail();
        notify(res.data.message, 'success');

        //google Analytics
        ReactGA.gtag('event', 'add_to_wishlist', {
          currency: 'EUR',
          value: productDetail.price,
          items: [
            {
              item_id: productDetail.id,
              item_name: productDetail.name,
              price: productDetail.price,
              quantity: 1,
              item_brand: productDetail?.seller?.name,
              item_category:
                productDetail?.tagsAttached &&
                productDetail?.tagsAttached[0]?.parentName,
              index: 0,
            },
          ],
        });
      } else {
        setIsWishList(false);
        notify(res.data.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductDetail();

    // eslint-disable-next-line
  }, [location, userData.userToken]);

  const deleteFromWishlist = async (id, colorId) => {
    try {
      const res = await axios.delete(
        `${baseUrl}wishlist/${id}?deviceType=3&deviceToken=${deviceToken}`,
        {
          headers: {
            userToken: userData.userToken,
          },
          params: {
            inventoryColorId: inventoryColorId,
          },
        }
      );
      notify(res.data.message, 'success');
      if (res.data.status === 'Success') {
        setIsWishList(false);
        getProductDetail();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFeatureProducts = async (SellerId) => {
    try {
      const res = await axios(
        `${baseUrlV1}product/user-products/${SellerId}?page=1&deviceType=1&deviceToken=${deviceToken}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken: userData.userToken,
          },
        }
      );
      setFeatureBannerData(res?.data?.result);
      setIsLoadingFe(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getBadges = async () => {
    try {
      const res = await axios(
        `${baseUrlV1}product/badges?deviceType=3&deviceToken=${deviceToken}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      const data = res?.data?.result;
      setBadges(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBadges();

    return () => {
      setBadges([]);
    };

    // eslint-disable-next-line
  }, [userData.userToken]);

  // console.log('productDetail', productDetail);
  useEffect(() => {
    const uniqueBadges = productDetail?.metadata?.filter(
      (item, index) =>
        productDetail?.metadata.findIndex((i) => i.content === item.content) ===
        index
    );

    uniqueBadges?.map((item, index) => {
      if (index < productDetail?.metadata?.length) {
        badges?.map((badge) => {
          // add to filtered badges and remove duplicates
          // console.log('badge', badge);
          if (parseInt(item.content) === badge.id) {
            setFilteredBadges((prevState) => [...prevState, badge]);
            // badge already exists in badges array then dont add
          }
        });
      }
    });

    return () => {
      setFilteredBadges([]);
    };
  }, [productDetail?.metadata, userData?.userToken, badges]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, userData.userToken]);

  const getColors = async () => {
    try {
      const res = await axios(
        `${baseUrlV1}product/colors?deviceType=3&deviceToken=${deviceToken}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      const data = res.data.result;
      productDetail?.cartInventory?.map((item) => {
        data.map((color) => {
          if (item.colorId === color.id) {
            return setColors((prev) => [...prev, color]);
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getColors();

    return () => {
      setColors([]);
    };
  }, [productDetail?.cartInventory, userData.userToken]);

  useEffect(() => {
    const filteredColors = [...new Set(colors.map((item) => item.id))].map(
      (id) => {
        return colors.find((item) => item.id === id);
      }
    );
    setFilteredColors(filteredColors);
  }, [colors, location.pathname]);

  const getTagProducts = async (tagID) => {
    try {
      const res = await axios(
        `${baseUrl}product?tags=${tagID}&feedtype=1&page=1&is_trending=0&deviceType=3&deviceToken=${deviceToken}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken: userData.userToken,
          },
        }
      );
      setTagProductList(res?.data?.result?.data);
      setIsLoadingTP(false);
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line
  useEffect(async () => {
    await setLoad(true);

    if (
      productDetail?.productInventory[0]?.sizeId ||
      productDetail?.productInventory?.length > 1
    ) {
      await productDetail?.cartInventory?.map((item) => {
        if (item.colorId === selectedColor) {
          setSizes(item.sizes);
        }
      });
    } else {
      setSizes([]);
    }
    await setLoad(false);

    // eslint-disable-next-line
  }, [selectedColor, productDetail?.productInventory]);

  useEffect(() => {
    if (selectedSize && selectedSize.stocks > 0) {
      setStockStatus(true);
    } else if (
      productDetail?.stockStatus === 1 &&
      selectedSize.stocks === undefined
    ) {
      setStockStatus(true);
    } else {
      setStockStatus(false);
    }
  }, [selectedSize, productDetail]);

  const initialValues = {
    isDeliveryType: productDetail?.shippingMethods?.length > 1 ? true : false,
    deliveryType: '',
    isGiftMessage: false,
    giftMessage: '',
    isPersonalization: false,
    personalization: '',
    subscription_type: 2,
    is_subscription: 1,
    is_gift_wrapping: false,
  };

  const validationSchema = Yup.object().shape({
    isDeliveryType: Yup.boolean(),
    deliveryType: Yup.string().when('isDeliveryType', {
      is: true,
      then: Yup.string().required('Required'),
    }),

    subscription_type: Yup.string().required('Subscription Type is required'),
  });

  useEffect(() => {
    if (productDetail?.cartInventory?.length <= 1) {
      setInventoryColorId(
        productDetail?.cartInventory[0].sizes[0]?.productInventoryId
      );
    }
  }, [productDetail?.cartInventory]);

  const buyNow = async (data) => {
    setIsLoadingBuyNow(true);
    try {
      const res = await axios.post(
        `${baseUrl}cart/buy-now?deviceType=3&deviceToken=${deviceToken}`,
        data,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      if (res.data.status === 'Success') {
        navigate(`/basket/${res.data.result.cartId}`);
      } else {
        notify(res.data.message, 'error');
      }
      setIsLoadingBuyNow(false);
    } catch (error) {
      console.log(error);
      setIsLoadingBuyNow(false);
    }
  };

  const addToBasket = async (data) => {
    setIsLoadingAddToBasket(true);
    try {
      const res = await axios.post(
        `${baseUrl}cart?deviceType=3&deviceToken=${deviceToken}`,
        data,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      if (res.data.status === 'Success') {
        navigate(`/basket`);
        getBasketCount();
        localStorage.setItem('isBasketCountUpdated', true);
      } else {
        notify(res.data.message, 'error');
      }
      setIsLoadingAddToBasket(false);
    } catch (error) {
      console.log(error);
      setIsLoadingAddToBasket(false);
    }
  };

  const getBasketCount = async () => {
    try {
      const res = await AxiosAll(
        'get',
        'basketCount?',
        '',
        userData?.userToken
      );
      const data = res?.data?.result?.totalProducts;
      await dispatch(addCart(data ? data : '0'));
      await localStorage.setItem('basketCount', data != undefined ? data : 0);
    } catch (error) {
      console.log(error);
    }
  };

  const setUpSubscription = async (data) => {
    setIsSetupNowLoading(true);
    try {
      const res = await axios.post(
        `${baseUrl}cart/buy-now-subscription?deviceType=3&deviceToken=${deviceToken}`,
        data,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );

      if (res.data.status === 'Success') {
        navigate(`/basket/${res.data.result.cartId}/isSubscription/products`);
      } else {
        notify(res.data.message, 'error');
      }
      setIsSetupNowLoading(false);
    } catch (error) {
      console.log(error);
      setIsSetupNowLoading(false);
    }
  };

  const onSubmit = (values) => {
    getBasketCount();
    if (productDetail?.shippingMethods?.length > 1 && selectedShipping === '') {
      notify('Please select delivery method', 'error');
    } else if (!values.isGiftMessage && values.giftMessage !== '') {
      notify('Please select gift message', 'error');
    } else if (values.isGiftMessage && values.giftMessage === '') {
      notify('please enter the gift message', 'error');
    } else if (
      !values.isPersonalization &&
      values.personalization.length > productDetail?.personalizationCharterLimit
    ) {
      notify(
        `You have exceeded the limit of characters (${productDetail?.personalizationCharterLimit}) for personalized messages.`,
        'error'
      );
    } else if (values.isPersonalization && values.personalization === '') {
      notify('please enter the personalization', 'error');
    } else {
      //google Analytics
      ReactGA.gtag('event', 'add_to_cart', {
        currency: 'EUR',
        value: productDetail.price,
        items: [
          {
            item_id: productDetail.id,
            item_name: productDetail.name,
            price: productDetail.price,
            quantity: 1,
            item_brand: productDetail?.seller?.name,
            item_category:
              productDetail?.tagsAttached &&
              productDetail?.tagsAttached[0]?.parentName,
            index: 0,
          },
        ],
      });

      if (formik.values.is_subscription === 1 && isSubscriptionShow) {
        const data = {
          deliveryType:
            productDetail.shippingMethods.length > 1
              ? selectedShipping
              : productDetail?.shippingMethods[0]?.id,
          giftMessage: values.giftMessage,
          inventoryColorId: inventoryColorId,
          is_gift_wrapping: values.is_gift_wrapping === true ? 1 : '0',
          personalization: values.personalization,
          productId: productDetail.id,
          stocks: 1,
          subscription_type: selectedSubscription,
          is_subscription: 1,
        };
        if (submitType === 'setupNow') {
          if (productDetail.isAlcoholBeverage === 1) {
            setAgeConfirmFor('submit');
            setIsAgeConfirmOpen(true);
            if (ageConfirmValue === true) {
              setUpSubscription(data);
            }
          } else {
            setUpSubscription(data);
          }
        }
      } else if (formik.values.is_subscription === 0 && isSubscriptionShow) {
        const data = {
          deliveryType:
            productDetail.shippingMethods.length > 1
              ? selectedShipping
              : productDetail?.shippingMethods[0]?.id,
          giftMessage: values.giftMessage,
          inventoryColorId: inventoryColorId,
          is_gift_voucher: 0,
          is_gift_wrapping: values.is_gift_wrapping === true ? 1 : '0',
          personalization: values.personalization,
          productId: productDetail.id,
          stocks: 1,
          subscription_type: '',
        };
        if (submitType === 'buyNow') {
          if (productDetail.isAlcoholBeverage === 1) {
            setAgeConfirmFor('submit');
            setIsAgeConfirmOpen(true);
            if (ageConfirmValue === true) {
              buyNow(data);
            }
          } else {
            buyNow(data);
          }
        } else {
          if (productDetail.isAlcoholBeverage === 1) {
            setAgeConfirmFor('submit');
            setIsAgeConfirmOpen(true);
            if (ageConfirmValue === true) {
              addToBasket(data);
            }
          } else {
            addToBasket(data);
          }
        }
      } else if (formik.values.is_subscription === 1 && !isSubscriptionShow) {
        const data = {
          deliveryType:
            productDetail.shippingMethods.length > 1
              ? selectedShipping
              : productDetail?.shippingMethods[0]?.id,
          giftMessage: values.giftMessage,
          inventoryColorId: inventoryColorId,
          is_gift_voucher: 0,
          is_gift_wrapping: values.is_gift_wrapping === true ? 1 : '0',
          personalization: values.personalization,
          productId: productDetail.id,
          stocks: 1,
          subscription_type: '',
        };
        if (submitType === 'buyNow') {
          if (productDetail.isAlcoholBeverage === 1) {
            setAgeConfirmFor('submit');
            setIsAgeConfirmOpen(true);

            if (ageConfirmValue === true) {
              buyNow(data);
            }
          } else {
            buyNow(data);
          }
        } else {
          if (productDetail.isAlcoholBeverage === 1) {
            setAgeConfirmFor('submit');
            setIsAgeConfirmOpen(true);

            if (ageConfirmValue === true) {
              addToBasket(data);
            }
          } else {
            addToBasket(data);
          }
        }
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const getRatings = async () => {
    try {
      const res = await axios(
        `${baseUrl}feedback/product/${
          productDetail?.id
        }?orderBy&deviceType=3&deviceToken=${deviceToken}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );

      setRating(res.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRatings();
  }, [userData.userToken, productDetail?.id]);

  const handleClose = () => {
    setAgeConfirmValue(false);
    setIsAgeConfirmOpen(false);
  };

  const letMeKnowOutOfStocks = async () => {
    try {
      const res = await axios.post(
        `${baseUrl}notificationOutOfStockProduct?deviceType=3&deviceToken=${deviceToken}`,
        {
          product_id: productDetail.id,
          product_inventory_color_id: inventoryColorId,
          product_inventory_id: 0,
          user_id: userData?.user?.id,
        },
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      if (res.data.status === 'Success') {
        setIsShowLetMeKnow(false);
        notify(
          res?.data?.message
            ? res?.data?.message
            : 'Data submitted successfully',
          'success'
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const title = `${
    productDetail?.name
      ? productDetail?.name
      : 'Buy Ethical Products on GoEthical - The Ethical Lifestyle App'
  }`;

  useEffect(() => {
    productDetail?.images?.map((image) => {
      if (image.isCover === 1) {
        setCoverImage(image.image);
      }
    });
  }, [productDetail]);

  const price = () => {
    if (
      productDetail?.isSubscription === 1 &&
      formik.values.is_subscription === 1
    ) {
      if (selectedSize.subscriptionPrice) {
        return selectedSize?.subscriptionPrice?.toFixed(2);
      } else {
        if (productDetail?.productInventory?.length > 0) {
          return productDetail?.productInventory[0]?.inventoryColors[0]?.subscriptionPrice?.toFixed(
            2
          );
        } else {
          return productDetail?.subscriptionPrice.toFixed(2);
        }
      }
    } else if (
      productDetail?.isSubscription === 1 &&
      formik.values.is_subscription === 0
    ) {
      if (selectedSize.price) {
        return selectedSize?.price?.toFixed(2);
      } else {
        if (productDetail?.productInventory?.length > 0) {
          return productDetail?.productInventory[0]?.inventoryColors[0]?.price?.toFixed(
            2
          );
        } else {
          return productDetail?.price.toFixed(2);
        }
      }
    } else if (productDetail?.isSubscription === 0) {
      if (selectedSize.price) {
        return selectedSize?.price?.toFixed(2);
      } else {
        if (productDetail?.productInventory?.length > 0) {
          return productDetail?.productInventory[0]?.inventoryColors[0]?.price?.toFixed(
            2
          );
        } else {
          if (productDetail?.productInventory?.length > 0) {
            return productDetail?.productInventory[0]?.inventoryColors[0]?.price?.toFixed(
              2
            );
          } else {
            return productDetail?.price.toFixed(2);
          }
        }
      }
    }
  };

  const actualPrice = () => {
    if (
      productDetail?.isSubscription === 1 &&
      formik.values.is_subscription === 1
    ) {
      if (selectedSize.actualPrice) {
        return selectedSize?.actualPrice?.toFixed(2);
      } else {
        if (productDetail?.productInventory?.length > 0) {
          return productDetail?.productInventory[0]?.inventoryColors[0]?.actualPrice?.toFixed(
            2
          );
        } else {
          return productDetail?.actualPrice.toFixed(2);
        }
      }
    } else if (
      productDetail?.isSubscription === 1 &&
      formik.values.is_subscription === 0
    ) {
      if (selectedSize.price) {
        return selectedSize?.actualPrice?.toFixed(2);
      } else {
        if (productDetail?.productInventory?.length > 0) {
          return productDetail?.productInventory[0]?.inventoryColors[0]?.actualPrice?.toFixed(
            2
          );
        } else {
          return productDetail?.actualPrice.toFixed(2);
        }
      }
    } else if (productDetail?.isSubscription === 0) {
      if (selectedSize.price) {
        return selectedSize?.actualPrice?.toFixed(2);
      } else {
        if (productDetail?.productInventory?.length > 0) {
          return productDetail?.productInventory[0]?.inventoryColors[0]?.actualPrice?.toFixed(
            2
          );
        } else {
          return productDetail?.actualPrice.toFixed(2);
        }
      }
    }
  };

  const discountPrice = () => {
    if (selectedSize.actualPrice) {
      return selectedSize?.actualPrice?.toFixed(2);
    } else {
      if (productDetail?.productInventory?.length > 0) {
        return productDetail?.productInventory[0]?.inventoryColors[0]?.actualPrice?.toFixed(
          2
        );
      } else {
        return productDetail?.actualPrice?.toFixed(2);
      }
    }
  };

  const discount = () => {
    if (selectedSize.discount) {
      return selectedSize?.discount;
    } else {
      if (productDetail?.productInventory?.length > 0) {
        return productDetail?.productInventory[0]?.inventoryColors[0]?.discount?.toFixed(
          2
        );
      } else {
        return productDetail?.discount?.toFixed(2);
      }
    }
  };

  return (
    <div className="singleproductdetails">
      <Helmet>
        <title>
          Buy Ethical Products on GoEthical - The Ethical Lifestyle App
        </title>
      </Helmet>
{/* discussed with anand  and rajneesh sir to update "keyword={productDetail?.seoMetaKeywords?productDetail?.seoMetaKeywords:productDetail?.name}" */}
      {title && (
        <CustomHelmet
          title={productDetail?.seoMetaTitle?productDetail?.seoMetaTitle: `${title} | GoEthical`}
          description={productDetail?.seoMetaDescription?productDetail?.seoMetaDescription: productDetail?.description}
          imageUrl={coverImage}
          keyword={productDetail?.seoMetaKeywords?productDetail?.seoMetaKeywords:productDetail?.name}
          url={window.location.href}
          path={window?.location?.pathname}
        />
      )}

      <div className="ConfirmYourAge">
        <Dialog
          open={isAgeConfirmOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="confirm-your-age"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="redeem-voucher">
                <div className="Confirm-text">Confirm your age</div>
                <div className="Confirm-para">
                  This item is an age-restricted product, so we need to verify
                  your age. This will be cross-checked on delivery.
                </div>
              </div>
              <div className="verify-age">
                <div>
                  <div className="verify-your-age">Please verify your age</div>
                  <div className="Confirm-age-para">
                    I confirm that I’m 18 years of age or older
                  </div>
                </div>

                <div>
                  <Checkbox
                    value={ageConfirmValue}
                    onChange={() => setAgeConfirmValue(!ageConfirmValue)}
                  />
                </div>
              </div>
              <div className="password">
                <div className="Confirm-btn">
                  <LightBtn
                    title="Confirm and continue"
                    onClick={() => {
                      if (ageConfirmValue === false) {
                        notify('Please confirm your age', 'error');
                      } else {
                        if (ageConfirmFor === 'submit') {
                          onSubmit(formik.values);
                          handleClose();
                        } else {
                          addToWishList();
                          handleClose();
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      {isLoading ? (
        <SingleProductSkeleton />
      ) : (
        <Container className="all-screen-container">
          <Grid container spacing={2} className="search-product-section">
            <Grid item xs={7}>
              <div className="search-heading">
                <span
                  className="cursorpointer"
                  onClick={() => {
                    navigate({
                      pathname: `/all-${productDetail?.tagsAttached[0]?.parentName.toLowerCase()}-products`,
                      search: `tags=${productDetail?.tagsAttached[0]?.parentId}`,
                    });
                  }}
                >
                  {' '}
                  Products{' '}
                </span>
                /{' '}
                <span
                  className="cursorpointer"
                  onClick={() => {
                    navigate({
                      pathname: `/all-${productDetail?.tagsAttached[0]?.parentName.toLowerCase()}-products`,
                      search: `tags=${productDetail?.tagsAttached[0]?.parentId}`,
                    });
                  }}
                >
                  {productDetail?.tagsAttached &&
                    productDetail?.tagsAttached[0]?.parentName}{' '}
                  Products /{' '}
                </span>
                <span className="search-product-name">
                  {productDetail?.name}
                </span>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className="mobileshow">
                <Share
                  url={productDetail?.shareLink}
                  title={productDetail?.name}
                />
              </div>
            </Grid>
          </Grid>
          {/* card start */}
          <Card className="product-card">
            <CardContent>
              <Grid container spacing={2}>
                <Grid xs={12} md={6} sm={6}>
                  <div className="image-container">
                    <CategoryBtn
                      category={
                        productDetail?.tagsAttached &&
                        productDetail?.tagsAttached[0]?.parentName
                      }
                    />
                    {isWishList ? (
                      <div
                        className="unpublished1"
                        onClick={() => {
                          deleteFromWishlist(
                            productDetail?.id,
                            productDetail?.cartInventory[0]?.sizes[0]
                              ?.productInventoryId
                          );
                        }}
                      >
                        <FavoriteIcon />
                      </div>
                    ) : (
                      <div
                        className="unpublished1"
                        onClick={() => {
                          if (
                            productDetail.shippingMethods.length > 1 &&
                            selectedShipping === ''
                          ) {
                            notify('Please select delivery method', 'error');
                          } else if (
                            !formik.values.isGiftMessage &&
                            formik.values.giftMessage !== ''
                          ) {
                            notify('Please select gift message', 'error');
                          } else if (
                            formik.values.isGiftMessage &&
                            formik.values.giftMessage === ''
                          ) {
                            notify('Please enter the gift message', 'error');
                          } else if (productDetail.isAlcoholBeverage === 1) {
                            setAgeConfirmFor('wishlist');
                            setIsAgeConfirmOpen(true);
                            if (ageConfirmValue === true) {
                              addToWishList();
                            }
                          } else if (userDetails.token) {
                            addToWishList();
                          } else {
                            notify('Please Login to add to wishlist', 'error');
                          }
                        }}
                      >
                        <FavoriteBorderIcon />
                      </div>
                    )}

                    <img
                      loading="lazy"
                      src={previewImage}
                      alt={
                        productDetail?.name
                          ? productDetail?.name
                          : 'product image'
                      }
                      title={
                        productDetail?.name
                          ? productDetail?.name
                          : 'product image'
                      }
                      className="img-row"
                    />
                  </div>
                  <div className="img-thumb-set">
                    {productDetail?.images?.length > 4
                      ? productDetail?.images
                          ?.slice(1, 5)
                          ?.map((item, index) => {
                            if (!item.isCover) {
                              return (
                                <div
                                  className="image-container-gram "
                                  key={index}
                                  onClick={() => {
                                    if (index < 3) setPreviewImage(item.image);
                                  }}
                                >
                                  <img
                                    loading="lazy"
                                    onClick={() => {
                                      if (index === 3) {
                                        setImageOpen(true);
                                      }
                                    }}
                                    key={index}
                                    src={item.image}
                                    alt={
                                      productDetail?.name
                                        ? productDetail?.name
                                        : 'product image'
                                    }
                                    title={
                                      productDetail?.name
                                        ? productDetail?.name
                                        : 'product image'
                                    }
                                    className="img-row-gram"
                                  />
                                  {index === 3 && (
                                    <ImageGallery
                                      open={imageOpen}
                                      setOpen={setImageOpen}
                                      images={productDetail?.images}
                                    />
                                  )}
                                </div>
                              );
                            }
                          })
                      : productDetail?.images?.map((item, index) => {
                          return (
                            <div
                              className="image-container-gram "
                              key={index}
                              onClick={() => {
                                setPreviewImage(item.image);
                              }}
                            >
                              <img
                                loading="lazy"
                                key={index}
                                src={item.image}
                                alt={
                                  productDetail?.name
                                    ? productDetail?.name
                                    : 'product image'
                                }
                                title={
                                  productDetail?.name
                                    ? productDetail?.name
                                    : 'product image'
                                }
                                className="img-row-gram"
                              />
                            </div>
                          );
                        })}
                  </div>
                </Grid>
                <Grid div xs={12} sm={6} className="single_product_content">
                  <div className="stock-section">
                    <div
                      className="seller-heading heading20 "
                      onClick={() =>
                        navigate(`/store/${productDetail?.seller?.nameSlug}`)
                      }
                    >
                      {productDetail?.seller?.userName
                        ? productDetail?.seller?.userName
                        : productDetail?.seller?.sellerName}
                    </div>
                    <div>
                      {stockStatus ? (
                        <div className="stock-text heading21">In stock</div>
                      ) : (
                        <div className="stock-text heading21">Out of stock</div>
                      )}

                      {productDetail?.isSubscription &&
                      formik.values.is_subscription === 1 ? (
                        <div className="desktopview mobilehide">
                          <SelectBoxSubscription
                            getSubscriptionValue={getSubscriptionValue}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6.5} sm={12} lg={8}>
                      <h1 style={{ display: 'none' }}>{productDetail?.name}</h1>
                      <h2 className="product-name">{productDetail?.name}</h2>
                      <div
                        class="seller-heading heading20 newdelivery"
                        style={{ cursor: ' default' }}
                      >
                        Delivery by{' '}
                        {/* <span className="newdatetext">{Friday, 8 July}</span> */}
                        <span className="newdatetext">
                          {moment(deliveryTime?.time).format('dddd, DD MMM')}
                        </span>
                        {deliveryTime?.isExpressAvaliable === 1 && (
                          <span className="newavailable">
                            Express options available
                          </span>
                        )}
                      </div>
                      {rating?.reviewsCount > 0 && (
                        <div className="star-section">
                          <Rate defaultRating={rating?.averageRating} />
                          <span className="review">
                            ({rating?.reviewsCount}{' '}
                            {`${
                              rating?.reviewsCount > 1 ? 'Reviews' : 'Review'
                            } `}
                            )
                          </span>
                          <span className="read-text">
                            Read{' '}
                            {rating?.reviewsCount > 1 ? 'Reviews' : 'Review'}
                          </span>
                        </div>
                      )}
                      {productDetail?.isSubscription &&
                      formik.values.is_subscription === 1 ? (
                        <div className="desktopview mobileshow">
                          <SelectBoxSubscription
                            getSubscriptionValue={getSubscriptionValue}
                          />
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12} md={5.5} sm={12} lg={4}>
                      <div className="single_pro_price_set">
                        <div className="price">
                          £ {price()}
                          {/* {productDetail?.isDiscount
                            ? productDetail?.isDiscount === '1'
                            : productDetail?.discount
                            ? discountPrice() !== price() && (
                                <span className="price1">
                                  £ {discountPrice()}
                                </span>
                              )
                            : null} */}
                          {selectedSize?.isDiscount === '1'
                            ? discountPrice() !== price() && (
                                <span className="price1">
                                  £ {discountPrice()}
                                </span>
                              )
                            : null}
                        </div>
                        {productDetail?.shippingCharge > 0 && (
                          <div className="delivery">
                            + £ {productDetail?.shippingCharge} Delivery
                          </div>
                        )}
                        {selectedSize?.isDiscount === '1' &&
                          actualPrice() - price() > 0 && (
                            <div className="price3">
                              You save £{(actualPrice() - price()).toFixed(2)} (
                              {discount()}%)
                            </div>
                          )}
                      </div>
                    </Grid>
                  </Grid>
                  <p
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                    className="para"
                  >
                    {productDetail?.description}
                  </p>
                  {filteredBadges?.length > 0 && (
                    <div className="image-part">
                      <div>
                        <div className="product-value">Product Values</div>
                        <div className="product_value_icon">
                          {filteredBadges?.map((item, index) => {
                            return (
                              <div className="icon-section" key={index}>
                                {item.id === 2 || item.id === 5 ? (
                                  <a
                                    href={
                                      item.id === 2
                                        ? 'https://goethical.com/blog/cruelty-free-value-criteria/'
                                        : 'https://goethical.com/blog/vegan-value-criteria/'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      loading="lazy"
                                      src={item?.image}
                                      alt={item?.name}
                                      title={item?.name}
                                      className="shop-icon"
                                    />
                                  </a>
                                ) : (
                                  <img
                                    loading="lazy"
                                    src={item?.image}
                                    alt={item?.name}
                                    title={item?.name}
                                    className="shop-icon"
                                  />
                                )}

                                <div className="icon-name">{item?.name}</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                  <form onSubmit={formik.handleSubmit}>
                    {/* {filteredColors.length > 0 && ( */}
                    <div>
                      {filteredColors.length > 0 && (
                        <Grid container spacing={2} className="color-section">
                          <Grid item xs={12} md={4} sm={12} lg={4}>
                            <div className="option-heading heading20">
                              Color
                            </div>
                          </Grid>
                          <Grid item xs={12} md={8} sm={12} lg={8}>
                            <SelectBoxColor
                              options={filteredColors}
                              getColorValue={getColorValue}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {sizes !== undefined && sizes.length > 0 && (
                        <Grid container spacing={2} className="color-section">
                          <Grid item xs={12} md={4} sm={12} lg={4}>
                            <div className="option-heading heading20">Size</div>
                          </Grid>
                          <Grid item xs={12} md={8} sm={12} lg={8}>
                            {load ? null : (
                              <SelectBoxSize
                                options={sizes}
                                getSizeValue={getSizeValue}
                              />
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </div>
                    {/* )} */}

                    <Grid
                      container
                      spacing={2}
                      className="color-section gitftsection"
                    >
                      <Grid item xs={1.2} sm={1}>
                        <Checkbox
                          name="isGiftMessage"
                          value={formik.values.isGiftMessage}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={9.5} md={3} sm={9.5} lg={3}>
                        <span className="option-heading heading20">
                          Gift Message
                        </span>
                      </Grid>
                      <Grid item xs={12} md={8} sm={12} lg={8}>
                        <InputField
                          placeholder="Add a message to your gift"
                          name="giftMessage"
                          value={formik.values.giftMessage}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Grid>
                    </Grid>

                    {productDetail?.isWrappingAvailable === '1' && (
                      <Grid
                        container
                        spacing={2}
                        className="color-section gitftsection"
                      >
                        <Grid item xs={1.2} sm={1}>
                          <Checkbox
                            name="is_gift_wrapping"
                            value={formik.values.is_gift_wrapping}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={9.5} md={3} sm={9.5} lg={3}>
                          <span className="option-heading heading20">
                            Gift Wrapping
                          </span>
                        </Grid>
                        <Grid item xs={12} md={8} sm={12} lg={8}>
                          <span className="option-heading heading20">
                            (£
                            {parseFloat(productDetail?.wrappingPrice)?.toFixed(
                              2
                            )}
                            )
                          </span>
                        </Grid>
                      </Grid>
                    )}

                    {productDetail?.personalization == 1 && (
                      <Grid
                        container
                        spacing={2}
                        className="personalization-section"
                      >
                        <Grid item xs={12} md={4} sm={12} lg={4}>
                          <div className="option-heading heading20">
                            Personalize this item
                          </div>
                        </Grid>

                        <Grid item xs={12} md={8} sm={12} lg={8}>
                          <div
                            className="option-heading-warning"
                            style={{
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {productDetail?.personalisationNote}
                          </div>
                          <textarea
                            className="select-field-textarea heading20"
                            placeholder="Your message here"
                            name="personalization"
                            value={formik.values.personalization}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          ></textarea>
                          {formik.touched.personalization &&
                            formik.errors.personalization && (
                              <div className="error-message">
                                {formik.errors.personalization}
                              </div>
                            )}
                        </Grid>
                      </Grid>
                    )}

                    {productDetail?.shippingMethods?.length > 1 && (
                      <Grid container spacing={2} className="color-section">
                        <Grid item xs={12} md={4} sm={12} lg={4}>
                          <div className="option-heading heading20">
                            Delivery Method
                          </div>
                        </Grid>
                        <Grid item xs={12} md={8} sm={12} lg={8}>
                          <SelectBoxShipping
                            options={productDetail?.shippingMethods}
                            getShippingValue={getShippingValue}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {productDetail?.isSubscription === 1 && (
                      <>
                        <div className="sub_check_box">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            defaultValue={1}
                            name="is_subscription"
                            value={formik.values.is_subscription}
                            onChange={(e) =>
                              formik.setFieldValue(
                                'is_subscription',
                                parseInt(e.target.value)
                              )
                            }
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label=" Subscription"
                            />
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label=" One time purchase"
                            />
                          </RadioGroup>
                        </div>

                        {formik.values.is_subscription === 1 && (
                          <div className="cycle">
                            You pay £ {price()} today, and £ {price()} each
                            delivery cycle. Cancel anytime.
                          </div>
                        )}
                      </>
                    )}

                    <div className="button-section">
                      <div className="btn_div_n">
                        {!isSubscriptionShow && stockStatus && (
                          <>
                            <div className="mobilehide">
                              <LightBtn
                                type="submit"
                                title=" Buy Now"
                                className="now-btn"
                                onClick={async () =>
                                  await setSubmitType('buyNow')
                                }
                                loading={isLoadingBuyNow}
                              />

                              <DarkBtn
                                type="submit"
                                title="Add to Basket"
                                onClick={async () =>
                                  await setSubmitType('basket')
                                }
                                loading={isLoadingAddToBasket}
                              />
                            </div>
                            <div className="mobilebtn mobileshow">
                              <LightBtn
                                type="submit"
                                title="Add to Basket"
                                className="now-btn"
                                onClick={async () =>
                                  await setSubmitType('basket')
                                }
                                loading={isLoadingAddToBasket}
                              />
                              <DarkBtn
                                title="Buy Now"
                                type="submit"
                                onClick={async () =>
                                  await setSubmitType('buyNow')
                                }
                                loading={isLoadingBuyNow}
                              />
                            </div>
                          </>
                        )}
                        {stockStatus && formik.values.is_subscription === 0 ? (
                          <>
                            <div className="mobilehide">
                              <LightBtn
                                type="submit"
                                title=" Buy Now"
                                className="now-btn"
                                onClick={async () =>
                                  await setSubmitType('buyNow')
                                }
                                loading={isLoadingBuyNow}
                              />
                              <DarkBtn
                                title="Add to Basket"
                                type="submit"
                                onClick={async () =>
                                  await setSubmitType('basket')
                                }
                                loading={isLoadingAddToBasket}
                              />
                            </div>
                            <div className="mobilebtn mobileshow">
                              <LightBtn
                                type="submit"
                                title="Add to Basket"
                                className="now-btn"
                                onClick={async () =>
                                  await setSubmitType('basket')
                                }
                                loading={isLoadingAddToBasket}
                              />
                              <DarkBtn
                                title="Buy Now"
                                type="submit"
                                onClick={async () =>
                                  await setSubmitType('buyNow')
                                }
                                loading={isLoadingBuyNow}
                              />
                            </div>
                          </>
                        ) : (
                          productDetail?.isSubscription === 1 &&
                          stockStatus && (
                            <LightBtn
                              title="Set Up Now"
                              type="submit"
                              className="now-btn"
                              onClick={async () =>
                                await setSubmitType('setupNow')
                              }
                              loading={isSetupNowLoading}
                            />
                          )
                        )}

                        {!stockStatus && (
                          <>
                            <div className="selection-text heading20">
                              Selection is currently out of stock
                            </div>
                            {isShowLetMeKnow &&
                              (userDetails.token ? (
                                <DarkBtn
                                  className="basket-btn1"
                                  title="Let me know when its back"
                                  onClick={letMeKnowOutOfStocks}
                                />
                              ) : (
                                <NotifyProductDialog
                                  item={{
                                    product_id: productDetail.id,
                                    product_inventory_color_id:
                                      inventoryColorId,
                                    product_inventory_id: 0,
                                  }}
                                />
                              ))}
                          </>
                        )}
                      </div>
                      <div className="mobilehide">
                        <Share
                          url={productDetail?.shareLink}
                          title={productDetail?.name}
                        />
                      </div>
                    </div>
                  </form>
                  {productDetail?.cartItemCount > 0 && (
                    <div className="last-text">
                      {productDetail?.cartItemCount}{' '}
                      {productDetail?.cartItemCount === 1
                        ? 'person currently has this in their basket! Don’t miss out!'
                        : 'people currently have this in their basket! Don’t miss out!'}
                    </div>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/*  card end */}

          {/* tab section */}
          <SingleProductTab
            // Description={productDetail?.description}
            Specifications={productDetail?.specification}
            // Policies={productDetail?.sellermetadata}
            productDetail={productDetail}
          />

          {/* You might also like */}
          <Grid spacing={2} className="headingmarbtm youmightsection">
            <Grid item xs={12} sm={12} md={10} className="">
              <MainHeading title="You might also like... " />
            </Grid>
            <Grid item xs={12} sm={2} className="textright mobilehide ">
              <DarkBtn
                onClick={() => {
                  navigate(
                    productDetail?.headerMenuSlug
                      ? productDetail?.headerMenuSlug
                      : `/${
                          productDetail?.tagsAttached &&
                          productDetail?.tagsAttached[0]?.middleTagName
                            ?.replace(/\s/g, '-')
                            ?.toLowerCase()
                        }/${
                          productDetail?.tagsAttached &&
                          productDetail?.tagsAttached[0]?.tagName
                            .replace(/\s/g, '-')
                            .toLowerCase()
                        }`
                  );
                }}
                title="Shop all"
              />
            </Grid>
          </Grid>

          <div className="hidedot scrollenable">
            {isLoadingTP
              ? null
              : TagProductList && (
                  <ProductSlider
                    trending
                    Data={TagProductList}
                    mainID={mainID}
                  />
                )}{' '}
          </div>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              className="mobileshow textcenter might-section dexktophide"
            >
              <DarkBtn
                onClick={() => {
                  navigate(
                    productDetail?.headerMenuSlug
                      ? productDetail?.headerMenuSlug
                      : `/${
                          productDetail?.tagsAttached &&
                          productDetail?.tagsAttached[0]?.middleTagName
                            ?.replace(/\s/g, '-')
                            ?.toLowerCase()
                        }/${
                          productDetail?.tagsAttached &&
                          productDetail?.tagsAttached[0]?.tagName
                            .replace(/\s/g, '-')
                            .toLowerCase()
                        }`
                  );
                }}
                title="Shop all"
              />
            </Grid>
          </Grid>
        </Container>
      )}
      {/* LatestArrivals */}
      {isLoadingFe
        ? null
        : FeatureBannerData && (
            <div className="scrollenable">
              <LatestArrivals
                mainID={mainID}
                CustomData={{
                  products: FeatureBannerData?.data,
                  title: 'Sellers other Products',
                  description:
                    'Browse this sellers other products and their store to find more items similar to this',
                  buttonLabel: 'Go to Store',
                  path: `${productDetail?.seller?.nameSlug}`,
                }}
              />
            </div>
          )}

      {isLoading
        ? null
        : productDetail && (
            <Reviews
              id={productDetail?.id}
              itemName={productDetail.name}
              purchasedDate={`20 march`}
            />
          )}
    </div>
  );
};

export default SingleProductDetails;
