import {
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { images } from "../../../assets/_index";
import ConfirmRegistrationDialog from "../../../components/dialogs/ConfirmRegistration";
import ResendPasswordDialog from "../../../components/dialogs/ResendPassword";
import DarkBtn from "../../../components/universal/Buttons/DarkBtn";
import CheckoutField from "../../../components/universal/InputField/CheckoutField";
import { baseUrl, notify } from "../../../core/constants";
import { updateGuestUser } from "../../../redux/actions/guestUser";
import { addLogin } from "../../../redux/actions/login";
import AxiosAll from "../../../services/axiosAll";
const LoginCheckout = () => {
  const [open, setOpen] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [emailstate, setEmailstate] = React.useState(false);
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const [tempToken, settempToken] = React.useState("");
  const nav = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const cart_id = params.get("cart_id");
  const isSubscription = params.get("isSubscription");
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const voucher = searchParams.get("voucher");

  React.useEffect(() => {
    if (
      !userDetails?.token &&
      location.pathname === "/is_reedem_gift_voucher" &&
      voucher
    ) {
      setOpen(true);
    }
  }, []);

  React.useEffect(() => {
    if (!userDetails?.token && location.pathname === "/signin") {
      setOpen(true);
    }
  }, []);

  // formik start
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("E-mail address is required.")
      .email("Enter valid email address"),
    password: Yup.string().required("Password is required."),
  });

  const onSubmit = (values) => {
    var data = {
      email: values.email,
      password: values.password,
      deviceToken: guestDetails.token,
      deviceType: 3,
      guest_user_id: userData?.user?.id,
    };
    login(data);
  };
  const login = async (data) => {
    setLoading(true);
    try {
      const response = await axios(`${baseUrl}user/login`, {
        method: "post",
        data: data,
        headers: {
          "Content-Type": "application/json",
          userToken: userData?.userToken,
        },
      });

      if (response.data.status === "Success") {
        notify(response.data.message, "success");
        if (response?.data?.result?.user?.isVerified == 0) {
          setEmailstate(true);
          reSendMail(data.email);
          settempToken({ userToken: response?.data?.result?.userToken });
        } else {
          let userDetails = {
            token: response?.data?.result?.userToken,
            user: response?.data?.result?.user,
          };
          dispatch(addLogin(userDetails));
          let user = response?.data?.result?.user;
          dispatch(
            updateGuestUser({
              user: user,
              userToken: userDetails.token,
            })
          );

          //   if (isSubscription) {
          //     nav(`/checkout?cart_id=${cart_id}&isSubscription=1`);
          //   } else {
          //     nav(cart_id ? `/checkout/${cart_id}` : `/basket`);
          //   }
          // } else {
          //   notify(response.data.message, 'error');
          // }
          if (cart_id) {
            if (isSubscription) {
              nav(`/checkout?cart_id=${cart_id}&isSubscription=1`, {
                replace: true,
              });
            } else {
              nav(`/checkout?cart_id=${cart_id}&skip=true`, { replace: true });
            }
          } else {
            nav("/checkout", { replace: true });
          }
        }
      } else {
        notify(response.data.message, "error");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const reSendMail = async (data) => {
    try {
      const res = await AxiosAll("post", `user/resend-link?`, { email: data });
      notify(res.data.message, "success");
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const handleClickOpen = () => {
    formik.handleReset();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="login-checkout-new">
      <Container className="all-screen-container">
        <div className="login-section">
          <img src={images.mainLogoinner} className="logocheckout" />
          <div className="login-text">Login to your account</div>
          <form onSubmit={formik.handleSubmit}>
            <CheckoutField
              name="email"
              id="email"
              type="email"
              placeholder="Email address"
              autocomplete="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
            <CheckoutField
              password
              name="password"
              id="password"
              outocomplete="current-password"
              type={isPasswordShow ? "password" : "text"}
              placeholder="Password"
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
              isPasswordShow={isPasswordShow}
              setIsPasswordShow={setIsPasswordShow}
            />
            <div className="text-section">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Keep me signed in"
                  className="keep-text"
                />
              </FormGroup>
              <div className="forgot-text">
                {" "}
                <ResendPasswordDialog msg="Forgotten your password?" />
              </div>
            </div>
            <div className="logging-text">
              By logging in, you agree to GoEthical’s{" "}
              <span
                onClick={() =>
                  window.open(
                    "https://goethical.com/blog/privacy-policy/",
                    "_self"
                  )
                }
              >
                {" "}
                Privacy Policy
              </span>{" "}
              and{" "}
              <span
                onClick={() =>
                  window.open(
                    "https://goethical.com/blog/terms-and-conditions/",
                    "_self"
                  )
                }
              >
                {" "}
                Terms of Use.
              </span>
            </div>
            <DarkBtn
              disable={Loading}
              type="submit"
              title={Loading ? "Loading..." : "Login and Checkout"}
            />
          </form>
        </div>
        {emailstate ? (
          <ConfirmRegistrationDialog
            type={2}
            query={
              cart_id
                ? isSubscription
                  ? `?cart_id=${cart_id}&isSubscription=1`
                  : `?cart_id=${cart_id}&skip=true`
                : ""
            }
            userDatahas={tempToken}
            status={emailstate}
            setState={setEmailstate}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default LoginCheckout;
