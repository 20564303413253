import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import * as React from 'react';

export default function ImageBannerSkeleton() {
  return (
      <div className='skeleton'>
    <Stack spacing={1}>
     <Skeleton variant="rectangular" className="bannerskeleton"   width="100%"/>
    </Stack>
    </div>
  );
}