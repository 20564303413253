import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { images } from '../../../assets/_index';
import LightBtn from '../../universal/Buttons/LightBtn';
import SellerMobileMenu from '../../universal/SellerHeader/SellerMobileMenu';

const SellerHeader = () => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (userDetails.token) {
      window.open(`${window.location.origin.toString()}`, '_Self');
    }
  }, []);
  return (
    <div className="sellerheader">
      <div className="header-seller">
        <div>
          <img
            loading="lazy"
            onClick={() => {
              window.open(`${window.location.origin.toString()}`, '_Self');
            }}
            src={images.mainLogo}
            alt="goEthical logo"
            className="logo-img"
          />
        </div>
        <div className="category-section desktopshow">
          <div className="category-name">Home</div>
          <div className="category-name">I'm a Seller</div>
          <div className="category-name">I'm a Buyer</div>
          <div className="category-name">About Us</div>
          <div className="category-name">Our Blog</div>
          <div className="category-name">Contact Us</div>
          <div>
            <LightBtn title="Login" />
          </div>
        </div>
        <div className="desktophide">
          <SellerMobileMenu />
        </div>
      </div>
    </div>
  );
};

export default SellerHeader;
