import FilterListIcon from '@mui/icons-material/FilterList';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import * as React from 'react';
import DynamicFIlters from '../../universal/DynamicFIlters';

export default function DynamicMobileFilter({ getFilterValues, Categories,loadState }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <p className="mobileshow mobilefilter" onClick={handleClickOpen}>
        <FilterListIcon /> Filter
      </p>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mobilefilterdialogs"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DynamicFIlters
            loadState={loadState}
              Categories={Categories}
              getFilterValues={getFilterValues}
              setMobileOpen={setOpen}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
