import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from "formik";
import * as React from 'react';
import * as Yup from "yup";
import { notify } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';
import LightBtn from '../../universal/Buttons/LightBtn';
import InputField from '../../universal/InputField';

export default function ResendPasswordDialog({msg}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetPassword = async (data) => {
    try {
      const res = await AxiosAll(
        'post',
        `user/forgot-password?`,
        data
      )
      if (res?.data?.status == "Success") {
        notify(res.data.message, "success")
      } else {
        notify(res.data.message, "error")
      }
      handleClose()
    }
    catch (error) {
      console.log(error);
    }
  };
  // formik start
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required('E-mail address is required.')
      .email('Enter valid email address'),
  });
  const onSubmit = (values) => {
    formik.handleReset();
    const formdata = {
      email: values?.email,
      url: window.location.origin.toString()

    }
    resetPassword(formdata)
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (

    <div >
      <div className='signin'>
        <div className="forgot-text textcenter heading21"
          onClick={handleClickOpen}
        >
        {msg?msg:'Forgot your password?'}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='resendpassword'
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div >
                <div className='dialog-heading34 password-heading textcenter'>Password Reset</div>
                <div className='resend-para pera-text18 textcenter'>
                  Please enter your email to receive a reset link
                </div>
                <InputField
                  name="email"
                  id='email'
                  autocomplete="email"
                  placeholder='Email Address'
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                />
                <div className='send-linkbtn-part'>
                  <LightBtn title="Send Link" type="submit" />
                </div>

              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};


