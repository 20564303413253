import React from 'react';
import { Button } from '@mui/material';

const OutOfStock = () => {
  return (
    <div>
        <Button variant="outlined" className="outofstock">Out Of Stock</Button>
    </div>
  )
}

export default OutOfStock