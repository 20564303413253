import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { images } from '../../../../assets/_index';

const SelectBoxSubscription = ({ getSubscriptionValue }) => {
  const [value, setValue] = useState(2);

  useEffect(() => {
    getSubscriptionValue(value);
  }, [value]);

  return (
    <div className="selectbox">
      <FormControl className="selectboxwidth">
        <Select
          defaultValue={2}
          style={{
            backgroundImage: `url(${images.errowDown})`,
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          className="select-field"
        >
          <MenuItem value={1}>Weekly subscription</MenuItem>
          <MenuItem value={2}>Monthly subscription</MenuItem>
          <MenuItem value={3}>Quarterly subscription</MenuItem>
          <MenuItem value={4}>Half Yearly subscription</MenuItem>
          <MenuItem value={5}>Yearly subscription</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectBoxSubscription;
