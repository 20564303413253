import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DehazeIcon from '@mui/icons-material/Dehaze';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
}));
function ResponsiveDrawer({ headerData }) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const navigate = useNavigate();

  const drawer = (
    <div className="mobilemenu">
      {headerData?.map((item, index) => {
        return (
          <div key={index}>
           
                {item?.subMenu?.map((subitem, subindex) => {
                  return (
                    <Accordion key={subindex}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel3a-header"
                      >
                        <Typography>{subitem.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <ul>
                            <li
                              onClick={() => {
                                setMobileOpen(false);
                                window.open(
                                  `${subitem?.navigationPath
                                    .replace(/\s/g, '-')
                                    .toLowerCase()}`,
                                  '_blank'
                                );
                              }}
                            >
                              {subitem.name}
                            </li>
                            {subitem?.headerSubMenuChilds?.map(
                              (menuItem, menuIndex) => {
                                return (
                                  <li
                                    key={menuIndex}
                                    onClick={() => {
                                      setMobileOpen(false);
                                     
                                      window.open(
                                        `${menuItem.navigationPath
                                          .replace(/\s/g, '-')
                                          .toLowerCase()}`,
                                        '_blank'
                                      );
                                    }}
                                  >
                                    {menuItem.subMenuChildName}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              
          </div>
        );
      })}
    </div>
  );
  return (
    <div className={classes.root}>
      {/* <img 
        loading="lazy"
        src={images.menuIcon}
        alt="hamburger icon"
        color="inherit"
        aria-label="Open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      /> */}
      <DehazeIcon
      loading="lazy"
      // src={images.menuIcon}
      alt="hamburger icon"
      color="inherit"
      aria-label="Open drawer"
      edge="start"
      onClick={handleDrawerToggle}
      className={classes.menuButton}
      />

      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              className={classes.closeMenuButton}
            >
              <CloseIcon />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}
ResponsiveDrawer.propTypes = {
  container: PropTypes.object,
};
export default ResponsiveDrawer;
