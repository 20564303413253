import Button from '@mui/material/Button';
import { images } from '../../../../assets/_index';

const WpSignIn = () => {
  return (
    <>
      <div className="signin">
        <Button
          type="button"
          className="sign-in"
          onClick={() => window.open('/signin')}
        >
          <span className="mobilehide tabshow signintext">Sign in</span>
          <img
            loading="lazy"
            alt="Profile image"
            src={images.userIcon}
            className="profile mobilehide tabshow "
          />
          <img
            loading="lazy"
            alt="Profile image"
            src={images.userMobileIcon}
            className="profile mobileshow tabhide"
          />
        </Button>
      </div>
    </>
  );
};

export default WpSignIn;
