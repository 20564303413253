import { useEffect } from "react";
import { useNavigate } from "react-router";
import DarkBtn from "../../components/universal/Buttons/DarkBtn";


export const NotFoundProduct = () => {

    const nav = useNavigate()
    useEffect(() => {
        if (window?.location?.pathname == '/checkout-email')
         { nav(-1) }
    if(window.location.pathname?.includes(`/products`))
    {
      nav(window.location.pathname?.replace('/products',''))  
    }
    }, [ ]);
    return (
        <div className="notfound">
            <h1>Oops, Product not found</h1>
            <p>We are very sorry for the inconvenience. It looks like you're trying to</p>
            <p>
                access a Prodcut the has been removed or never even existed.
            </p>
            <DarkBtn onClick={() => nav('/')} title="BACK TO HOMEPAGE" />
        </div>
    )
}