import Grid from '@mui/material/Grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../../../assets/_index';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import ProductBtn from '../Buttons/ProductBtn';

const CustomerEmptyBasket = () => {
  const nav = useNavigate();
  return (
    <div className="customeremptybasket">
      <Grid container spacing={2} className="card-part">
        <Grid item xs={12} sm={9}>
          <table>
            <tbody>
              <tr>
                <td className="table_product_name">
                  <div className="product-text">
                    {' '}
                    Your basket is empty! Lets change that
                  </div>
                  <div className="product-para">
                    Check out our ethical products today
                  </div>
                </td>
                <td className="image-table">
                  <img
                    loading="lazy"
                    alt="basket"
                    src={images.customerBasket}
                    className="myimg"
                  />
                </td>
                <td className="table_btn">
                  <LightBtn onClick={() => nav('/')} title="Shop Now" />
                  <DarkBtn onClick={() => nav('/')} title="Browse Categories" />
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="customer-card">
            <div className="zero-item">0 Items in Basket</div>
            <div className="zero-price">£0</div>
            <div className="heading20">Total</div>
            <div className="btn-section-empty-side">
              <ProductBtn onClick={() => nav('/')} title="Add Products!" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerEmptyBasket;
