import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


const sweatAlertBox = async (title, text, setConfirmDel,confirmButtonText,cancelButtonText) => {
  const result = await MySwal.fire({
    title: title,
    text: text,
    type: 'warning',
    cancelButtonText: cancelButtonText?cancelButtonText:'Cancel',
    confirmButtonColor: '#ff6600',
    confirmButtonText: confirmButtonText?confirmButtonText:'Confirm',
    showCancelButton: cancelButtonText?true:false,
    allowOutsideClick: false,
  });
  if (result.isConfirmed) {
    setConfirmDel(true);
  }
};

export default sweatAlertBox;