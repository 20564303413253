import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../../core/constants';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';

export default function OutOfStockDialog({
  arrayOutOfStock,
  totalItem,
  open,
  setOpen,
  params,
  isSubscription,
  plateForm,
  setState,
  state,
}) {
  const { userDetails } = useSelector((state) => state.loginReducer);

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="outofstockdialog">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="out-of-stock-dialog"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="colorheading">
              The following items are out of stock.{' '}
            </div>
            <div className="midcontent">
              <ul>
                {arrayOutOfStock?.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </div>
            <div className="messagetext">
              Are you sure you want to proceed without these items?
            </div>
            <div className="popbtn">
              <DarkBtn title="No" onClick={handleClose} />

              <LightBtn
                disabled={totalItem === 0 ? true : false}
                title="Continue"
                onClick={() => {
                  if (plateForm === 'desktop') {
                    userDetails.token
                      ? totalItem > 0
                        ? params?.id
                          ? isSubscription
                            ? navigate({
                                pathname: '/checkout',
                                search: `?cart_id=${params?.id}&isSubscription=1`,
                              })
                            : navigate({
                                pathname: '/checkout',
                                search: `?cart_id=${params?.id}`,
                              })
                          : navigate({
                              pathname: '/checkout',
                              search: `?selected_all=0&skip=true`,
                            })
                        : notify('You must select items to checkout', 'error')
                      : navigate(
                          params?.id
                            ? isSubscription
                              ? `/guest-checkout?cart_id=${params?.id}&isSubscription=1`
                              : `/guest-checkout?cart_id=${params?.id}&skip=true`
                            : '/guest-checkout'
                        );
                  } else {
                    userDetails?.token
                      ? totalItem > 0
                        ? params?.id
                          ? isSubscription
                            ? navigate({
                                pathname: '/checkout',
                                search: `?cart_id=${params?.id}&isSubscription=1`,
                              })
                            : navigate({
                                pathname: '/checkout',
                                search: `?cart_id=${params?.id}`,
                              })
                          : navigate({
                              pathname: '/checkout',
                              search: `?selected_all=0&skip=true`,
                            })
                        : notify('You must select items to checkout', 'error')
                      : setState(!state);
                    setOpen(false);
                  }
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
