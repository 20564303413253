import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CrawerLink } from '../CrawlerLink';

const ShopCollectionCard = ({ title, image, link, id, type, item }) => {
  const [filter, setFilter] = useState({
    tags: '',
    feedtype: 0,
    metadatas: '',
    sortbyprice: '',
    startprice: 0,
    endprice: 1000,
    search: '',
    isFreeDelivery: 0,
    discountSale: 0,
    isSellerFilter: 0,
    sellers: 0,
    isEthicalValueFilter: 0,
    shop_collection: id,
    ethicalValues: 0,
    customHomeFilterType: '',
    customHomeFilterTypeId: '',
    customHeaderLinkFilterType: undefined,
    customHeaderLinkFilterTypeId: 0,
    customHeaderLinkFilterTypeAll: 0,
    customButtonLinkFilterType: 0,
    customButtonLinkFilterTypeId: 0,
    per_page_limit: 24,
  });

  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        link != undefined && link.toString().length > 0
          ? window.open(link, '_blank')
          : type == 'favouritebrands'
          ? navigate(`/store/${item?.seller?.nameSlug.replace(/ /g, '-')}`)
          : navigate({
              pathname: `/shop-collection-${item?.linkSlug?.replaceAll(/ /g, '-')}`,
            });
      }}
      className="shopcollection"
    >
      {link != undefined && link.toString().length > 0 ? (
        <CrawerLink path={link} type="url" />
      ) : (
        <CrawerLink
          path={
            type == 'favouritebrands'
              ? `/store/${item?.seller?.nameSlug.replace(/ /g, '-')}`
              : `/shop-collection-${item?.linkSlug?.replaceAll(/ /g, '-')}`
          }
        />
      )}
      <img loading="lazy" alt={title} title={title} className="img-row" src={image} />
      <div className="unpublished"> {title} </div>
    </div>
  );
};

export default ShopCollectionCard;
