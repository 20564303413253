import { Container, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { images } from '../../assets/_index';
import CreateRegister from '../../components/dialogs/CreateRegister';
import MobileFilter from '../../components/dialogs/MobileFilter';
import RegisterDialog from '../../components/dialogs/Register';
import TrendingCollections from '../../components/home/TrendingCollections';
import ProductCardSkeleton from '../../components/Skeleton/ProductCardSkeleton';
import ProductBtn from '../../components/universal/Buttons/ProductBtn';
import RetailersBtn from '../../components/universal/Buttons/RetailersBtn';
import Filters from '../../components/universal/Filters';
import MainHeading from '../../components/universal/Heading/MainHeading';
import CustomHelmet from '../../components/universal/Helmet';
import InnerBanner from '../../components/universal/InnerBanner';
import Pagination from '../../components/universal/Pagination';
import ProductCard from '../../components/universal/ProductCard';
import ProductSearchResult from '../../components/universal/ProductSearchResult';
import {
  baseUrl,
  capitalizeFirstLetter,
  deviceToken,
  getScreenSize,
  getScreenSizeImagDimention,
} from '../../core/constants';
import AxiosAll from '../../services/axiosAll';

const SearchProduct = () => {
  const location = useLocation();
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [trendingCollection, setTrendingCollection] = useState([]);
  const [BannerData, setBannerData] = useState({});
  const [productList, setProductList] = useState([]);

  const [isLoading, setisLoading] = useState(true);
  const [loadingBanner, setloadingBanner] = useState(false);
  const [query, setQuery] = useState({});
  const searchParams = new URLSearchParams(location.search);
  const nav = useNavigate();
  const [shop, setshop] = useState(false);
  const [shopCollection, setshopCollection] = useState(false);
  const [loadingProducts, setloadingProducts] = useState(true);
  const [shopBanner, setshopBanner] = useState(false);
  let params = new URLSearchParams(searchParams);
  const UrlParams = useParams();
  let q = query?.metadatas;
  let feed = params.get('feedtype');
  let customeProductId = params.get('customHomeFilterTypeId');
  let type = params.get('type');
  let shop_collection_id = params.get('shop_collection_id');
  let customHeaderLinkFilterTypeAll = params.get(
    'customHeaderLinkFilterTypeAll'
  );
  let isTrending = params.get('isTrending');
  let subMenuId = params.get('subMenuId');
  const pageNumber = params.get('page');
  const [page, setPage] = useState(pageNumber ? parseInt(pageNumber) : 1);

  useEffect(() => {
    setPage(pageNumber ? parseInt(pageNumber) : 1);
  }, [pageNumber]);

  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, [window.location.pathname, window.location.search]);

  const getFilterValues = (values) => {
    setQuery((prev) => ({ ...prev, ...values, page: pageNumber }));
  };

  useEffect(() => {
    for (const [key, value] of searchParams) {
      setQuery((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href, productList]);

  const path = location.pathname
    ? location.pathname.replace(/^\/|\/$/g, '').split('/')[0]
    : '';

  const urlParams = useParams();

  const search = async () => {
    setloadingProducts(true);
    try {
      const res = await axios(
        `${baseUrl.replace(
          'api/v2/',
          ''
        )}elastic/api/search_new.php?page=${page}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}&startprice=0&endprice=1000&sortbyprice=${
          query.sortbyprice
        }&tags=${query.tags}&query=${
          urlParams.search_query
        }&feedType=0&metadatas=${
          query.metadatas
        }&records=24&deviceType=3&deviceToken=${deviceToken}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      const data = res.data.result;
      if (res.data.status === 'Success') {
        setProductList(data);
        setloadingProducts(false);
      }
    } catch (error) {
      console.log(error);
      setloadingProducts(false);
    }
  };

  useEffect(async () => {
    await search();
  }, [query, page]);

  const getTrendingCollection = async () => {
    try {
      const res = await AxiosAll(
        'get',
        `home/shop_collection?search=&filter=2&page=1&&shop_all=0&`,
        '',
        userData?.userToken
      );
      setTrendingCollection(res?.data?.result?.data);
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTrendingCollection();
  }, []);

  const getBanner = async (metadatas) => {
    try {
      const res = await AxiosAll(
        'get',
        metadatas
          ? `home/product_Badge/${query?.metadatas}?`
          : query.shop_collection_id
          ? `home/shop_collection/${query.shop_collection_id}?`
          : subMenuId
          ? `home/header_menu_banner/${
              customHeaderLinkFilterTypeAll > 0
                ? customHeaderLinkFilterTypeAll
                : subMenuId
            }?is_all_sub_menu=${customHeaderLinkFilterTypeAll > 0 ? 1 : 0}&`
          : `home/banner?customeProductId=${customeProductId}&type=${
              type && type?.length > 0
                ? type?.slice(0, 1)
                : isTrending
                ? isTrending
                : feed
            }&`,
        '',
        userData?.userToken
      );
      setBannerData(res?.data?.result);
      setloadingBanner(false);
    } catch (error) {
      console.log(error);
      setloadingBanner(false);
    }
  };

  useEffect(async () => {
    setloadingBanner(true);
    if ((q && q?.length > 0) || query?.metadatas) {
      await getBanner(q);
      await setshop(true);
    } else if (
      (shop_collection_id && shop_collection_id?.length > 0) ||
      subMenuId ||
      customHeaderLinkFilterTypeAll
    ) {
      await setshopCollection(true);
      if (
        shop_collection_id &&
        shop_collection_id?.length > 0 &&
        customHeaderLinkFilterTypeAll == 0
      ) {
        await setshopBanner(true);
        await getBanner();
      } else {
        await getBanner();
      }
    } else {
      await setshop(false);
      await setshopBanner(false);
      await setshopCollection(false);
      await getBanner();
    }
  }, [
    q,
    query?.metadatas,
    shop,
    feed,
    shop_collection_id,
    shopCollection,
    isTrending,
    subMenuId,
    customHeaderLinkFilterTypeAll,
    query,
    location.search,
    page,
    urlParams,
    location.pathname,
    window.location.href,
  ]);

  const tabTitle =
    path === 'search'
      ? capitalizeFirstLetter(UrlParams.search_query)
      : capitalizeFirstLetter(UrlParams?.page_name?.replaceAll('-', ' '));

  return (
    <div className="products">
      <CustomHelmet
        title={tabTitle}
        description="An ethical marketplace and lifestyle app that empowers shoppers to buy and sell with purpose and protect the planet."
        keyword={tabTitle}
        path={window?.location?.pathname}
      />
      {location.pathname.includes('/search') ? null : (
        <InnerBanner
          data={BannerData}
          shopBannerStatus={shopBanner}
          shopValue={shop}
          shopCollectiontype={query.shop_collection_id ? true : false}
        />
      )}
      <Container className="all-screen-container">
        {path === 'search' && (
          <ProductSearchResult query={urlParams.search_query} />
        )}
        <Grid container spacing={2}>
          <Grid item sm={3} className="mobilehide">
            <Filters getFilterValues={getFilterValues} page={page} />
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            {location.pathname.includes('/search') ? null : (
              <div className="headbtn-section">
                <div className="heading-product-btn">
                  <MainHeading title="Products" />
                </div>
                <p className="display-title mobilehide   ">
                  Displaying {productList?.total ? productList?.total : 0}{' '}
                  results
                </p>
              </div>
            )}

            {location.pathname.includes('/search') &&
              productList?.data?.length > 0 && (
                <>
                  <div className="headbtn-section">
                    <div className="mobilehide">
                      <MainHeading title="Products" />
                    </div>
                    <div>
                      <RetailersBtn title="Products" />
                      <ProductBtn
                        onClick={() => {
                          nav({
                            pathname: `/search/retailers/${urlParams.search_query}`,
                          });
                        }}
                        title="Retailers"
                      />
                    </div>
                  </div>
                  <div className="title-data mobilehide">
                    {' '}
                    <p className="display-title ">
                      Displaying {productList?.total ? productList?.total : 0}{' '}
                      results{' '}
                    </p>
                  </div>
                </>
              )}

            <div className="displayresult">
              <MobileFilter getFilterValues={getFilterValues} />
              <p className="display-title mobileshow">
                Displaying {productList?.total ? productList?.total : 0} results
              </p>
            </div>
            <div className="image-container">
              {loadingProducts ? (
                <ProductCardSkeleton type="productPage" />
              ) : productList?.data?.length > 0 ? (
                productList?.data?.map((product, index) => {
                  return (
                    <ProductCard
                      showRatingAndDelivery
                      position={index}
                      key={index}
                      productName={
                        path === 'search'
                          ? product?._source?.name
                          : product.name
                      }
                      productPrice={
                        path === 'search'
                          ? product?._source?.subscriptionPrice
                            ? product?._source?.subscriptionPrice
                            : product?._source?.price
                          : product.price
                      }
                      image={
                        path === 'search'
                          ? product?._source?.images[0].image
                          : product.images[0].image
                      }
                      tag={
                        path === 'search'
                          ? product?._source?.tagsAttached[0].parentName
                          : product.tagsAttached[0].parentName
                      }
                      item={path === 'search' ? product?._source : product}
                      productId={product?._id}
                    />
                  );
                })
              ) : (
                <div className="emptydata">
                  <div className="ahh-text">Oops!</div>
                  <div className="result-text">
                    <div>
                      We can’t find a match for {UrlParams.search_query}
                    </div>
                    <div>
                      Don’t give up! Check the spelling or try something more
                      generic.
                    </div>
                  </div>
                </div>
              )}
            </div>
            {loadingProducts
              ? null
              : productList?.data?.length > 0 && (
                  <Pagination
                    count={productList?.lastPage}
                    page={page}
                    onChange={(e, val) => {
                      setPage(val);
                      window.scrollTo(0, 0);
                      nav({
                        pathname: `${location.pathname}`,
                        search: `${createSearchParams({
                          tags: query?.tags ? query?.tags : '',
                          metadatas: query?.metadatas ? query?.metadatas : '',
                          sortbyprice: query?.sortbyprice
                            ? query?.sortbyprice
                            : 0,
                          page: val,
                        })}`,
                      });
                    }}
                  />
                )}
          </Grid>
        </Grid>
      </Container>
      {isLoading ? null : location.pathname.includes('/search') ? null : (
        <TrendingCollections CustomData={trendingCollection} />
      )}
      {userDetails?.token ? null : (
        <div className="make-my-account-section ng-star-inserted">
          <div className="container16 new_bg-27inch">
            <Grid container spacing={2}>
              <Grid item sm={3} xs={12} className="accountimg">
                <img
                  loading="lazy"
                  alt="create Account "
                  title="create Account "
                  src={images.createAccount}
                />
              </Grid>
              <Grid item sm={6} xs={12} className="accountcontent">
                <MainHeading title="Create an account today" />
                <p className="account-info">
                  {' '}
                  Looking to shop a range of products from our approved ethical
                  sellers? Creating an account is free and will only take a few
                  minutes. It’s as easy as that!{' '}
                </p>
                <CreateRegister title="Make my account!" register={true} />
              </Grid>
              <Grid item sm={3} xs={12}></Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchProduct;
