import { Grid, Skeleton } from '@mui/material'
import React from 'react'

const NewCheckoutPageSkeleton = () => {
    return (
        <div className='newcheckoutpageskeleton'>
            <Grid container spacing={2}>
                <Grid item sx={12} xs={8}>
                    <Skeleton variant="rectangular" className="offerbar" />
                    <Skeleton variant="text" className='titleme' />
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Skeleton variant="rectangular" className="proimg" />
                        </Grid>
                        <Grid item xs={9}>
                            <Skeleton variant="text" className='sptext' />
                            <Skeleton variant="text" className='sptext' />
                            <Skeleton variant="text" className='sptext' />
                            <div className='twoval'>
                                <Skeleton variant="text" className='sptext' />
                                <Skeleton variant="text" className='sptext' />
                            </div>
                            <div className='twoval'>
                                <Skeleton variant="text" className='sptext' />
                                <Skeleton variant="text" className='sptext' />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={12} xs={4}>
                    <Skeleton variant="text" className='titlemesm' />
                    <Skeleton variant="text" className='sptext80' />
                    <div className='twovalsm'>
                        <Skeleton variant="text" className='sptext' />
                        <Skeleton variant="text" className='sptext' />
                    </div>
                    <div className='twovalsm mrbtm'>
                        <Skeleton variant="text" className='sptext' />
                        <Skeleton variant="text" className='sptext' />
                    </div>
                    <div className='twoval_total'>
                        <Skeleton variant="text" className='sptext' />
                        <Skeleton variant="text" className='sptext' />
                    </div>
                    <Skeleton variant="rectangular" className="sum_btn" />
                </Grid>
            </Grid>
        </div>
    )
}

export default NewCheckoutPageSkeleton