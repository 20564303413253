import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import {
  baseUrlV1,
  category,
  getScreenSize,
  getScreenSizeImagDimention,
  sortBy
} from '../../../core/constants';
import ShopByValueFilterSkeleton from '../../Skeleton/ShopByValueFilterSkeleton';

//for the icon change
const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}
    >
      <div className="expandIconWrapper">
        <AddCircleOutlineIcon />
      </div>
      <div className="collapsIconWrapper">
        <DoNotDisturbOnOutlinedIcon />
      </div>
    </Box>
  );
};
const Filters = ({ getFilterValues, setMobileOpen, setPage }) => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { token } = useSelector((state) => state.guestReducer.guestDetails);
  const location = useLocation();
  const nav = useNavigate();
  const search = location?.search;

  const tags = search?.tags;
  const metadatas = search?.metadatas;
  const sortbyprice = search?.sortbyprice;

  const [badges, setBadges] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    tags: tags ? (tags === '' ? '0' : tags) : '0',
    metadatas: metadatas ? metadatas : '',
    sortbyprice: sortbyprice ? parseInt(sortbyprice) : '-1',
  });

  // console.log('filters', filters);

  useEffect(() => {
    if (location?.pathname?.includes('shop-by-value')) {
      badges.map((item) => {
        const name = item?.name?.toLowerCase()?.replace(' ', '-');
        const urlName = location?.pathname
          ?.split('/')[1]
          .replace('shop-by-value-', '');

        if (name === urlName) {
          setFilters((prev) => {
            return {
              ...prev,
              metadatas: item?.id,
            };
          });
        }
      });
    }
  }, [location.pathname, badges]);

  useEffect(() => {
    if (tags === undefined && metadatas === undefined) {
      setFilters({
        tags: '0',
        metadatas: '',
        sortbyprice: '-1',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getBadges = async () => {
    setLoading(true);
    try {
      const res = await axios(
        `${baseUrlV1}product/badges?deviceType=3&deviceToken=${token}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken: userData?.userToken?userData?.userToken:'12345',
          },
        }
      );
      const data = res?.data?.result;
      setBadges(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // console.log('location', location.pathname);

  useEffect(() => {
    getBadges();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, userData?.userToken]);

  useEffect(() => {
    getFilterValues(filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleCategoryChange = (e) => {
    // setPage(1);
    setFilters({
      ...filters,
      tags: e.target.value,
    });
    nav({
      pathname: `${location.pathname}`,
      search: `${createSearchParams({
        page: '1',
        ...filters,
        tags: e.target.value,
      })}`,
    });
    if (window.innerWidth < 920) {
      setMobileOpen(false);
    }
  };

  const handleMetadataChange = (item) => {
    // setPage(1);
    if (location.pathname.includes('shop-by-value')) {
      const name = item.name.toLowerCase().replace(' ', '-');
      nav({
        pathname: `/shop-by-value-${name}`,
      });
    } else {
      setFilters({ ...filters, metadatas: `${item.id}` });
      nav({
        pathname: `${location.pathname}`,
        search: `${createSearchParams({
          page: '1',
          ...filters,
          metadatas: item.id,
        })}`,
      });
      if (window.innerWidth < 920) {
        setMobileOpen(false);
      }
    }
  };

  const handleSortByPriceChange = (e) => {
    // setPage(1);
    setFilters({
      ...filters,
      sortbyprice: `${e.target.value}`,
    });
    nav({
      pathname: `${location.pathname}`,
      search: `${createSearchParams({
        page: 1,
        ...filters,
        sortbyprice: `${e.target.value}`,
      })}`,
    });
    if (window.innerWidth < 920) {
      setMobileOpen(false);
    }
  };

  return (
    <div className="filters">
      <Accordion defaultExpanded className="accordianbox">
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="filterheading">Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="filterbody marginbott">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={category[0]?.value}
              name="radio-buttons-group"
              value={filters?.tags}
            >
              {category.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio />}
                  label={item.name}
                  onChange={(e) => handleCategoryChange(e)}
                />
              ))}
            </RadioGroup>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className="accordianbox">
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="filterheading">Filter by values</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="filterbody">
            {Loading ? (
              <ShopByValueFilterSkeleton />
            ) : (
              badges.length > 0 && (
                <ul className="catfilter">
                  {badges?.map((item, index) => {
                    return (
                      <li
                        className="cursorpointer"
                        key={index}
                        onClick={() => handleMetadataChange(item)}
                      >
                        {/* <Skeleton
                          variant="circular"
                          width="60px"
                          height="60px"
                          // style={{ display: imageLoad ? 'none' : 'block' }}
                        /> */}
                        <img
                          // onLoad={() => setimageLoad(true)}
                          alt={item.name}
                          title={item.name}
                          loading="lazy"
                          src={item.image}
                          // style={{ display: !imageLoad ? 'none' : 'block' }}
                        />
                        <p className="catname">{item.name}</p>
                      </li>
                    );
                  })}
                </ul>
              )
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className="accordianbox">
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="filterheading">Sort by</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="filterbody">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={'-1'}
              name="radio-buttons-group"
              value={filters?.sortbyprice}
            >
              {sortBy.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio />}
                  label={item.name}
                  onChange={(e) => handleSortByPriceChange(e)}
                />
              ))}
            </RadioGroup>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default Filters;
