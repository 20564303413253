import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from 'react-router-dom';
import EthicalBrand from '../../components/home/EthicalBrand';
import ProductCardSkeleton from '../../components/Skeleton/ProductCardSkeleton';
import MainHeading from '../../components/universal/Heading/MainHeading';
import CustomHelmet from '../../components/universal/Helmet';
import BasicPagination from '../../components/universal/Pagination';
import ShopCollectionCard from '../../components/universal/ShopCollectionCard';
import AxiosAll from '../../services/axiosAll';

const ShopCollection = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let params = new URLSearchParams(searchParams);

  const [shopCollectionData, setShopCollectionData] = useState([]);
  const [loading, setloading] = useState(true);
  const pageNumber = params.get('page');

  const [page, setPage] = useState(pageNumber ? parseInt(pageNumber) : 1);

  const nav = useNavigate();

  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const getShopCollection = async () => {
    try {
      const res = await AxiosAll(
        'get',
        `home/shop_collection?page=${page}&per_page_limit=12&shop_all=1&`
      );
      const data = res.data.result;
      setShopCollectionData(data);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getShopCollection();
  }, [page]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [shopCollectionData]);

  return (
    <div className="shopcollectionpage">
      <CustomHelmet
        title={window?.location?.pathname.replace('/', '')}
        keyword={window?.location?.pathname.replace('/', '')}
        description={window?.location?.pathname.replace('/', '')}
        path={window?.location?.pathname}
      />
      <Container className="all-screen-container">
        <div className="textcenter">
          <MainHeading title="Shop Collections" />
        </div>
        <div className="image-container">
          {loading ? (
            <ProductCardSkeleton type="productPage" />
          ) : (
            shopCollectionData?.data?.map((item, index) => {
              return (
                <ShopCollectionCard
                  key={index}
                  id={item?.id}
                  image={item?.image}
                  title={item?.title}
                  item={item}
                />
              );
            })
          )}
        </div>
        {shopCollectionData?.lastPage > 1 && (
          <BasicPagination
            count={shopCollectionData?.lastPage}
            page={page}
            onChange={(e, val) => {
              setPage(val);
              window.scrollTo(0, 0);
              nav(`${location.pathname}?page=${val}`);
            }}
          />
        )}
      </Container>
      <EthicalBrand />
    </div>
  );
};

export default ShopCollection;
