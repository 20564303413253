import { Grid } from '@mui/material';
import moment from 'moment';
import React from 'react';

const CartItem = ({ item }) => {
  const today = moment();
  const arrivingDate = moment(today)
    .add(item?.arrives_at?item?.arrives_at:
      item?.shippingDetails?.proccessingTime, 'days')
    .format('ddd, DD MMMM');

  return (
    
      <div className="productcardcheckout">
        <div className="arrivestitle">Arrives by {moment(item?.arrivesAt).format('ddd, DD MMMM')}</div>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3} xl={3}>
            {item?.productDetails?.images?.map((image) => {
              if (image.isCover === 1) {
                return (
                  <img src={image.image} alt="product" className="cardproimg" />
                );
              }
            })}
          </Grid>
          <Grid item xs={9} sm={9} md={9} xl={9} className="leftcartitem">
            <div className="productdetails">
              <div>{item?.productDetails?.name}</div>
              {item?.productInventory?.sizes && (
                <p>Size: {item?.productInventory?.sizes?.size}</p>
              )}
              {item?.productInventoryColor?.colorMaster && (
                <p>Colour: {item?.productInventoryColor?.colorMaster?.color}</p>
              )}
              <p>Qty: {item?.stocks}</p>
            </div>
          </Grid>
        </Grid>
      </div>
    
  );
};

export default CartItem;
