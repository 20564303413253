import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { images } from '../../../../assets/_index';

const SelectBoxSize = ({ options, getSizeValue }) => {
  const [value, setValue] = useState(options[0]?.productInventoryId);

  useEffect(() => {
    getSizeValue(value);
  }, [value, options]);

  return (
    <div className="selectbox">
      <FormControl className="selectboxwidth">
        <Select
          defaultValue={options[0]?.productInventoryId}
          style={{ backgroundImage: `url(${images.errowDown})` }}
          className="select-field"
          onChange={(e) => {
            setValue(e.target.value);
          }}
        >
          {options?.map((item, index) => {
            return (
              <MenuItem selected value={item.productInventoryId} key={index}>
                {item.size}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectBoxSize;
