import { MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { notify } from '../../../../core/constants';
import '../../../../scss/universal/_quantity.scss';
import AxiosAll from '../../../../services/axiosAll';
import OutOfStock from '../../OutOfStock';

export default function MultiQuantity({
  item,
  virtualIdArray,
  setTotalItem,
  setTotalShippingCharges,
  setTotalGiftWrapp,
  setTotalPrice,
  setTotalPayable,
  customerAccount,
  setIsQuantityUpdated,
  isQuantityUpdated,
  disable,
  InStock,
}) {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [quantity, setQuantity] = useState(item?.stocks);

  const maximumQuantity = InStock >= 20 ? 20 : InStock;

  const [quantityList, setQuantityList] = useState([]);

  const makeQuantityList = () => {
    for (let i = 1; i <= maximumQuantity; i++) {
      if (!quantityList.includes(i)) {
        setQuantityList((prevState) => [...prevState, i]);
      }
    }
  };

  useEffect(() => {
    makeQuantityList();
    // eslint-disable-next-line
  }, []);

  const realCartUpdate = async (value) => {
    const data = {
      stocks: value,
    };
    try {
      const res = await AxiosAll(
        'put',
        `cart/${item?.cartItemId}?`,
        data,
        userData?.userToken
      );
      const apiData = res?.data?.result;
      // console.log('apiData', res?.data?.message);
      setTotalPrice && setTotalPrice(apiData?.totalPrice);
      setTotalShippingCharges &&
        setTotalShippingCharges(apiData?.totalShippingCharges);
      setTotalGiftWrapp && setTotalGiftWrapp(apiData?.totalGiftWrapping);
      setTotalItem && setTotalItem(apiData?.totalProducts);
      setIsQuantityUpdated && setIsQuantityUpdated(!isQuantityUpdated);
      setTotalPayable&& setTotalPayable(apiData?.totalPayablePrice);
      notify(res?.data?.message, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const virtualCartUpdate = async (value, ids) => {
    const data = {
      stocks: value,
    };
    try {
      const res = await AxiosAll(
        'put',
        `cart/${ids?.virtualId}?cart_id=${ids?.newCart_id}`,
        data,
        userData?.userToken
      );
      const apiData = res?.data?.result;
      setTotalPrice(apiData?.totalPrice);
      setTotalShippingCharges(apiData?.totalShippingCharges);
      setTotalGiftWrapp(apiData?.totalGiftWrapping);
      setTotalItem(apiData?.totalProducts);
      setIsQuantityUpdated && setIsQuantityUpdated(!isQuantityUpdated);

      // setTotalpayable(apiData?.totalPayablePrice);
    } catch (error) {
      console.log(error);
    }
  };
  const ForupdateStocks = (value) => {
    const virtualIdUn = virtualIdArray?.find(
      (e) => e?.realId === `${item?.cartItemId}`
    );
    const isFound = virtualIdArray.some((e) => {
      if (e?.realId === `${item?.cartItemId}`) {
        return true;
      }
    });

    if (isFound === true) {
      realCartUpdate(value);
      virtualCartUpdate(value, virtualIdUn);
    }
    if (isFound === false) realCartUpdate(value);
  };

  const handleChange = (event) => {
    setQuantity(event.target.value);

    customerAccount
      ? realCartUpdate(event.target.value)
      : ForupdateStocks(event.target.value);
    setIsQuantityUpdated && setIsQuantityUpdated(!isQuantityUpdated);
  };

  return (
    <>
      <div className="multiquantity">
        {InStock === 0 ? (
          <OutOfStock />
        ) : (
          <div className="quantity ">
            Quantity
            <span>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  disabled={disable ? disable : false}
                  sx={{
                    '&:hover': {
                      '&& fieldset': {
                        border: '1px solid #707070',
                      },
                    },
                  }}
                  value={quantity}
                  onChange={(e) => handleChange(e)}
                  displayEmpty
                  className={`quantity-btn-multi ${disable ? 'imgnone' : null}`}
                >
                  {quantityList?.map((quantity, index) => (
                    <MenuItem
                      className="widthsetup"
                      key={index}
                      value={quantity}
                    >
                      {quantity}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </span>
          </div>
        )}
      </div>
    </>
  );
}
