import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import * as React from 'react';
import { images } from '../../../assets/_index';
import Checkbox from '../../universal/Checkbox';

export default function PaymentMethodDialog({
  cardLIst,
  defaultSelected,
  select,
  isDefaultCardChanged,
}) {
  const [open, setOpen] = React.useState(false);
  const [Selected, setSelected] = React.useState(
    defaultSelected ? defaultSelected : cardLIst?.length > 0 && cardLIst[0]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = async (data) => {
    await setSelected(data);
    await select(data);
    setOpen(false);
  };

  const cardImage = (cardBrand) => {
    if (cardBrand === 'Visa') {
      return <img loading="lazy" className="card-img-visa" src={images.visa} />;
    } else if (cardBrand === 'MasterCard') {
      return (
        <img loading="lazy" className="card-img-visa" src={images.mastercard} />
      );
    } else if (cardBrand === 'American Express') {
      return <img loading="lazy" className="card-img-visa" src={images.amex} />;
    } else if (cardBrand === 'Discover') {
      return (
        <img loading="lazy" className="card-img-visa" src={images.discover} />
      );
    } else if (cardBrand === 'JCB') {
      return <img loading="lazy" className="card-img-visa" src={images.jcb} />;
    } else if (cardBrand === 'Diners Club') {
      return (
        <img
          loading="lazy"
          className="card-img-visa"
          src={images.dinner_club}
        />
      );
    } else if (cardBrand === 'UnionPay') {
      return (
        <img loading="lazy" className="card-img-visa" src={images.union_pay} />
      );
    } else {
      return <img loading="lazy" className="card-img-visa" src={images.card} />;
    }
  };

  React.useEffect(() => {
    setSelected(defaultSelected ? defaultSelected : Selected && Selected);
  }, [isDefaultCardChanged]);

  return (
    <div className="PaymentMethod">
      <img
        loading="lazy"
        onClick={handleClickOpen}
        src={images.edit}
        className="editimg"
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="payment-method"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="GiftVoucher">
              <div className="voucher-text">Payment Method</div>
              <div className="voucher-para  pera-text18">Select card</div>
            </div>
            <div className="password">
              {cardLIst?.map((item, index) => {
                return (
                  <div className="cardName" key={index}>
                    <div>
                      <div>{cardImage(item.cardBrand)}</div>
                    </div>
                    <div>
                      <div className="name">{item?.firstName}</div>
                      <div className="card-number">
                        **** **** **** {item?.cardLast4Digits}
                      </div>
                    </div>

                    <Checkbox
                      onClick={() => {
                        handleChange(item);
                      }}
                      checked={item?.id == Selected?.id ? true : false}
                      value={Selected?.id}
                    />
                  </div>
                );
              })}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
