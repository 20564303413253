import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { images } from '../../../assets/_index';
import { baseUrl, deviceToken, notify } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';
import LightBtn from '../../universal/Buttons/LightBtn';
import InputField from '../../universal/InputField';

export default function ReferAFriendDialog({ type, setCreditScore }) {
  const [open, setOpen] = React.useState(false);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [balance, setBalance] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getBalance = async () => {
    try {
      const res = await AxiosAll(
        'get',
        'wallet/balance?',
        '',
        userData.userToken
      );
      const data = res.data.result;
      setBalance(data);
      if (setCreditScore) {
        setScore(data?.balance);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setScore = (balance) => {
    return setCreditScore(balance);
  };

  useEffect(() => {
    getBalance();
  }, []);

  const initialValues = {
    name: '',
    email: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Enter valid email address')
      .required('E-mail address is required.'),
    message: Yup.string(),
  });

  const sendInvite = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${baseUrl}invite_friend?deviceType=3&deviceToken=${deviceToken}`,
        data,
        {
          headers: {
            userToken: userData.userToken,
          },
        }
      );
      setLoading(false);
      formik.handleReset();
      setOpen(false);
      notify(res.data.message, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (values) => {
    const data = {
      email: userData?.user?.email,
      friend_name: values.name,
      friend_email: values.email,
      message: values.message,
      is_web: 1,
      via: 'email',
    };
    sendInvite(data);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="referfriend">
      {type == 'header' ? (
        <div onClick={handleClickOpen} className="user-name1 heading20">
          Refer a Friend
        </div>
      ) : (
        <LightBtn title="Refer a Friend" onClick={handleClickOpen} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="refer-friend"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="refer-friend">
              <div className="refer-heading">Refer a Friend</div>
              <div className="refer-friend-img">
                <img
                  loading="lazy"
                  src={images.referAFriend}
                  alt="Refer a Friend"
                  className="refer-img"
                />
              </div>
              <div className="credit-text">Credit: £{balance.balance?.toFixed(2)}</div>
              <div className="credit-para">
                Invite a friend and get £5 credit you can spend when they’ve
                created an account!
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <InputField
                placeholder="Friends Name"
                name="name"
                type="text"
                autocomplete="given-name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && formik.errors.name}
              />
              <div className="password">
                <InputField
                  placeholder="Friends Email Address"
                  name="email"
                  type="email"
                  autocomplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />
                <textarea
                  formControlName="msg"
                  placeholder="Add Message?"
                  className="input-field-textarea"
                  rows="4"
                  cols="50"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
                <div className="send-invite-btn">
                  <LightBtn
                    title={Loading ? 'Loading...' : 'Send Invite'}
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
