import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { images } from '../../../../assets/_index';

const SelectBoxShipping = ({ options, getShippingValue }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    getShippingValue(value);
  }, [value]);

  return (
    <div className="selectbox">
      <FormControl className="selectboxwidth">
        <Select
          defaultValue="0"
          style={{
            backgroundImage: `url(${images.errowDown})`,
          }}
          className="select-field"
          onChange={(e) => {
            setValue(e.target.value);
          }}
        >
          <MenuItem disabled value="0">
            Please select delivery method
          </MenuItem>
          {options.map((item, index) => {
            return (
              <MenuItem value={item.id} key={index}>
                {item.courierName} £{item.postageCharge}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectBoxShipping;
