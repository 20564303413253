import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import '../../../scss/universal/_checkbox.scss';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function checkbox({ ...props }) {
  return (
      <div className='checkbox'>
        <Checkbox
          {...label}
          {...props}
          checkedIcon={<CheckCircleIcon />}
          icon={<RadioButtonUncheckedIcon />}

        />
      </div>
  );
};

