import StarIcon from '@mui/icons-material/Star';
import { Fragment } from 'react';
import { useState } from 'react';
const Rate = ({ defaultRating, show, From }) => {
  const [rating, setRating] = useState(defaultRating ? defaultRating : 0);

  return (
    <div className="star-rating">
      {[...Array(show ? Math.ceil(show) : 5)].map((star, index) => {
        return (
          <Fragment key={index}>
            {Math.ceil(rating) - 1 >= index ? (
              <StarIcon
                key={index}
                style={{ color: show ? '#2f2b23' : '#949680' }}
              />
            ) : (
              <StarIcon
                key={index}
                style={{ color: From == 'reviews' ? '#e4e1d9' : '#2f2b23' }}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Rate;
