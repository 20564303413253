import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const RightCategory = ({ subMenu, extraContent, topPicksContent }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="subnav-content">
        <Grid container spacing={1} className="mobileset-left">
          {extraContent?.isShowExtraInfo == 1 ? <Grid item xs={1} className="main_left_menu width49-left-mobile">
            <div>
              <div className="width20-right-mobile">
                <div className="top-heading1 mobilehead">
                  {extraContent?.extraInfoTitle}
                </div>
                <div className="ng-star-inserted">
                  {extraContent?.extraInfoImage ? <img
                    loading="lazy"
                    alt={extraContent?.extraInfoTitle}
                    title={extraContent?.extraInfoTitle}
                    className="preloved-img"
                    src={extraContent?.extraInfoImage}
                  /> : null}
                </div>
              </div>
            </div>
          </Grid> : null}
          <Grid item xs={6} className="width100-left-mobile bor-pad">
            <div className="top-heading">Top Picks</div>
            <div fxlayout="column">
              <div className="displayset-mobile">
                {topPicksContent?.map((item, index) => {
                  const price = () => {
                    if (item.productInventory) {
                      if (
                        item?.productInventory[0]?.inventoryColors[0]
                          ?.subscriptionPrice > 0
                      ) {
                        return item?.productInventory[0]?.inventoryColors[0]
                          ?.subscriptionPrice;
                      } else {
                        return item?.productInventory[0]?.inventoryColors[0]
                          ?.price;
                      }
                    } else {
                      if (item?.subscriptionPrice > 0) {
                        return item?.subscriptionPrice;
                      } else {
                        return item?.price;
                      }
                    }
                  };
                  return (
                    <div
                      onClick={() => {
                        navigate(
                          item?.headerMenuSlug
                            ? `${item?.headerMenuSlug}/${item?.productSlug}`
                            : `/${item?.tagsAttached[0]?.middleTagName
                              ? item?.tagsAttached[0]?.middleTagName?.replace(
                                /\s/g,
                                '-'
                              )
                              : item?.tagsAttached[0]?.parentName
                                ?.replace(/\s/g, '-')
                                ?.toLowerCase()
                            }/${item?.tagsAttached[0]?.tagName
                              ?.replace(/\s/g, '-')
                              ?.toLowerCase()}/${item?.productSlug
                                ?.replace(/\s/g, '-')
                                ?.toLowerCase()}`
                        );
                      }}
                      key={index}
                      className="image-container"
                    >
                      {item?.images.map((img, imgIndex) => {
                        if (img.isCover === 1) {
                          return (
                            <img
                              loading="lazy"
                              alt={item?.name}
                              title={item?.name}
                              key={imgIndex}
                              className="img-row"
                              src={img.image}
                            />
                          );
                        }
                      })}

                      <div className="product-name">{item?.name}</div>
                      <div className="product-price">
                        £ {price()?.toFixed(2)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={5}
            className="main_left_menu width49-left-mobile desktop-grid"
          >
            {subMenu?.map((item, index) => (
              <div key={index} className="desktoprow">
                <div
                  className="top-heading1"
                  onClick={() => {
                    navigate(item?.navigationPath?.toLowerCase());
                  }}
                >
                  {item?.name}
                </div>
                {item?.headerSubMenuChilds?.map((subItem, subIndex) => (
                  <div
                    key={subIndex}
                    className="option-heading "
                    onClick={() => {
                      navigate(
                        subItem?.navigationPath
                          ? subItem?.navigationPath.toLowerCase()
                          : `/${subItem?.uniqueId}`
                      );
                    }}
                  >
                    {subItem.subMenuChildName}
                  </div>
                ))}
              </div>
            ))}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RightCategory;
