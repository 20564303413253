import React from 'react';
import '../../../../scss/universal/_heading.scss';

const MainHeading = ({ title }) => {
  return (
    <>
      <h2 className="main-heading40 trending-heading" id="heading">
        {' '}
        {title}
      </h2>
    </>
  );
};

export default MainHeading;
