import Slider from 'react-slick';
import BlogCard from '../BlogsCard/index';
import NewFeaturedCard from '../NewFeaturedcard';
import ProductCard from '../ProductCard';
import ShopByValueCard from '../ShopByValueCard';
import ShopCollectionCard from '../ShopCollectionCard';
import TestimonialsCard from '../TestimonialsCard';

const ProductSlider = ({
  trending,
  blog,
  testimonials,
  slideToShowNo,
  latest,
  shop,
  shopcollection,
  favouritebrands,
  fromthegram,
  CountOFCatdMobile,
  CountOFCatdTab,
  Data,
  mainID,
  sectionId,
  newFeaturedSection,
}) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 1400,
    slidesToShow: slideToShowNo ? slideToShowNo : 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: CountOFCatdTab ? CountOFCatdTab : 3,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
          speed: 1200,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: CountOFCatdMobile ? CountOFCatdMobile : 2.1,
          slidesToScroll: 2.1,
          initialSlide: 1,
          // centerMode: true,
          // centerPadding: "16px",
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: CountOFCatdMobile ? CountOFCatdMobile : 2.1,
          slidesToScroll: 2.1,
          initialSlide: 1,
          //speed: 200,
          swipeToSlide: true,
          // centerMode: true,
          // centerPadding: "16px",
          dots: true,
        },
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: CountOFCatdMobile ? CountOFCatdMobile : 1,
          slidesToScroll: 1,
          initialSlide: 1,
          // centerMode: true,
          //speed: 200,
          swipeToSlide: true,
          // centerPadding: "16px",
          dots: true,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {trending &&
        Data &&
        Data?.map((item, index) => {
          return (
            mainID != item?.id && (
              <ProductCard
                key={index}
                position={index}
                productName={item?.name}
                productPrice={item?.price}
                subscriptionPrice={item?.subscriptionPrice}
                image={item?.images[0]?.imageScreenWise}
                productId={item?.id}
                item={item}
              />
            )
          );
        })}
      {/* shopByValeCardImg */}
      {shop &&
        Data &&
        Data?.map((item, index) => {
          return (
            <ShopByValueCard
              sectionId={sectionId}
              key={index}
              image={
                item?.imageScreenWise
                  ? item?.imageScreenWise
                  : item?.imageWebp
                  ? item?.imageWebp
                  : item?.image
              }
              title={item.name}
              id={item.id}
            />
          );
        })}
      {/* TestimonialsCard */}
      {testimonials &&
        testimonials?.map((item, index) => {
          return (
            <TestimonialsCard
              key={index}
              name={item?.name}
              review={item?.description}
              rating={item?.rating}
            />
          );
        })}

      {/* latestArrivals  */}
      {latest &&
        Data &&
        Data?.map((item, index) => {
          return (
            mainID != item?.id && (
              <ShopByValueCard
                type="latest"
                item={item}
                key={index}
                image={item?.images[0]?.imageScreenWise}
                title={item?.name}
                position={index}
              />
            )
          );
        })}
      {/* BlogSection */}
      {blog &&
        Data &&
        Data?.map((item, index) => {
          return (
            <BlogCard
              key={index}
              image={
                item?.imageScreenWise
                  ? item?.imageScreenWise
                  : item?.imageWebp
                  ? item?.imageWebp
                  : item?.image
              }
              link={item.link}
              title={item.title}
              content={item.description}
            />
          );
        })}

      {/* shopCollection */}
      {shopcollection &&
        Data &&
        Data?.map((item, index) => {
          return (
            <ShopCollectionCard
              key={index}
              image={
                item?.imageScreenWise
                  ? item?.imageScreenWise
                  : item?.imageWebp
                  ? item?.imageWebp
                  : item?.image
              }
              title={item?.title}
              id={item?.id}
              item={item}
            />
          );
        })}

      {favouritebrands &&
        Data &&
        Data?.map((item, index) => {
          return (
            <ShopCollectionCard
              type="favouritebrands"
              item={item}
              key={index}
              image={
                item?.imageScreenWise
                  ? item?.imageScreenWise
                  : item?.imageWebp
                  ? item?.imageWebp
                  : item?.image
              }
              title={item?.title}
            />
          );
        })}

      {/* from the gram */}
      {fromthegram &&
        Data &&
        Data?.map((item, index) => {
          return (
            <ShopCollectionCard
              key={item.id}
              link={item?.feedLink}
              image={
                item?.imageScreenWise
                  ? item?.imageScreenWise
                  : item?.imageWebp
                  ? item?.imageWebp
                  : item?.image
              }
              title={item?.title}
            />
          );
        })}
          {/*new  feature Collection */}
      {newFeaturedSection &&
        Data &&
        Data?.map((item, index) => {
          return (
            <NewFeaturedCard
              key={index}
              image={
                item?.imageScreenWise
                  ? item?.imageScreenWise
                  : item?.imageWebp
                  ? item?.imageWebp
                  : item?.image
              }
              title={item?.title}
              id={item?.id}
              item={item}
            />
          );
        })}

    </Slider>
  );
};

export default ProductSlider;
