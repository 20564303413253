import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { images } from '../../../assets/_index';
import {
  baseUrl,
  deviceToken,
  getScreenSize,
  getScreenSizeImagDimention
} from '../../../core/constants';
import ReviewSkeleton from '../../Skeleton/ReviewSkeleton';
import Rate from '../Rating';

const Reviews = ({ id, type, itemName, purchasedDate }) => {
  const [Review, setReviews] = React.useState({});
  const [ReviewList, setReviewList] = React.useState([]);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [isLoadingReview, setIsLoadingReview] = React.useState(true);
  const [pageNumber, setpageNumber] = useState(1);
  const [pageLastNumber, setpageLastNumber] = useState(1);
  const getReviews = async (id, pagenum) => {
    try {
      const res = await axios(
        type == 'Store'
          ? `${baseUrl}feedback/seller/${id}/product?orderBy&page=${pagenum}&deviceType=3&deviceToken=${deviceToken}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`
          : `${baseUrl}feedback/product/${id}?orderBy&page=${pagenum}&deviceType=3&deviceToken=${deviceToken}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken: userData.userToken,
          },
        }
      );
      if (pagenum > 1) {
        setReviewList([...ReviewList, ...res?.data?.result?.reviews?.data]);
      } else setReviewList(res?.data?.result?.reviews?.data);
      {
        setReviews(res?.data?.result);
      }
      setpageNumber(res?.data?.result?.reviews?.currentPage);
      setpageLastNumber(res?.data?.result?.reviews?.lastPage);
      setIsLoadingReview(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getReviews(id, 1);
  }, [id, type,userData.userToken]);
  return !isLoadingReview && ReviewList?.length > 0 ? (
    <div className="reviews">
      <Container className="all-screen-container">
        {isLoadingReview ? (
          <ReviewSkeleton />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10} md={10}>
                <div className="review-heading">Reviews</div>
              </Grid>
              <Grid item xs={12} sm={2} className="mobilehide tabshow"></Grid>
            </Grid>

            <Grid
              container
              spacing={4}
              className="review-topset con-responsive-wm"
            >
              <Grid item xs={12} sm={4} md={4}>
                <div className="card">
                  <div className="average">Average Reviews</div>
                  <div className="star-section">
                    <Rate
                      From="reviews"
                      defaultRating={Review?.averageRating}
                    />
                    <span>
                      ({Review?.reviewsCount}{' '}
                      {`${Review?.reviewsCount > 1 ? 'Reviews' : 'Review'} `})
                    </span>
                  </div>
                  <div className="card-para">{Review?.description}</div>
                </div>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className="mobileshow textcenter select-mobile tabhide"
                  ></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                {ReviewList.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <Grid
                        key={index}
                        container
                        spacing={2}
                        className="user-icon-section"
                      >
                        <Grid item xs={2.5} sm={1} md={1}>
                          <img
                            defaultValue={images?.reviewUser}
                            onError={(event) => {
                              event.target.onerror = '';
                              event.target.src = images?.reviewUser;
                              return true;
                            }}
                            src={
                              item?.buyer?.profilePicture
                                ? item?.buyer?.profilePicture
                                : images?.reviewUser
                            }
                            alt="user icon"
                            title={item?.buyer?.name}
                            loading="lazy"
                            className="user-icon "
                          />
                        </Grid>
                        <Grid item xs={9.5} sm={10} md={11}>
                          <div className="buyer-name heading20">
                            {item?.buyer?.name}
                          </div>
                          <div className="purchased-name">
                            {' '}
                            Reviewed <span className="bold">
                              {itemName}
                            </span> on{' '}
                            <span className="bold">
                              {' '}
                              {moment(item?.createdAt).format(
                                'DD/MM/YYYY'
                              )}{' '}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                      <Rate show={item?.rating} />
                      <div className="buyer-para heading20  ">
                        {' '}
                        {item?.review ? item?.review : null}
                      </div>

                      {item?.orderDetail && (
                        <>
                          <Grid
                            container
                            spacing={2}
                            className="user-icon-section linebottom"
                          >
                            {item?.orderDetail?.image ? (
                              <Grid item xs={3} sm={3} md={2}>
                                <img
                                  loading="lazy"
                                  src={item?.orderDetail?.image}
                                  alt={item?.orderDetail?.name}
                                  title={item?.orderDetail?.name}
                                  className="product-img "
                                />
                              </Grid>
                            ) : null}
                            <Grid item xs={9} sm={9} md={10}>
                              <div className="item-name ">
                                {item?.orderDetail?.name}{' '}
                              </div>
                            </Grid>
                          </Grid>
                          {/* <hr className='line' /> */}
                        </>
                      )}
                    </Fragment>
                  );
                })}
                {pageNumber == pageLastNumber ? null : (
                  <div>
                    <div
                      className="load-more"
                      onClick={() => getReviews(id, pageNumber + 1)}
                    >
                      <img
                        loading="lazy"
                        alt="pluse icon"
                        src={images.plus}
                        className="plus-icon"
                      />
                      <div>Load more Reviews</div>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </div>
  ) : (
    type == 'Store' && (
      <div className="reviews">
        <Container className="all-screen-container">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={10}>
              <div className="review-heading">Reviews</div>
            </Grid>
            <Grid item xs={12} sm={2} className="mobilehide tabshow"></Grid>
          </Grid>
          <div className="noavailable">No Reviews Available</div>
        </Container>
      </div>
    )
  );
};

export default Reviews;
