import { Container, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import DarkBtn from '../../universal/Buttons/DarkBtn'
import MainHeading from '../../universal/Heading/MainHeading'
import ProductSlider from '../../universal/ProductSlider'
const CandleSection = ({ CustomData, type, mainID }) => {
  const navigate = useNavigate();
  return (
    <div className="candlesection">
    <div className="latest-section mobilebg desktopbg">
      <Container className="all-screen-container">
        <Grid container spacing={2} className="con-responsive-wm">
          <Grid item xs={12} sm={12} md={4}>
            <div className='buttontext_section'>
              <div>
            <div className="sellername paddleft16">{CustomData?.subTitle}</div>
            <MainHeading title={CustomData?.title} />
            <p className="latest-para pera-text18 mobilehide paddleft16 ">
            {CustomData?.description}
            </p>
            </div>
            <div >       
              <DarkBtn
              onClick={() =>      
                  navigate({
                      pathname: `/${CustomData?.linkSlug?CustomData?.linkSlug:CustomData?.title}`,
                    })
              }
              title={
                CustomData?.buttonLabel
                  ? CustomData?.buttonLabel
                  : 'Shop All'
              }   
              />
            </div>
            </div>
            <p className="latest-para pera-text18 mobileshow paddleft16">
            {CustomData?.description}  </p>   
          </Grid>
          <Grid item xs={12} sm={12} md={8} className="hidedot ls97inch">
             {  CustomData?.productLists?.length > 0 && (
                      <ProductSlider
                        latest
                        Data={CustomData?.productLists}
                        mainID={mainID}
                      />)}
                  
              </Grid>
        </Grid>
        <div className="mobileshow textcenter">
        </div>
      </Container>
    </div>
  </div>
  )
}

export default CandleSection