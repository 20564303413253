import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { baseUrl, notify } from '../../../core/constants';
import ConfirmRegistration from '../ConfirmRegistration';
import LightBtn from '../../universal/Buttons/LightBtn';
import Checkbox from '../../universal/Checkbox';
import InputField from '../../universal/InputField';

const CreateRegister = ({ basket, title }) => {
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [state, setstate] = React.useState(false);
  const [tempToken, settempToken] = React.useState('');
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const [isConfirmShow, setIsConfirmShow] = React.useState(true);

  const handleChange = async (event) => {
    await setChecked(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // formik start
  const initialValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Name is required.')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    password: Yup.string()
      .required('Password is required.')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Use 8 or more characters with a mix of letters, numbers & symbols'
      ),
    confirmPassword: Yup.string().when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .required('Confirm password is required.')
        .oneOf([Yup.ref('password')], 'Both password are not match.'),
    }),
    email: Yup.string()
      .required('E-mail address is required.')
      .email('Enter valid email address'),
  });

  const onSubmit = (values) => {
    if (checked) {
      setloading(true);
      var axios = require('axios');
      var data = JSON.stringify({
        email: values.email,
        name: values.name,
        password: values.password,
        password_confirmation: values.confirmPassword,
        deviceType: 3,
        deviceToken: guestDetails.token,
        guest_user_id: userData?.user?.id,
        referral_code: values.referralCode,
      });

      var config = {
        method: 'post',
        url: `${baseUrl}user/signup`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(async (response) => {
          if (response.data.statusCode == '102') {
            await notify(response.data.message, 'error');
          } else {
            await settempToken(response?.data?.result);
            await handleClose();
            await setstate(true);
            await notify(response.data.message, 'success');
          }
          setChecked(false);
          setloading(false);
        })
        .catch(function (error) {
          setChecked(false);
          setloading(false);
        });
    } else {
      notify('Please accept terms & conditions', 'error');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleClickOpen = () => {
    formik.handleReset();
    setOpen(true);
  };
  return (
    <div className="register-btn-section">
      <LightBtn title={title} onClick={handleClickOpen} />
      {basket && (
        <div className="account-text  textcenter" onClick={handleClickOpen}>
          Create Account{' '}
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="registerdialogs"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <div className="dialog-heading34 register-heading ">Register</div>
              <form onSubmit={formik.handleSubmit}>
                <InputField
                  placeholder="Your Name"
                  name="name"
                  id="name"
                  type="text"
                  onKeyDown={(e) => {
                    if (
                      !(
                        (e.keyCode >= 65 && e.keyCode <= 90) ||
                        (e.keyCode >= 97 && e.keyCode <= 122) ||
                        e.keyCode === 32 ||
                        e.keyCode === 8 ||
                        e.keyCode === 9 ||
                        e.keyCode === 13 ||
                        e.keyCode === 37 ||
                        e.keyCode === 38 ||
                        e.keyCode === 39 ||
                        e.keyCode === 40
                      )
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.errors.name}
                />
                <InputField
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                />
                <InputField
                  password
                  name="password"
                  id="password"
                  type={isPasswordShow ? 'password' : 'text'}
                  placeholder="Password"
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  isPasswordShow={isPasswordShow}
                  setIsPasswordShow={setIsPasswordShow}
                />
                <InputField
                  password
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  id="confirmPassword"
                  autocomplete="new-password"
                  type={isConfirmShow ? 'password' : 'text'}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.errors.confirmPassword}
                  isPasswordShow={isConfirmShow}
                  setIsPasswordShow={setIsConfirmShow}
                />
                <InputField
                  placeholder="Referral Code (Optional)"
                  name="referralCode"
                  id="referralCode"
                  onBlur={formik.handleBlur}
                  value={formik.values.referralCode}
                  onChange={formik.handleChange}
                  error={formik.errors.referralCode}
                />
                <div className="checkbox-section">
                  <Checkbox checked={checked} onChange={handleChange} />{' '}
                  <span className="register-para">
                    {' '}
                    I accept our{' '}
                    <span
                      className="cursorpointer"
                      onClick={() =>
                        window.open(
                          'https://goethical.com/blog/terms-and-conditions/',
                          '_self'
                        )
                      }
                    >
                      Terms & conditions
                    </span>{' '}
                    and{' '}
                    <span
                      className="cursorpointer"
                      onClick={() =>
                        window.open(
                          'https://goethical.com/blog/privacy-policy/',
                          '_self'
                        )
                      }
                    >
                      Privacy Policy
                    </span>{' '}
                  </span>
                  {formik.errors.agree ? (
                    <div className="input-error-message">
                      {formik.errors.agree}
                    </div>
                  ) : null}
                </div>
                <div className="btn-section">
                  <LightBtn
                    type="submit"
                    title={loading ? 'Loading...' : 'Register'}
                  />
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {state ? (
        <ConfirmRegistration
          userDatahas={tempToken}
          status={state}
          setState={setstate}
        />
      ) : null}
    </div>
  );
};

export default CreateRegister;
