import { useFormik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { images } from '../../../assets/_index';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import { notify, path } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';
export default function Feedback() {
  const [loading, setloading] = React.useState(false);
  const [emoji, setEmoji] = React.useState(0);
  //Formik form initial states
  const initialValues = {
    name: '',
    message: '',
    email: '',
  };
  //Formik form validation
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required.'),
    message: Yup.string().required('message is required.'),
    email: Yup.string()
      .required('E-mail address is required.')
      .email('Enter valid email address.'),
  });

  //Formik form Submit function
  const onSubmit = (values) => {
    setloading(true);
    const data = {
      name: values.name,
      description: values.message,
      email: values.email,
      how_are_we_doing: emoji,
    };
    AxiosAll('post', path.feedBack, data)
      .then(function (response) {
        notify(response.data.message, 'success');
        formik.handleReset();
        setloading(false);
      })
      .catch(function (error) {
        console.log(error);
        setloading(false);
      });
  };

  //formik defined using hook
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <button type="button" className="myfooter feedback-section">
        <div className="feedback-text"> Feedback</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="feedback-cart-menu">
            <div className="feedback-input-section">
              <div>
                <input
                  type="text"
                  placeholder="Your Name"
                  className="input-field"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="feedback-error-message">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Your Email"
                  className="input-field"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="feedback-error-message">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <textarea
                type="text"
                rows="4"
                cols="60"
                placeholder="Your message"
                className="select-field-textarea"
                name="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              />

              {formik.touched.message && formik.errors.message ? (
                <div className="feedback-error-message">
                  {formik.errors.message}
                </div>
              ) : null}
            </div>

            <div className="doing-section">
              <div className="doing-text">How are we doing?</div>
              <div className="emojibutton">
                <div onClick={() => setEmoji(1)}>
                  <img
                    loading="lazy"
                    src={emoji == 1 ? images.smilewhite : images.smilegray}
                    alt="happy"
                    title="happy"
                    className="face-class2"
                  />
                </div>
                <div onClick={() => setEmoji(2)}>
                  <img
                    loading="lazy"
                    src={emoji == 2 ? images.mehwhite : images.mehgray}
                    alt="face"
                    title="face"
                    className="face-class2"
                  />
                </div>
                <div onClick={() => setEmoji(3)}>
                  <img
                    loading="lazy"
                    src={emoji == 3 ? images.frownwhite : images.frowngray}
                    alt="sad"
                    title="sad"
                    className="face-class2"
                  />
                </div>
              </div>
              <div>
                <DarkBtn
                  type="submit"
                  title={loading ? 'loading...' : 'send'}
                />
              </div>
            </div>
          </div>
        </form>
      </button>
    </div>
  );
}
