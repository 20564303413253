import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import GoogleIcon from '@mui/icons-material/Google';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../../core/constants';
import SocialButton from '../../../core/SocialButton';
import { updateGuestUser } from '../../../redux/actions/guestUser';
import { addLogin } from '../../../redux/actions/login';
import AxiosAll from '../../../services/axiosAll';
const SocialLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [LoadingGoogle, setLoadingGoogle] = React.useState(false);
  const [LoadingFb, setLoadingFb] = React.useState(false);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { guestDetails } = useSelector((state) => state.guestReducer);

  //func social login
  const handleSocialLogins = async (data) => {
    try {
      const res = await AxiosAll(
        'post',
        'user/google-connect?',
        data,
        userData?.userToken
      );

      let userDetails = {
        token: res?.data?.result?.userToken,
        user: res?.data?.result?.user,
      };
      dispatch(addLogin(userDetails));
      let user = res?.data?.result?.user;
      dispatch(
        updateGuestUser({
          user: user,
          userToken: userDetails.token,
        })
      );
      setLoadingGoogle(false)
      setLoadingFb(false)
      notify(res.data.message, "success")
    } catch (error) {
      console.log(error);
      setLoadingGoogle(false)
      setLoadingFb(false)
    }
  };

  // func google and facebook social login
  const handleSocialLogin = (user) => {
   
    if (user._provider === 'google') {
      setLoadingGoogle(true)
      const data = {
        email: user._profile.email,
        name: user._profile.name,
        googleId: user._profile.id,
        googleIdToken: user._token.idToken,
        imageUrl: user._profile.profilePicURL,
        deviceType: 3,
        deviceToken: guestDetails?.token,
        guest_user_id: userData.user.id,
      };
      handleSocialLogins(data);
    } else if (user._provider === 'facebook') {
      setLoadingFb(true)
      // handleSocialLogins(data);
    }
  };

  // feild social login func
  const handleSocialLoginFailure = (err) => {
    console.error(err);
    setLoadingGoogle(false)
  };

  return (
    <div className="SocialLink m-b-20">
      <div className="social-field ">
        <SocialButton
          provider="google"
          appId={process.env.REACT_APP_GOOGLE_APP_ID}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
        >
         {LoadingGoogle? 'Loading..':<> <GoogleIcon />
          <span className="google-icon heading21 ">Continue with Google </span></>}
        </SocialButton>
      </div>
      <div className="social-field">
        <SocialButton
          provider="facebook"
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
        >
        {LoadingFb?"Loading.." :<>  <FacebookRoundedIcon />
          <span className="google-icon heading21 ">Continue with Facebook</span></>}
        </SocialButton>
      </div>
    </div>
  );
};

export default SocialLogin;
