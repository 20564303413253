import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { getScreenSize } from '../../../core/constants';
import '../../../scss/pages/_imageBanner.scss';
import ImageBannerSkeleton from '../../Skeleton/imageBanner';
import { CrawerLink } from '../../universal/CrawlerLink';

const ImageBanner = ({ CustomData }) => {
  const nav = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    swipeToSlide: true,
  };
  const imageview = (deviceSize, mobile, tablet, web, defaultSize) => {
    if (deviceSize === 'mobile') {
      if (mobile) {
        return mobile;
      } else {
        return defaultSize;
      }
    } else if (deviceSize === 'tablet') {
      if (tablet) {
        return tablet;
      } else {
        return defaultSize;
      }
    } else if (deviceSize === 'web') {
      if (web) {
        return web;
      } else {
        return defaultSize;
      }
    } else {
      return defaultSize;
    }
  };

  return CustomData ? (
    <div className="image-banner-home">
      <Slider {...settings}>
        {CustomData?.map((item, index) => {
          return (
            <div key={index} className=" desktop_slider bannercursor">
              {item?.buttonLink[0]?.filterQuery &&
              item?.buttonLink[0]?.filterQuery?.externalLink.length > 0 ? (
                <CrawerLink
                  path={item?.buttonLink[0]?.filterQuery?.externalLink}
                  type="url"
                />
              ) : (
                <CrawerLink
                  path={`/banner_with_multiple_image_${index}`}
                />
              )}
              <div
                onClick={() => {
                  if (item?.buttonLink[0]?.filterQuery) {
                    if (
                      item?.buttonLink[0]?.filterQuery?.externalLink.length > 0
                    ) {
                      window.open(
                        item?.buttonLink[0]?.filterQuery?.externalLink,
                        '_self'
                      );
                    } else {
                      nav({
                        pathname: `/banner_with_multiple_image_${index}`,
                      });
                    }
                  }
                }}
                className={
                  item?.buttonLink[0]?.filterQuery
                    ? 'img-part'
                    : 'img-part cursordefult'
                }
                // style={{
                //   backgroundImage: `url(
                //       ${imageview(
                //         getScreenSize(),
                //         item?.mobileImagesWebp
                //           ? item?.mobileImagesWebp
                //           : item?.mobileImages,
                //         item?.tabletImagesWebp
                //           ? item?.tabletImagesWebp
                //           : item?.tabletImages,
                //         item?.webImagesWebp
                //           ? item?.webImagesWebp
                //           : item?.webImages,
                //         item?.defaultImagesWebp
                //           ? item?.defaultImagesWebp
                //           : item?.defaultImages
                //       )})`,
                // }}
              >
                <img
                  loading="lazy"
                  src={imageview(
                    getScreenSize(),
                    item?.mobileImagesWebp
                      ? item?.mobileImagesWebp
                      : item?.mobileImages,
                    item?.tabletImagesWebp
                      ? item?.tabletImagesWebp
                      : item?.tabletImages,
                    item?.webImagesWebp ? item?.webImagesWebp : item?.webImages,
                    item?.defaultImagesWebp
                      ? item?.defaultImagesWebp
                      : item?.defaultImages
                  )}
                  className="mainbannerimg"
                  alt={item?.title?item?.title:`banner_with_multiple_image_${index}`}
                  title={item?.title?item?.title:`banner_with_multiple_image_${index}`}
                />
                <Container className="all-screen-container">
                  <div className="image-section">
                    <div>
                      <h1 className="buy-heading">{item?.title}</h1>
                      <p className="buy-para pera-text18">
                        {item?.description}
                      </p>
                      {item?.buttonLabel && (
                        <button className="browse-btn gray-btn">
                          {item?.buttonLabel}
                        </button>
                      )}
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  ) : (
    <ImageBannerSkeleton />
  );
};
export default ImageBanner;
