import TaskAltIcon from '@mui/icons-material/TaskAlt';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import TextField from '@mui/material/TextField';
export default function CheckoutField({
  title,
  placeholder,
  name,
  id,
  onChange,
  error,
  value,
  onBlur,
  isPassword,
  icon,
  password,
  isPasswordShow,
  setIsPasswordShow,
  type,
  ...props
}) {
  return (
    <div className="login-checkout">
      <div className="inputicon">
        <TextField
          label={title ? title : placeholder}
          name={`${name}`}
          security={isPassword}
          id={`${id}`}
          placeholder={`${placeholder ? placeholder : title}`}
          className=""
          type={type ? type : 'text'}
          onBlur={onBlur}
          helperText={<span className="input-error-message">{error}</span>}
          value={value}
          onChange={onChange}
          {...props}
        />
        {password ? (
          <span
            className="visibility-icon"
            onClick={() => setIsPasswordShow(!isPasswordShow)}
          >
            {isPasswordShow ? <VisibilitySharpIcon /> : <VisibilityOffIcon />}
          </span>
        ) : error || value === '' ? null : (
          <TaskAltIcon className="inputiconme" />
        )}
      </div>
    </div>
  );
}
