import { Container, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { images } from '../../assets/_index';
import CreateRegister from '../../components/dialogs/CreateRegister';
import DynamicMobileFilter from '../../components/dialogs/DynamicMobileFilter';
import MobileFilter from '../../components/dialogs/MobileFilter';
import TrendingCollections from '../../components/home/TrendingCollections';
import CategoryFilterSkeleton from '../../components/Skeleton/CategoryFilterSkeleton';
import ImageBannerSkeleton from '../../components/Skeleton/imageBanner';
import ProductCardSkeleton from '../../components/Skeleton/ProductCardSkeleton';
import DynamicFIlters from '../../components/universal/DynamicFIlters';
import Filters from '../../components/universal/Filters';
import MainHeading from '../../components/universal/Heading/MainHeading';
import CustomHelmet from '../../components/universal/Helmet';
import InnerBanner from '../../components/universal/InnerBanner';
import Pagination from '../../components/universal/Pagination';
import ProductCard from '../../components/universal/ProductCard';
import {
  capitalizeFirstLetter,
  getScreenSizeImagDimention
} from '../../core/constants';
import AxiosAll from '../../services/axiosAll';

const Products = () => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [trendingCollection, setTrendingCollection] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [bannerData, setBannerData] = useState({});
  const [query, setQuery] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [isQueryFetching, setIsQueryFetching] = useState(false);
  const [isBannerLoading, setIsBannerLoading] = useState(false);
  const [isTrendingCollectionLoading, setIsTrendingCollectionLoading] =
    useState(false);
  const [dynamicFilter, setDynamicFilter] = useState({});
  const [isShowEmptyMsg, setIsShowEmptyMsg] = useState(false);

  const location = useLocation();
  const pathParams = useParams();
  const navigate = useNavigate();
  let searchParams = new URLSearchParams(location.search);

  const pageNumber = searchParams.get('page');
  const tags = searchParams.get('tags');
  const metadatas = searchParams.get('metadatas');
  const sortbyprice = searchParams.get('sortbyprice');

  const [page, setPage] = useState(pageNumber ? parseInt(pageNumber) : 1);

  useEffect(() => {
    setPage(pageNumber ? parseInt(pageNumber) : 1);
  }, [location.pathname, pageNumber]);

  useEffect(() => {
    if (window.location.pathname?.includes(`/products`)) {
      navigate(window.location.pathname?.replace('/products', ''));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, window.location.search]);

  useEffect(() => {
    if (query?.metadatas === 0) {
      setQuery((prev) => ({ ...prev, ...filterValues }));
    } else {
      if (location.pathname.includes('shop-by-value')) {
        const { metadatas, ...rest } = filterValues;
        setQuery((prev) => ({ ...prev, ...rest }));
      } else {
        setQuery((prev) => ({ ...prev, ...filterValues }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const getQueryFilters = useCallback(
    async (slug) => {
      setIsQueryFetching(true);
      try {
        const res = await AxiosAll(
          'get',
          `getQueryStringData?slug=${slug}&`,
          '',
          userData?.userToken!=undefined?userData?.userToken:"12345"
        );
        const data = res?.data;
        const {
          filter_menu,
          menu_slug,
          sortbyprice,
          metadatas,
          tags,
          ...rest
        } = data;

        setDynamicFilter(filter_menu);
        setQuery({
          ...rest,
          sortbyprice: `${sortbyprice}`,
          metadatas: `${metadatas}`,
          tags: `${tags}`,
        });
        setIsQueryFetching(false);
      } catch (error) {
        setIsQueryFetching(false);
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const getFilterValues = (values) => {
    // setQuery((prev) => ({ ...prev, ...values }));
    setFilterValues(values);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, productList]);

  const getProducts = async (query) => {
    setIsProductLoading(true);
    try {
      let newObject = query;
      let keys = Object.keys(newObject);
      keys.map((key) => {
        query[key] = newObject[key];
        return null;
      });

      const res = await AxiosAll(
        'get',
        `product?page=${page}&screen_type=${getScreenSizeImagDimention()}&${Object.keys(
          newObject
        )
          .map((key) => `${key}=${query[key]}`)
          .join('&')}&`,
        '',
        userData?.userToken!=undefined?userData?.userToken:"12345"
      );

      const data = res?.data.result;
      setProductList(data);
      if (!data?.data) {
        setTimeout(() => setIsShowEmptyMsg(true), 200);
      }
    
       setIsProductLoading(false);
    } catch (error) {
      console.log(error);
      // setIsProductLoading(false);
    }
  };

  useEffect(() => {
    if (!isQueryFetching && Object.keys(query).length > 3) {
      if (tags || metadatas || sortbyprice) {
        getProducts({ ...query, tags, metadatas, sortbyprice });
      } else {
        getProducts(query);
      }
    }

    return () => {
      setProductList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [query, isQueryFetching, page ]);

 
  const getBanner = async (slug) => {
    setIsBannerLoading(true);
    try {
      const res = await AxiosAll(
        'get',
        `getBannerData?slug=${slug}&`,
        '',
        userData?.userToken!=undefined?userData?.userToken:"12345"
      );
      setBannerData(res?.data?.result);
      setIsBannerLoading(false);
    } catch (error) {
      console.log(error);
      setIsBannerLoading(false);
    }
  };

  useEffect(() => {
    if (pathParams?.page_name || pathParams?.page_name_1) {
      if (pathParams?.page_name && pathParams?.page_name_1) {
        getBanner(`${pathParams?.page_name_1}/${pathParams?.page_name}`);
        getQueryFilters(`${pathParams?.page_name_1}/${pathParams?.page_name}`);
      } else if (pathParams?.page_name) {
        getBanner(pathParams?.page_name);
        getQueryFilters(pathParams?.page_name);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathParams?.page_name, pathParams?.page_name_1, pathParams?.page_name]);

  const getTrendingCollection = async () => {
    setIsTrendingCollectionLoading(true);
    try {
      const res = await AxiosAll(
        'get',
        `home/shop_collection?search=&filter=2&page=1&screen_type=${getScreenSizeImagDimention()}&shop_all=0&`,
        '',
        userData?.userToken!=undefined?userData?.userToken:"12345"
      );
      setTrendingCollection(res?.data?.result?.data);
      setIsTrendingCollectionLoading(false);
    } catch (error) {
      console.log(error);
      setIsTrendingCollectionLoading(false);
    }
  };

  useEffect(() => {
    getTrendingCollection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line
  const tabTitle = capitalizeFirstLetter(
    pathParams?.page_name?.replaceAll('-', ' ')
  );

  const bannerTitle =bannerData?.title?bannerData?.title:
  bannerData?.bannerTitle?bannerData?.bannerTitle:tabTitle

  const bannerDesc =bannerData?.bannerDescription?bannerData?.bannerDescription:
  bannerData?.subTitle?bannerData?.subTitle:null

  return (
    <div className="products productborder">
      <CustomHelmet
        title={`${bannerData?.seoMetaTitle ? bannerData?.seoMetaTitle : `${bannerTitle} | GoEthical`}`}
        imageUrl={bannerData?.seoMetaImage?bannerData?.seoMetaImage:bannerData?.bannerImage}
        metaTitle={
          bannerData?.seoMetaTitle ? bannerData?.seoMetaTitle : bannerTitle
        }
        metaDescription={
          bannerData?.seoMetaDescription
            ? bannerData?.seoMetaDescription
            :bannerDesc
        }
        description={bannerData?.bannerDescription?bannerData?.bannerDescription:bannerDesc}
        keyword={bannerData?.seoMetaKeywords?bannerData?.seoMetaKeywords:bannerTitle}
        path={window?.location?.pathname}
      />
      {bannerData ? (
        <InnerBanner
          data={bannerData}
          shopCollectiontype={query.shop_collection_id ? true : false}
        />
      ) : (
        <ImageBannerSkeleton />
      )}
      <Container className="all-screen-container">
        <Grid container spacing={2}>
          <Grid item sm={3} className="mobilehide">
            {isQueryFetching ? (
              <CategoryFilterSkeleton />
            ) : pathParams?.page_name &&
              dynamicFilter &&
              dynamicFilter?.childrens ? (
              <DynamicFIlters
                loadState={isBannerLoading}
                getFilterValues={getFilterValues}
                Categories={dynamicFilter}
                page={page}
              />
            ) : (
              <Filters
                getFilterValues={getFilterValues}
                page={page}
                setPage={setPage}
              />
            )}
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <div className="headbtn-section">
              <div className="heading-product-btn">
                <MainHeading title="Products" />
              </div>
              <p className="display-title mobilehide   ">
                Displaying {productList?.total ? productList?.total : 0} results
              </p>
            </div>
            <div className="displayresult">
              {isQueryFetching ? (
                <CategoryFilterSkeleton />
              ) : pathParams?.page_name &&
                dynamicFilter &&
                dynamicFilter?.childrens ? (
                <DynamicMobileFilter
                  loadState={isBannerLoading}
                  getFilterValues={getFilterValues}
                  Categories={dynamicFilter}
                />
              ) : (
                <MobileFilter
                  getFilterValues={getFilterValues}
                  setPage={setPage}
                />
              )}
              <p className="display-title mobileshow">
                Displaying {productList?.total ? productList?.total : 0} results
              </p>
            </div>
            <div className="image-container">
              {isProductLoading ? (
                <ProductCardSkeleton type="productPage" />
              ) : productList?.data?.length > 0 ? (
                productList?.data?.map((product, index) => {
                  return (
                    <ProductCard
                      key={index}
                      position={index}
                      productName={product.name}
                      productPrice={product.price}
                      subscriptionPrice={product?.subscriptionPrice}
                      image={
                        product.images[0].imageScreenWise
                          ? product.images[0].imageScreenWise
                          : product.images[0].imageWebp
                          ? product.images[0].imageWebp
                          : product.images[0].image
                      }
                      tag={product.tagsAttached[0].parentName}
                      item={product}
                      productId={product?.id}
                      showRatingAndDelivery
                    />
                  );
                })
              ) : (
                !isProductLoading &&
                isShowEmptyMsg && (
                  <div className="emptydata">
                    <div className="ahh-text">Oops!</div>
                    <div className="result-text">
                      <div>We can’t find a match for</div>
                      <div>
                        Don’t give up! Check the spelling or try something more
                        generic.
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            {!isProductLoading && productList?.data?.length > 0 && (
              <Pagination
                count={productList?.lastPage}
                page={page}
                onChange={(e, val) => {
                  setPage(val);
                  window.scrollTo(0, 0);
                  navigate({
                    pathname: `${location.pathname}`,
                    search: `${createSearchParams({
                      tags: query?.tags ? query?.tags : '',
                      metadatas: query?.metadatas ? query?.metadatas : '',
                      sortbyprice: query?.sortbyprice ? query?.sortbyprice : 0,
                      page: val,
                    })}`,
                  });
                }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      {!isTrendingCollectionLoading && trendingCollection.length > 0 ? (
        <div className="scrollenable">
          <TrendingCollections CustomData={trendingCollection} />
        </div>
      ) : null}
      {userDetails?.token ? null : (
        <div className="make-my-account-section ng-star-inserted">
          <div className="container16 new_bg-27inch">
            <Grid container spacing={2}>
              <Grid item sm={3} xs={12} className="accountimg">
                <img
                  loading="lazy"
                  alt="create Account "
                  title="create Account "
                  src={images.createAccount}
                />
              </Grid>
              <Grid item sm={6} xs={12} className="accountcontent">
                <MainHeading title="Create an account today" />
                <p className="account-info">
                  {' '}
                  Looking to shop a range of products from our approved ethical
                  sellers? Creating an account is free and will only take a few
                  minutes. It’s as easy as that!{' '}
                </p>
                <CreateRegister title="Make my account!" register={true} />
              </Grid>
              <Grid item sm={3} xs={12}></Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};
export default Products;
