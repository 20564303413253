import React from 'react';
import '../../../scss/universal/_productSearchResult.scss';

const ProductSearchResult = ({ query }) => {
  return (
    <>
      <div className="productSearchResult">
        <div className="search-heading heading20 ">
          Search Results for:
          <span className="search-result-text"> {query}</span>
        </div>
      </div>
    </>
  );
};

export default ProductSearchResult;
