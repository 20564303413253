import { useLocation } from 'react-router-dom';
import Header from '.';
import CheckoutHeader from '../../../pages/NewCheckoutPage/CheckoutHeader';

export const CurrrentHeader = () => {
  const location = useLocation();
  return location.pathname.includes('checkout') ? (
    <CheckoutHeader />
  ) : (
    <Header />
  );
};
