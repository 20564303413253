import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { notify } from '../../../../core/constants';
import AxiosAll from '../../../../services/axiosAll';
import DarkBtn from '../../Buttons/DarkBtn';
import ProductBtn from '../../Buttons/ProductBtn';
import ChcekoutField from '../../InputField/CheckoutField';
import creditCardType from 'credit-card-type';

const Payment = ({
  isAddNewCard,
  setIsAddNewCard,
  setIsNewCardAdded,
  isNewCardAdded,
  cardList,
  address,
  setIsShowCardEditList,
  isCardListLoading,
}) => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [cardType, setCardType] = useState({});
  const [maxLengths, setMaxLengths] = useState(16);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    cardNumber: '',
    cardExpiry: '',
    cardCvv: '',
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    postCode: '',
    country: '',
    phone: '',
    sameAsBilling: true,
  };

  const validationSchema = Yup.object().shape({
    cardNumber: Yup.string()
      .required('Card number is required')
      .min(cardType?.lengths ? cardType?.lengths[0] : 16, 'Invalid card number')
      .max(
        cardType?.lengths
          ? cardType?.lengths[cardType?.lengths?.length - 1]
          : 16,
        'Invalid card number'
      ),
    cardExpiry: Yup.string().required('Expiry date is required'),
    cardCvv: Yup.string()
      .min(3, 'Invalid CVV')
      .max(4, 'Invalid CVV')
      .required('CVV is required'),
    firstName: Yup.string().when('sameAsBilling', {
      is: false,
      then: Yup.string()
        .matches(/^[a-zA-Z ]+$/, 'First name must be alphabets only')
        .required('First name is required'),
    }),
    lastName: Yup.string().when('sameAsBilling', {
      is: false,
      then: Yup.string()
        .matches(/^[a-zA-Z ]+$/, 'Surname  must be alphabets only')
        .required('Last name is required'),
    }),
    sameAsBilling: Yup.boolean(),
    address: Yup.string().when('sameAsBilling', {
      is: false,
      then: Yup.string().required('Address is required'),
    }),
    address2: Yup.string(),
    city: Yup.string().when('sameAsBilling', {
      is: false,
      then: Yup.string().required('City is required'),
    }),
    postCode: Yup.string().when('sameAsBilling', {
      is: false,
      then: Yup.string().required('Post code is required'),
    }),
    country: Yup.string().when('sameAsBilling', {
      is: false,
      then: Yup.string().required('Country is required'),
    }),
    phone: Yup.string().when('sameAsBilling', {
      is: false,
      then: Yup.string()
        .required('Phone is required')
        .min(10, 'Phone number is invalid')
        .max(13, 'Phone number is invalid'),
    }),
  });

  const addNewCard = async (data) => {
    setIsLoading(true);
    try {
      const res = await AxiosAll('post', 'card?', data, userData?.userToken);
      setIsNewCardAdded(!isNewCardAdded);
      if (res.data.statusCode === 200) {
        if (cardList?.length === 0) {
          setIsShowCardEditList(false);
          setIsAddNewCard(false);
          const height = (
            (window.innerHeight ||
              document.documentElement.clientHeight ||
              document.body.clientHeight) / 20
          ).toFixed(0);
          window.scrollTo(
            0,
            document.getElementById('review-section').offsetTop -
              parseInt(height)
          );
        } else {
          setIsShowCardEditList(true);
          const height = (
            (window.innerHeight ||
              document.documentElement.clientHeight ||
              document.body.clientHeight) / 20
          ).toFixed(0);
          window.scrollTo(
            0,
            document.getElementById('payment-section').offsetTop -
              parseInt(height)
          );
        }
      } else {
        notify(res.data.message, 'error');
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onSubmit = (values) => {
    const selectedAddress = {
      addressName: address?.addressName,
      address: address?.address,
      address2: address?.address2,
      city: address?.city,
      country: address?.country,
      postcode: address?.postcode,
      phone: address?.phone,
    };

    const enteredAddress = {
      address: values.address,
      address2: values.address2,
      addressName: values.firstName + ' ' + values.lastName,
      city: values.city,
      country: values.country,
      phone: values.phone,
      postcode: values.postCode,
    };
    const data = {
      is_default_address: 0,
      is_new: 1,
      latitude: '28.4575230000000000',
      longitude: '77.0263440000000000',
      address_type: 1,
      number: values.cardNumber,
      expMonth: parseInt(values.cardExpiry.split('/')[0]),
      expYear: parseInt(values.cardExpiry.split('/')[1]),
      cvc: values.cardCvv,
    };
    if (values.sameAsBilling) {
      addNewCard({ ...data, ...selectedAddress });
    } else {
      addNewCard({ ...data, ...enteredAddress });
    }
  };

  const isValid = () => {
    if (formik.values.sameAsBilling) {
      return (
        cardType?.lengths?.includes(formik.values.cardNumber?.length) &&
        formik.values.cardExpiry?.replace('/', '')?.replace(/\s/g, '')
          ?.length === 4 &&
        formik.values.cardCvv.replace(/\s/g, '')?.length >= 3
      );
    } else if (!formik.values.sameAsBilling) {
      return (
        cardType?.lengths?.includes(formik.values.cardNumber?.length) &&
        formik.values.cardExpiry?.replace('/', '')?.replace(/\s/g, '')
          ?.length === 4 &&
        formik.values.cardCvv.replace(/\s/g, '')?.length >= 3 &&
        formik.values.firstName &&
        formik.values.lastName &&
        formik.values.address &&
        formik.values.city &&
        formik.values.country &&
        formik.values.postCode &&
        formik.values.phone
      );
    } else {
      return false;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleCardNumberChange = (e) => {
    const { value } = e.target;
    const cardType = creditCardType(value)[0];
    const maxLength = cardType
      ? cardType.lengths[cardType.lengths.length - 1]
      : 16;
    setMaxLengths(maxLength);
    setCardType(cardType);
    formik.setFieldValue('cardNumber', value);
  };

  const cvvLength = () => {
    // make string of # based on cardType.code.size
    let cvv = '';
    for (let i = 0; i < cardType?.code?.size; i++) {
      cvv += '#';
    }
    return cvv;
  };

  const cvvPlaceholder = () => {
    let cvv = '';
    for (let i = 0; i < cardType?.code?.size; i++) {
      cvv += 'X';
    }
    return cvv;
  };

  return isCardListLoading ? (
    <div>Loading...</div>
  ) : (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="billingcontry paymentmethod">
          <div className="cardbox">
            <p className="addcard">Add card</p>
            <div className="cardfield">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={5} xl={5}>
                  <p>Card Number</p>
                  <div className="inputicon">
                    <input
                      type="text"
                      placeholder="Debit/Credit card number"
                      name="cardNumber"
                      maxLength={maxLengths}
                      value={formik.values.cardNumber}
                      onChange={handleCardNumberChange}
                      onBlur={formik.handleBlur}
                      autocomplete="cc-number"
                      id="cc-number"
                    />
                    {cardType?.lengths?.includes(
                      formik.values.cardNumber?.length
                    ) &&
                      !formik.errors.cardNumber && (
                        <TaskAltIcon className="inputiconme" />
                      )}
                    {formik.touched.cardNumber && formik.errors.cardNumber && (
                      <div className="input-error-message">
                        {formik.errors.cardNumber}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={3.5} md={3.5} xl={3.5}>
                  <p>Expiration Date</p>
                  <div className="inputicon">
                    <NumberFormat
                      format="##/##"
                      name="cardExpiry"
                      value={formik.values.cardExpiry}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autocomplete="cc-exp"
                      placeholder="MM/YY"
                      id="cc-exp"
                      // mask={['M', 'M', 'Y', 'Y']}
                    />
                    {formik.values.cardExpiry
                      ?.replace('/', '')
                      ?.replace(/\s/g, '')?.length === 4 &&
                      !formik.errors.cardExpiry && (
                        <TaskAltIcon className="inputiconme" />
                      )}
                    {formik.touched.cardExpiry && formik.errors.cardExpiry && (
                      <div className="input-error-message">
                        {formik.errors.cardExpiry}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={3.5} md={3.5} xl={3.5}>
                  <p>Security Code</p>
                  <div className="inputicon">
                    <NumberFormat
                      format={cvvLength()}
                      placeholder={cvvPlaceholder() || 'XXX'}
                      name="cardCvv"
                      value={formik.values.cardCvv}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autocomplete="cc-csc"
                      id="cc-csc"
                    />
                    {formik.values.cardCvv.replace(/\s/g, '')?.length >= 3 &&
                      !formik.errors.cardCvv && (
                        <TaskAltIcon className="inputiconme" />
                      )}
                    {formik.touched.cardCvv && formik.errors.cardCvv && (
                      <div className="input-error-message">
                        {formik.errors.cardCvv}
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        <div className="usecredit">
          <FormControlLabel
            control={<Checkbox />}
            label="Billing address same as delivery"
            value={formik.values.sameAsBilling}
            checked={formik.values.sameAsBilling}
            onChange={(e) => {
              formik.setFieldValue('sameAsBilling', e.target.checked);
            }}
          />
        </div>
        {!formik.values.sameAsBilling && (
          <div className="">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <ChcekoutField
                  title="First Name"
                  name="firstName"
                  type="text"
                  id="given-name"
                  autocomplete="given-name"
                  value={formik.values.firstName?.replaceAll(' ', '')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <ChcekoutField
                  title="Surname"
                  name="lastName"
                  id="family-name"
                  autocomplete="family-name"
                  value={formik.values.lastName?.replaceAll(' ', '')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
            </Grid>

            <div className="addressmanually">
              <>
                <ChcekoutField
                  title="Enter address line 1"
                  name="address"
                  autocomplete="address-line1"
                  id="address-line1"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.address && formik.errors.address}
                />
                <ChcekoutField
                  title="Add Company, C/O, Apt, Suite, Unit"
                  name="address2"
                  id="address-line2"
                  autocomplete="address-line2"
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.address2 && formik.errors.address2}
                />
              </>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} xl={4}>
                  <ChcekoutField
                    title="Town/City"
                    autocomplete="address-level2"
                    name="city"
                    id="address-level2"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} xl={4}>
                  <ChcekoutField
                    title="Postcode"
                    autocomplete="postal-code"
                    name="postCode"
                    id="postal-code"
                    value={formik.values.postCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.postCode && formik.errors.postCode}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} xl={4}>
                  <ChcekoutField
                    title="Country/Region"
                    name="country"
                    id="country"
                    autocomplete="country-name"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.country && formik.errors.country}
                  />
                </Grid>
              </Grid>

              <ChcekoutField
                title="Phone"
                name="phone"
                type="number"
                min="1"
                autocomplete="tel"
                id="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onKeyDown={(e) => {
                  if (
                    [
                      'Backspace',
                      'Delete',
                      'Tab',
                      'Escape',
                      'Enter',
                      'ArrowLeft',
                      'ArrowRight',
                      '0',
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      '6',
                      '7',
                      '8',
                      '9',
                    ].indexOf(e.key) < 0
                  ) {
                    e.preventDefault();
                  }
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && formik.errors.phone}
              />
            </div>
          </div>
        )}
        {formik.values.sameAsBilling && (
          <div className="billingcontry">
            <span> Billing Address</span>
            <p>{address?.addressName}</p>
            <p>{address?.address}</p>
            <p>
              {address?.city},{address?.postcode},{address?.country}
            </p>
          </div>
        )}
        <div className="btnalignright">
          {/* <DarkBtn title="Add Card" type="submit" loading={isLoading} /> */}
          {isValid() ? (
            <DarkBtn
              title={`${
                cardList?.length === 0 ? 'Continue To Order Review' : 'Add Card'
              }`}
              type="submit"
              loading={isLoading}
            />
          ) : (
            <ProductBtn
              title={`${
                cardList?.length > 0 && isAddNewCard
                  ? 'Add Card'
                  : 'Continue To Order Review'
              }`}
              type="button"
              disabled={true}
            />
          )}
          {cardList?.length > 0 && isAddNewCard && (
            <DarkBtn
              title="Cancel"
              type="button"
              onClick={() => {
                setIsAddNewCard(false);
                const height = (
                  (window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight) / 20
                ).toFixed(0);
                window.scrollTo(
                  0,
                  document.getElementById('payment-section').offsetTop -
                    parseInt(height)
                );
              }}
            />
          )}
        </div>
      </form>
    </>
  );
};

export default Payment;
