import { Button } from "@mui/material";
import React from "react";

const RetailersBtn = ({ title, style, onClick, ...props }) => {
  return (
    <>
      <Button className="retailers-btn" style={style} onClick={onClick} {...props}>
        {title}</Button>
    </>
  );
};

export default RetailersBtn;
