import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import DarkBtn from "../../../../components/universal/Buttons/DarkBtn";
import CheckoutField from "../../../../components/universal/InputField/CheckoutField";
import { notify } from "../../../../core/constants";
import AxiosAll from "../../../../services/axiosAll";

const CheckoutEmail = () => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [state, setstate] = React.useState(false);
  const [tempToken, settempToken] = React.useState("");
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const [isConfirmShow, setIsConfirmShow] = React.useState(true);
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const cart_id = params.get('cart_id');
  const isSubscription = params.get('isSubscription');
  useEffect(() => {
  if(userDetails?.token)
  {
    navigate(-1)
  }
  }, [ userDetails]);
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("E-mail address is required.")
      .email("Enter valid email address"),
  });

  const EmailExists = async (values) => {
    try {
      const res = await AxiosAll(
        "get",
        `user/email-exists?email=${values?.email}&`,
        "",
        userData?.userToken
      );

      if (res?.data.statusCode === 200) {
        setloading(false)
        notify(res?.data.message, "success");
        if (!res?.data.result.status) {
          navigate({
            pathname: "/checkout-register",
            search: `email=${values?.email}&${cart_id ? isSubscription ? `cart_id=${cart_id}&isSubscription=1` :
              `cart_id=${cart_id}&skip=true` : 'skip=true'}`
          }, );

        }
        else {
          navigate({
            pathname: "/checkout-password",
            search: `email=${values?.email}&${cart_id ? isSubscription ? `cart_id=${cart_id}&isSubscription=1` :
              `cart_id=${cart_id}&skip=true` : 'skip=true'}`
          });
        }

       
      }
    } catch (error) {
      setloading(false)
      notify(error, "error");
    }
  };

  const onSubmit = (values) => {
    setloading(true)
    EmailExists(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mobilecheckoutemail">
      <form onSubmit={formik.handleSubmit}>
        <div className="email-text">Enter your email to join us or sign in</div>
        <CheckoutField
          name="email"
          id="email"
          type="email"
          placeholder="Email address"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
        />
        <div className="continuing-text">
          By continuing, you agree to GoEthical’s <span onClick={() => window.open('https://goethical.com/blog/privacy-policy/', '_self')}> Privacy Policy</span> and <span onClick={() => window.open('https://goethical.com/blog/terms-and-conditions/', '_self')}> Terms of Use.</span>
        </div>
        <div className="btn-section-new">
          <DarkBtn type="submit" title={loading ? "Loading..." : "Next"} />
        </div>
      </form>
    </div>
  );
};

export default CheckoutEmail;
