import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { images } from '../../../assets/_index';
import { baseUrl, deviceToken, notify } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';
import LightBtn from '../../universal/Buttons/LightBtn';
import InputField from '../../universal/InputField';
import sweatAlertBox from '../../universal/SweetAlertBox';
import PaymentMethodDialog from '../PaymentMethod';
import RedeemVoucherDialog from '../RedeemVoucher';

export default function GiftVoucherDialog({ type }) {
  const [open, setOpen] = useState(false);
  const [cardList, setCardList] = useState([]);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [giftCard, setGiftCard] = useState([]);
  const [defaultCard, setDefaultCard] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.handleReset();
  };

  const getCardList = async () => {
    try {
      const res = await AxiosAll('get', 'card?', '', userData.userToken);
      const data = res.data.result.cards;
      if (data?.find((item) => item.isDefault == 1)) {
        setDefaultCard(data.find((item) => item.isDefault == 1));
      } else {
        setDefaultCard(data[0]);
      }
      setCardList(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCardList();
  }, [userData.userToken]);

  const initialValues = {
    name: '',
    email: '',
    amount: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(2, 'Name must be at least 2 characters'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    amount: Yup.string()
      .required('Amount is required')
      .min(0.3, 'Amount must be at least 0.30'),
    message: Yup.string(),
  });

  const order = async (data) => {
    try {
      const res = await axios.post(
        `${baseUrl}order?deviceType=3&deviceToken=${deviceToken}`,
        data,
        {
          headers: {
            userToken: userData.userToken,
          },
        }
      );
      if (res.data.status === 'Success') {
        ReactGA.gtag('event', 'purchase', {
          transaction_id: res?.data?.result?.data?.generatedOrderId,
          currency: 'EUR',
          tax: res?.data?.result?.data?.tax,
          shipping: res?.data?.result?.data?.shipping,
          value: res?.data?.result?.data?.price,
          items: [
            {
              item_id: giftCard?.id,
              item_name: 'Gift voucher',
              price: res?.data?.result?.data?.price,
              quantity: 1,
              item_category: 'giftCard',
              index: 0,
            },
          ],
        });

        if (res?.data?.result?.data?.is3DSecure) {
          window.open(res?.data?.result?.data?.is3DSecureURL, '_Self');
        } else {
          navigate({
            pathname: '/confirm-order',
            search: `?generated_order_id=${res?.data?.result?.data?.generatedOrderId}&order_id=${res?.data?.result?.data?.orderId}&status=success`,
          });
        }
        handleClose();
        formik.handleReset();
        notify(res.data.message, 'success');
      } else {
        sweatAlertBox('Order Failed!', `${res.data.message}`, false, 'Ok', '');
        formik.setFieldValue('amount', '');
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const buyNow = async (data) => {
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${baseUrl}cart/buy-now?deviceType=3&deviceToken=${deviceToken}`,
        data,
        {
          headers: {
            userToken: userData.userToken,
          },
        }
      );

      const orderData = {
        cart_id: res.data.result.cartId,
        cardId: defaultCard.id,
        deviceToken: deviceToken,
        deviceType: '3',
        is_purchase_gift_voucher: 1,
        is_redeem_wallet_payment: 0,
        totalAmount: data.voucher_amount,
        web_redirect_url: `${window.location.origin.toString()}/`,
      };
      order(orderData);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getGiftCard = async () => {
    try {
      const res = await AxiosAll(
        'get',
        'voucher/products?',
        '',
        userData.userToken
      );
      const data = res?.data?.result?.data[0];
      setGiftCard(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGiftCard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.userToken]);

  const onSubmit = (values) => {
    setIsLoading(true);
    const data = {
      deliveryType: '',
      description:
        "It's not always easy to come up with a card message. Here we've compiled a list of ideas which you can draw from to create your own personalised card message.",
      giftMessage: '',
      inventoryColorId: giftCard?.productInventory[0]?.inventoryColors[0]?.id,
      is_gift_voucher: '1',
      message: values.message,
      personalization: '0',
      productId: giftCard?.id,
      recipient_email: values.email,
      recipient_name: values.name,
      stocks: '1',
      subscription: 0,
      voucher_amount: values.amount,
    };
    if (cardList.length < 1) {
      notify('Please add payment method', 'error');
      setIsLoading(false);
    } else {
      buyNow(data);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const cardImage = (cardBrand) => {
    if (cardBrand === 'Visa') {
      return (
        <img
          loading="lazy"
          className="card-img-visa"
          alt="Visa"
          src={images.visa}
        />
      );
    } else if (cardBrand === 'MasterCard') {
      return (
        <img
          loading="lazy"
          className="card-img-visa"
          alt="MasterCard"
          src={images.mastercard}
        />
      );
    } else if (cardBrand === 'American Express') {
      return (
        <img
          loading="lazy"
          className="card-img-visa"
          alt="American Express"
          src={images.amex}
        />
      );
    } else if (cardBrand === 'Discover') {
      return (
        <img
          loading="lazy"
          className="card-img-visa"
          alt="Discover"
          src={images.discover}
        />
      );
    } else if (cardBrand === 'JCB') {
      return (
        <img
          loading="lazy"
          className="card-img-visa"
          alt="JCB"
          src={images.jcb}
        />
      );
    } else if (cardBrand === 'Diners Club') {
      return (
        <img
          loading="lazy"
          className="card-img-visa"
          alt="Diners Club"
          src={images.dinner_club}
        />
      );
    } else if (cardBrand === 'UnionPay') {
      return (
        <img
          loading="lazy"
          className="card-img-visa"
          alt="UnionPay"
          src={images.union_pay}
        />
      );
    } else {
      return (
        <img
          loading="lazy"
          className="card-img-visa"
          alt="Card"
          src={images.card}
        />
      );
    }
  };

  return (
    <div>
      {type == 'header' ? (
        <div onClick={handleClickOpen} className="user-name1 heading20">
          Gift Vouchers
        </div>
      ) : (
        <LightBtn title="Send a Voucher" onClick={handleClickOpen} />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="gift-voucher"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="GiftVoucher">
              <div className="voucher-text">Gift Vouchers</div>

              <div className="send-text">Send Voucher</div>
              <RedeemVoucherDialog type="giftVoucher" />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <InputField
                placeholder="Friends Name"
                name="name"
                autocomplete="given-name"
                pattern="^[A-Za-z]+[A-Za-z ]*$"
                onKeyDown={(e) => {
                  if (e.key.match(/^[a-zA-Z\s]+$/)) {
                  } else {
                    e.preventDefault();
                  }
                }}
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && formik.errors.name}
              />
              <div className="password">
                <InputField
                  placeholder="Friends Email Address"
                  name="email"
                  type="email"
                  autocomplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />
                <InputField
                  placeholder="Amount to gift"
                  name="amount"
                  type="text"
                  min="0.3"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.amount && formik.errors.amount}
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === 8 ||
                      e.keyCode === 46 ||
                      e.keyCode === 9 ||
                      e.keyCode === 27 ||
                      e.keyCode === 13 ||
                      e.keyCode === 190 ||
                      e.keyCode === 37 ||
                      e.keyCode === 39 ||
                      (e.keyCode >= 48 && e.keyCode <= 57) ||
                      (e.keyCode >= 96 && e.keyCode <= 105)
                    ) {
                    } else {
                      if (e.keyCode === 190) {
                        e.preventDefault();
                      }
                      e.preventDefault();
                    }
                  }}
                />

                <textarea
                  formControlName="msg"
                  placeholder="Add Message?"
                  className="input-field-textarea"
                  rows="4"
                  cols="50"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
                {formik.touched.message && formik.errors.message ? (
                  <div className="error-msg">{formik.errors.message}</div>
                ) : null}
                <div className="payment-text">Payment Method</div>

                {cardList.length > 0 && (
                  <div className="cardName">
                    <div>
                      <div>{cardImage(defaultCard?.cardBrand)}</div>
                    </div>
                    <div className="carddata">
                      <div className="name">{defaultCard?.firstName}</div>
                      <div className="card-number">
                        **** **** **** {defaultCard?.cardLast4Digits}
                      </div>
                    </div>
                    <div className="edit-icon">
                      <PaymentMethodDialog
                        cardLIst={cardList}
                        defaultSelected={defaultCard}
                        select={setDefaultCard}
                      />
                    </div>
                  </div>
                )}
                <div className="total-price">
                  <div className="total-text">Total</div>
                  <div className="price-text">
                    £
                    {formik.values.amount
                      ? parseFloat(formik.values.amount).toFixed(2)
                      : '0.00'}
                  </div>
                </div>

                <div className="purchase-btn">
                  <LightBtn
                    loading={isLoading}
                    title={isLoading ? ' Loading... ' : 'Purchase and Send'}
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
