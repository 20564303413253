import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import DarkBtn from '../../../../components/universal/Buttons/DarkBtn';
import CheckoutField from '../../../../components/universal/InputField/CheckoutField';
import { baseUrl, deviceToken, notify } from '../../../../core/constants';
import { updateGuestUser } from '../../../../redux/actions/guestUser';
import { addLogin } from '../../../../redux/actions/login';
import AxiosAll from '../../../../services/axiosAll';

const CheckoutRegister = () => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const [isConfirmShow, setIsConfirmShow] = React.useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const GuestEmail = searchParams?.get('email');
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const cart_id = params.get('cart_id');
  const isSubscription = params.get('isSubscription');
  let pattern = new RegExp('^(?=.*[A-Z])');
  let pattern2 = new RegExp('(?=.*[-+_!@#$%^&*., ?])');
  let pattterNo = new RegExp('^(?=.*[0-9])');

  const dispatch = useDispatch();

  const initialValues = {
    code: '',
    firstName: '',
    lastName: '',
    email: GuestEmail,
    password: '',
    referralCode: '',
  };
  const validationSchema = Yup.object({
    code: Yup.string().required('Code is required.'),
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string()
      .required('last Name is required.')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    password: Yup.string()
      .required('Password is required.')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Use 8 or more characters with a mix of letters, numbers & symbols'
      ),
  });

  const handleChange = async (event) => {
    await setChecked(event.target.checked);
  };

  const newsLetterEntry = async (values) => {
    const data = { email: GuestEmail, type: 2 };
    try {
      const res = await AxiosAll(
        'post',
        `newsLetter-Entries?deviceType=3&deviceToken=${deviceToken}`,
        data
      );
      notify(res.data.message, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (values) => {
    if (
      !(
        values.password.length > 7 &&
        pattern.test(values.password) &&
        (pattern2.test(values.password) || pattterNo.test(values?.password))
      )
    ) {
      notify('Please fill the password correctly', 'error');
    } else {
      if (checked) {
        newsLetterEntry(values);
      }
      setloading(true);
      var axios = require('axios');
      var data = JSON.stringify({
        code: values.code,
        email: GuestEmail ? GuestEmail : '',
        name: `${values.firstName} ${values.lastName}`,
        password: values.password,
        deviceType: 3,
        deviceToken: guestDetails.token,
        guest_user_id: userData?.user?.id,
        referral_code: values.referralCode,
      });

      var config = {
        method: 'post',
        url: `${baseUrl}user/register`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(async (response) => {
          if (response.data.statusCode == '200') {
            let userDetails = {
              token: response?.data?.result?.userToken,
              user: response?.data?.result?.user,
            };
            dispatch(addLogin(userDetails));
            let user = response?.data?.result?.user;
            dispatch(
              updateGuestUser({
                user: user,
                userToken: userDetails.token,
              })
            );
            await notify(response.data.message, 'success');
            if (cart_id) {
              if (isSubscription) {
                navigate(`/checkout?cart_id=${cart_id}&isSubscription=1`, {
                  replace: true,
                });
              } else {
                navigate(`/checkout?cart_id=${cart_id}&skip=true`, {
                  replace: true,
                });
              }
            } else {
              navigate('/checkout', { replace: true });
            }
          } else {
            notify(response?.data?.message, 'error');
          }
          setChecked(false);
          setloading(false);
        })
        .catch(function (error) {
          console.log(error);
          setChecked(false);
          setloading(false);
          notify(error, 'error');
        });
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="mobilecheckoutregister">
      <div className="email-text">Now let’s make you a GoEthical member</div>
      <div className="continuing-text">We’ve sent a code to </div>
      <div className="forgot-text">
        {GuestEmail}{' '}
        <span
          onClick={() => {
            navigate(
              cart_id
                ? isSubscription
                  ? `/checkout-email?cart_id=${cart_id}&isSubscription=1`
                  : `/checkout-email?cart_id=${cart_id}&skip=true`
                : '/checkout-email',
              { replace: true }
            );
          }}
        >
          {' '}
          Edit
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <CheckoutField
          name={'code'}
          placeholder="Code"
          onBlur={formik.handleBlur}
          value={formik.values.code}
          onChange={formik.handleChange}
          error={formik.errors.code}
        />
        <div className="name-section">
          <CheckoutField
            name={'firstName'}
            placeholder="First Name"
            type="text"
            id="given-name"
            autocomplete="given-name"
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.errors.firstName}
            onKeyDown={(e) => {
              if (
                !(
                  (e.keyCode >= 65 && e.keyCode <= 90) ||
                  (e.keyCode >= 97 && e.keyCode <= 122) ||
                  e.keyCode === 32 ||
                  e.keyCode === 8 ||
                  e.keyCode === 9 ||
                  e.keyCode === 13 ||
                  e.keyCode === 37 ||
                  e.keyCode === 38 ||
                  e.keyCode === 39 ||
                  e.keyCode === 40
                )
              ) {
                e.preventDefault();
              }
            }}
          />
          <CheckoutField
            autocomplete="family-name"
            id="family-name"
            name={'lastName'}
            placeholder="Surname"
            type="text"
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.errors.lastName}
            onKeyDown={(e) => {
              if (
                !(
                  (e.keyCode >= 65 && e.keyCode <= 90) ||
                  (e.keyCode >= 97 && e.keyCode <= 122) ||
                  e.keyCode === 32 ||
                  e.keyCode === 8 ||
                  e.keyCode === 9 ||
                  e.keyCode === 13 ||
                  e.keyCode === 37 ||
                  e.keyCode === 38 ||
                  e.keyCode === 39 ||
                  e.keyCode === 40
                )
              ) {
                e.preventDefault();
              }
            }}
          />
        </div>
        <CheckoutField
          password={true}
          name="password"
          type={isPasswordShow ? 'password' : 'text'}
          placeholder="Password"
          onBlur={formik.handleBlur}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
          isPasswordShow={isPasswordShow}
          setIsPasswordShow={setIsPasswordShow}
        />

        <FormControl className="radio-section">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="characters"
            name="radio-buttons-group"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  checked={formik?.values.password.length > 7 ? true : false}
                />
              }
              label="Must be at least 8 character long"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  checked={
                    pattern2.test(formik?.values.password) ||
                    pattterNo.test(formik?.values?.password)
                      ? true
                      : false
                  }
                />
              }
              label="Contains at least one number or symbol"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  checked={pattern.test(formik?.values.password) ? true : false}
                />
              }
              label="Have one uppercase letter"
            />
          </RadioGroup>
        </FormControl>
        <CheckoutField
          placeholder="Referral Code (Optional)"
          name="referralCode"
          id="referralCode"
          onBlur={formik.handleBlur}
          value={formik.values.referralCode}
          onChange={formik.handleChange}
          error={formik.errors.referralCode}
        />
        <FormGroup className="keep-text">
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="Sign up for emails to get updates from GoEthical on products, offers and more."
          />
        </FormGroup>
        <div className="btn-section-new">
          <DarkBtn type="submit" title={loading ? 'Loading...' : 'Register'} />
        </div>
      </form>
    </div>
  );
};

export default CheckoutRegister;
