import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { notify } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import InputField from '../../universal/InputField';

export default function NotifyProductDialog({ item }) {
  const [open, setOpen] = React.useState(false);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [hide, setHide] = React.useState(false);

  console.log({ item });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //   https://api.staging.goethical.skunktest.work/api/v2/notificationOutOfStockProduct?deviceType=3&deviceToken=df190d92-15ac-4c36-81bd-5d29361457c2

  const notifyMe = async (data) => {
    try {
      const res = await AxiosAll(
        'post',
        `notificationOutOfStockProduct?`,
        data,
        userData.token
      );
      if (res.data.status === 'Success') {
        notify(res?.data?.message?res?.data?.message:'Successfully notified', 'success');
        handleClose();
        formik.handleReset();
        setHide(true);
      } else {
        notify('Something went wrong', 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  });

  console.log('hide', hide);

  const onSubmit = (values) => {
    const data = {
      email: values.email,
      product_id: item.product_id,
      product_inventory_color_id: item.product_inventory_color_id,
      product_inventory_id: item.product_inventory_id,
    };
    notifyMe(data);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      {!hide && (
        <Button variant="outlined" onClick={handleClickOpen}>
          <DarkBtn className="basket-btn1" title="Let me know when its back" />
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="notifyproduct"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="redeem-voucher">
              <div className="voucher-text">
                Enter email to receive notification
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} className="password">
              <InputField
                placeholder="Email Address"
                name="email"
                type="email"
                autocomplete="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email}
              />
              <div className="purchase-btn">
                <LightBtn title="Submit" type="submit" />
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
