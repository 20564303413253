import React from 'react';
import '../../../../scss/universal/_heading.scss';

const MultiColor = ({ coloredTitle, title, black, gray }) => {
  return (
    <>
      <h2 className="main-heading40 graycolor949d8ce3 textcenter" style={{color:black}}>
        {' '}
       <span> {coloredTitle} </span>
        <span className="blackcolor2F2B23" style={{color:gray}}> {title} </span>
      </h2>
    </>
  );
};

export default MultiColor;
