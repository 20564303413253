import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import AddGiftMessageDialog from '../../components/dialogs/AddGiftMessage';
import OutOfStockDialog from '../../components/dialogs/OutOfStockDialog';
import NewCheckoutPageSkeleton from '../../components/Skeleton/Newcheckoutpage/index.';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import Checkbox from '../../components/universal/Checkbox';
import CustomerEmptyBasket from '../../components/universal/CustomerEmptyBasket';
import InputField from '../../components/universal/InputField';
import LatestArrivals from '../../components/universal/LatestArrivals';
import OutOfStock from '../../components/universal/OutOfStock';
import MultiQuantity from '../../components/universal/Quantity/MultiQuantity';
import sweatAlertBox from '../../components/universal/SweetAlertBox';
import {
  baseUrl,
  deviceToken,
  getScreenSize,
  notify,
} from '../../core/constants';
import { addCart } from '../../redux/actions/cartcount';
import { addCartItem } from '../../redux/actions/itemCount';
import AxiosAll from '../../services/axiosAll';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const NewCheckout = () => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [isUpdatecartmsg, setisUpdatecartmsg] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const params = useParams();
  const [cartData, setCartData] = useState([]);
  const [cartList, setCartList] = useState();
  const [loading, setLoading] = useState(true);
  const [virtualIdArray, setVirtualIdArray] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [totalPayable, setTotalPayable] = useState(0.0);
  const [totalShippingCharges, setTotalShippingCharges] = useState(0.0);
  const [totalGiftWrapp, setTotalGiftWrapp] = useState(0);
  const [confirmDel, setConfirmDel] = useState(false);
  const [idToDel, setIdToDel] = useState();
  const location = useLocation();
  const isSubscription = location.pathname.includes('isSubscription');
  const [isQuantityUpdated, setIsQuantityUpdated] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [totalItem, setTotalItem] = useState(0);
  const [arrayOutOfStock, setArrayOutOfStock] = useState([]);
  const [isApicall, setIsApicall] = useState(true);
  const [state, setState] = useState(false);
  const [FeatureBannerData, setFeatureBannerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [giftWrappingSaved, setGiftWrappingSaved] = useState(false);
  const [openOutOfStock, setOpenOutOfStock] = useState(false);
  const [plateForm, setPlateForm] = useState('desktop');

  //Basket count Api
  const getBasketCount = async () => {
    try {
      const res = await AxiosAll(
        'get',
        'basketCount?',
        '',
        userData?.userToken
      );
      const data = res?.data?.result?.totalProducts;
      await dispatch(addCart(data ? data : '0'));
      await localStorage.setItem('basketCount', data !== undefined ? data : 0);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    localStorage.setItem('isBasketCountUpdated', true);
    getBasketCount();
    getFeatureProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = async (id) => {
    const product = cartData?.find((e) => e?.cartItemId === id);
    const data = {
      item_id: id,
      status: product?.isSelectedItem === 1 ? 0 : 1,
    };

    try {
      await AxiosAll(
        'post',
        `cart/select-unselect-item/${product?.cartId}?`,
        data,
        userData?.userToken
      );
      getCartData();
    } catch (error) {
      console.log(error);
    }
  };

  const getCartDataSubscription = async () => {
    try {
      const res = await axios(
        `${baseUrl}cart-subscription?cart_id=${params.id}&page=1&pageSize=8&isCredit=0&deviceType=3&deviceToken=${deviceToken}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      const data = res?.data?.result?.cart?.data;

      setArrayOutOfStock(
        data
          ?.filter(function (e) {
            return findStock(e) === 0;
          })
          ?.map((title) => title?.productDetails?.name)
      );

      await setCartList(res?.data?.result);
      await setCartData(data);
      setTotalPrice(res?.data?.result?.totalPrice);
      setTotalShippingCharges(res?.data?.result?.totalShippingCharges);
      setTotalGiftWrapp(res?.data?.result?.totalGiftWrapping);
      setTotalItem(res?.data?.result?.totalProducts);
      setTotalPayable(res?.data?.result?.totalPayablePrice);
      await setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCartData = async () => {
    try {
      const res = await AxiosAll(
        'get',
        params?.id
          ? `cart?page=${1}&cart_id=${params?.id}&per_page_limit=1000&`
          : `cart?page=${page}&per_page_limit=10000&`,
        '',
        userData?.userToken
      );
      const data = res?.data?.result?.cart?.data;
      setisUpdatecartmsg(false);
      setArrayOutOfStock(
        data
          ?.filter(function (e) {
            return findStock(e) === 0;
          })
          ?.map((title) => title?.productDetails?.name)
      );

      // setArrayOutOfStock([arrayOutOfStock, data?.find((e) => e?.productDetails?.productInventory[0]?.inventoryColors[0]?.stocks == 0)?.productDetails?.name])
      await setCartList(res?.data?.result);
      setTotalPrice(res?.data?.result?.totalPrice);
      setTotalPayable(res?.data?.result?.totalPayablePrice);
      setTotalShippingCharges(res?.data?.result?.totalShippingCharges);
      setTotalGiftWrapp(res?.data?.result?.totalGiftWrapping);
      setTotalItem(res?.data?.result?.totalProducts);
      setPagination(res.data.result.cart);
      await setCartData(data);
      await setLoading(false);
      getBasketCount();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (isSubscription) {
      getCartDataSubscription();
    } else {
      getCartData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userData,
    location.pathname,
    isApicall,
    page,
    isUpdatecartmsg,
    giftWrappingSaved,
    // isQuantityUpdated,
  ]);

  const realCartDelete = async (id) => {
    try {
      const res = await AxiosAll(
        'delete',
        `cart/${id}?`,
        '',
        userData?.userToken
      );
      notify(res.data.message, 'success');
      setConfirmDel(false);
      setLoading(true);
      getCartData();
    } catch (error) {
      console.log(error);
    }
  };

  const virtualCartDelete = async (id) => {
    try {
      const res = await AxiosAll(
        'delete',
        `cart/buy-now-delete/${id?.virtualId}?`,
        '',
        userData?.userToken
      );
    } catch (error) {
      console.log(error);
    }
  };

  const ForDelete = async (value) => {
    //gaDelete(DeletingItem);
    const virtualIdUn = virtualIdArray?.find((e) => e?.realId == `${value}`);
    const isFound = virtualIdArray.some((e) => {
      if (e?.realId === `${value}`) {
        return true;
      }
    });
    if (isFound === true) {
      realCartDelete(value);
      virtualCartDelete(virtualIdUn);
    }
    if (isFound === false) realCartDelete(value);
  };
  useEffect(() => {
    confirmDel && ForDelete(idToDel);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDel]);

  const subscriptionType = (type) => {
    if (type === 1) {
      return <span>Weekly</span>;
    } else if (type === 2) {
      return <span>Monthly</span>;
    } else if (type === 3) {
      return <span>Quarterly</span>;
    } else if (type === 4) {
      return <span>Half Yearly</span>;
    } else if (type === 5) {
      return <span>Yearly</span>;
    }
  };

  const findStock = (item) => {
    let stocksAvailable = item?.productInventoryColor?.stocks;
    return stocksAvailable;
  };

  useEffect(() => {
    if (pagination?.total === 8 && page > 1) {
      setPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.total]);

  const findPrice2 = (item) => {
    let realPrice;
    if (isSubscription) {
      realPrice =
        item?.productInventoryColor?.subscriptionPrice > 0
          ? item?.productInventoryColor?.subscriptionPrice
          : item?.productDetails?.subscriptionPrice;
    } else {
      realPrice =
        item?.productInventoryColor?.price > 0
          ? item?.productInventoryColor?.price
          : item?.productDetails?.price;
    }
    return realPrice?.toFixed(2);
  };

  // eslint-disable-next-line
  useEffect(async () => {
    await dispatch(addCartItem(totalItem ? totalItem : '0'));
    // localStorage.setItem('quantity', totalItem);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItem]);

  //formk
  const initialValues = {
    voucher: '',
  };
  const validationSchema = Yup.object().shape({
    voucher: Yup.string().required('Voucher No. is required'),
  });

  const ReedemVouchar = async (data) => {
    try {
      const res = await AxiosAll(
        'post',
        `voucher/redeemToken?`,
        data,
        userData?.userToken
      );
      notify(res?.data?.message, res?.data?.status.toLowerCase());
      if (res?.data?.statusCode === 200) {
        getCartData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (values) => {
    const data = {
      voucherToken: values?.voucher,
    };
    ReedemVouchar(data);
    formik.handleReset();
    //setOpen(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  //feature products
  const getFeatureProducts = async () => {
    try {
      const res = await AxiosAll(
        'get',
        `feature_products?`,
        '',
        userData?.userToken
      );
      setFeatureBannerData(res?.data?.result);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="newcheckout">
      <OutOfStockDialog
        arrayOutOfStock={arrayOutOfStock}
        totalItem={totalItem}
        open={openOutOfStock}
        setOpen={setOpenOutOfStock}
        plateForm={plateForm}
        state={state}
        setState={setState}
      />
      <Container className="all-screen-container">
        {loading ? (
          <NewCheckoutPageSkeleton />
        ) : Object.entries(cartList).length === 0 ? (
          <CustomerEmptyBasket />
        ) : (
          <Grid container spacing={12}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              xl={8.5}
              className="moblespacetop"
            >
              <div className="top-basket-heading mobileshow">
                <div className="basket-heading ">Basket</div>
                <div className="item-text ">
                  {totalItem == 1 ? `${totalItem} item` : `${totalItem} items`}{' '}
                  <span>|</span>{' '}
                  <span className="item-price">£{totalPrice?.toFixed(2)}</span>
                </div>
              </div>

              {userDetails?.token ? null : (
                <div className="exclusive-box">
                  <div className="exclusive-text">
                    Exclusive offers and benefits for members.
                  </div>
                  <div className="exclusive-text margintext">
                    Become a GoEthical Member to receive offers and benefits.
                    <span
                      onClick={() =>
                        nav(
                          getScreenSize() === 'mobile'
                            ? params?.id
                              ? isSubscription
                                ? `/checkout-email?cart_id=${params?.id}&isSubscription=1`
                                : `/checkout-email?cart_id=${params?.id}&skip=true`
                              : '/checkout-email'
                            : params?.id
                            ? isSubscription
                              ? `/register-checkout?cart_id=${params?.id}&isSubscription=1`
                              : `/register-checkout?cart_id=${params?.id}&skip=true`
                            : '/register-checkout'
                        )
                      }
                    >
                      Join us
                    </span>{' '}
                    or{' '}
                    <span
                      onClick={() =>
                        nav(
                          getScreenSize() === 'mobile'
                            ? params?.id
                              ? isSubscription
                                ? `/checkout-email?cart_id=${params?.id}&isSubscription=1`
                                : `/checkout-email?cart_id=${params?.id}&skip=true`
                              : '/checkout-email'
                            : params?.id
                            ? isSubscription
                              ? `/login-checkout?cart_id=${params?.id}&isSubscription=1`
                              : `/login-checkout?cart_id=${params?.id}&skip=true`
                            : '/login-checkout'
                        )
                      }
                    >
                      Sign in
                    </span>
                  </div>
                </div>
              )}
              <div className="basket-heading mobilehide">Basket</div>
              <table className="newcheckouttable">
                <tbody>
                  {cartData?.length > 0 &&
                    cartData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="table_checkbox mobilehide">
                            {!params?.id && findStock(item) !== 0 && (
                              <div>
                                <Checkbox
                                  {...label}
                                  checked={
                                    item?.isSelectedItem === 1 ? true : false
                                  }
                                  value={item?.cartItemId}
                                  checkedIcon={<CheckCircleIcon />}
                                  icon={<RadioButtonUncheckedIcon />}
                                  onChange={(e) =>
                                    handleSelect(item?.cartItemId)
                                  }
                                />
                              </div>
                            )}
                          </td>

                          {/* Product Image Column  */}
                          <td
                            onClick={() =>
                              nav(
                                item.productDetails?.headerMenuSlug
                                  ? `${
                                      item.productDetails?.headerMenuSlug
                                    }/${item?.productDetails.productSlug
                                      ?.replaceAll(' ', '-')
                                      ?.toLowerCase()}`
                                  : `/${
                                      item.productDetails.tagsAttached[0]
                                        ?.middleTagName
                                        ? item.productDetails.tagsAttached[0]?.middleTagName
                                            ?.toLowerCase()
                                            ?.replaceAll(' ', '-')
                                        : item.productDetails.tagsAttached[0]?.parentName?.toLowerCase()
                                    }/${item?.productDetails.tagsAttached[0]?.tagName
                                      ?.replaceAll(' ', '-')
                                      ?.toLowerCase()}/${item?.productDetails.productSlug
                                      ?.replaceAll(' ', '-')
                                      ?.toLowerCase()}`
                              )
                            }
                            className="table_product_img"
                          >
                            <img
                              loading="lazy"
                              src={item?.productDetails?.images[0]?.image}
                              className="checkoutimg"
                              alt="product"
                            />
                          </td>

                          {/* Product Name Column   */}
                          <td>
                            <div
                              className="product-text"
                              onClick={() =>
                                nav(
                                  item.productDetails?.headerMenuSlug
                                    ? `${
                                        item.productDetails?.headerMenuSlug
                                      }/${item?.productDetails.productSlug
                                        ?.replaceAll(' ', '-')
                                        ?.toLowerCase()}`
                                    : `/${
                                        item.productDetails.tagsAttached[0]
                                          ?.middleTagName
                                          ? item.productDetails.tagsAttached[0]?.middleTagName
                                              ?.toLowerCase()
                                              ?.replaceAll(' ', '-')
                                          : item.productDetails.tagsAttached[0]?.parentName?.toLowerCase()
                                      }/${item?.productDetails.tagsAttached[0]?.tagName
                                        ?.replaceAll(' ', '-')
                                        ?.toLowerCase()}/${item?.productDetails.productSlug
                                        ?.replaceAll(' ', '-')
                                        ?.toLowerCase()}`
                                )
                              }
                            >
                              {item?.productDetails?.name}
                            </div>
                            <div
                              className="sub-product-text cursorpointer"
                              onClick={() =>
                                nav(
                                  `/store/${item?.productDetails?.seller?.nameSlug}`
                                )
                              }
                            >
                              {item?.productDetails?.seller?.userName
                                ? item?.productDetails?.seller?.userName
                                : item?.productDetails?.seller?.name}
                            </div>
                            {/* <div className='sub-product-text'>Light Iron Ore/Cobblestone/Football Grey/Volt</div> */}
                            {isSubscription && cartData[0]?.subscriptionType ? (
                              <div className="sub-product-text">
                                Subscription duration:{' '}
                                <span className="subscription-text-ans">
                                  {subscriptionType(
                                    cartData[0]?.subscriptionType
                                  )}
                                </span>
                              </div>
                            ) : null}

                            {/* size section */}
                            <div className="size-section">
                              {/* colour section */}
                              {item?.productInventoryColor?.colorMaster
                                ?.color && (
                                <div className="size-part ">
                                  Colour{' '}
                                  <span>
                                    {`${item?.productInventoryColor?.colorMaster?.color} `}{' '}
                                  </span>
                                </div>
                              )}

                              {/* size section */}
                              {item?.productInventory?.sizes?.size && (
                                <div className="size-part">
                                  Size{' '}
                                  <span>
                                    {' '}
                                    {`${item?.productInventory?.sizes?.size}`}
                                  </span>{' '}
                                </div>
                              )}
                              {/* Mobile quantity */}
                              <div className="size-part quantityspace">
                                {findStock(item) === 0 ? (
                                  <OutOfStock />
                                ) : (
                                  <MultiQuantity
                                    disable={params?.id ? true : false}
                                    InStock={findStock(item)}
                                    item={item}
                                    productInventory={
                                      item?.productDetails?.productInventory[0]
                                    }
                                    virtualIdArray={virtualIdArray}
                                    setTotalItem={setTotalItem}
                                    setTotalShippingCharges={
                                      setTotalShippingCharges
                                    }
                                    setTotalGiftWrapp={setTotalGiftWrapp}
                                    setTotalPrice={setTotalPrice}
                                    setTotalPayable={setTotalPayable}
                                    // disable={params?.id ? true : false}
                                    isApicall={isApicall}
                                    isQuantityUpdated={isQuantityUpdated}
                                    setIsQuantityUpdated={setIsQuantityUpdated}
                                    setIsApicall={setIsApicall}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="gift-section">
                              <AddGiftMessageDialog
                                setisUpdatecartmsg={setisUpdatecartmsg}
                                item={item}
                                findPrice2={findPrice2(item)}
                                giftWrappingSaved={giftWrappingSaved}
                                setGiftWrappingSaved={setGiftWrappingSaved}
                              />
                              {params?.id ? null : (
                                <buttton
                                  className="gift-option-dialog"
                                  onClick={() => (
                                    sweatAlertBox(
                                      'Remove From Basket',
                                      'Are you sure you want to remove this product from your list?',
                                      setConfirmDel,
                                      'Remove',
                                      'NOT NOW'
                                    ),
                                    setIdToDel(item?.cartItemId)
                                  )}
                                >
                                  Remove
                                </buttton>
                              )}
                            </div>
                          </td>
                          <td className="table_price mobileshow">
                            <div className="table-price-text">
                              £{findPrice2(item)}
                            </div>
                            {!params?.id && findStock(item) != 0 && (
                              <div>
                                <Checkbox
                                  {...label}
                                  checked={
                                    item?.isSelectedItem === 1 ? true : false
                                  }
                                  value={item?.cartItemId}
                                  checkedIcon={<CheckCircleIcon />}
                                  icon={<RadioButtonUncheckedIcon />}
                                  onChange={(e) =>
                                    handleSelect(item?.cartItemId)
                                  }
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              xl={3.5}
              className="summary-section"
            >
              <div className="summary-text">Summary</div>
              {!userDetails?.token || isSubscription ? null : (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="promo-code">
                      Do you have a Promo Code?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div>
                        <form
                          onSubmit={formik.handleSubmit}
                          className="btn-section"
                        >
                          {/* <div> */}
                          <InputField
                            placeholder="Enter code"
                            name="voucher"
                            id="voucher"
                            type="voucher"
                            onBlur={formik.handleBlur}
                            value={formik.values.voucher}
                            onChange={formik.handleChange}
                            error={formik.errors.voucher}
                            // className="inputfield"
                          />
                          {/* </div> */}
                          <div>
                            <button
                              type="submit"
                              className={
                                formik?.values?.voucher
                                  ? 'applybtn applybtnactive'
                                  : 'applybtn'
                              }
                            >
                              Apply
                            </button>
                          </div>
                        </form>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              <div className="price-section">
                <div>
                  <div className="subtotal">Subtotal</div>
                  <div className="subtotal margbott">Estimated Delivery</div>
                  {totalGiftWrapp > 0 ? (
                    <div className="subtotal">Gift Wrapping</div>
                  ) : null}
                </div>
                <div className="subtotal-price">
                  <div className="subtotal subtotal-price">
                    £{totalPrice?.toFixed(2)}
                  </div>
                  <div className="subtotal subtotal-price  margbott">
                    £{totalShippingCharges?.toFixed(2)}
                  </div>
                  {totalGiftWrapp > 0 ? (
                    <div className="subtotal">
                      £{totalGiftWrapp?.toFixed(2)}
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                className="price-section total-price-section"
                style={{ borderBottom: '2.5px solid #e8e8e8' }}
              >
                <div className="total">Total</div>
                <div className="total subtotal-price">
                  £{totalPayable?.toFixed(2)}
                </div>
              </div>
              <div className="mobile-button-section mobilehide">
                {' '}
                <DarkBtn
                  onClick={() => {
                    if (arrayOutOfStock?.length >= 1) {
                      if (arrayOutOfStock?.length === cartData?.length) {
                        notify(
                          `${arrayOutOfStock?.length} ${
                            arrayOutOfStock?.length > 1 ? 'items' : 'item'
                          } ${
                            arrayOutOfStock?.length > 1 ? 'are' : 'is'
                          } out of stock in your basket`,
                          'error'
                        );
                      } else {
                        setPlateForm('desktop');
                        setOpenOutOfStock(true);
                      }
                    } else {
                      userDetails.token
                        ? totalItem > 0
                          ? params?.id
                            ? isSubscription
                              ? nav({
                                  pathname: '/checkout',
                                  search: `?cart_id=${params?.id}&isSubscription=1`,
                                })
                              : nav({
                                  pathname: '/checkout',
                                  search: `?cart_id=${params?.id}`,
                                })
                            : nav({
                                pathname: '/checkout',
                                search: `?selected_all=0&skip=true`,
                              })
                          : notify('You must select items to checkout', 'error')
                        : nav(
                            params?.id
                              ? isSubscription
                                ? `/guest-checkout?cart_id=${params?.id}&isSubscription=1`
                                : `/guest-checkout?cart_id=${params?.id}&skip=true`
                              : '/guest-checkout'
                          );
                    }
                  }}
                  title="Checkout"
                />
              </div>
            </Grid>
          </Grid>
        )}
      </Container>

      {loading ? null : Object.entries(cartList).length == 0 ? null : !state ? (
        <div className="mobile-button-section mobileshow">
          <DarkBtn
            onClick={() => {
              if (arrayOutOfStock?.length >= 1) {
                if (arrayOutOfStock?.length === cartData?.length) {
                  notify(
                    `${arrayOutOfStock?.length} ${
                      arrayOutOfStock?.length > 1 ? 'items' : 'item'
                    } ${
                      arrayOutOfStock?.length > 1 ? 'are' : 'is'
                    } out of stock in your basket`,
                    'error'
                  );
                } else {
                  setPlateForm('mobile');
                  setOpenOutOfStock(true);
                }
              } else {
                userDetails?.token
                  ? totalItem > 0
                    ? params?.id
                      ? isSubscription
                        ? nav({
                            pathname: '/checkout',
                            search: `?cart_id=${params?.id}&isSubscription=1`,
                          })
                        : nav({
                            pathname: '/checkout',
                            search: `?cart_id=${params?.id}`,
                          })
                      : nav({
                          pathname: '/checkout',
                          search: `?selected_all=0&skip=true`,
                        })
                    : notify('You must select items to checkout', 'error')
                  : setState(!state);
              }
            }}
            title="Checkout"
          />
        </div>
      ) : (
        <div
          onClick={() => {
            setState(!state);
          }}
          className="checkoutoverlay mobileshow"
        >
          <div className="logincheckout">
            <DarkBtn
              onClick={() => {
                nav(
                  params?.id
                    ? isSubscription
                      ? `/checkout?cart_id=${params?.id}&isSubscription=1`
                      : `/checkout?cart_id=${params?.id}&skip=true`
                    : '/checkout'
                );
                setState(!state);
              }}
              title="Guest Checkout"
            />
            <DarkBtn
              onClick={() => {
                nav(
                  params?.id
                    ? isSubscription
                      ? `/checkout-email?cart_id=${params?.id}&isSubscription=1`
                      : `/checkout-email?cart_id=${params?.id}&skip=true`
                    : '/checkout-email'
                );
                setState(!state);
              }}
              title="Member Checkout"
            />
          </div>
        </div>
      )}

      {isLoading
        ? null
        : FeatureBannerData[0]?.products?.data?.length > 0 &&
          FeatureBannerData?.map((item, index) => {
            return (
              <div key={index} className="scrollenable">
                <LatestArrivals CustomData={item} type="basket" />
              </div>
            );
          })}
    </div>
  );
};

export default NewCheckout;
