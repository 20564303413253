import Container from '@mui/material/Container';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { images } from '../../assets/_index';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import { GTAG_Key } from '../../core/constants';
import AxiosAll from '../../services/axiosAll';
const OrderComplete = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get('generated_order_id');
  const cart_id = params.get('cart_id');
  const order_id = params.get('order_id');
  const status = params.get('status');
  const is_gift_voucher = params.get('is_gift_voucher');
  const is_subscription = params.get('is_subscription');
  const { userData } = useSelector((state) => state.guestUserReducer);
  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);
  const nav = useNavigate();

  useEffect(() => {
    if (is_gift_voucher == 0 && status == 'failed' && is_subscription == 1) {
      setTimeout(
        () =>
          nav({
            pathname: '/checkout',
            search: `?cart_id=${cart_id}&payment=${status}&skip=true&isSubscription=${is_subscription}`,
          }),
        800
      );
    } else if (
      is_gift_voucher == 0 &&
      status == 'failed' &&
      is_subscription == 0
    ) {
      setTimeout(
        () =>
          nav({
            pathname: '/checkout',
            search: `?cart_id=${cart_id}&payment=${status}&skip=true&isSubscription=${is_subscription}`,
          }),
        800
      );
    }
  }, [window.location.search]);

  useEffect(async () => {
    if (status === 'success' && order_id) {
      try {
        const res = await AxiosAll(
          'get',
          `order/details/${order_id}?is_buyer=1&`,
          '',
          userData?.userToken
        );

        if (res?.data?.statusCode === 200) {
          ReactGA.gtag('event', 'purchase', {
            transaction_id: res?.data?.result?.order?.generatedOrderId,
            currency: 'EUR',
            tax: res?.data?.result?.orderSummary?.vatTotal,
            shipping: res?.data?.result?.orderSummary?.shippingTotal,
            value: res?.data?.result?.order?.totalAmount,
            items: res?.data?.result?.orderItems?.map((item, index) => {
              return {
                item_id: item?.productId,
                item_name: item?.name,
                price: item?.productPrice,
                quantity: 1,
                item_brand: item?.seller?.name,
                item_category: item?.tagsAttached[0]?.parentName
                  ? item?.tagsAttached[0]?.parentName
                  : item?.tagsAttached[0]?.tags?.parent?.parent?.name,
                index: index,
              };
            }),
          });
          if (
            res?.data?.statusCode === 200 &&
            window?.location?.origin === 'https://goethical.com'
          ) {
            ReactGA.gtag('event', 'conversion', {
              send_to: `${GTAG_Key}`,
              value: res?.data?.result?.order?.totalAmount,
              currency: 'EUR',
              transaction_id: res?.data?.result?.order?.generatedOrderId,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [window.location.search]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="OrderComplete">
      <Container className="all-screen-container">
        <div className="order-section">
          <div className="order-heading  main-heading40">
            {status == 'failed'
              ? 'Payment Failed!'
              : ' Thank you for your order!'}
          </div>
          <div className="order-number ">
            {status == 'failed'
              ? 'Payment for last transaction failed due to authorization , please retry payment.'
              : `Order Number: ${id}`}
          </div>
          {status == 'failed' ? null : (
            <>
              <div className="animation-card">
                <div className="animation-text">
                  <img
                    loading="lazy"
                    alt="tree animation"
                    src={images.treegif}
                    className="gif"
                  />
                </div>
              </div>
              <div className="order-heading main-heading40">
                We’ve planted you a tree!
              </div>
              <div className="tree-text">
                To say thanks, we plant a tree with every order. To find out the
                impact your tree has, visit the account section
              </div>
              <div className="back-btn">
                <LightBtn onClick={() => nav('/')} title="Back to Shop" />
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default OrderComplete;
