import { Container } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { images } from '../../../assets/_index'
import { dynamicContent, getScreenSize } from '../../../core/constants'
import MainHeading from '../Heading/MainHeading'
import ProductSlider from '../ProductSlider'

const FeaturedCollections = ({ CustomData }) => {
  const nav = useNavigate();
  const mobile = CustomData?.mobile;
  const tablet = CustomData?.tablet;
  const web = CustomData?.web;
  const defaultSize = CustomData?.default;
  return (
    <div className='featuredCollectionsNew'>
      <img src={images.LineBgFeatured} className="mobilebgnew" />
      <div className="featurednew-section  desktopbgnew">
        <Container className="hidedot sectionpadding2p5  all-screen-container">
          <div className="head-btn">
            <MainHeading  title={dynamicContent(
            getScreenSize(),
            'title',
            mobile,
            tablet,
            web,
            defaultSize
          )}/>
          </div>
          {/* ProductSlider started  */}
          <ProductSlider
            Data={dynamicContent(
              getScreenSize(),
              'shopCollections',
              mobile,
              tablet,
              web,
              defaultSize
            )?.length > 0 ? dynamicContent(
              getScreenSize(),
              'shopCollections',
              mobile,
              tablet,
              web,
              defaultSize
            ) :
              defaultSize?.shopCollections}
            newFeaturedSection

            slideToShowNo={4}
            CountOFCatdTab={4}
          />
        </Container>
      </div>
    </div>
  )
}

export default FeaturedCollections