import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { notify } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import InputField from '../../universal/InputField';
export default function RedeemVoucherDialog({ type, status }) {
  const [open, setOpen] = React.useState(false);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    voucher: '',
  };

  const validationSchema = Yup.object().shape({
    voucher: Yup.string().required('Voucher No. is required'),
  });

  const ReedemVouchar = async (data) => {
    try {
      const res = await AxiosAll(
        'post',
        `voucher/redeemToken?`,
        data,
        userData?.userToken
      );
      notify(
        res?.data?.message,
        res?.data?.statusCode == 102 ? 'error' : 'success'
      );
      formik.handleReset();

      if (res?.data?.statusCode == 200) {
        updatestatus();
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };
  const updatestatus = () => {
    return status(true);
  };

  const onSubmit = (values) => {
    const data = {
      voucherToken: values?.voucher,
    };
    ReedemVouchar(data);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="redeemfriend">
      {type == 'giftVoucher' ? (
        <div onClick={handleClickOpen} className="redeem-text">
          Redeem Vouchers
        </div>
      ) : (
        <DarkBtn title="Redeem Voucher" onClick={handleClickOpen} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="redeem-gift-voucher"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="redeem-voucher">
              <div className="voucher-text">Redeem gift voucher</div>
              <div className="voucher-para">
                Treat friends and family with GoEthical gift vouchers! After
                purchase, we'll do the rest.
              </div>
            </div>

            <div className="password">
              <form onSubmit={formik.handleSubmit}>
                <InputField
                  placeholder="Voucher No."
                  name="voucher"
                  id="voucher"
                  type="voucher"
                  onBlur={formik.handleBlur}
                  value={formik.values.voucher}
                  onChange={formik.handleChange}
                  error={formik.errors.voucher}
                />

                <div className="purchase-btn">
                  <DarkBtn type="submit" title="Redeem" />
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
