import { Container } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { images } from '../../assets/_index';
import ProductCardSkeleton from '../../components/Skeleton/ProductCardSkeleton';
import ProductBtn from '../../components/universal/Buttons/ProductBtn';
import RetailersBtn from '../../components/universal/Buttons/RetailersBtn';
import MainHeading from '../../components/universal/Heading/MainHeading';
import CustomHelmet from '../../components/universal/Helmet';
import BasicPagination from '../../components/universal/Pagination';
import ProductSearchResult from '../../components/universal/ProductSearchResult';
import ShopByValueCard from '../../components/universal/ShopByValueCard';
import {
  baseUrl,
  capitalizeFirstLetter,
  getScreenSize,
  getScreenSizeImagDimention
} from '../../core/constants';

const Retailers = () => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [retailersList, setRetailersList] = useState([]);
  const [loading, setloading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlParams = useParams();
  let params = new URLSearchParams(searchParams);

  let q = urlParams?.query;
  const pageNumber = params.get('page');
  const [page, setPage] = useState(pageNumber ? parseInt(pageNumber) : 1);

  const navigate = useNavigate();

  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, [window.location.pathname, window.location.search]);

  const query = {
    startprice: 0,
    endprice: 1000,
    sortbyprice: 0,
    tags: '',
    query: q,
    feedType: 5,
    metadatas: '',
    records: 24,
  };

  const getRetailers = async () => {
    try {
      const res = await axios(
        `${baseUrl.replace(
          'api/v2/',
          '/'
        )}elastic/api/search_new.php?page=${page}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          method: 'get',
          params: query,
          headers: {
            'Content-Type': 'application/json',
            userToken: userData?.userToken,
          },
        }
      );
      const data = res.data.result;
      setRetailersList(data);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    getRetailers();
  }, [page]);
  const UrlParams = useParams();
  const tabTitle =
    window?.location?.pathname === 'retailers'
      ? capitalizeFirstLetter(UrlParams?.search_query)
      : capitalizeFirstLetter(UrlParams?.page_name?.replaceAll('-', ' '));
  return (
    <>
      <div className="retailers">
        <CustomHelmet
          title={tabTitle}
          description="An ethical marketplace and lifestyle app that empowers shoppers to buy and sell with purpose and protect the planet."
          keyword={tabTitle}
          path={window?.location?.pathname}
        />
        <Container className="all-screen-container">
          {q && <ProductSearchResult query={q} />}
          <div className="headbtn-section">
            <div>
              <MainHeading title="Retailers" />
            </div>
            <div>
              <ProductBtn
                title="Products"
                onClick={() => navigate(`/search/${q}`)}
              />
              <RetailersBtn title="Retailers" />
            </div>
          </div>
          <div className="image-container">
            {loading ? (
              <ProductCardSkeleton type="productPage" />
            ) : (
              retailersList?.data?.map((item, index) => {
                return (
                  <ShopByValueCard
                    key={index}
                    image={
                      item?._source?.seller?.profilePicture
                        ? item?._source?.seller?.profilePicture
                        : images?.usericon
                    }
                    title={item?._source?.userName?item?._source?.userName:item?._source?.sellerName}
                    type="retailer"
                    slug={item?._source?.seller?.nameSlug}
                  />
                );
              })
            )}
          </div>
          {retailersList?.data?.length > 0 && (
            <BasicPagination
              count={retailersList?.lastPage}
              page={page}
              onChange={(e, val) => {
                setPage(val);
                window.scrollTo(0, 0);
                navigate({
                  pathname: location.pathname,
                  search: `?query=${q}&page=${val}`,
                });
              }}
            />
          )}

          {/* <RaiseDisputeDialog/> */}
        </Container>
      </div>
    </>
  );
};

export default Retailers;
