import React, { useState } from 'react';
import { MenuItem, FormControl, Select } from '@mui/material';
import { images } from '../../../../assets/_index';
import { useEffect } from 'react';

const SelectBoxColor = ({ options, getColorValue }) => {
  const [value, setValue] = useState(options[0]?.id);

  useEffect(() => {
    getColorValue(value);
  }, [value]);

  return (
    <div className="selectbox">
      <FormControl className="selectboxwidth">
        <Select
          defaultValue={options[0]?.id}
          style={{
            backgroundImage: `url(${images.errowDown})`,
          }}
          className="select-field"
          name=""
          onChange={(e) => {
            setValue(e.target.value);
            getColorValue(e.target.value);
          }}
        >
          {options?.map((item, index) => {
            return (
              <MenuItem value={item?.id} key={index}>
                {item?.color}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectBoxColor;
