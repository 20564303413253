import { Container, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import MainHeading from '../../universal/Heading/MainHeading';
import MultiColor from '../../universal/Heading/MultiColor';
import ProductSlider from '../../universal/ProductSlider';

const TrendingCollections = ({ CustomData }) => {
  const nav = useNavigate();
  return (
    <div className="trendingcollection">
      <Container className="hidedot sectionpadding3p5vw all-screen-container">
        <div className="head-btn">
          <Grid container spacing={2} className="headingmarbtm">
            <Grid item xs={12} sm={12} md={10} className="">
              <div className="mobilehide">
                <MainHeading title="Trending Collections" />
              </div>
              <div className="mobileshow">
                <MultiColor coloredTitle="Trending" title="Collections" />
              </div>
            </Grid>
            <Grid item xs={12} sm={2} className="textright mobilehide">
              <DarkBtn
                onClick={() => {
                  nav('/shop-collection');
                }}
                title="Shop All"
              />
            </Grid>
          </Grid>
        </div>
        <ProductSlider Data={CustomData} shopcollection slideToShowNo={5} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} className="mobileshow textcenter">
            <DarkBtn
              onClick={() => {
                nav('/shop-collection');
              }}
              title="View All"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default TrendingCollections;
