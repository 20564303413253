import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import * as React from 'react';

export default function ProductCardSkeleton({type}) {
  const skeletonArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <>
    {type=="productPage"?
    skeletonArray?.map((skeleton, index) => {
      return (
    <div spacing={1} key={index} className='skeleton' style={{width:'33%', display:'inline-block'}}> 
      <div className='loopskeleton' style={{width:'100%'}}>
            <Skeleton variant="rectangular" className='imgcard' width='100%' height='250px' />
            <Skeleton variant="text" />
            <Skeleton width="40%"  className='pricesktn'/>
        </div>
    </div>)})
   :
      <div className='skeleton'>
    <Stack spacing={1}>
      <div className='gridskeleton'>
      <div className='loopskeleton' style={{width:'100%'}}>
            <Skeleton variant="rectangular" className='imgcard' width='100%' height='250px' />
            <Skeleton variant="text" />
            <Skeleton width="40%" />
        </div>
       
      </div>
    </Stack>
    </div>}
    </>
  );
}