import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
const CustomHelmet = ({
  title,
  description,
  imageUrl,
  url,
  keyword,
  path,
  metaTitle,
  metaDescription,
}) => {
  const [newTitle, setNewTitle] = useState(title);
  const [newDescription, setNewDescription] = useState(description);

  useEffect(() => {
    if (title?.length > 57) {
      setNewTitle(title?.substring(0, 57) + '...');
    } else {
      setNewTitle(title);
    }
  }, [title]);

  useEffect(() => {
    if (newDescription) {
      if (newDescription?.length > 350) {
        setNewDescription(newDescription?.substring(0, 350) + '...');
      } else {
        setNewDescription(newDescription);
      }
    }
  }, [description]);

  return (
    <Helmet>
      <html lang="en" />
      <base href="/" />
      <title>
        {newTitle !== undefined
          ? `${newTitle}`
          : 'Buy Ethical Products on GoEthical - The Ethical Lifestyle App'}
      </title>
      {keyword ? (
        <meta
          name="Keywords"
          content={
            keyword
              ? `${keyword}`
              : 'Buy Ethical Products on GoEthical - The Ethical Lifestyle App'
          }
        />
      ) : null}
      {title || metaTitle ? (
        <meta name="title" content={metaTitle ? `${metaTitle}` : title} />
      ) : null}
      {description || metaDescription ? (
        <meta
          name="description"
          content={
            metaDescription ? metaDescription : `${description?.slice(0, 169)}`
          }
        />
      ) : null}
      <meta name="theme-color" content="#E6E6FA" />
      <meta name="robot" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <link rel={url ? `${url}` : 'https://goethical.com/'} />
      {path ? (
        <link rel="canonical" href={`${window.location.origin}${path}`} />
      ) : null}{' '}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={metaTitle ? `${metaTitle}` : newTitle}
      />
      {description || metaDescription ? (
        <meta
          property="og:description"
          content={
            metaDescription ? metaDescription : `${description?.slice(0, 169)}`
          }
        />
      ) : null}
      <meta
        property="og:url"
        content={url ? `${url}` : 'https://goethical.com/'}
      />
      <meta property="og:site_name" content="GoEthical" />
      <meta
        property="og:image"
        content={
          imageUrl
            ? `${imageUrl}`
            : 'https://goethical.com/assets/images/header/goethical_logo.png'
        }
      />
      <meta
        property="og:image:secure_url"
        content={
          imageUrl
            ? `${imageUrl}`
            : 'https://goethical.com/assets/images/header/goethical_logo.png'
        }
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1287" />
      <meta property="og:image:height" content="322" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:description"
        content="Shop artisan, shop a wide range of organic, cruelty-free, vegan & sustainable products including, gifts and gift sets, fashion, Featured Designerwear, toys & much more."
      />
      <meta
        name="twitter:title"
        content="Buy Ethical Products on GoEthical - The Ethical Lifestyle App"
      />
      <meta name="twitter:site" content="@GoEthical_App" />
      <meta
        name="twitter:image"
        content="https://goethical.com/assets/images/header/goethical_logo.png"
      />
      <meta name="twitter:creator" content="@GoEthical_App" />
      <meta
        name="google-site-verification"
        content="NaqzW5uOc_mRVrXXI03KhSQY0M95PiN_NWFL7Sujggs"
      />
      <meta name="msvalidate.01" content="1BEA944E9588CBBBD6047C21089D5D07" />
    </Helmet>
  );
};

export default CustomHelmet;
