import { getScreenSize } from "../../../../core/constants"

export const TopBar = ({ topBarData }) => {

    const mobile = topBarData?.mobile
    const tablet = topBarData?.tablet
    const web = topBarData?.web
    const defaultSize = topBarData?.default
    
    const imageview = (deviceSize) => {
        if (deviceSize === 'mobile') {
            if (mobile) {
                return mobile;
            } else {
                return defaultSize;
            }
        } else if (deviceSize === 'tablet') {
            if (tablet) {
                return tablet;
            } else {
                return defaultSize;
            }
        } else if (deviceSize === 'web') {
            if (web) {
                return web;
            } else {
                return defaultSize;
            }
        } else {
            return defaultSize;
        }
    };




    return (<div
        onClick={() =>
            window.open(
                imageview(getScreenSize())?.buttonLink[0]?.filterQuery?.externalLink? imageview(getScreenSize())?.buttonLink[0]?.filterQuery?.externalLink: defaultSize?.buttonLink[0]?.filterQuery?.externalLink,
                '_self'
            )
        }
        className="top-bar"
        style={{
            backgroundImage: `url(${imageview(getScreenSize())?.backgroundImageWebp ? imageview(getScreenSize())?.backgroundImageWebp : imageview(getScreenSize())?.backgroundImage?imageview(getScreenSize())?.backgroundImage:
                defaultSize?.backgroundImageWebp?defaultSize?.backgroundImageWebp:defaultSize?.backgroundImage
                })`,
            cursor: 'pointer',
        }}
    >
        {(imageview(getScreenSize())?.imageWebp !== null || imageview(getScreenSize())?.image!== null) && (
            <img
                loading="lazy"
                src={
                    imageview(getScreenSize())?.imageWebp ? imageview(getScreenSize())?.imageWebp : imageview(getScreenSize())?.image?imageview(getScreenSize())?.image:defaultSize?.imageWebp?defaultSize?.imageWebp:defaultSize?.image
                }
                alt={imageview(getScreenSize())?.title?imageview(getScreenSize())?.title:defaultSize?.title}
                title={imageview(getScreenSize())?.title?imageview(getScreenSize())?.title:defaultSize?.title}
            />
        )}
        <span dangerouslySetInnerHTML={{ __html: imageview(getScreenSize())?.title?imageview(getScreenSize())?.title:defaultSize?.title }} />
    </div>)
}