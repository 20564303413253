import { Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { images } from '../../../../assets/_index';
import { deleteLogin } from '../../../../redux/actions/login';
import { deleteUser } from '../../../../redux/actions/user';
import GiftVoucherDialog from '../../../dialogs/GiftVoucher';
import ReferAFriendDialog from '../../../dialogs/ReferaFriend';
const ViewAccount = () => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const nav = useNavigate();
  return (
    <div className="header">
      <button className="user-icon5 myDIV">
        <button
          onClick={() => window.open('/customer-account', '_blank')}
          className="logged-btn"
        >
          {' '}
          <span className="view_account">
            {' '}
            view
            <br /> account
          </span>{' '}
          <img
            loading="lazy"
            alt="user icon"
            src={images.userIcon}
            className="profile-logged"
          />
        </button>
        <div className="user-menu">
          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <img
                loading="lazy"
                onClick={() => window.open('/customer-account', '_blank')}
                alt="shop By Value image"
                src={images.shopByValeCardImg}
                className="user-icon"
              />
            </Grid>
            <Grid item xs={9} sm={10}>
              <div
                onClick={() => window.open('/customer-account', '_blank')}
                className="user-name heading20"
              >
                {userData?.user?.name}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button
                onClick={() => window.open('/my-orders', '_blank')}
                className="user-icon1"
              ></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <div
                onClick={() => window.open('/my-orders', '_blank')}
                className="user-name1 heading20"
              >
                My Orders
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button
                onClick={() => window.open('/wishlist', '_blank')}
                className="user-icon1"
              ></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <div
                onClick={() => window.open('/wishlist', '_blank')}
                className="user-name1 heading20"
              >
                Saved Products
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button className="user-icon1"></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <ReferAFriendDialog type="header" />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button className="user-icon1"></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <GiftVoucherDialog type="header" />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button className="user-icon1"></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <div
                onClick={() => {
                  dispatch(deleteLogin());
                  dispatch(deleteUser());
                  window.open('/', '_blank');
                }}
                className="user-name1 heading20"
              >
                Sign Out
              </div>
            </Grid>
          </Grid>
        </div>
      </button>
    </div>
  );
};

export default ViewAccount;
