import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/home';
import NewCheckout from '../pages/NewCheckoutPage';
import CheckoutPage from '../pages/NewCheckoutPage/Checkout';
import GuestCheckoutNew from '../pages/NewCheckoutPage/GuestCheckoutNew';
import CheckoutEmail from '../pages/NewCheckoutPage/MobileCheckoutFlow/CheckoutEmail';
import { NotFound } from '../pages/NotFound';
import { NotFoundProduct } from '../pages/NotFoundProduct';
import OrderFailed from '../pages/OrderFailed';
import SingleProductDetails from '../pages/SingleProductDetails';
import WordpressIfream from '../pages/wordPressIfream';

const Checkout = lazy(() => import('../pages/Checkout'));
const CustomerAccount = lazy(() => import('../pages/CustomerAccount'));
const MyOrderSeeAll = lazy(() =>
  import('../pages/CustomerAccount/MyOrderSeeAll')
);
const MySavedItemsSeeAll = lazy(() =>
  import('../pages/CustomerAccount/MySavedItemsSeeAll')
);
const MySubscriptionsSeeAll = lazy(() =>
  import('../pages/CustomerAccount/MySubscriptionsSeeAll')
);
const CustomerSavedCard = lazy(() => import('../pages/CustomerSavedCard'));
const MyAccountMessages = lazy(() => import('../pages/MyAccountMessages'));
const OrderComplete = lazy(() => import('../pages/OrderComplete'));
const Products = lazy(() => import('../pages/Products'));
const Retailers = lazy(() => import('../pages/Retailers'));
const Wishlist = lazy(() => import('../pages/Wishlist'));
const SavedAddresses = lazy(() => import('../pages/SavedAddresses'));
const SearchProduct = lazy(() => import('../pages/SearchProduct'));
const SellerStorefront = lazy(() => import('../pages/SellerStorefront'));
const ShopCollection = lazy(() => import('../pages/ShopCollection'));

// Private routings
const AppRoutes = () => {
  
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/is_reedem_gift_voucher" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/product-not-found" element={<NotFoundProduct />} />
        <Route path="shop-collection" element={<ShopCollection />} />
        <Route path="retailers" element={<Retailers />} />
        <Route path="search/retailers/:query" element={<Retailers />} />
        <Route path="search-product" element={<SearchProduct />} />
        <Route path="wishlist" element={<Wishlist />} />
        <Route
          path=":parentName/:tagName/:productSlug/:generatedProductId"
          element={<SingleProductDetails />}
        />
        <Route
          path=":parentName/:tagName/:productSlug"
          element={<SingleProductDetails />}
        />
        <Route
          path="customer-account-message"
          element={<MyAccountMessages />}
        />
        <Route path="saved-addresses" element={<SavedAddresses />} />
        <Route path="customer-saved-card" element={<CustomerSavedCard />} />
   
        

        <Route path="basket" element={<NewCheckout />} />
        <Route path="basket/:id" element={<NewCheckout />} />
        <Route
          path="basket/:id/isSubscription/products"
          element={<NewCheckout />}
        />
        {/* old routes */}
        <Route path="products/:page_name" element={<Products />} />
        <Route path="products/:page_name/:catId" element={<Products />} />
        <Route path="products" element={<Products />} />
        {/* latest routes  */}
        <Route path="/:page_name" element={<Products />} />
        <Route path="/:page_name_1/:page_name" element={<Products />} />
       

        <Route
          path="search/:search_query"
          element={<SearchProduct />}
        />
      
        <Route path="store/:title" element={<SellerStorefront />} />
        <Route
          path="customer-account"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <CustomerAccount />
            </Suspense>
          }
        />
        <Route path="confirm-order" element={<OrderComplete />} />
        <Route path="checkout" element={<CheckoutPage />} />
        <Route path="my-orders" element={<MyOrderSeeAll />} />
        <Route path="my-saved-items" element={<MySavedItemsSeeAll />} />
        <Route path="my-subscriptions" element={<MySubscriptionsSeeAll />} />
        <Route path="login" element={<Home />} />
        <Route path="wordpress-header" element={<WordpressIfream />} />
        <Route path="order-failed" element={<OrderFailed />} />
        {/* <Route path="new-checkout" element={<NewCheckout />} /> */}
        {/* <Route path="new-checkout-page" element={<CheckoutPage />} /> */}
        <Route path="guest-checkout" element={<GuestCheckoutNew />} />
       
        <Route path="checkout-email" element={<CheckoutEmail />} />
      
        <Route path="/goethical_fees-3" element={<Navigate to="/blog/goethical_fees-3/"  />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
