import { Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { images } from '../../../../assets/_index';
import { deleteLogin } from '../../../../redux/actions/login';
import { deleteUser } from '../../../../redux/actions/user';
import GiftVoucherDialog from '../../../dialogs/GiftVoucher';
import ReferAFriendDialog from '../../../dialogs/ReferaFriend';
import packageEnv from '../../../../../package.json';

const ViewAccount = () => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { user } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const nav = useNavigate();

  const navigateToSeller = () => {
    if (packageEnv?.env === 1) {
      return `https://dev.goethical.seller.skunktest.work/onboarding/dashboard?email=${userData?.user?.email}`;
    } else if (packageEnv?.env === 2) {
      return `https://pre-release.goethical.seller.skunktest.work/onboarding/dashboard?email=${userData?.user?.email}`;
    } else if (packageEnv?.env === 3) {
      return `https://seller.goethical.com/onboarding/dashboard?email=${userData?.user?.email}`;
    }
  };

  return (
    <div className="header">
      <button className="user-icon5 myDIV">
        <button onClick={() => nav('/customer-account')} className="logged-btn">
          {' '}
          <span className="view_account">
            {' '}
            view
            <br /> account
          </span>{' '}
          <img
            loading="lazy"
            alt="user icon"
            title="View account"
            src={images.userIcon}
            className="profile-logged"
          />
        </button>
        <div className="user-menu">
          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2} lg={3}>
              <img
                loading="lazy"
                onError={(event) => {
                  event.target.onerror = '';
                  event.target.src = images.shopByValeCardImg;
                  return true;
                }}
                onClick={() => nav('/customer-account')}
                alt={user?.name ? user?.name : userData?.user?.name}
                title={user?.name ? user?.name : userData?.user?.name}
                src={
                  user?.profilePicture
                    ? user?.profilePicture
                    : userData?.user?.profilePicture
                    ? userData?.user?.profilePicture
                    : images.shopByValeCardImg
                }
                className="user-icon"
              />
            </Grid>
            <Grid item xs={9} sm={10} lg={9}>
              <div
                onClick={() => nav('/customer-account')}
                className="user-name heading20"
              >
                {user?.name ? user?.name : userData?.user?.name}
              </div>
            </Grid>
          </Grid>

          {userData?.user?.isBuyer == 1 ? (
            <Grid container spacing={2} className="card-margin">
              <Grid item xs={3} sm={2}>
                <button
                  onClick={() => window.open(navigateToSeller(), '_self')}
                  className="user-icon1"
                ></button>
              </Grid>
              <Grid item xs={9} sm={10}>
                <div
                  onClick={() => window.open(navigateToSeller(), '_self')}
                  className="user-name1 heading20"
                >
                  Dashboard
                </div>
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button
                onClick={() => nav('/my-orders')}
                className="user-icon1"
              ></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <div
                onClick={() => nav('/my-orders')}
                className="user-name1 heading20"
              >
                My Orders
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button
                onClick={() => nav('/wishlist')}
                className="user-icon1"
              ></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <div
                onClick={() => nav('/wishlist')}
                className="user-name1 heading20"
              >
                Saved Products
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button className="user-icon1"></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <ReferAFriendDialog type="header" />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button className="user-icon1"></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <GiftVoucherDialog type="header" />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="card-margin">
            <Grid item xs={3} sm={2}>
              <button className="user-icon1"></button>
            </Grid>
            <Grid item xs={9} sm={10}>
              <div
                onClick={() => {
                  dispatch(deleteLogin());
                  dispatch(deleteUser());
                  nav('/');
                }}
                className="user-name1 heading20"
              >
                Sign Out
              </div>
            </Grid>
          </Grid>
        </div>
      </button>
    </div>
  );
};

export default ViewAccount;
