import {
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { images } from '../../../assets/_index';
import ConfirmRegistrationDialog from '../../../components/dialogs/ConfirmRegistration';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import CheckoutField from '../../../components/universal/InputField/CheckoutField';
import { baseUrl, deviceToken, notify } from '../../../core/constants';
import AxiosAll from '../../../services/axiosAll';

const RegisterCheckout = () => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [state, setstate] = React.useState(false);
  const [tempToken, settempToken] = React.useState('');
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const [isConfirmShow, setIsConfirmShow] = React.useState(true);
  const nav = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const cart_id = params.get('cart_id');
  const isSubscription = params.get('isSubscription');
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string()
      .required('last Name is required.')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    password: Yup.string()
      .required('Password is required.')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Use 8 or more characters with a mix of letters, numbers & symbols'
      ),
    confirmPassword: Yup.string().when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .required('Confirm password is required.')
        .oneOf([Yup.ref('password')], 'Both password are not match.'),
    }),
    email: Yup.string()
      .required('E-mail address is required.')
      .email('Enter valid email address'),
  });

  const handleChange = async (event) => {
    await setChecked(event.target.checked);
  };

  const newsLetterEntry = async (values) => {
    const data = { email: values?.email, type: 2 };
    try {
      const res = await AxiosAll(
        'post',
        `newsLetter-Entries?deviceType=3&deviceToken=${deviceToken}`,
        data
      );
      notify(res.data.message, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (values) => {
    if (checked) {
      newsLetterEntry(values);
    }
    setloading(true);
    var axios = require('axios');
    var data = JSON.stringify({
      email: values.email,
      name: `${values.firstName} ${values.lastName}`,
      password: values.password,
      password_confirmation: values.confirmPassword,
      deviceType: 3,
      deviceToken: guestDetails.token,
      guest_user_id: userData?.user?.id,
      referral_code: values.referralCode,
    });

    var config = {
      method: 'post',
      url: `${baseUrl}user/signup`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(async (response) => {
        if (response.data.statusCode == '102') {
          await notify(response.data.message, 'error');
        } else {
          await settempToken(response?.data?.result);
          await setstate(true);
          await notify(response?.data?.message, 'success');
          // navigate({
          //   pathname: "/checkout",
          // });
        }
        setChecked(false);
        setloading(false);
      })
      .catch(function (error) {
        console.log(error);
        setChecked(false);
        setloading(false);
        notify(error, 'error');
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="registercheckout">
      <Container className="all-screen-container">
        <div className="register-section">
          <form onSubmit={formik.handleSubmit}>
            <img src={images.mainLogoinner} className="logocheckout" />
            <div className="register-text">Become a GoEthical member</div>
            <div className="register-para">
              Create your GoEthical Member profile and get access to special
              offers, our best products, inspiration and more.
            </div>
            <div className="field-section">
              <CheckoutField
                name={'firstName'}
                placeholder="First Name"
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.errors.firstName}
                onKeyDown={(e) => {
                  if (
                    !(
                      (e.keyCode >= 65 && e.keyCode <= 90) ||
                      (e.keyCode >= 97 && e.keyCode <= 122) ||
                      e.keyCode === 32 ||
                      e.keyCode === 8 ||
                      e.keyCode === 9 ||
                      e.keyCode === 13 ||
                      e.keyCode === 37 ||
                      e.keyCode === 38 ||
                      e.keyCode === 39 ||
                      e.keyCode === 40
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <CheckoutField
                name={'lastName'}
                placeholder="Last Name"
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.errors.lastName}
                onKeyDown={(e) => {
                  if (
                    !(
                      (e.keyCode >= 65 && e.keyCode <= 90) ||
                      (e.keyCode >= 97 && e.keyCode <= 122) ||
                      e.keyCode === 32 ||
                      e.keyCode === 8 ||
                      e.keyCode === 9 ||
                      e.keyCode === 13 ||
                      e.keyCode === 37 ||
                      e.keyCode === 38 ||
                      e.keyCode === 39 ||
                      e.keyCode === 40
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <CheckoutField
                name="email"
                id="email"
                type="email"
                placeholder="Email Address"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
              />
            </div>
            <div className="field-section1">
              <CheckoutField
               password={true}
                name="password"
                type={isPasswordShow ? 'password' : 'text'}
                placeholder="Password"
                onBlur={formik.handleBlur}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.errors.password}
                isPasswordShow={isPasswordShow}
                setIsPasswordShow={setIsPasswordShow}
              />
              <CheckoutField
               password={true}
                name="confirmPassword"
                placeholder="Confirm Password"
                id="confirmPassword"
                autocomplete="new-password"
                type={isConfirmShow ? 'password' : 'text'}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.errors.confirmPassword}
                isPasswordShow={isConfirmShow}
                setIsPasswordShow={setIsConfirmShow}
              />
              <CheckoutField
                placeholder="Referral Code (Optional)"
                name="referralCode"
                id="referralCode"
                onBlur={formik.handleBlur}
                value={formik.values.referralCode}
                onChange={formik.handleChange}
                error={formik.errors.referralCode}
              />
            </div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label="Sign up for emails to get updates from GoEthical on products, offers and more."
                className="keep-text"
              />
            </FormGroup>
            <div className="logging-text">
            By creating an account, you agree to GoEthical’s{' '}
              <span
                onClick={() =>
                  window.open(
                    'https://goethical.com/blog/privacy-policy/',
                    '_self'
                  )
                }
              >
                {' '}
                Privacy Policy
              </span>{' '}
              and{' '}
              <span
                onClick={() =>
                  window.open(
                    'https://goethical.com/blog/terms-and-conditions/',
                    '_self'
                  )
                }
              >
                {' '}
                Terms of Use.
              </span>
            </div>
            <DarkBtn
              type="submit"
              disable={loading}
              title={loading ? 'Loading...' : 'Register'}
            />
          </form>
        </div>
      </Container>
      {state ? (
        <ConfirmRegistrationDialog
          type={2}
          query={
            cart_id
              ? isSubscription
                ? `?cart_id=${cart_id}&isSubscription=1`
                : `?cart_id=${cart_id}&skip=true`
              : ''
          }
          userDatahas={tempToken}
          status={state}
          setState={setstate}
        />
      ) : null}
    </div>
  );
};

export default RegisterCheckout;
