import { Grid } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useNavigate } from 'react-router-dom';

const LeftCategory = ({ subMenu, topPicksContent }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="subnav-content">
        <Grid container spacing={1} className="mobileset-left ">
          {/* <Grid
            item
            xs={2.5}
            className="main_left_menu width49-left-mobile desktopleftrow"
          > */}

          {subMenu?.map((item, index) => {
            // console.log("subMenu",item?.navigationPath)
            return (
              <Grid
                key={index}
                item
                xs={2}
                className="main_left_menu width49-left-mobile desktopleftrow"
              >
                <div key={index} className="desktopleftpad">
                  <div
                    className="top-heading1"
                    onClick={() => {
                      navigate(item?.navigationPath);
                    }}
                  >
                    {' '}
                    {item?.name}{' '}
                  </div>
                  {item?.headerSubMenuChilds.map((subitem, subitemIndex) => {
                    // console.log('headerSubMenuChilds',subitem?.navigationPath)
                    return (
                      <div
                        className="option-heading "
                        key={subitemIndex}
                        onClick={() => {
                          navigate(
                            subitem?.navigationPath
                              ? subitem?.navigationPath.toLowerCase()
                              : `/${subitem?.uniqueId}`
                          );
                        }}
                      >
                        {subitem?.subMenuChildName}
                      </div>
                    );
                  })}
                </div>
              </Grid>
            );
          })}
          {/* </Grid> */}
          <Grid item xs={8} className="width100-left-mobile bor-pad ">
            <div className="top-heading">Top Picks</div>
            <div fxlayout="column">
              <ScrollContainer className="scroll-container">
                <div className="displayset-mobile">
                  {topPicksContent?.map((item, index) => {
                    const price = () => {
                      if (item.productInventory) {
                        if (
                          item?.productInventory[0]?.inventoryColors[0]
                            ?.subscriptionPrice > 0
                        ) {
                          return item?.productInventory[0]?.inventoryColors[0]
                            ?.subscriptionPrice;
                        } else {
                          return item?.productInventory[0]?.inventoryColors[0]
                            ?.price;
                        }
                      } else {
                        if (item?.subscriptionPrice > 0) {
                          return item?.subscriptionPrice;
                        } else {
                          return item?.price;
                        }
                      }
                    };

                    return (
                      <div
                        key={index}
                        onClick={() => {
                          navigate(
                            item?.headerMenuSlug
                              ? `${item?.headerMenuSlug}/${item?.productSlug}`
                              : `/${
                                  item?.tagsAttached[0]?.middleTagName
                                    ? item?.tagsAttached[0]?.middleTagName?.replace(
                                        /\s/g,
                                        '-'
                                      )
                                    : item?.tagsAttached[0]?.parentName
                                        ?.replace(/\s/g, '-')
                                        ?.toLowerCase()
                                }/${item?.tagsAttached[0]?.tagName
                                  ?.replace(/\s/g, '-')
                                  ?.toLowerCase()}/${item?.productSlug
                                  ?.replace(/\s/g, '-')
                                  ?.toLowerCase()}`
                          );
                        }}
                        className="image-container"
                      >
                        {item?.images.map((img, index) => {
                          if (img.isCover) {
                            return (
                              <img
                                loading="lazy"
                                alt={item?.name}
                                title={item?.name}
                                draggable="false"
                                key={index}
                                className="img-row "
                                src={img.image}
                              />
                            );
                          }
                        })}
                        <div className="product-name"> {item?.name}</div>
                        <div className="product-price">
                          £ {price()?.toFixed(2)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </ScrollContainer>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LeftCategory;
