import React from 'react';
import { Button } from '@mui/material';

const CategoryBtn = ({ category }) => {
  return (
    <>
      <Button className="catbtn">{category}</Button>
    </>
  );
};

export default CategoryBtn;
