import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  baseUrlV1,
  getScreenSize,
  getScreenSizeImagDimention,
  sortBy,
} from '../../../core/constants';
import CategoryFilterSkeleton from '../../Skeleton/CategoryFilterSkeleton';
import ShopByValueFilterSkeleton from '../../Skeleton/ShopByValueFilterSkeleton';

//for the icon change
const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}
    >
      <div className="expandIconWrapper">
        <AddCircleOutlineIcon />
      </div>
      <div className="collapsIconWrapper">
        <DoNotDisturbOnOutlinedIcon />
      </div>
    </Box>
  );
};
const DynamicFIlters = ({
  getFilterValues,
  setMobileOpen,
  page,
  Categories,
  loadState,
}) => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { token } = useSelector((state) => state.guestReducer.guestDetails);
  const location = useLocation();
  const nav = useNavigate();
  const pathparams = useParams();
  const search = location.search;
  // const [imageLoad, setimageLoad] = useState(false);
  // extract tags from search query
  // const params = new URLSearchParams(search);
  // console.log(params.get('tags')?.replace('?', ''))
  // console.log(params.get('metadatas'))
  // console.log(params.get('sortbyprice'))
  const tags = search
    ?.split('&')
    ?.filter((item) => item?.includes('tags'))[0]
    ?.replace('tags=', '')
    ?.replace('?', '');

  const metadatas = search
    ?.split('&')
    ?.filter((item) => item?.includes('metadatas'))[0]
    ?.replace('metadatas=', '')
    ?.replace('?', '');

  const sortbyprice = search
    ?.split('&')
    ?.filter((item) => item?.includes('sortbyprice'))[0]
    ?.replace('sortbyprice=', '');

  const [badges, setBadges] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    tags: tags ? (tags === '' ? '0' : tags) : '0',
    metadatas: metadatas ? metadatas : '',
    sortbyprice: sortbyprice ? parseInt(sortbyprice) : -1,
  });

  useEffect(() => {
    if (location.pathname.includes('shop-by-value')) {
      badges.map((item) => {
        const name = item.name.toLowerCase()?.replace(' ', '-');
        const urlName = location.pathname
          ?.split('/')[2]
          ?.replace('shop-by-value-', '');
        if (name === urlName) {
          setFilters((prev) => {
            return {
              ...prev,
              metadatas: item?.id,
            };
          });
        }
      });
    }
  }, [location.pathname, , badges]);

  useEffect(() => {
    if (tags === undefined && metadatas === undefined) {
      setFilters({
        tags: '0',
        metadatas: '',
        sortbyprice: -1,
      });
    }
  }, [location.pathname]);

  const getBadges = async () => {
    setLoading(true);
    try {
      const res = await axios(
        `${baseUrlV1}product/badges?deviceType=3&deviceToken=${token}&screen_type=${getScreenSizeImagDimention()}&platform=${getScreenSize()}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      const data = res?.data?.result;
      setBadges(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getBadges();
  }, []);

  useEffect(() => {
    getFilterValues(filters);
  }, [filters]);

  const handleCategoryChange = (e) => {
    nav(`${e}`);
    if (window.innerWidth < 920) {
      setMobileOpen(false);
    }
  };

  const handleMetadataChange = (item) => {
    if (location.pathname.includes('shop-by-value')) {
      const name = item.name.toLowerCase().replace(' ', '-');
      nav({
        pathname: `/shop-by-value-${name}`,
      });
    } else {
      setFilters({ ...filters, metadatas: item.id });
      nav({
        pathname: `${location.pathname}`,
        search: `${createSearchParams({
          ...filters,
          page: 1,
          metadatas: item.id,
        })}`,
      });
      if (window.innerWidth < 920) {
        setMobileOpen(false);
      }
    }
  };

  const handleSortByPriceChange = (e) => {
    setFilters({
      ...filters,
      sortbyprice: e.target.value,
    });
    nav({
      pathname: `${location.pathname}`,
      search: `${createSearchParams({
        ...filters,
        sortbyprice: e.target.value,
        page: 1,
      })}`,
    });
    if (window.innerWidth < 920) {
      setMobileOpen(false);
    }
  };

  return (
    <div className="filters">
      <Accordion defaultExpanded className="accordianbox categoryfilters">
        <AccordionSummary
          className="mainfilterheading iconset"
          expandIcon={<CustomExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {Categories?.name &&
          pathparams?.page_name &&
          pathparams?.page_name_1 ? (
            <Typography className="filterheading">
              More Sub Categories of{' '}
              {Categories?.name ? Categories?.name : 'Categories'}{' '}
            </Typography>
          ) : (
            <Typography className="filterheading">
              More {Categories?.name ? Categories?.name : 'Categories'}{' '}
              Categories
            </Typography>
          )}
        </AccordionSummary>

        {
          loadState ? (
            <CategoryFilterSkeleton />
          ) : (
            <Accordion
              key={Categories?.id}
              expanded={true}
              id={Categories?.id}
              onChange={() => handleCategoryChange(Categories?.navigation_path)}
              defaultExpanded={true}
              className="accordianbox inneraccord"
            >
              <AccordionSummary
                className="firstcat2"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header1"
              >
                <Typography className="filterbody ">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label1"
                    name="radio-buttons-group1"
                    // defaultChecked={Categories?.is_selected?true:false}
                    value={
                      Categories?.navigation_path === window?.location?.pathname
                        ? Categories?.navigation_path
                        : ''
                    }
                  >
                    <FormControlLabel
                      className="valuefix"
                      value={Categories?.navigation_path}
                      control={<Radio />}
                      label={`${
                        Categories?.childrens?.length > 0 ? 'All ' : ''
                      }${Categories?.name}`}
                      onChange={(e) => handleCategoryChange(e.target.value)}
                    />
                  </RadioGroup>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="firstcat3">
                <Typography className="filterbody inneraccord">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group2"
                    value={window?.location?.pathname}
                  >
                    {Categories?.childrens?.map((childs, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          className="valuefix"
                          value={childs?.navigation_path}
                          control={<Radio />}
                          label={`${childs?.name}`}
                          onChange={(e) => handleCategoryChange(e.target.value)}
                        />
                      );
                    })}
                  </RadioGroup>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
          //   )
          // })
        }
      </Accordion>
      <Accordion
        defaultExpanded
        className="accordianbox categoryfilters bordertop"
      >
        <AccordionSummary
          className="mainfilterheading iconset"
          expandIcon={<CustomExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="filterheading">Filter by values</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="filterbody">
            {Loading ? (
              <ShopByValueFilterSkeleton />
            ) : (
              badges.length > 0 && (
                <ul className="catfilter">
                  {badges?.map((item, index) => {
                    return (
                      <li
                        className="cursorpointer"
                        key={index}
                        onClick={() => handleMetadataChange(item)}
                      >
                        {/* <Skeleton
                          variant="circular"
                          width="60px"
                          height="60px"
                          style={{ display: imageLoad ? 'none' : 'block' }}
                        /> */}
                        <img
                          // onLoad={() => setimageLoad(true)}
                          alt={item.name}
                          title={item.name}
                          loading="lazy"
                          src={item.image}
                          // style={{ display: !imageLoad ? 'none' : 'block' }}
                        />
                        <p className="catname">{item.name}</p>
                      </li>
                    );
                  })}
                </ul>
              )
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded
        className="accordianbox categoryfilters bordertop"
      >
        <AccordionSummary
          className="mainfilterheading iconset"
          expandIcon={<CustomExpandIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="filterheading">Sort by</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="filterbody addspace">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={-1}
              name="radio-buttons-group"
              value={filters?.sortbyprice}
            >
              {sortBy.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio />}
                  label={item.name}
                  onChange={(e) => handleSortByPriceChange(e)}
                />
              ))}
            </RadioGroup>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default DynamicFIlters;
