import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { CrawerLink } from '../CrawlerLink';
const ShopCard = ({
  title,
  image,
  id,
  type,
  item,
  slug,
  position,
  sectionId,
}) => {
  const navigate = useNavigate();

  const title1 = title?.replace(/\s/g, '-').toLowerCase();
  const navigationFunction = () => {
    if (type == 'latest') {
      ReactGA.gtag('event', 'add_to_cart', {
        currency: 'EUR',
        value: item?.price,
        items: [
          {
            item_id: item?.id,
            item_name: title,
            price: item?.price,
            quantity: 1,
            item_brand: item?.seller?.name,
            item_category: item?.tagsAttached[0]?.parentName
              ? item?.tagsAttached[0]?.parentName
              : item?.tagsAttached[0]?.tags?.parent?.parent?.name,
            index: position,
          },
        ],
      });
      navigate({
        pathname: item?.headerMenuSlug
          ? `${item?.headerMenuSlug}/${item?.productSlug}`
          : `/${
              item?.tagsAttached[0]?.middleTagName
                ? item?.tagsAttached[0]?.middleTagName
                    ?.replace(/\s/g, '-')
                    .toLowerCase()
                : item?.tagsAttached[0]?.parentName
                ? item?.tagsAttached[0]?.parentName
                : item?.tagsAttached[0]?.tags?.parent?.parent?.name
                    ?.replace(/\s/g, '-')
                    .toLowerCase()
            }/${
              item?.tagsAttached[0]?.tagName
                ? item?.tagsAttached[0]?.tagName
                    ?.replace(/\s/g, '-')
                    ?.toLowerCase()
                : item?.tagsAttached[0]?.tags?.name
                    ?.replace(/\s/g, '-')
                    ?.toLowerCase()
            }/${item?.productSlug}`,
      });
    } else {
      if (type === 'retailer') {
        navigate({
          pathname: `/store/${slug}`,
        });
      } else {
        navigate({
          pathname: `/shop-by-value-${title1}`,
        });
      }
    }
  };

  const price = () => {
    if (item?.productInventory) {
      if (
        item?.productInventory[0]?.inventoryColors[0]?.subscriptionPrice > 0
      ) {
        return item?.productInventory[0]?.inventoryColors[0]?.subscriptionPrice;
      } else {
        return item?.productInventory[0]?.inventoryColors[0]?.price;
      }
    } else {
      if (item?.subscriptionPrice > 0) {
        return item?.subscriptionPrice;
      } else {
        return item?.price;
      }
    }
  };
 

  return (
    <div
      onClick={() => {
        navigationFunction();
      }}
      className="shopbyvaluecard cursorpointer"
    >
      {type == 'latest' ? (
        <CrawerLink
          path={
            item?.headerMenuSlug
              ? `${item?.headerMenuSlug}/${item?.productSlug}`
              : `/${
                  item?.tagsAttached[0]?.middleTagName
                    ? item?.tagsAttached[0]?.middleTagName
                        ?.replace(/\s/g, '-')
                        .toLowerCase()
                    : item?.tagsAttached[0]?.parentName
                    ? item?.tagsAttached[0]?.parentName
                    : item?.tagsAttached[0]?.tags?.parent?.parent?.name
                        ?.replace(/\s/g, '-')
                        .toLowerCase()
                }/${
                  item?.tagsAttached[0]?.tagName
                    ? item?.tagsAttached[0]?.tagName
                        ?.replace(/\s/g, '-')
                        ?.toLowerCase()
                    : item?.tagsAttached[0]?.tags?.name
                        ?.replace(/\s/g, '-')
                        ?.toLowerCase()
                }/${item?.productSlug}`
          }
        />
      ) : null}
      {type == 'retailer' ? <CrawerLink path={`/store/${slug}`} /> : null}
      {type != 'retailer' && type != 'latest' ? (
        <CrawerLink path={`/shop-by-value-${title1}`} />
      ) : null}
      <img
        alt={title}
        title={title}
        className={image ? 'shop-icon' : 'shop-icon placeholder-img'}
        loading="lazy"
        src={image}
      />
      <div className="icon-name">{title}</div>
      <div className='price'>£ {item?.productInventory&&price()?.toFixed(2)}</div>
    </div>
  );
};

export default ShopCard;
