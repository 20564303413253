import Rate from '../Rating';
const TestimonialsCard = ({ review, name,rating }) => {
  return (
    <>
      <div className="testimonials-card">
        <div className="card ">
          <div className="card_inner">
            <div className="star-section textcenter">
              <Rate show={rating?rating:0}  />
              
            </div>
            <h3 className="name-text">{name}</h3>
            <p className="name-para">{review}</p>
            <div className="last-text">
              {/* <img src={images.trustpilotLogo} alt=" trust pilot logo" className="trustpilot" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialsCard;
