import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { baseUrl, deviceToken, notify } from '../../../core/constants';
import { addCart } from '../../../redux/actions/cartcount';
import AxiosAll from '../../../services/axiosAll';
import ProductCardSkeleton from '../../Skeleton/ProductCardSkeleton';
import CategoryBtn from '../Buttons/CategoryBtn';
import DarkBtn from '../Buttons/DarkBtn';
import LightBtn from '../Buttons/LightBtn';
import { CrawerLink } from '../CrawlerLink';
import Rate from '../Rating';

const ProductCard = ({
  productName,
  productPrice,
  subscriptionPrice,
  image,
  productId,
  tag,
  item,
  position,
  showRatingAndDelivery,
}) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.guestUserReducer);
  const [isLoadingBasket, setIsLoadingBasket] = useState(false);
  const [isLoadingBuy, setIsLoadingBuy] = useState(false);
  const navigate = useNavigate();

  const addToBasket = async (data) => {
    try {
      const res = await axios.post(
        `${baseUrl}cart?deviceType=3&deviceToken=${deviceToken}`,
        data,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      if (res.data.status === 'Success') {
        notify(res.data.message, 'success');
        // navigate(`/basket`);
      } else {
        notify(res.data.message, 'error');
      }
      getBasketCount();
    } catch (error) {
      console.log(error);
    }
  };

  const buyNow = async (data) => {
    try {
      const res = await axios.post(
        `${baseUrl}cart/buy-now?deviceType=3&deviceToken=${deviceToken}`,
        data,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      if (res.data.status === 'Success') {
        navigate(`/basket/${res.data.result.cartId}`);
      } else {
        notify(res.data.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBasketCount = async () => {
    try {
      const res = await AxiosAll(
        'get',
        'basketCount?',
        '',
        userData?.userToken
      );
      const data = res?.data?.result?.totalProducts;
      await dispatch(addCart(data ? data : '0'));
      await localStorage.setItem('basketCount', data != undefined ? data : 0);
    } catch (error) {
      console.log(error);
    }
  };
  const getProductDetail = async (type) => {
    //google Analytics
    ReactGA.gtag('event', 'add_to_cart', {
      currency: 'EUR',
      value: productPrice,
      items: [
        {
          item_id: productId,
          item_name: productName,
          price: subscriptionPrice ? subscriptionPrice : productPrice,
          quantity: 1,
          item_brand: item?.seller?.name,
          item_category: item?.tagsAttached[0]?.parentName
            ? item?.tagsAttached[0]?.parentName
            : item?.tagsAttached[0]?.tags?.parent?.parent?.name,
          index: position,
        },
      ],
    });

    if (type === 'buyNow') {
      setIsLoadingBuy(true);
    } else {
      setIsLoadingBasket(true);
    }
    try {
      const res = await AxiosAll(
        'get',
        `product/${productId}?`,
        '',
        userData?.userToken
      );
      const data = res?.data?.result?.product;
      if (
        data.isSubscription === 1 ||
        data.shippingMethods.length > 1 ||
        data.cartInventory.length > 1 ||
        data.isAlcoholBeverage === 1 ||
        data?.cartInventory[0]?.sizes?.length > 1
      ) {
        navigate(
          `/${
            middleTagName ? middleTagName : parentName
          }/${tagName}/${productSlug}`
        );
      } else {
        const payload = {
          deliveryType: data?.shippingMethods[0]?.id,
          giftMessage: '',
          inventoryColorId:
            data?.cartInventory[0]?.sizes[0]?.productInventoryId,
          is_gift_voucher: 0,
          is_gift_wrapping: '0',
          personalization: '',
          productId: data?.id,
          stocks: 1,
          subscription_type: '',
        };
        if (type === 'basket') {
          addToBasket(payload);
        } else {
          buyNow(payload);
        }
      }
      if (type === 'buyNow') {
        setIsLoadingBuy(false);
      } else {
        setIsLoadingBasket(false);
      }
      getBasketCount();
    } catch (error) {
      console.log(error);
      if (type === 'buyNow') {
        setIsLoadingBuy(false);
      } else {
        setIsLoadingBasket(false);
      }
    }
  };

  const parentName = item?.tagsAttached[0]?.parentName
    ? item?.tagsAttached[0]?.parentName?.replaceAll(' ', '-')?.replaceAll("'", '')?.toLowerCase()
    : item?.tagsAttached[0]?.tags?.parent?.parent?.name
        ?.replaceAll(' ', '-')
        ?.toLowerCase();
  const middleTagName = item?.tagsAttached[0]?.middleTagName
    ? item?.tagsAttached[0]?.middleTagName
        ?.toLowerCase()
        ?.replaceAll(' ', '-')?.replaceAll("'", '')
        ?.toLowerCase()
    : item?.tagsAttached[0]?.tags?.parent?.parent?.name
        ?.replaceAll(' ', '-')
        ?.toLowerCase();
  const tagName = item?.tagsAttached[0]?.tagName
    ? item?.tagsAttached[0]?.tagName?.toLowerCase()?.replaceAll(' ', '-')?.replaceAll("'", '')
    : item?.tagsAttached[0]?.tags?.name?.replaceAll(' ', '-')?.replaceAll("'", '')?.toLowerCase();
  const productSlug = item?.productSlug?.replaceAll(' ', '-')?.toLowerCase();

  const price = () => {
    if (item.productInventory) {
      if (
        item?.productInventory[0]?.inventoryColors[0]?.subscriptionPrice > 0
      ) {
        return item?.productInventory[0]?.inventoryColors[0]?.subscriptionPrice;
      } else {
        return item?.productInventory[0]?.inventoryColors[0]?.price;
      }
    } else {
      if (item?.subscriptionPrice > 0) {
        return item?.subscriptionPrice;
      } else {
        return item?.price;
      }
    }
  };

  return item || image ? (
    <div className=" productcard">
      <div
        onClick={() => {
          ReactGA.gtag('event', 'select_item', {
            items: [
              {
                item_id: productId,
                item_name: productName,
                price: productPrice,
                item_brand: item?.seller?.name,
                item_category: item?.tagsAttached[0]?.parentName
                  ? item?.tagsAttached[0]?.parentName
                  : item?.tagsAttached[0]?.tags?.parent?.parent?.name, // Product category (string).,
                index: position,
              },
            ],
            item_list_name: 'homepage',
            item_list_id: 'homepage',
            currency: 'EUR',
          });

          navigate(
            item?.headerMenuSlug
              ? `${item?.headerMenuSlug}/${productSlug}`
              : `/${
                  middleTagName ? middleTagName?.trim() : parentName.trim()
                }/${tagName?.trim()}/${productSlug}`
          );
        }}
        className="img-background-row cursorpointer "
      >
        <CrawerLink
          path={`/${
            middleTagName ? middleTagName?.trim() : parentName.trim()
          }/${tagName?.trim()}/${productSlug}`}
        />
        {/* //this change is in review */}
        <CategoryBtn
          category={
            item?.tagsAttached[0]?.parentName
              ? item?.tagsAttached[0]?.parentName
              : item?.tagsAttached[0]?.tags?.parent?.parent?.name
              ? item?.tagsAttached[0]?.tags?.parent?.parent?.name
              : item?.tagsAttached[0]?.tags?.name
          }
        />

        <img
          title={productName}
          alt={productName}
          loading="lazy"
          className="img-row1"
          src={image}
        />
      </div>
      <div className="title-price">
        <div className="product-name1">{productName}</div>
        <div className="addnewstar">
          <div className="product-price1"> £{price()?.toFixed(2)} </div>
          <div className="star-section">
            {showRatingAndDelivery && item?.avgProductRating !== 0 && (
              <Rate
                defaultRating={item?.avgProductRating}
                // show={item?.avgProductRating}
              />
            )}
            {showRatingAndDelivery && item?.avgProductRating !== 0 && (
              <span className="review">
                ({item?.totalProductRating}{' '}
                {`${item?.totalProductRating > 1 ? 'Reviews' : 'Review'}`})
              </span>
            )}
          </div>
        </div>

        <div class="seller-heading heading20 newdelivery">
          {/* Delivery by <span className="newdatetext">Friday, 8 July</span> */}
          {showRatingAndDelivery && (
            <div>
              Delivery by{' '}
              <span className="newdatetext">
                {moment(item?.deliveredBy?.time).format('dddd, DD MMM')}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="button-section">
        <DarkBtn
          title="Add to Basket"
          loading={isLoadingBasket}
          onClick={() => {
            getProductDetail('basket');
          }}
        />
        <LightBtn
          title="Buy Now"
          onClick={() => getProductDetail('buyNow')}
          loading={isLoadingBuy}
        />
      </div>
    </div>
  ) : (
    <ProductCardSkeleton />
  );
};

export default ProductCard;
